import { useEffect, useState, useRef } from "react";
import axios from "axios"

import dstyles from "./dashboardstyles.module.css"


const IndUser = ({ param, indInfo, handleInd, indNav, changeIndNav, search_control }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [properties, setProperties] = useState([])
	const [inspections, setInspections] = useState([])
	const [services, setServices] = useState([])


	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		const initLoad = async () => {
			setIsLoading(true)

			const data = await axios.get(`/api/admin/properties/${param._id}/${indInfo._id}`, axios_config);

			setProperties(data.data.user_properties)
			setInspections(data.data.user_inspections)
			setServices(data.data.user_services)

			setIsLoading(false)
		};
		initLoad();
	}, []);


	return (
		<div className={dstyles.indsite_container}>
			<div className={`${dstyles.indsite_popup_set}`}>

				<div className={dstyles.indsite_inner}>
					<button className={dstyles.closePopup} onClick={() => handleInd('close')}><i className="fa-solid fa-x"></i></button>

					<h1 className={dstyles.medium_font}>User details</h1>
					<p className={dstyles.smaller_font}>Please find the specific details for this user.</p>

					<div className={dstyles.indsite_nav}>
						<div className={dstyles.sitenav}>
							<button className={`${dstyles.sitenav_box} ${indNav == 'Properties' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Properties')}><p>Properties</p></button>
							<button className={`${dstyles.sitenav_box} ${indNav == 'Inspections' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Inspections')}><p>Inspections</p></button>
							<button className={`${dstyles.sitenav_box} ${indNav == 'Services' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Services')}><p>Services</p></button>
						</div>
					</div>

					{indNav == "Properties" &&
						<>
							{isLoading
								?
									<div className={dstyles.load}>
						  				<hr /><hr /><hr /><hr />
					  				</div>
								:
									<div className={dstyles.sites_tablecontainer}>
										<div className={`${dstyles.sites_controls} ${dstyles.indsite_controls_wh}`}>
											<div className={`${dstyles.sites_searchcontainer} ${dstyles.autowidth}`}>
												<div className={dstyles.sites_search}>
													<i className="fa-solid fa-magnifying-glass"></i>
													<input
													    type="text"
													    className={dstyles.smaller_font}
													    placeholder="Search for any of the column headings..."
													    onKeyUp={(event) => search_control(event, "ind_table")}
													/>
												</div>
											</div>
										</div>

										<div className={dstyles.table_container}>
											<table className={dstyles.sites_table} id="ind_table">
											    <thead className={dstyles.sitesind_thead}>
											      <tr className={`${dstyles.table_rowth} ${dstyles.tr_only}`}>
											      	<th className={dstyles.small_font}>Address</th>
											      </tr>
											    </thead>
											    <tbody className={dstyles.indtbody}>
									    			{properties.length > 0
									    				? 
									    				<>
										    				{properties.map((prop) => {
										    					return (
															    	<tr className={`${dstyles.sitesind_tr} ${dstyles.tr_only}`}>
															    		<td className={dstyles.td_overflow}><p>{prop.address}</p></td>
															    	</tr>
														    	)
														    })}
													    </>
									    				: <h1 className={`${dstyles.medium_font} ${dstyles.create_first2}`}>This account does not have any properties yet!</h1>
									    			}
											    </tbody>
											</table>
										</div>
									</div>
							}
						</>
					}

					{indNav == "Inspections" &&
						<>
							{isLoading
								?
									<div className={dstyles.load}>
						  				<hr /><hr /><hr /><hr />
					  				</div>
								:
									<div className={dstyles.sites_tablecontainer}>
										<div className={`${dstyles.sites_controls} ${dstyles.indsite_controls_wh}`}>
											<div className={`${dstyles.sites_searchcontainer} ${dstyles.autowidth}`}>
												<div className={dstyles.sites_search}>
													<i className="fa-solid fa-magnifying-glass"></i>
													<input
													    type="text"
													    className={dstyles.smaller_font}
													    placeholder="Search for any of the column headings..."
													    onKeyUp={(event) => search_control(event, "ind_table")}
													/>
												</div>
											</div>
										</div>

										<div className={dstyles.table_container}>
											<table className={dstyles.sites_table} id="ind_table">
											    <thead className={dstyles.sitesind_thead}>
											      <tr className={`${dstyles.table_rowth} ${dstyles.tr_only}`}>
											      	<th colspan="1" className={dstyles.small_font}>Reference</th>
											      	<th colspan="3" className={dstyles.small_font}>Address</th>
											      </tr>
											    </thead>
											    <tbody className={dstyles.indtbody}>
									    			{inspections.length > 0
									    				? 
									    				<>
										    				{inspections.map((insp) => {
										    					return (
															    	<tr className={`${dstyles.sitesind_tr} ${dstyles.tr_only}`}>
															    		<td className={dstyles.td_overflow} colspan="1"><p>{insp.inspection_name}</p></td>
															    		<td className={dstyles.td_overflow} colspan="3"><p>{insp.property}</p></td>
															    	</tr>
														    	)
														    })}
													    </>
									    				: <h1 className={`${dstyles.medium_font} ${dstyles.create_first2}`}>This account does not have any inspections yet!</h1>
									    			}
											    </tbody>
											</table>
										</div>
									</div>
							}
						</>
					}

					{indNav == "Services" &&
						<>
							{isLoading
								?
									<div className={dstyles.load}>
						  				<hr /><hr /><hr /><hr />
					  				</div>
								:
									<div className={dstyles.sites_tablecontainer}>
										<div className={`${dstyles.sites_controls} ${dstyles.indsite_controls_wh}`}>
											<div className={`${dstyles.sites_searchcontainer} ${dstyles.autowidth}`}>
												<div className={dstyles.sites_search}>
													<i className="fa-solid fa-magnifying-glass"></i>
													<input
													    type="text"
													    className={dstyles.smaller_font}
													    placeholder="Search for any of the column headings..."
													    onKeyUp={(event) => search_control(event, "ind_table")}
													/>
												</div>
											</div>
										</div>

										<div className={dstyles.table_container}>
											<table className={dstyles.sites_table} id="ind_table">
											    <thead className={dstyles.sitesind_thead}>
											      <tr className={`${dstyles.table_rowth} ${dstyles.tr_only}`}>
											      	<th colspan="1" className={dstyles.small_font}>Reference</th>
											      	<th colspan="1" className={dstyles.small_font}>Type</th>
											      	<th colspan="3" className={dstyles.small_font}>Address</th>
											      </tr>
											    </thead>
											    <tbody className={dstyles.indtbody}>
									    			{services.length > 0
									    				? 
									    				<>
										    				{services.map((serv) => {
										    					return (
															    	<tr className={`${dstyles.sitesind_tr} ${dstyles.tr_only}`}>
															    		<td className={dstyles.td_overflow} colspan="1"><p>{serv.service_name || "-"}</p></td>
															    		<td className={dstyles.td_overflow} colspan="1"><p>{serv.service_type || "-"}</p></td>
															    		<td className={dstyles.td_overflow} colspan="3"><p>{serv.property}</p></td>
															    	</tr>
														    	)
														    })}
													    </>
									    				: <h1 className={`${dstyles.medium_font} ${dstyles.create_first2}`}>This account does not have any services yet!</h1>
									    			}
											    </tbody>
											</table>
										</div>
									</div>
							}
						</>
					}
				</div>
			</div>
		</div>
    )
}

export default IndUser;