import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import moment from "moment-timezone"

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import mylologo from '../../../images/mylologo.svg'
import styles from "./AdminUsers.module.css"
import Forbidden from '../../Forbidden';
import IndTechnician from './IndTechnician';
import PopUpTradie from './PopUpTradie';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={`${styles.sidebar_account}`}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/admin/home/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-house ${styles.sidebar_icon}`} />
						</div>
					</Link>
				
					<Link to={`/admin/users/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-users ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/technicians/${param._id}`}>
						<div className={`${styles.sidebar_box} ${styles.sidebar_active}`}>
							<i className={`fa-solid fa-screwdriver-wrench ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/payments/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/map/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-map ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link className={styles.disabled_link}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gear ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

const DashboardPage = ({ param, navType, changeNavType, search_control, allUsers, formatDate, allJobs, indControls, indTradie, createTradie, handleNewTradie }) => {
	return (
		<div className={`${styles.page_container}`}>
			<div className={`${styles.sites_container} ${(indTradie || createTradie) && styles.hidden_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						{navType == 'Tradies' &&
							<button onClick={() => handleNewTradie('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add tradie</p>
							</button>
						}
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Tradies' && styles.sitenav_active}`} onClick={() => changeNavType('Tradies')}><p>Tradies</p></button>
					</div>
				</div>

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => search_control(event, "main_table")}
								/>
							</div>
						</div>
					</div>

					<div className={styles.table_container}>
						<table className={styles.sites_table} id="main_table" align="center">						    
						    {navType == "Tradies" &&
						    	<>
							    <thead>
							      <tr className={`${styles.table_rowth} ${styles.table_heading_custom}`}>
		                  <th>Tradies</th>
		                  <th>Expertise</th>
		                  <th>Verified</th>
		                  <th>Status</th>
		                  <th>Last login</th>
		                  <th>Jobs today</th>
		                  <th>Total jobs</th>
							      </tr>
							    </thead>
						    	<tbody>
		                <>
		                  {allUsers ?
		                    <>
		                      {allUsers.map((IU, index) => {
		                        return (
		                          <tr onClick={() => indControls('open', IU)} className={styles.hover_styling}>
		                            <td><p>{IU.first_name} {IU.last_name}</p></td>
		                            <td><p>{IU.expertise}</p></td>
		                            <td><p>{IU.verified ? <i className={`fa-solid fa-check ${styles.green_tick}`}></i> : <i className={`fa-solid fa-xmark ${styles.red_mark}`}></i>}</p></td>
		                            <td><p>{IU.active ? "Active" : "Inactive"}</p></td>
		                            <td><p>{IU.last_login ? formatDate(IU.last_login, true) : '-'}</p></td>
		                            <td>
										<p>
										  {(
										    () => {
										      const filteredJobs = allJobs.filter((job) => {
										        let dateInMelbourne = moment.tz("Australia/Melbourne");
										        let jobDate = moment(job.scheduled_timestamp).tz('Australia/Melbourne');
										        let sameDay = dateInMelbourne.format('D') === jobDate.format('D') &&
										                      dateInMelbourne.format('MM') === jobDate.format('MM') &&
										                      dateInMelbourne.format('YYYY') === jobDate.format('YYYY');

										        return (job.tradieId.toString() === IU._id.toString() && sameDay);
										      });

										      return `${filteredJobs.length} ${filteredJobs.length === 1 ? 'job' : 'jobs'}`;
										    }
										  )()}
										</p>
									</td>
		                            <td><p>{allJobs.filter(job => job.tradieId.toString() === IU._id.toString()).length === 1 ? `${allJobs.filter(job => job.tradieId.toString() === IU._id.toString()).length} job` : `${allJobs.filter(job => job.tradieId.toString() === IU._id.toString()).length} jobs`}</p></td>
		                          </tr>
		                        );
		                      })}
		                    </>
		                  :
		                    <div className={styles.load}>
		                      <hr />
		                      <hr />
		                      <hr />
		                      <hr />
		                    </div>
		                  }
		                </>
								</tbody>
								</>
							}
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}


const Technicians = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [UserData, setUserData] = useState({});
	const [isLoading, setIsLoading] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(null)
	const [authStatusLoading, setIsAuthStatusLoading] = useState(null)
	
	const [validationError, setValidationError] = useState(null);
	const [navType, setNavType] = useState('Tradies');

	const [allUsers, setAllUsers] = useState(null);
	const [allJobs, setAllJobs] = useState(null);
	const [showTools, setShowTools] = useState(null);
	const [showAdminTools, setShowAdminTools] = useState(false);
	const [indTradie, setIndTradie] = useState(null);
	const [indNav, setIndNav] = useState("Details");
	const [addEvent, setAddEvent] = useState(false)
	const [newEvent, setNewEvent] = useState({
		event_users: [],
		title: '',
		event_dates: [],
		comments: ''
	})
	const [newEventAddress, setNewEventAddress] = useState(null)
	const [createTradie, setCreateTradie] = useState(false)
	const [newTradieData, setNewTradieData] = useState({})
	const [changingStatus, setChangingStatus] = useState(false)

	const [deleteActivated, setDeleteActivated] = useState(false)
	const [jobsDelete, setJobsDelete] = useState({})
	const [cleanPostMessage, setCleanPostMessage] = useState(null)


	const navigate = useNavigate();

	const param = useParams();
	const url = `/api/admin/technicians/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};


	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					
					setUserData(data.data.user_data);
					setAllUsers(data.data.all_users) 
					setAllJobs(data.data.all_jobs) 

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}
				setIsLoading(false)

			} catch (error) {
				console.log(error)
			}
		};
		initLoad();
	}, []);


	const changeNavType = (type) => {
		setNavType(type)
	}


	const search_control = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = document.getElementById(tableId).children[1].children;

	    for (let i = 0; i < target_div.length; i++) {
	        let txtValues = [];
	        for (let j = 0; j < target_div[i].children.length; j++) {
                if (target_div[i].children[j].className.includes('status')) {
                    // handle status
                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
                    txtValues.push(txtValue.toUpperCase());
                } else {
	            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
	            	txtValues.push(txtValue.toUpperCase());
	            }
	        }

	        let showItem = false;
	        txtValues.forEach((value) => {
	            if (value.indexOf(filter) > -1) {
	                showItem = true;
	            }
	        });

	        if (showItem) {
	            target_div[i].style.display = "table";
	        } else {
	            target_div[i].style.display = "none";
	        }
	    }
	};

	function formatDate(dateString, showTime) {
	    const dateObj = new Date(dateString);

	    let options = {
	        year: 'numeric',
	        month: '2-digit',
	        day: '2-digit',
	        timeZone: 'Australia/Melbourne'
	    };

	    if (showTime) {
	        options.hour = '2-digit';
	        options.minute = '2-digit';
	        options.hour12 = false;
	    }

	    const formattedDate = dateObj.toLocaleString('en-AU', options);
	    return formattedDate;
	}

	const formatOptions = (options) => {
		let options_arr = []
		for (let o=0; o < options.length; o++) {
			options_arr.push({label: options[o], value: options[o]})
		}

		return options_arr
	}

	function isValidEmail(email) {
	  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	  return emailRegex.test(email);
	}

	function calculateCompletedJobs(job) {
	    let totalTrueJobTypes = 0;
	    let totalCompleted = 0;

	    // console.log(job)

	    // Iterate through the jobTypes keys
	    for (const key in job.jobTypes) {
	    	// if (job.sf_id === "a00Oa000002vAWFIA2") {
	    	// 	console.log(job.jobTypes[key], key, job.completed[key])
	    	// }

	        // Check if jobType is true
	        if (job.jobTypes[key] === true) {
	            totalTrueJobTypes++;

	            // Check if the same jobType is completed
	            if (job.completed[key] === true) {
	                totalCompleted++;
	            }
	        }
	    }

	    return `${totalCompleted}/${totalTrueJobTypes} completed`;
	}

	const handleShowAdminTools = (control, nav) => {
		switch (control) {
			case 'set':
				setShowAdminTools(true)
				break;
			case 'close':
				setShowAdminTools(false);
				break;
			case 'navigate':
				switch (nav) {
					case 'users':
						navigate(`/admin/users/${param._id}`);
						break;
					case 'technicians':
						navigate(`/admin/technicians/${param._id}`);
						break;
				}
				break;
		}
	}

	const changeIndNav = async (type) => {
		setIndNav(type)
	}

	const indControls = async (control, ind) => {
		switch (control) {
			case 'open':
				setIndTradie(ind)
				break;
			case 'close':
				setIndTradie(null)
				setIndNav("Details")
				break;
			case 'edit':
				setCreateTradie(true);
				setNewTradieData({
					first_name: indTradie.first_name,
					last_name: indTradie.last_name,
					email: indTradie.email,
					expertise: indTradie.expertise,
					license_number: indTradie.license_number,
					cognito_id: indTradie.cognito_id,

					edit: true,
					tradieID: indTradie._id
				})

				setIndNav("Details")
				setIndTradie(null)
				break;
			case 'add_event':
				setAddEvent(true)
				setNewEvent({...newEvent, event_users: [{label: `${indTradie.first_name} ${indTradie.last_name}`, value: `${indTradie.first_name} ${indTradie.last_name}`}]})
				break;
			case 'event_close':
				setNewEvent({
					event_users: [],
					title: '',
					event_dates: [],
					comments: ''
				})
				setNewEventAddress(null)
				setAddEvent(false)
				break;
			case 'clean':
				let confirm_clean = window.confirm("Are you sure you would like to delete all jobs that are marked as complete on Salesforce?")
				if (confirm_clean) {
					setIsLoading(true)
					let deleteCount = await axios.post(`/api/admin/technicians/cleanJobs/${param._id}`, {
						tradie: indTradie
					});

					setIsLoading(false)

					setCleanPostMessage(deleteCount.data.deletedAmt);
					setTimeout(() => {
					  window.location.reload(false);
					}, 5000);
				}
				break;
		}
	}


	const handleNewEvent = async (control, name, input) => {
		switch (control) {
			case 'set':
				let newEventDup = { ...newEvent }

				if (name === "event_dates1") {
					newEventDup['event_dates'][0] = input
				} else if (name === "event_dates2") {
					newEventDup['event_dates'][1] = input
				} else if (name === "event_users") {
					newEventDup['event_users'] = input
				} else {
					newEventDup[name] = input.target.value
				}

				setNewEvent(newEventDup)
				break;
			case 'submit':
				if (!(newEvent.hasOwnProperty('title')) || newEvent['title'] == '') {
					setValidationError('Please enter a title');
					return false;
				}

				if (!newEventAddress || newEventAddress === '') {
					setValidationError('Please select an address');
					return false;
				}

				if (newEvent.event_dates.length !== 2) {
					setValidationError('Please select both a start and finish date/time');
					return false;
				}

				if (!newEvent.event_dates[0] || newEvent.event_dates[0] === '') {
					setValidationError('Please select a start date/time');
					return false;
				}

				if (!newEvent.event_dates[1] || newEvent.event_dates[1] === '') {
					setValidationError('Please select a finish date/time');
					return false;
				}

				if (!(newEvent.event_dates[1] > newEvent.event_dates[0])) {
					setValidationError('The finish time can not be before the start time.');
					return false;
				}

				await axios.post(`/api/admin/technicians/customEvent/${param._id}`, {
					tradie: indTradie,
					event: newEvent,
					eventAddress: newEventAddress
				});
				window.location.reload(false);
				break;
		}
	}

	const handleNewEventAddress = (input) => {
		setNewEventAddress(input.formatted_address)
	}


	const handleCustomEvent = async (control, ind) => {
		switch (control) {
			case 'delete':
				let confirm_delete = window.confirm("Are you sure you would like to delete this custom event?")
				if (confirm_delete) {
	  				await axios.post(`/api/admin/technicians/deleteCustom/${param._id}`, {
	  					event_id: ind.event_id
	  				});
	  				window.location.reload(false);
				}
				break;
			case 'edit':
				setAddEvent(true)


				setNewEvent({
					event_users: [{label: `${indTradie.first_name} ${indTradie.last_name}`, value: `${indTradie.first_name} ${indTradie.last_name}`}],
					title: ind.title,
					event_dates: [new Date(ind.start), new Date(ind.end)],
					comments: ind.comments,
					edit: true,
					eventID: ind.event_id
				})
				setNewEventAddress(ind.property)
				// route back to the admin component to edit the event 
				break;
		}
	}

	const handleNewTradie = async (control, name=null, input=null) => {
		switch (control) {
			case 'open':
				setCreateTradie(true)
				break;
			case 'close':
				if (Object.keys(newTradieData).length > 0) {
					const confirm_close = window.confirm(`Are you sure you would like to discard this new tradie?`)
					if (confirm_close) {
						setCreateTradie(false);

						// RESET ADD FORM DATA
						setNewTradieData({})
						setJobsDelete({})
						setValidationError(null)
					}
				} else {
					setCreateTradie(false);
					setNewTradieData({})
					setJobsDelete({})
					setValidationError(null)
				}
				break;
			case 'set':
				if (name === "expertise") {
					setNewTradieData({...newTradieData, [name]: input.value})
				} else {
					setNewTradieData({...newTradieData, [name]: input.target.value})
				}
				
				break
			case 'submit':
				if (!(newTradieData.hasOwnProperty('first_name')) || newTradieData['first_name'] == '') {
					setValidationError(`Please enter the tradie's first name.`)
					return false;
				}

				if (!(newTradieData.hasOwnProperty('last_name')) || newTradieData['last_name'] == '') {
					setValidationError(`Please enter the tradie's last name.`)
					return false;
				}

				if (!(newTradieData.hasOwnProperty('email')) || newTradieData['email'] == '') {
					setValidationError(`Please enter the tradie's email.`)
					return false;
				} else {
					if (!(isValidEmail(newTradieData.email))) {
						setValidationError(`Please enter a valid email address.`)
						return false;
					}
				}

				if (!(newTradieData.hasOwnProperty('cognito_id')) || newTradieData['cognito_id'] == '') {
					setValidationError(`Please enter the cognito ID.`)
					return false;
				}

				if (!(newTradieData.hasOwnProperty('expertise')) || newTradieData['expertise'] == '') {
					setValidationError(`Please select the tradie's expertise.`)
					return false;
				}

				if (!(newTradieData.hasOwnProperty('license_number')) || newTradieData['license_number'] == '') {
					setValidationError(`Please enter the tradie's license number.`)
					return false;
				}

				setIsAuthStatusLoading(true)
				setIsLoading(true)
				setValidationError(null)
				await axios.post(`/api/admin/technicians/newTradie/${param._id}`, {
					tradieData: newTradieData
				});
				window.location.reload(false);
				break;
		}
	}

	const changeStatus = async (control, type=null, ind=null, status=null) => {
		switch (control) {
			case 'toggle':
				setChangingStatus(!changingStatus)
				break;
			case 'set':
				const confirm_status = window.confirm(`Are you sure you would like to change the status to ${status}?`)
				if (confirm_status) {
					await axios.post(`/api/admin/technicians/status/${param._id}`, {
						type: type,
						ind: ind,
						status: status
					});
					window.location.reload(false)
				}
				break;
		}
	}


	const handleDelete = async (control, id=null, input=null) => {
		switch (control) {
			case 'toggle':
				setJobsDelete({})
				setDeleteActivated(!deleteActivated)
				break;
			case 'set':
				let jobsDeleteDup = {...jobsDelete}

				if (jobsDeleteDup.hasOwnProperty(id)) {
					if (input.target.checked === true) {
						jobsDeleteDup[id] = input.target.checked
					} else {
						delete jobsDeleteDup[id]
					}
				} else {
					jobsDeleteDup[id] = input.target.checked
				}

				setJobsDelete(jobsDeleteDup)
				break;
			case 'submit':
				if (Object.keys(jobsDelete).length === 0) {
					window.alert("Please select at least 1 job to delete")
					return false;
				} else {
					let confirm_bulkdelete = window.confirm(`You are about to delete ${Object.keys(jobsDelete).length} jobs. Are you sure you would like to procced?`)
					if (confirm_bulkdelete) {
						await axios.post(`/api/admin/technicians/bulkdelete/${param._id}`, {
							jobsDelete: jobsDelete
						});
						window.location.reload(false)
					}
				}
				break;
		}
	}


	return (
		<>	
			<Offline>
				<div className="offline_container">
					<div className="offline_inner animate__animated animate__slideInDown">
						<i className="fa-solid fa-heart-crack"></i>
						<div className="offline_content">
							<p className="offline_title">You are offline</p>
							<p className="offline_subtitle">Please check your internet connect.</p>
						</div>
					</div>
				</div>
			</Offline>


			{isLoading
				? <div className={styles.load}>
					  <hr /><hr /><hr /><hr />
				  </div>
				: isAuthenticated === null
					? <div className={styles.load}>
						  <hr /><hr /><hr /><hr />
					  </div>
	        : isAuthenticated 
				  ? <div className={styles.main_container}>
							<NavigationBar {...{ param, handleLogout }} />
							<DashboardPage {...{ param, navType, changeNavType, search_control, allUsers, formatDate, allJobs, indControls, indTradie, createTradie, handleNewTradie }} />

							{indTradie && <IndTechnician {...{ allUsers, indTradie, changeIndNav, indNav, allJobs, formatDate, calculateCompletedJobs, indControls, search_control, addEvent, handleNewEvent, newEventAddress, newEvent, validationError, handleNewEventAddress, handleCustomEvent, formatOptions, changeStatus, changingStatus, deleteActivated, handleDelete, cleanPostMessage }} />}
							{createTradie && <PopUpTradie {...{ param, validationError, handleNewTradie, newTradieData }} />}
					</div>
					: <Forbidden/>
			}
		</>
	);
};


export default Technicians;
