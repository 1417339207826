import { useEffect, useState, useRef } from "react";
import axios from "axios";
import _ from 'lodash';

import styles from './utilstyles.module.css';


const PopUpSettings = ({ param, handleActions, userData }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: ""
  })


  useEffect(() => {
    const handleLoad = async () => {
      if (userData) {
        setFormData({
          first_name: userData.first_name || "",
          last_name: userData.last_name || "",
          email: userData.email || "",
          phone: userData.phone || "",
        })
      }
    }

    handleLoad()
  }, []);

  const handlePopup = async (control, name?, input?) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          let sendObj = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            phone: formData.phone,
            _id: userData._id
          }

          await axios.post(`/api/dashboard/${param._id}/editDetails`, sendObj);
          window.location.reload()
        }

        setInnerLoading(false)
        break;
      case 'set':
        formDataDup[name] = input.target.value
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setFormData({
        first_name: userData.first_name || "",
        last_name: userData.last_name || "",
        email: userData.email || "",
        phone: userData.phone || "",
      })
      setValidationError(null)

      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)
        setFormData({
          first_name: userData.first_name || "",
          last_name: userData.last_name || "",
          email: userData.email || "",
          phone: userData.phone || "",
        })
        setValidationError(null)
        handleActions('close')
      }
    }

  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validationTest = async () => {
    if (page === 1) {
      let startingForm = {
        first_name: userData.first_name || "",
        last_name: userData.last_name || "",
        email: userData.email || "",
        phone: userData.phone || "",
      }

      if (_.isEqual(startingForm, formData)) {
        setValidationError("You did not change any details")
        return false;
      } else {
        if (!(formData.hasOwnProperty('first_name')) || formData['first_name'] === '') {
          setValidationError("You did enter your first name.");
          return false;
        }

        if (!(formData.hasOwnProperty('last_name')) || formData['last_name'] === '') {
          setValidationError("You did enter your last name.");
          return false;
        }

        if (!(formData.hasOwnProperty('email')) || formData['email'] === '') {
          setValidationError("You did enter your email.");
          return false;
        } else {
          if (!(isValidEmail(formData.email))) {
            setValidationError(`You did not enter a valid email.`)
            return false;
          }
        }

        if (!(formData.hasOwnProperty('phone')) || formData['phone'] === '') {
          setValidationError("You did enter your phone number.");
          return false;
        }
      }
    }

    setValidationError(null)
    return true
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_auto} ${styles.popup_auto_set}`}>
      <div className={`${styles.popup_heading}`}>
        <div>
          <p className={styles.mediumer_text}>Change user infomation</p>
          <p className={styles.small_text}>Use this module to change your user information.</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  <div className={`${styles.text_grid} ${styles.mb3}`}>
                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>First name</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.first_name} onChange={(input) => handlePopup("set", "first_name", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Last name</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.last_name} onChange={(input) => handlePopup("set", "last_name", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Email</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.email} onChange={(input) => handlePopup("set", "email", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Phone</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.phone} onChange={(input) => handlePopup("set", "phone", input)}/>
                    </div>
                  </div>

                </>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>
      </div>
    </div>
  );
};

export default PopUpSettings;