import { useEffect, useState, useRef } from "react";
import axios from "axios";

import styles from './utilstyles.module.css';
import { Slider } from '@mui/material';


const PropertiesTable = ({ param, handlePopup, formData }) => {
  const [key, setKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [nodes, setNodes] = useState([])
  const [nodesOG, setNodesOG] = useState([])
  const [search, setSearch] = useState("")


  useEffect(() => {
    const handleLoad = async () => {
      setIsLoading(true)
      const url = `/api/hub/main/${param._id}/properties`;
      const role_token = localStorage.getItem('role_token');
      const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

      setNodes(data.data.dataMapped)
      setNodesOG(data.data.dataMapped)
      setIsLoading(false)
    }

    handleLoad()
  }, [key]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    let nodesDup = [...nodesOG]
    let nodesFiltered;

    if (event.target.value === "") {
      setNodes(nodesDup)
    } else {
      nodesFiltered = nodesDup.filter(node => node.address.toLowerCase().includes(event.target.value.toLowerCase()))

      setNodes(nodesFiltered)
    }
  };

  return (
    <>
      <div className={styles.table_search}>
        <div className={styles.search_container}>
          <i className={`fa-solid fa-magnifying-glass ${styles.medium_text}`}></i>
          <input
            type="text"
            placeholder="Search for anything in this table..."
            className={styles.mid_text}
            value={search}
            onChange={(event) => handleSearch(event)}
          />
        </div>
      </div>

      <div className={styles.service_grid}>
        {isLoading
          ?
            <div className={styles.load}>
              <hr />
              <hr />
              <hr />
              <hr />
            </div> 
          :
            <>
              {nodes.map((p, index) => {
                return (
                  <button className={`${styles.service_item} ${styles.property_item} ${(formData['property_info'] && formData['property_info']['address']) === p.address && styles.service_active}`} onClick={() => handlePopup("set", "property_info", {address: p.address, sf_id: p.sf_id})}>
                    <p className={styles.mid_text}>{p.address}</p>
                  </button>
                )
              })}
            </>
          }
      </div>
    </>
  )
}


const FinanceForm = ({ param, handleActions }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({})


  const headings = {
    2: {
      title: "Request a finance loan",
      sub: "In order to request a finance loan, please follow the request guide below."
    },

    3: {
      title: "Loan terms",
      sub: "Please describe the loan amount and the loan payment terms."
    },

    4: {
      title: "Select a property",
      sub: "Select the property you would like this loan for."
    },
  }

  const handlePopup = async (control, name?, input?, target=false) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'next':
        let valTest = await validationTest()
        if (valTest) {
          setPage(page+1)
        }

        break;
      case 'back':
        setValidationError(null)
        setPage(page-1)
        break;
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          let sendObj = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            phone: formData.phone,
            amount: formData.amount,
            loan_terms: formData.loan_terms,
            property: formData.property_info.address,
            prop_sfid: formData.property_info.sf_id
          }

          await axios.post(`/api/services/${param._id}/finance`, {
            finance_data: sendObj,
          });

          window.location.reload()
        }

        setInnerLoading(false)
        break;
      case 'set':
        if (target) {
          formDataDup[name] = input.target.value
        } else {
          formDataDup[name] = input
        }
        
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setFormData({})
      setValidationError(null)
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)
        setFormData({})
        setValidationError(null)
        handleActions('close')
      }
    }

  }


  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validationTest = async () => {
    if (page === 2) {
      if (!(formData.hasOwnProperty('first_name')) || formData['first_name'] === '') {
        setValidationError("You did enter your first name.");
        return false;
      }

      if (!(formData.hasOwnProperty('last_name')) || formData['last_name'] === '') {
        setValidationError("You did enter your last name.");
        return false;
      }

      if (!(formData.hasOwnProperty('email')) || formData['email'] === '') {
        setValidationError("You did enter your email.");
        return false;
      } else {
        if (!(isValidEmail(formData.email))) {
          setValidationError(`You did not enter a valid email.`)
          return false;
        }
      }

      if (!(formData.hasOwnProperty('phone')) || formData['phone'] === '') {
        setValidationError("You did enter your phone number.");
        return false;
      }
    }

    if (page === 3) {
      if (!(formData.hasOwnProperty('amount')) || formData['amount'] === '') {
        setValidationError("You did select a loan amount.");
        return false;
      } else {
        if (formData.amount === 0) {
          setValidationError("Please select a loan amount more than 0.");
          return false;
        }
      }

      if (!(formData.hasOwnProperty('loan_terms')) || formData['loan_terms'] === '') {
        setValidationError("Please enter the loan terms.");
        return false;
      }
    }

    if (page === 4) {
      if (!(formData.hasOwnProperty('property_info'))) {
        setValidationError("You did not select a property.");
        return false;
      }
    }

    setValidationError(null)
    return true
  }

  function valuetext(value: number) {
    return `${value}°C`;
  }

  const marks = [
    {
      value: 0,
      label: '$0',
    },
    {
      value: 50000,
      label: '$50,000',
    }
  ];

  return (
    <div className={`${styles.popup_container} ${styles.popup_form}`}>
      <div className={`${styles.popup_heading} ${styles.popup_heading_notitle}`}>
        {headings[page] &&
          <div>
            <p className={styles.mediumer_text}>{headings[page].title}</p>
            <p className={styles.small_text}>{headings[page].sub}</p>
          </div>
        }
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <div className={styles.init_page}>
            <h1 className={styles.largest_text}>WORK NOW,<br/><span>PAY LATER</span></h1>
            <p className={styles.mediumer_text}>Request a finance loan for one of your property from this form module.</p>
          </div>

        }

        {page === 2 &&
          <div className={`${styles.text_grid} ${styles.mb3}`}>
            <div className={styles.text_area}>
              <p className={`${styles.text_label} ${styles.small_text}`}>First name</p>
              <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.first_name} onChange={(input) => handlePopup("set", "first_name", input, true)}/>
            </div>

            <div className={styles.text_area}>
              <p className={`${styles.text_label} ${styles.small_text}`}>Last name</p>
              <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.last_name} onChange={(input) => handlePopup("set", "last_name", input, true)}/>
            </div>

            <div className={styles.text_area}>
              <p className={`${styles.text_label} ${styles.small_text}`}>Email</p>
              <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.email} onChange={(input) => handlePopup("set", "email", input, true)}/>
            </div>

            <div className={styles.text_area}>
              <p className={`${styles.text_label} ${styles.small_text}`}>Phone</p>
              <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.phone} onChange={(input) => handlePopup("set", "phone", input, true)}/>
            </div>
          </div>
        }

        {page === 3 &&
          <>
            <div className={styles.warning_box}>
              <p className={styles.small_text}>Please select the amount you would like and describe the loan terms. Ex: weekly/monthly payments, ect...</p>
            </div>

            <div className={`${styles.text_grid} ${styles.mb3} ${styles.m1_5}`}>
              <div className={styles.text_area}>
                <p className={`${styles.text_label} ${styles.small_text}`}>Loan amount</p>
                <div className={`${styles.slider_container}`}>
                  <div className={styles.slider_inner}>
                    <Slider
                      defaultValue={0}
                      getAriaValueText={valuetext}
                      valueLabelDisplay="auto"
                      marks={marks}
                      min={0}
                      max={18000}
                      step={100}
                      onChange={(input) => handlePopup("set", "amount", input, true)}
                      value={formData.amount}
                      sx={{
                          '& .MuiSlider-thumb': {
                            color: "#ffac1b !important"
                          },
                          '& .MuiSlider-thumb:hover': {
                            boxShadow: '0px 0px 0px 8px rgba(255, 172, 27, 0.16) !important'
                          },
                          '& .MuiSlider-track': {
                            color: "#ffac1b !important"
                          },
                          '& .MuiSlider-rail': {
                            color: "#ffd791 !important"
                          },
                          '& .MuiSlider-active': {
                            color: "#ffac1b !important"
                          },
                          '& .MuiSlider-markLabel': {
                            color: "#ffac1b !important",
                            textDecoration: "none !important"
                          },
                          '& .MuiSlider-valueLabel': {
                            backgroundColor: "#ffac1b !important",
                            textDecoration: "none !important"
                          },
                          '& .MuiSlider-valueLabelCircle': {
                            textDecoration: "none !important"
                          },
                          '& .MuiSlider-valueLabelLabel': {
                            color: "white !important",
                            textDecoration: "none !important"
                          }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={`${styles.text_area} ${styles.mt3_5}`}>
                <p className={`${styles.text_label} ${styles.small_text}`}>Loan terms</p>
                <textarea
                  className={`${styles.general_textarea} ${styles.mid_text}`}
                  onChange={(input) => handlePopup("set", "loan_terms", input, true)}
                  value={formData["loan_terms"]}
                />
              </div>
            </div>
          </>
        }

        {page === 4 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              : <PropertiesTable {... { param, handlePopup, formData } }/>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        {page !== 1 && <button className={styles.control_btn} disabled={innerLoading} onClick={() => handlePopup('back')}><i className={`fa-solid fa-chevron-left ${styles.medium_text}`}></i></button>}
        {page !== 4 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('next')}><i className={`fa-solid fa-chevron-right ${styles.medium_text}`}></i></button>}
        {page === 4 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>}
      </div>
    </div>
  );
};

export default FinanceForm;