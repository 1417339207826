import { useEffect, useState, useRef } from "react";
import axios from "axios";
import styles from './utilstyles.module.css';


const PropertiesTable = ({ param, handlePopup, formData }) => {
  const [key, setKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [nodes, setNodes] = useState([])
  const [nodesOG, setNodesOG] = useState([])
  const [search, setSearch] = useState("")


  useEffect(() => {
    const handleLoad = async () => {
      setIsLoading(true)
      const url = `/api/hub/main/${param._id}/properties`;
      const role_token = localStorage.getItem('role_token');
      const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

      setNodesOG(data.data.dataMapped)
      setNodes(data.data.dataMapped)
      setIsLoading(false)
    }

    handleLoad()
  }, [key]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    let nodesDup = [...nodesOG]
    let nodesFiltered;

    if (event.target.value === "") {
      setNodes(nodesDup)
    } else {
      nodesFiltered = nodesDup.filter(node => node.address.toLowerCase().includes(event.target.value.toLowerCase()))

      setNodes(nodesFiltered)
    }
  };

  return (
    <>
      <div className={styles.table_search}>
        <div className={styles.search_container}>
          <i className={`fa-solid fa-magnifying-glass ${styles.medium_text}`}></i>
          <input
            type="text"
            placeholder="Search for anything in this table..."
            className={styles.mid_text}
            value={search}
            onChange={(event) => handleSearch(event)}
          />
        </div>
      </div>

      <div className={styles.service_grid}>
        {isLoading
          ?
            <div className={styles.load}>
              <hr />
              <hr />
              <hr />
              <hr />
            </div> 
          :
            <>
              {nodes.map((p, index) => {
                return (
                  <button className={`${styles.service_item} ${styles.property_item} ${formData['property'] === p.address && styles.service_active}`} onClick={() => handlePopup("set", "property", p.address)}>
                    <p className={styles.mid_text}>{p.address}</p>
                  </button>
                )
              })}
            </>
        }
      </div>
    </>
  )
}


const PopUpService = ({ param, handleActions, node }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({})
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const serviceIcons = {
    'EV Charging Station Install': 'fa-solid fa-charging-station',
    'Roof Cleaning': 'fa-solid fa-chevron-up',
    'Garage Door Service': 'fa-solid fa-warehouse',
    'Plumbing': 'fa-solid fa-faucet',
    'Electrical': 'fa-solid fa-lightbulb',
    'Handyman': 'fa-solid fa-screwdriver-wrench',
    'Locksmith': 'fa-solid fa-unlock-keyhole',
    'Gutter Cleaning': 'fa-solid fa-broom',
    'Rubbish Removal': 'fa-solid fa-dumpster',
    'Pest Control': 'fa-solid fa-bug-slash',
    'Gardening': 'fa-solid fa-seedling',
    'Fence Painting': 'fa-solid fa-paint-roller',
    'Home Modifications': 'fa-solid fa-house-chimney',
    'Accessibility Equipment': 'fa-solid fa-person-walking-with-cane',
    'Other': 'fa-solid fa-ellipsis'
  }

  const headings = {
    1: {
      title: "Create a service",
      sub: "Use this page to select a service type."
    },

    2: {
      title: "Select property",
      sub: "Use this page to select a property for the service."
    },

    3: {
      title: "Service information",
      sub: "Use this page to select information regarding the service."
    }
  }


  useEffect(() => {
    const handleLoad = async () => {
      if (node) {
        setFormData({property: node.address})
      }
    }

    handleLoad()
  }, []);

  const handlePopup = async (control, name?, input?, target=false) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'next':
        let valTest = await validationTest()
        if (valTest) {
          setPage(page+1)
        }

        break;
      case 'back':
        setValidationError(null)
        setPage(page-1)
        break;
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          let formDataObj = new FormData();

          uploadedFiles.forEach((file) => {
            formDataObj.append('files', file);
          });
          
          for (const key in formData) {
            if (Object.hasOwnProperty.call(formData, key)) {
              formDataObj.append(key, formData[key]);
            }
          }

          let res_files = await axios.post(`/api/services/${param._id}`, formDataObj, { headers: { 'Content-Type': 'multipart/form-data' } });
          window.location.reload()
        }

        setInnerLoading(false)
        break;
      case 'set':
        if (target) {
          formDataDup[name] = input.target.value
        } else {
          formDataDup[name] = input
        }
        
        setFormData(formDataDup)
        break;
    }
  }

  const handleFileUpload = (acceptedFiles) => {
    const filesArray = Array.from(acceptedFiles.target.files);
    setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...filesArray]);
  };


  const validationTest = async () => {
    if (page === 1) {
      if (!(formData.hasOwnProperty('service_type')) || formData['service_type'] === '') {
        setValidationError("You did not select a service type.");
        return false;
      }
    }

    if (page === 2) {
      if (!(formData.hasOwnProperty('property')) || formData['property'] === '') {
        setValidationError("You did not select a property.");
        return false;
      }
    }

    if (page === 3) {
      if (!(formData.hasOwnProperty('serv_overview')) || formData['serv_overview'] === '') {
        setValidationError("Please enter a service overview.");
        return false;
      }
    }

    setValidationError(null)
    return true
  }


  function handleClose() {
    const confirmClose = window.confirm("Are you sure you would like to close this?")

    if (confirmClose) {
      setInnerLoading(false)
      setValidationError(null)
      setPage(1)
      setFormData({})
      setUploadedFiles([])
      handleActions('close')
    }
  }



  return (
    <div className={`${styles.popup_container} ${styles.popup_service}`}>
      <div className={styles.popup_heading}>
        <div>
          <p className={styles.mediumer_text}>{headings[page].title}</p>
          <p className={styles.small_text}>{headings[page].sub}</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 && 
          <>
            <div className={styles.service_grid}>
              {Object.keys(serviceIcons).map((s, index) => {
                return (
                  <button className={`${styles.service_item} ${formData['service_type'] === s && styles.service_active}`} onClick={() => handlePopup("set", "service_type", s)}>
                    <div className={styles.service_circle}><i className={`${serviceIcons[s]}`}></i></div>
                    <p className={styles.mid_text}>{s}</p>
                  </button>
                )
              })}
            </div>
          </>
        }

        {page === 2 &&
          <PropertiesTable {... { param, handlePopup, formData } }/>
        }

        {page === 3 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  <div className={styles.warning_box}>
                    <p className={styles.small_text}>After submitting your service request, the office will contact you for scheduling.</p>
                  </div>

                  <div className={styles.text_large_container}>
                    <div className={styles.text_grid}>
                      <div className={styles.text_area}>
                        <p className={`${styles.text_label} ${styles.small_text}`}>Service overview</p>
                        <textarea onChange={(input) => handlePopup("set", "serv_overview", input, true)} value={formData["serv_overview"]} className={`${styles.general_textarea} ${styles.mid_text}`}/>
                      </div>

                      <div className={styles.text_area}>
                        <p className={`${styles.text_label} ${styles.small_text}`}>Service files</p>
                        <input onChange={handleFileUpload} type="file" className={`${styles.general_input} ${styles.mid_text}`} multiple/>
                      </div>
                    </div>
                  </div>
                </>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        {page !== 1 && <button className={styles.control_btn} disabled={innerLoading} onClick={() => handlePopup('back')}><i className={`fa-solid fa-chevron-left ${styles.medium_text}`}></i></button>}
        {page !== 3 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('next')}><i className={`fa-solid fa-chevron-right ${styles.medium_text}`}></i></button>}
        {page === 3 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>}
      </div>
    </div>
  );
};

export default PopUpService;