import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import CognitoForm from '@tylermenezes/cognitoforms-react';
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import Logo from "../../../images/mylologo.svg";
import Forbidden from '../../Forbidden';
import LoadingForm from '../../LoadingForm';
import tstyles from "./Tradie.module.css"
import payment_img from '../../../images/payment.jpg'

import initializeMapWithAccessToken from '../../../utils/mapUtils';


const Dashboard = ({ param, userJobs, formatDate, formatDateAndTime, handleTradieJob, indJob, indNav, indHistory, setHoveredInfo, hoveredInfo, cancellationReason, handleCognito, resetToInitialState, search_control, internalLoading, handleSavedCognito, savedCognitoLoading, loadingComplete, formatDateCognito, filterResults, activeFilter, upcomingCases, markerClicked, markerJob, handleMarkerClick, userJobsOG, showTradieTools, handleShowTradieTools, handleLogout, oldIncompleteJobs, mapLoading, paymentLoading, finalPrice, toggleSquarePayment, priceTimer, startProgress, accountData, navigationOptions, showNavigationOptions }) => {
	return (
		<div className={`${tstyles.wrapper} ${showNavigationOptions && tstyles.hidden_container}`}>
		  <div className={tstyles.tq_topbar}>
		    <div className={tstyles.tq_info}>
		      <div className={tstyles.logoContainer}>
		      	<button onClick={() => resetToInitialState()} className={tstyles.logo_inner}>
			        <img
			          className={tstyles.logo}
			          style={{ height: "60%", paddingLeft: 20 }}
			          src={Logo}
			        />
		        </button>
		      </div>

		        <div className="toolsContainer">
		        	<div className="toolsOuter" style={{marginRight: '30px'}}>
			        	<td className={tstyles.align_td}>
	                      	<div className={tstyles.toolsFlex}>
	                            {(showTradieTools) ?
		                            <div className={tstyles.toolsInner}>
		                              	<button onClick={() => handleShowTradieTools('close')} className={`${tstyles.trash_button} ${tstyles.admin_close}`}>
			                            	<i className="fa-solid fa-xmark"></i>
			                            </button>

			                            <button onClick={() => handleShowTradieTools('navigate', 'dashboard')} className={tstyles.trash_button} disabled={true}>
			                              	<i className="fa-solid fa-house"></i>
			                              	<p>Dashboard</p>
			                            </button>

			                            <button onClick={() => handleShowTradieTools('navigate', 'calendar')} className={tstyles.trash_button}>
			                              	<i className="fa-solid fa-calendar-days"></i>
			                              	<p>Calendar</p>
			                            </button>

			                            <button onClick={handleLogout} className={tstyles.trash_button}>
			                              	<i className="fa-solid fa-right-from-bracket"></i>
			                              	<p>Logout</p>
			                            </button>
			                        </div>
			                    :
	                              	<button onClick={() => handleShowTradieTools('set')} className={tstyles.trash_button}>
		                            	<i className="fa-solid fa-bars"></i>
		                            </button>
		                    	}
	                      	</div>
	                    </td>
		            </div>
		        </div>
		    </div>

		    <div className={tstyles.tq_search}>
		      <div className={tstyles.tq_search_container}>
		        <div className={tstyles.tq_search_bar}>
		          <i className="fa-solid fa-magnifying-glass" />
		          <input
		          	type="text"
		          	name="search"
		          	placeholder="Search your jobs..."
		          	onKeyUp={(event) => search_control(event)}
		            id="searchbar"
		          />
		        </div>
		      </div>
		    </div>
		  </div>
		  <div className={tstyles.tq_container}>
		  	{!indJob &&
		  		<>
			    <div className={tstyles.tq_jobs} id="tq_jobs">

					<div className={tstyles.filter_container}>
						<button className={`${tstyles.filter_item} ${activeFilter === "Pending" && tstyles.Pending}`} onClick={() => filterResults('pending')}>
							<p className={tstyles.PendingP}>Pending {userJobsOG.filter(job => job.status === "Pending").length}</p>
						</button>
						<button className={`${tstyles.filter_item} ${activeFilter === "Incomplete" && tstyles.Incomplete}`} onClick={() => filterResults('incomplete')}>
							<p className={tstyles.IncompleteP}>Incomplete {userJobsOG.filter(job => job.status === "Incomplete").length + oldIncompleteJobs.length}</p>
						</button>
						<button className={`${tstyles.filter_item} ${activeFilter === "Completed" && tstyles.Completed}`} onClick={() => filterResults('completed')}>
							<p className={tstyles.CompletedP}>Completed {userJobsOG.filter(job => job.status === "Completed").length}</p>
						</button>
						<button className={`${tstyles.filter_item} ${activeFilter === "Upcoming" && tstyles.Upcoming}`} onClick={() => filterResults('upcoming')}>
							<p className={tstyles.UpcomingP}>Upcoming {upcomingCases.length}</p>
						</button>
					</div>

					{activeFilter === "Upcoming" && <p className={tstyles.upcoming_warning}>The list below will only include upcoming cases and custom events.</p>}

					
					{activeFilter === "Upcoming"
						?
							<>
							{upcomingCases.length > 0
								?
								<>
								    {upcomingCases.map((UJ, idx) => {
								    	return (
									      <button disabled={UJ['custom']} type="button" className={`${tstyles.tq_job} ${tstyles.main_tq}`} onClick={() => handleTradieJob('open', UJ)}>
									        <div className={tstyles.tq_job_container}>
									          <div className={tstyles.tq_job_status}>
									            {!UJ['custom'] && <div className={`${tstyles.tq_dot} ${tstyles[UJ.status]}`}/>}

									            {!UJ['custom']
									            ?
										            <>
										            {UJ.jobTypes['case']
										            ? 
										            	<p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Case | {UJ.type}</p>
										            : 
										            	UJ.jobTypes['service'] || UJ.jobTypes['checklist']
										            ?
										            	<p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Service | {UJ.type}</p>
										            :
											            <p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Inspection | {
														Object.entries(UJ.jobTypes)
														  .filter(([jobType, selected]) => selected)
														  .map(([jobType]) => jobType.charAt(0).toUpperCase() + jobType.slice(1))
														  .join(', ')
										  				}</p>
										        	}
										        	</>
										        : <p className={tstyles.tq_job_type}>Custom | {UJ.title}</p>
										    	}


									          </div>

									          {UJ.property &&
									          <div className={tstyles.tq_job_overview}>
									            <div className={tstyles.tq_dot_icon}>
									              <i className="fa-solid fa-location-dot" />
									            </div>
									            {!UJ['custom']
									            	? <p>{UJ.property.street}, {UJ.property.suburb}, {UJ.property.state}, {UJ.property.postcode}</p>
									            	: <p>{UJ.property}</p>
									        	}
									          </div>
									      		}
									          <div className={tstyles.tq_job_overview}>
									            <div className={tstyles.tq_dot_icon}>
									              <i className="fa-solid fa-clock" />
									            </div>
									            {!UJ['custom']
									            	? <p>{formatDateAndTime(UJ.scheduled_timestamp)}</p>
									            	: <p>{formatDateAndTime(UJ.event_dates[0])}</p>
									        	}
									            
									          </div>

									          {(UJ['custom'] && UJ.comments) &&
										          <div className={tstyles.tq_job_overview}>
										            <div className={tstyles.tq_dot_icon}>
										              <i className="fa-solid fa-clipboard"></i>
										            </div>
										            <p>{UJ.comments}</p>
										          </div>
									      	  }

									          {(UJ.key_pickup && UJ.key_comment) &&
									          	<>
										          <div className={tstyles.tq_job_overview}>
										            <div className={tstyles.tq_dot_icon}>
										              <i className={`fa-solid fa-key ${tstyles.keypickup}`}/>
										            </div>
										            <p>{UJ.key_pickup}</p>
										          </div>

										          <div className={tstyles.tq_job_overview}>
										            <div className={tstyles.tq_dot_icon}>
										              <i className={`fa-solid fa-clipboard ${tstyles.keypickup}`}/>
										            </div>
										            <p>{UJ.key_comment}</p>
										          </div>
										        </>
									      	  }
									        </div>
									      </button>
								      )
								  	})}
								</>
								: <p className={tstyles.noneavailable}>There are no {activeFilter.toLowerCase()} jobs available</p>
							}
							</>
						:
							<>
							{(activeFilter === "Incomplete" && userJobs.filter(ind => ind['old']).length > 0) && <p className={tstyles.upcoming_warning}>There are incomplete jobs from before today. These jobs have the date highlighted in red.</p>}

							{userJobs.length > 0
								?
								<>
								    {userJobs.map((UJ, idx) => {
										const isFirstPendingNonCustom = idx === userJobs.findIndex(job => 
											job.status === 'Pending' && !job['custom']
										);

								    	return (
									      <button disabled={(activeFilter === "Pending" || activeFilter === "Completed") && !isFirstPendingNonCustom || UJ['custom']} type="button" className={`${tstyles.tq_job} ${tstyles.main_tq}`} onClick={() => handleTradieJob('open', UJ)}>
									        <div className={tstyles.tq_job_container}>
									          <div className={tstyles.tq_job_status}>
									            {!UJ['custom'] && <div className={`${tstyles.tq_dot} ${tstyles[UJ.status]}`}/>}

									            {!UJ['custom']
									            ?
										            <>
											            {UJ.jobTypes['case']
											            ? 
											            	<p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Case | {UJ.type}</p>
											            : 
											            	UJ.jobTypes['service'] || UJ.jobTypes['checklist']
											            ?
											            	<p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Service | {UJ.type}</p>
											            :
												            <p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Inspection | {
															Object.entries(UJ.jobTypes)
															  .filter(([jobType, selected]) => selected)
															  .map(([jobType]) => jobType.charAt(0).toUpperCase() + jobType.slice(1))
															  .join(', ')
											  				}</p>
											        	}
										        	</>
										        : <p className={tstyles.tq_job_type}>Custom | {UJ.title}</p>
										    	}

									          </div>

									          <div className={tstyles.tq_job_overview}>
									            <div className={tstyles.tq_dot_icon}>
									              <i className="fa-solid fa-location-dot" />
									            </div>
									            {!UJ['custom']
									            	? <p>{UJ.property.street}, {UJ.property.suburb}, {UJ.property.state}, {UJ.property.postcode}</p>
									            	: <p>{UJ.property}</p>
									        	}
									          </div>
									          <div className={tstyles.tq_job_overview}>
									            <div className={tstyles.tq_dot_icon}>
									              <i className="fa-solid fa-clock" />
									            </div>
									            {!UJ['custom']
									            	? <p className={UJ['old'] && tstyles.old_date}>{formatDateAndTime(UJ.scheduled_timestamp)}</p>
									            	: <p>{formatDateAndTime(UJ.event_dates[0])}</p>
									        	}
									          </div>

									          {(UJ['custom'] && UJ.comments) &&
										          <div className={tstyles.tq_job_overview}>
										            <div className={tstyles.tq_dot_icon}>
										              <i className="fa-solid fa-clipboard"></i>
										            </div>
										            <p>{UJ.comments}</p>
										          </div>
									      	  }

									          {(UJ.key_pickup && UJ.key_comment) &&
									          	<>
										          <div className={tstyles.tq_job_overview}>
										            <div className={tstyles.tq_dot_icon}>
										              <i className={`fa-solid fa-key ${tstyles.keypickup}`}/>
										            </div>
										            <p>{UJ.key_pickup}</p>
										          </div>

										          <div className={tstyles.tq_job_overview}>
										            <div className={tstyles.tq_dot_icon}>
										              <i className={`fa-solid fa-clipboard ${tstyles.keypickup}`}/>
										            </div>
										            <p>{UJ.key_comment}</p>
										          </div>
										        </>
									      	  }

									          {UJ.contact_formula &&
									            <div className={tstyles.tq_job_overview}>
									              <div className={tstyles.tq_dot_icon}>
									                <i className="fa-solid fa-address-book" />
									              </div>
									              <div>
									                <p>{UJ.contact_formula.split(' - ')[0]} - <a href={`tel:${UJ.contact_formula.split(' - ')[1]}`} style={{margin: '0'}}>{UJ.contact_formula.split(' - ')[1]}</a></p>
									              </div>
									           </div>
									           }
									        </div>
									      </button>
								      )
								  	})}
								</>
								: <p className={tstyles.noneavailable}>There are no {activeFilter.toLowerCase()} jobs available</p>
							}
							</>
					}
			    </div>

				<div className={tstyles.tq_map}>
					{!mapLoading
					?
						<>
						<div id="map" onClick={(marker) => handleMarkerClick('clear', marker)}></div>

						<button id="resetMapButton" className={tstyles.centerIcon}><i class="fa-solid fa-arrows-to-dot"></i></button>

						{(markerClicked && markerJob) &&
							<div id={`markerJob_${markerJob._id}`} className={`marker_clicked ${tstyles.tq_job} ${tstyles.main_tq}`} style={{width: 'auto', backgroundColor: 'white', transform: `translate(${markerClicked[0]}, ${markerClicked[1]}) translate(-50%, -50%) translate(0px, 0px)` }}>
								<div className={tstyles.tq_job_container}>
									<div className={tstyles.tq_job_status}>
										<div className={`${tstyles.tq_dot} ${tstyles[markerJob.status]}`}/>

										{markerJob.jobTypes['case']
										? 
											<p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Case | {markerJob.type}</p>
										: 
											(markerJob.jobTypes['service'] || markerJob.jobTypes['checklist'])
										?
											<p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Service | {markerJob.type}</p>
										:
											<p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>Inspection | {
												Object.entries(markerJob.jobTypes)
												.filter(([jobType, selected]) => selected)
												.map(([jobType]) => jobType.charAt(0).toUpperCase() + jobType.slice(1))
												.join(', ')
											}</p>
										}
									</div>

									<div className={tstyles.tq_job_overview}>
										<div className={tstyles.tq_dot_icon}>
											<i className="fa-solid fa-location-dot" />
										</div>
										<p>{markerJob.property.street}, {markerJob.property.suburb}, {markerJob.property.state}, {markerJob.property.postcode}</p>
									</div>

									<div className={tstyles.tq_job_overview}>
										<div className={tstyles.tq_dot_icon}>
											<i className="fa-solid fa-clock" />
										</div>
										<p className={markerJob['old'] && tstyles.old_date}>{formatDateAndTime(markerJob.scheduled_timestamp)}</p>
									</div>

									{(markerJob.key_pickup && markerJob.key_comment) &&
										<>
										<div className={tstyles.tq_job_overview}>
											<div className={tstyles.tq_dot_icon}>
												<i className={`fa-solid fa-key ${tstyles.keypickup}`}/>
											</div>
											<p>{markerJob.key_pickup}</p>
										</div>

							          <div className={tstyles.tq_job_overview}>
							            <div className={tstyles.tq_dot_icon}>
							              <i className={`fa-solid fa-clipboard ${tstyles.keypickup}`}/>
							            </div>
							            <p>{markerJob.key_comment}</p>
							          </div>
							          </>
									}
								</div>
							</div>
						}
						</>
					:
						<div className={tstyles.load}>
							<hr/><hr/><hr/><hr/>
						</div>
					}
				</div>
			    </>
			}

		    {indJob && <Job {...{ param, userJobs, formatDate, formatDateAndTime, handleTradieJob, indJob, indNav, indHistory, setHoveredInfo, hoveredInfo, cancellationReason, handleCognito, resetToInitialState, internalLoading, handleSavedCognito, savedCognitoLoading, loadingComplete, formatDateCognito, activeFilter, paymentLoading, finalPrice, toggleSquarePayment, priceTimer, accountData, navigationOptions }} />}
		  </div>
		</div>

	)
}

const Job = ({ param, userJobs, formatDate, formatDateAndTime, handleTradieJob, indJob, indNav, indHistory, setHoveredInfo, hoveredInfo, cancellationReason, handleCognito, resetToInitialState, internalLoading, handleSavedCognito, savedCognitoLoading, loadingComplete, formatDateCognito, activeFilter, paymentLoading, finalPrice, toggleSquarePayment, priceTimer, accountData, navigationOptions }) => {
	return (
	    <div
	      className={`${tstyles.tq_jobs} ${tstyles.full_width}`}
	      id="tq-job-specific"
	    >
	      <div className={`${tstyles.tq_job} ${tstyles.full_height} ${tstyles.mb_15}`}>
	        <div className={`${tstyles.tq_nav} ${tstyles.q_width}`}>
	          <div className={tstyles.tq_job_container}>
	            <div className={tstyles.tq_job_status}>
	              <div className={`${tstyles.tq_dot} ${tstyles[indJob.status]}`} />
	              <p className={`${tstyles.tq_job_type} ${tstyles.ml15_custom}`}>
	              	{indJob.jobTypes['case']
	              	? `${indJob.type} Case`
	              	: indJob.jobTypes['service']
	              	? `${indJob.type} Service`
	              	: indJob.jobTypes['checklist']
	              	? `${indJob.type}`
	              	: `${indJob.formattedType} Inspection`
	              	} 
	              </p>
	              <button onClick={() => resetToInitialState()} className={tstyles.back_btn_inner} disabled={!loadingComplete}><i class="fa-solid fa-arrow-left-long"></i></button>
	            </div>
	            <div className={tstyles.tq_job_overview}>
	              <div className={tstyles.tq_dot_icon}>
	                <i className="fa-solid fa-location-dot" />
	              </div>

	              <button className={tstyles.navOptionsBtn} onClick={() => navigationOptions('toggle', `${indJob.property.street}, ${indJob.property.suburb}, ${indJob.property.state}, ${indJob.property.postcode}`)}><p>{indJob.property.street}, {indJob.property.suburb}, {indJob.property.state}, {indJob.property.postcode}</p></button>
	            </div>
	            <div className={tstyles.tq_job_overview}>
	              <div className={tstyles.tq_dot_icon}>
	                <i className="fa-solid fa-clock" />
	              </div>
	              <p>{formatDateAndTime(indJob.scheduled_timestamp)}</p>
	            </div>
	            {(indJob.key_pickup && indJob.key_comment) &&
	            <>
		            <div className={tstyles.tq_job_overview}>
		              <div className={tstyles.tq_dot_icon}>
		                <i className={`fa-solid fa-key ${tstyles.keypickup}`}/>
		              </div>
		              <button className={tstyles.navOptionsBtn} onClick={() => navigationOptions('toggle', `${indJob.key_pickup}`)}><p>{indJob.key_pickup}</p></button>
		            </div>

		          <div className={tstyles.tq_job_overview}>
		            <div className={tstyles.tq_dot_icon}>
		              <i className={`fa-solid fa-clipboard ${tstyles.keypickup}`}/>
		            </div>
		            <p>{indJob.key_comment}</p>
		          </div>
		          </>
	        	}
	        	{indJob.contact_formula &&
		            <div className={tstyles.tq_job_overview}>
		              <div className={tstyles.tq_dot_icon}>
		                <i className="fa-solid fa-address-book" />
		              </div>
		              <div>
		                <p>{indJob.contact_formula.split(' - ')[0]} - <a href={`tel:${indJob.contact_formula.split(' - ')[1]}`} style={{margin: '0'}}>{indJob.contact_formula.split(' - ')[1]}</a></p>
		              </div>
		            </div>
	        	}

	            {(indJob.comments && indJob.comments !== '') &&
		            <div className={tstyles.tq_job_overview}>
		              	<div className={tstyles.tq_dot_icon}>
		                	<i className="fa-solid fa-clipboard"></i>
		              	</div>

					  	<p className={tstyles.tradie_comments_break}>{indJob.comments.split('<br>').map((line, index) => {
					        return <span key={index}>{line}<br /></span>;
					  	})}</p>
		            </div>
	        	}

	            <div className={tstyles.tq_job_overview}>
	              <div className={tstyles.tq_dot_icon}>
	                <i className="fa-solid fa-comment-sms"></i>
	              </div>
	              <button className={tstyles.smsbutton} onClick={() => handleTradieJob('sms', 'send')}><p>Send "On my way" SMS</p></button>
	            </div>
	          </div>
	          <div className={tstyles.tq_job_controls}>
	          	{(indJob.stamps.start.stamped && !(indJob.stamps.completed.stamped)) &&
	          		<>
		          	{(indJob.jobTypes['electrical'] && !(indJob.completed['electrical'])) &&
			            <button className={`${tstyles.tq_job_btn} ${indNav === "Electrical Report" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Electrical Report')} disabled={!loadingComplete}>
			              <div className={tstyles.tq_dot_icon_s}>
			                <i className="fa-solid fa-clipboard-question" />
			              </div>
			              <p className={tstyles.tq_btn_name}>Electrical Report</p>
			            </button>
		        	}
		        	{(indJob.jobTypes['gas'] && !(indJob.completed['gas'])) &&
			            <button className={`${tstyles.tq_job_btn} ${indNav === "Gas Report" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Gas Report')} disabled={!loadingComplete}>
			              <div className={tstyles.tq_dot_icon_s}>
			                <i className="fa-solid fa-clipboard-question" />
			              </div>
			              <p className={tstyles.tq_btn_name}>Gas Report</p>
			            </button>
		        	}
		        	{(indJob.jobTypes['smoke'] && !(indJob.completed['smoke'])) &&
			            <button className={`${tstyles.tq_job_btn} ${indNav === "Smoke Report" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Smoke Report')} disabled={!loadingComplete}>
			              <div className={tstyles.tq_dot_icon_s}>
			                <i className="fa-solid fa-clipboard-question" />
			              </div>
			              <p className={tstyles.tq_btn_name}>Smoke Report</p>
			            </button>
		        	}
		        	{(indJob.jobTypes['case'] && !(indJob.completed['case'])) &&
			            <button className={`${tstyles.tq_job_btn} ${indNav === "Case Report" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Case Report')} disabled={!loadingComplete}>
			              <div className={tstyles.tq_dot_icon_s}>
			                <i className="fa-solid fa-clipboard-question" />
			              </div>
			              <p className={tstyles.tq_btn_name}>Case Report</p>
			            </button>
		        	}
		        	{(indJob.jobTypes['service'] && !(indJob.completed['service'])) &&
			            <button className={`${tstyles.tq_job_btn} ${indNav === "Service Report" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Service Report')} disabled={!loadingComplete}>
			              <div className={tstyles.tq_dot_icon_s}>
			                <i className="fa-solid fa-clipboard-question" />
			              </div>
			              <p className={tstyles.tq_btn_name}>Service Report</p>
			            </button>
		        	}
		        	{(indJob.jobTypes['checklist'] && !(indJob.completed['checklist'])) &&
			            <button className={`${tstyles.tq_job_btn} ${indNav === "Checklist Report" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Checklist Report')} disabled={!loadingComplete}>
			              <div className={tstyles.tq_dot_icon_s}>
			                <i className="fa-solid fa-clipboard-question" />
			              </div>
			              <p className={tstyles.tq_btn_name}>Checklist Report</p>
			            </button>
		        	}
		        	</>
	        	}

	        	{(indJob.jobTypes.service || indJob.jobTypes.checklist)
	        	?
	            <button className={`${tstyles.tq_job_btn} ${indNav === "Service History" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Service History')} disabled={!loadingComplete}>
	              <div className={tstyles.tq_dot_icon_s}>
	                <i className="fa-solid fa-folder-open" />
	              </div>
	              <p className={tstyles.tq_btn_name}>Service History</p>
	            </button>
	            :
	            <button className={`${tstyles.tq_job_btn} ${indNav === "History" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'History')} disabled={!loadingComplete}>
	              <div className={tstyles.tq_dot_icon_s}>
	                <i className="fa-solid fa-folder-open" />
	              </div>
	              <p className={tstyles.tq_btn_name}>History</p>
	            </button>
	        	}

	        	{/* {(indJob.stamps.start.stamped && !(indJob.stamps.completed.stamped)) && */}
	        	{/* 	<> */}
		        {/*     {indJob.jobTypes['service'] && */}
			       {/*      <button className={`${tstyles.tq_job_btn} ${indNav === "Payment" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Payment')} disabled={!loadingComplete}> */}
			       {/*        <div className={tstyles.tq_dot_icon_s}> */}
			       {/*          <i className="fa-solid fa-credit-card"></i> */}
			       {/*        </div> */}
			       {/*        <p className={tstyles.tq_btn_name}>Payment</p> */}
			       {/*      </button> */}
		        {/*     } */}
		        {/*     </> */}
	        	{/* } */}

	        	{!indJob.stamps.completed.stamped &&
		            <button className={`${tstyles.tq_job_btn} ${indNav === "Cancel Job" && tstyles.active_job_btn}`} onClick={() => handleTradieJob('nav', 'Cancel Job')} disabled={!loadingComplete}>
		              <div className={tstyles.tq_dot_icon_s}>
		                <i className="fa-solid fa-ban" />
		              </div>
		              <p className={tstyles.tq_btn_name}>Cancel Job</p>
		            </button>
	        	}
	          </div>
	          <div className={tstyles.form_btn_container}>
	          	{(!indJob.stamps.start.stamped && activeFilter !== "Upcoming") &&
		            <button className={tstyles.form_send} onClick={() => handleTradieJob('start', indJob)} disabled={!loadingComplete}>
		              Start Job
		            </button>
	        	}

	        	{(indJob.stamps.start.stamped && !(indJob.stamps.completed.stamped)) &&
	        		<>
		        		{((indJob.stamps.start.stamped) && !(indJob.stamps.leaving.stamped)) &&
					        <button className={tstyles.form_send_leaving} onClick={() => handleTradieJob('leaving', indJob)} disabled={!loadingComplete}>
					          Leaving Property
					        </button>
		        		}

			            <button className={tstyles.form_send} onClick={() => handleTradieJob('completed', indJob)} disabled={!loadingComplete}>
			             	Complete Job
			            </button>
	        		</>
	        	}

	          </div>
	        </div>
	        <div className={tstyles.tq_width}>
	          {indNav === "Electrical Report" &&
		        <div className={tstyles.report_form}>
		        	{!indJob.completed['electrical'] ?
		        		<>
				        	{indJob.saved['electrical']
				        		?
				        			<>
				        				{savedCognitoLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

										<iframe src={indJob.forms['electrical']} onLoad={() => handleSavedCognito()}></iframe>
										<script src="https://www.cognitoforms.com/f/iframe.js"></script>
									</>
				        		:
				        			<>
				        				{internalLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}
					        			<div className={tstyles.report_form_loaded}>
										    <CognitoForm
										      formId={30}
										      accountId={`P2QqkY_Je0GmkF_5AKPl-g`}
										      onReady={() => handleCognito()}
										      prefill={{
										      	InspectionDate: formatDateCognito(indJob.scheduled_timestamp),
										      	Reference: indJob.internal ? indJob.internal['inspection_name'] : indJob.reference,
										      	PropertyDetails: {
										      		AddressOfInspection: {
											      		Line1: indJob.property.street,
											      		City: indJob.property.suburb,
											      		State: indJob.property.state,
											      		PostalCode: indJob.property.postcode,
										      		},
										      		Name: indJob.agency || '',
										      		Number: indJob.contact_formula
										      	},
										      	Supervisor: {
										      		PortalFormId: indJob._id,
										      	},
										      	Declaration: {
										      		NameOfTechnician: `16-${accountData.cognito_id}`
										      	}
										      }}
										    />
								    	</div>
								    </>
				        	}
				        </>
				        : <div className={tstyles.add_first}><p>Thank you for submitting the electrical form.</p></div>
		        	}
		        </div>
	      	  }

	          {indNav === "Gas Report" &&
		        <div className={tstyles.report_form}>
		        	{!indJob.completed['gas'] ?
		        		<>
				        	{indJob.saved['gas']
				        		?
				        			<>
				        				{savedCognitoLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

										<iframe src={indJob.forms.gas} onLoad={() => handleSavedCognito()}></iframe>
										<script src="https://www.cognitoforms.com/f/iframe.js"></script>
									</>
				        		:
				        			<>
				        				{internalLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}
					        			<div className={tstyles.report_form_loaded}>
										    <CognitoForm
										      formId={33}
										      accountId={`P2QqkY_Je0GmkF_5AKPl-g`}
										      onReady={() => handleCognito()}
										      prefill={{
										      	InspectionDate: formatDateCognito(indJob.scheduled_timestamp),
										      	Reference: indJob.internal ? indJob.internal['inspection_name'] : indJob.reference,
										      	PropertyDetails: {
										      		AddressOfInspection: {
											      		Line1: indJob.property.street,
											      		City: indJob.property.suburb,
											      		State: indJob.property.state,
											      		PostalCode: indJob.property.postcode,
										      		},
										      		Name: indJob.agency || '',
										      		Number: indJob.contact_formula
										      	},
										      	Supervisor: {
										      		PortalFormId: indJob._id
										      	},
										      	Declaration: {
										      		GasfitterName: `16-${accountData.cognito_id}`
										      	}
										      }}
										    />
									    </div>
								    </>
				        	}
				        </>
				        : <div className={tstyles.add_first}><p>Thank you for submitting the gas form.</p></div>
		        	}
		        </div>
	      	  }

	          {indNav === "Smoke Report" &&
		        <div className={tstyles.report_form}>
		        	{!indJob.completed['smoke'] ?
		        		<>
				        	{indJob.saved['smoke']
				        		?
				        			<>
				        				{savedCognitoLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

										<iframe src={indJob.forms.smoke} onLoad={() => handleSavedCognito()}></iframe>
										<script src="https://www.cognitoforms.com/f/iframe.js"></script>
									</>
				        		:
				        			<>
				        				{internalLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

					        			<div className={tstyles.report_form_loaded}>
										    <CognitoForm
										      formId={28}
										      accountId={`P2QqkY_Je0GmkF_5AKPl-g`}
										      onReady={() => handleCognito()}
										      prefill={{
										      	InspectionDate: formatDateCognito(indJob.scheduled_timestamp),
										      	Reference: indJob.internal ? indJob.internal['inspection_name'] : indJob.reference,
										      	PropertyDetails: {
										      		AddressOfInspection: {
											      		Line1: indJob.property.street,
											      		City: indJob.property.suburb,
											      		State: indJob.property.state,
											      		PostalCode: indJob.property.postcode,
										      		},
										      		Name: indJob.agency || '',
										      		Number: indJob.contact_formula
										      	},
										      	Supervisor: {
										      		PortalFormId: indJob._id
										      	},
										      	Declaration: {
										      		NameOfTechnician: `16-${accountData.cognito_id}`
										      	}
										      }}
										    />
									    </div>
									</>
				        	}
				        </>
				        : <div className={tstyles.add_first}><p>Thank you for submitting the smoke form.</p></div>
		        	}
		        </div>
	      	  }

	          {indNav === "Case Report" &&
		        <div className={tstyles.report_form}>
		        	{!indJob.completed['case'] ?
		        		<>
				        	{indJob.saved['case']
				        		?
				        			<>
				        				{savedCognitoLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

										<iframe src={indJob.forms['case']} onLoad={() => handleSavedCognito()}></iframe>
										<script src="https://www.cognitoforms.com/f/iframe.js"></script>
									</>
				        		:
				        			<>
				        				{internalLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

					        			<div className={tstyles.report_form_loaded}>
										    <CognitoForm
										      formId={18}
										      accountId={`P2QqkY_Je0GmkF_5AKPl-g`}
										      onReady={() => handleCognito()}
										      prefill={{
										      	InspectionDate: formatDateCognito(indJob.scheduled_timestamp),
										      	Reference: indJob.reference,
										      	PropertyDetails: {
										      		AddressOfCase: {
											      		Line1: indJob.property.street,
											      		City: indJob.property.suburb,
											      		State: indJob.property.state,
											      		PostalCode: indJob.property.postcode,
										      		},
										      		Name: indJob.agency || '',
										      		Number: indJob.contact_formula
										      	},
										      	WorkDescription: {
										      		DescriptionOfWorkCompleted: indJob.comments.replaceAll('<br>', '\n')
										      	},
										      	Supervisor: {
										      		PortalFormId: indJob._id
										      	},
										      	Declaration: {
										      		NameOfTechnician: `16-${accountData.cognito_id}`
										      	}
										      }}
										    />
									    </div>
									</>
				        	}
				        </>
				        : <div className={tstyles.add_first}><p>Thank you for submitting the case form.</p></div>
		        	}
		        </div>
	      	  }

	          {indNav === "Service Report" &&
		        <div className={tstyles.report_form}>
		        	{!indJob.completed['service'] ?
		        		<>
				        	{indJob.saved['service']
				        		?
				        			<>
				        				{savedCognitoLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

										<iframe src={indJob.forms['service']} onLoad={() => handleSavedCognito()}></iframe>
										<script src="https://www.cognitoforms.com/f/iframe.js"></script>
									</>
				        		:
				        			<>
				        				{internalLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

					        			<div className={tstyles.report_form_loaded}>
										    <CognitoForm
										      formId={34}
										      accountId={`P2QqkY_Je0GmkF_5AKPl-g`}
										      onReady={() => handleCognito()}
										      prefill={{
										      	InspectionDate: formatDateCognito(indJob.scheduled_timestamp),
										      	Reference: indJob.reference,
										      	PropertyDetails: {
										      		AddressOfService: {
											      		Line1: indJob.property.street,
											      		City: indJob.property.suburb,
											      		State: indJob.property.state,
											      		PostalCode: indJob.property.postcode,
										      		},
										      		Name: indJob.agency || '',
										      		Number: indJob.contact_formula
										      	},
										      	WorkDescription: {
										      		TypeOfWork: indJob.type,
										      		DescriptionOfWorkCompleted: indJob.comments
										      	},
										      	Supervisor: {
										      		PortalFormId: indJob._id
										      	},
										      	Declaration: {
										      		NameOfTechnician: `16-${accountData.cognito_id}`
										      	}
										      }}
										    />
									    </div>
									</>
				        	}
				        </>
				        : <div className={tstyles.add_first}><p>Thank you for submitting the service form.</p></div>
		        	}
		        </div>
	      	  }

	          {indNav === "Checklist Report" &&
		        <div className={tstyles.report_form}>
		        	{!indJob.completed['checklist'] ?
		        		<>
				        	{indJob.saved['checklist']
				        		?
				        			<>
				        				{savedCognitoLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

										<iframe src={indJob.forms['checklist']} onLoad={() => handleSavedCognito()}></iframe>
										<script src="https://www.cognitoforms.com/f/iframe.js"></script>
									</>
				        		:
				        			<>
				        				{internalLoading &&
											<div className={tstyles.load}>
												<hr/><hr/><hr/><hr/>
											</div>
										}

					        			<div className={tstyles.report_form_loaded}>
										    <CognitoForm
										      formId={31}
										      accountId={`P2QqkY_Je0GmkF_5AKPl-g`}
										      onReady={() => handleCognito()}
										      prefill={{
										      	DateOfCheck: formatDateCognito(indJob.scheduled_timestamp),
										      	Reference: indJob.reference,
										      	PropertyAddress: {
										      		Line1: indJob.property.street,
										      		City: indJob.property.suburb,
										      		State: indJob.property.state,
										      		PostalCode: indJob.property.postcode,
									      		},
										      	Supervisor: {
										      		PortalFormId: indJob._id
										      	},
										      	Declaration: {
										      		NameOfTechnician: `16-${accountData.cognito_id}`
										      	}
										      }}
										    />
									    </div>
									</>
				        	}
				        </>
				        : <div className={tstyles.add_first}><p>Thank you for submitting the checklist form.</p></div>
		        	}
		        </div>
	      	  }

	          {indNav === "History" &&
            	<div className={tstyles.historyTableGeneral} style={{padding: '30px', flexGrow: "1", boxSizing: "border-box", position: "relative"}}>
					<table style={{tableLayout: "fixed"}}>
						<thead className={`${tstyles.visible_l} ${tstyles.historyTableHEAD}`}>
						    <tr className="tr_heading_sticky">
						      	<th style={{color: "white"}}>Reference</th>
						      	<th style={{color: "white"}}>Type</th>
						        <th style={{color: "white"}}>Complete on</th>
						        <th style={{color: "white"}}>Compliance</th>
						        <th style={{color: "white"}}>Files</th>
						    </tr>
						</thead>
						<tbody>
							<>
		        				{internalLoading
		        				?
									<div className={tstyles.load}>
										<hr/><hr/><hr/><hr/>
									</div>
								:
									<>
										{indHistory && indHistory.length > 0 ? indHistory.map((IH, index) => {
											return (
												<tr className={`${tstyles.nohover} ${tstyles.historyTableTR}`}>
						        					<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Reference:</strong> <p>{IH.Name}</p></td>
													<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Type:</strong>
														<p>
													   		{IH.Electricity__c && 'Electrical'}
													    	{IH.Electricity__c && IH.Gas__c && ', '}
													    	{IH.Gas__c && 'Gas'}
													    	{(IH.Electricity__c || IH.Gas__c) && IH.Smoke_Alarm__c && ', '}
													    	{IH.Smoke_Alarm__c && 'Smoke'}
													  	</p>
													</td>
													<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Completed on:</strong> <p>{formatDate(IH.Completed_On__c)}</p></td>
													<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Compliance:</strong>
														<div className="serviceTD smallerStatus">
															{[IH.Electrical_Safety_Check_Result__c, IH.Gas_Safety_Check_Result__c, IH.Smoke_Alarms_Safety_Check_Result__c].map((compliance, comp_index) => {
																return (
																<>
																	{comp_index == 0 &&
																	<>
																		<div
																			style={{position: 'relative'}}
																			className={compliance && tstyles[compliance.toString().toLowerCase().replace(" ", "_")] || tstyles.not_tested}
										              						onMouseEnter={() => compliance && setHoveredInfo([index, compliance.toString(), comp_index])}
										              						onMouseLeave={() => setHoveredInfo(null)}
												            			>
												            				<i class="fa-solid fa-bolt"></i>
														            		{(hoveredInfo && hoveredInfo[0] === index && compliance && hoveredInfo[1] === compliance.toString() && hoveredInfo[2] === comp_index) && (
																            	<div className={tstyles.statusoverbox} style={{bottom: '-40px'}}>
																               		<p className={tstyles.statusovertext}>Electrical: {hoveredInfo[1]}</p>
																              	</div>
															             	)}
												            			</div>
													          		</>
																	}
																	{comp_index == 1 &&
														        	<>
												            			<div
												            				style={{position: 'relative'}}
												            				className={compliance && tstyles[compliance.toString().toLowerCase().replace(" ", "_")] || tstyles.not_tested}
										              						onMouseEnter={() => compliance && setHoveredInfo([index, compliance.toString(), comp_index])}
										              						onMouseLeave={() => setHoveredInfo(null)}
												            			>
												            				<i class="fa-solid fa-house-fire"></i>
												            				{(hoveredInfo && hoveredInfo[0] === index && compliance && hoveredInfo[1] === compliance.toString() && hoveredInfo[2] === comp_index) && (
														            			<div className={tstyles.statusoverbox} style={{bottom: '-40px'}}>
														            		    	<p className={tstyles.statusovertext}>Gas: {hoveredInfo[1]}</p>
														            		  	</div>
													             			)}
												            			</div>
													          		</>
																	}
																	{comp_index == 2 &&
												          			<>
												            			<div
												            				style={{position: 'relative'}}
												            				className={compliance && tstyles[compliance.toString().toLowerCase().replace(" ", "_")] || tstyles.not_tested}
										              						onMouseEnter={() => compliance && setHoveredInfo([index, compliance.toString(), comp_index])}
										              						onMouseLeave={() => setHoveredInfo(null)}
												            			>
												            				<i class="fa-solid fa-fire"></i>
											            					{(hoveredInfo && hoveredInfo[0] === index && compliance && hoveredInfo[1] === compliance.toString() && hoveredInfo[2] === comp_index) && (
													            				<div className={tstyles.statusoverbox} style={{bottom: '-40px'}}>
													            			    	<p className={tstyles.statusovertext}>Smoke: {hoveredInfo[1]}</p>
													            			  	</div>
												             				)}
												            			</div>
													         		</>
																	}
																</>
																)
															})}
														</div>
													</td>
													<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Files:</strong>
														<div className="serviceTD smallerStatus">
															{(IH.files && IH.files.length > 0) ? IH.files.map((OIF, idx) => {
																return (
																	<>
																	{Object.keys(OIF)[0] === "electrical" &&
																		<a
																			style={{position: 'relative'}}
																			className={tstyles.file_box}
																			href={Object.values(OIF)[0]}
																			target="_blank"
												            			>
												            				<i class="fa-solid fa-bolt"></i>
												            			</a>
																	}

																	{Object.keys(OIF)[0] === "gas" &&
																		<a
																			style={{position: 'relative'}}
																			className={tstyles.file_box}
																			href={Object.values(OIF)[0]}
																			target="_blank"
												            			>
												            				<i class="fa-solid fa-house-fire"></i>
												            			</a>
																	}

																	{Object.keys(OIF)[0] === "smoke" &&
																		<a
																			style={{position: 'relative'}}
																			className={tstyles.file_box}
																			href={Object.values(OIF)[0]}
																			target="_blank"
												            			>
												            				<i class="fa-solid fa-fire"></i>
												            			</a>
																	}

																	{Object.keys(OIF)[0] === "service" &&
																		<a
																			style={{position: 'relative'}}
																			className={tstyles.file_box}
																			href={Object.values(OIF)[0]}
																			target="_blank"
												            			>
												            				<i className="fa-solid fa-clipboard-list"></i>
												            			</a>
																	}
																	</>
																)})
																: <p>No files</p>
															}
														</div>
													</td>
												</tr>
											)
										})
											: <div className={tstyles.add_first}><p>There are no history files available.</p></div>
										}
									</>
								}
							</>
						</tbody>
					</table>
				</div>
	      	  }

	          {indNav === "Service History" &&
            	<div style={{padding: '30px', flexGrow: "1", boxSizing: "border-box", position: "relative"}}>
					<table style={{tableLayout: "fixed"}}>
						<thead className={tstyles.visible_l}>
						    <tr className="tr_heading_sticky">
						      	<th style={{color: "white"}}>Reference</th>
						      	<th style={{color: "white"}}>Type</th>
						        <th style={{color: "white"}}>Complete on</th>
						        <th style={{color: "white"}}>Files</th>
						    </tr>
						</thead>
						<tbody>
							<>
		        				{internalLoading
		        				?
									<div className={tstyles.load}>
										<hr/><hr/><hr/><hr/>
									</div>
								:
									<>
										{indHistory && indHistory.length > 0 ? indHistory.map((IH, index) => {
											return (
												<tr className={tstyles.nohover}>
						        					<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Reference:</strong> <p>{IH.Name}</p></td>
													<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Type:</strong><p>{IH.Type__c}</p></td>
													<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Completed on:</strong> <p>{formatDate(IH.Completed_Time__c) || '-'}</p></td>
													<td style={{whiteSpace: "normal", wordWrap: "break-word", padding: '10px 20px', cursor: 'default'}}><strong className={tstyles.hidden_l}>Files:</strong>
														<div className="serviceTD smallerStatus">
															{(IH.files && IH.files.length > 0) ? IH.files.map((OIF, idx) => {
																return (
																	<>
																	{Object.keys(OIF)[0] === "electrical" &&
																		<a
																			style={{position: 'relative'}}
																			className={tstyles.file_box}
																			href={Object.values(OIF)[0]}
																			target="_blank"
												            			>
												            				<i class="fa-solid fa-bolt"></i>
												            			</a>
																	}

																	{Object.keys(OIF)[0] === "gas" &&
																		<a
																			style={{position: 'relative'}}
																			className={tstyles.file_box}
																			href={Object.values(OIF)[0]}
																			target="_blank"
												            			>
												            				<i class="fa-solid fa-house-fire"></i>
												            			</a>
																	}

																	{Object.keys(OIF)[0] === "smoke" &&
																		<a
																			style={{position: 'relative'}}
																			className={tstyles.file_box}
																			href={Object.values(OIF)[0]}
																			target="_blank"
												            			>
												            				<i class="fa-solid fa-fire"></i>
												            			</a>
																	}

																	{Object.keys(OIF)[0] === "service" &&
																		<a
																			style={{position: 'relative'}}
																			className={tstyles.file_box}
																			href={Object.values(OIF)[0]}
																			target="_blank"
												            			>
												            				<i className="fa-solid fa-clipboard-list"></i>
												            			</a>
																	}
																	</>
																)})
																: <p>No files</p>
															}
														</div>
													</td>
												</tr>
											)
										})
											: <div className={tstyles.add_first}><p>There are no history files available.</p></div>
										}
									</>
								}
							</>
						</tbody>
					</table>
				</div>
	      	  }

	          {indNav === "Payment" &&
		          <div className={tstyles.payment_container}>
		          	{!paymentLoading
		          	?
		          		<>
			          		{finalPrice
			          			?
					          		<div className={finalPrice ? tstyles.payment_content_price : tstyles.payment_content}>
					          			<div className={tstyles.payment_price}>
											<p>Price to charge customer:</p>
											<h1>${finalPrice}</h1>
										</div>

										<div className={tstyles.payment_sub}>
											<a href="#" onClick={toggleSquarePayment}>Open Square</a>
											<div ref={(el) => { priceTimer.current = el }} className={tstyles.w3_green} style={{ height: '12px', width: '0%', transition: 'width 1s' }}></div>
										</div>
									</div>
			          			:
					          		<div className={tstyles.payment_content}>
										<img src={payment_img}/>

										<button onClick={() => handleTradieJob('payment', indJob)}>Reveal Price</button>
									</div>
			          		}
						</>
			        :
						<div className={tstyles.load}>
							<hr/><hr/><hr/><hr/>
						</div>
					}
				  </div>
	      	  }

	          {indNav === "Cancel Job" &&
	          	<div style={{padding: '15px', width: '25%'}}>
					<div className={tstyles.req_overview}>
						<p className={tstyles.proptype_heading}>Reason of cancellation</p>
						<select onChange={(input) => handleTradieJob('cancel', 'set', 'reason', input)} value={cancellationReason && cancellationReason['reason']}>
							<option value="No one home">No one home</option>
							<option value="Key unavailable">Key unavailable</option>
							<option value="No power/gas">No power/gas</option>
							<option value="Running late">Running late</option>
						</select>
					</div>

					<div className={tstyles.req_overview}>
						<p className={tstyles.proptype_heading}>Comments</p>
						<textarea type="text" onChange={(input) => handleTradieJob('cancel', 'set', 'comments', input)} value={cancellationReason && cancellationReason['comments']}></textarea>
					</div>

					<button className={tstyles.cancelsubmit} onClick={() => handleTradieJob('cancel', 'submit')}>Cancel Job</button>
	          	</div>
	      	  }
	        </div>
	      </div>
	    </div>
	)
}


const NavOptions = ({ param, navAddress, navigationOptions }) => {
	return (
		<div className={tstyles.navOptionsContainer}>
			<div className={tstyles.navOptionsInner}>
				<div className={tstyles.showNavTitle}>
					<h3>Select Navigation</h3>
					<button className={tstyles.backPopup} onClick={() => navigationOptions('toggle')}><i className="fa-solid fa-x"></i></button>
				</div>

				<div className={tstyles.req_overview}>
					<a className={tstyles.proptype_heading} href={`http://maps.google.com/?q=${navAddress}`} target="_blank">Google Maps</a>
				</div>

				<div className={tstyles.req_overview}>
					<a className={tstyles.proptype_heading} href={`http://maps.apple.com/?q=${navAddress}`} target="_blank">Apple Maps</a>
				</div>

				<div className={tstyles.req_overview}>
					<a className={tstyles.proptype_heading} href={`https://waze.com/ul?q=${navAddress}`} target="_blank">Waze</a>
				</div>
			</div>
		</div>
	)
}


const TradieDashboard = () => {
	const handleLogout = () => {
		localStorage.removeItem("admintoken");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const navigate = useNavigate();
  	const location = useLocation();
  	const isExternal = location.state && location.state.arrivedExternally;
  	const navigationData = location.state && location.state.updatedJob;

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [internalLoading, setInternalLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
 	const [validationError, setValidationError] = useState(null);
 	const [savedCognitoLoading, setSavedCognitoLoading] = useState(true);
 	const [loadingComplete, setLoadingComplete] = useState(false);
 	const [mapLoading, setMapLoading] = useState(false)
 	const [paymentLoading, setPaymentLoading] = useState(false)

 	const [userJobs, setUserJobs] = useState([])
 	const [userJobsOG, setUserJobsOG] = useState([])
 	const [showTradieTools, setShowTradieTools] = useState(false)
 	const [indJob, setIndJob] = useState(null)
 	const [indNav, setIndNav] = useState(null)
 	const [indHistory, setIndHistory] = useState([])
 	const [hoveredInfo, setHoveredInfo] = useState(null);
 	const [cancellationReason, setCancellationReason] = useState({})
 	const [position, setPosition] = useState([])
 	const [activeFilter, setActiveFilter] = useState("Pending")
 	const [oldIncompleteJobs, setOldIncompleteJobs] = useState([])
 	const [upcomingCases, setUpcomingCases] = useState([])
 	const [markerClicked, setMarkerClicked] = useState(null)
 	const [markerJob, setMarkerJob] = useState(null)
 	const [finalPrice, setFinalPrice] = useState(null)
 	const [showNavigationOptions, setShowNavigationOptions] = useState(false)
 	const [navAddress, setNavAddress] = useState(null)
	const priceTimer = useRef(null);

 	
	const param = useParams();
	const url = `/api/technician/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					if (isExternal && navigationData) {
						handleTradieJob('open', navigationData)
					}

					const data = await axios.get(url, axios_config);
					setAccountData(data.data.accountData);
					setUserJobs(data.data.userJobs.filter(job => job.status === "Pending" || job.custom))
					setUserJobsOG(data.data.userJobs)

					setOldIncompleteJobs(data.data.oldIncompleteJobs)
					setUpcomingCases(data.data.upcomingCases)

					setIsAuthenticated(true)
				} catch(e) {
					console.log(e)
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				setAccountData(error);
			} finally {
				setMapLoading(true)
				const markerData = await axios.get(`/api/technician/mapMarkers/${param._id}`);

				setMapLoading(false)
				await initializeMapWithAccessToken(param, markerData.data.mapMarkers, mapDrag)


				let marker_btn = document.getElementsByClassName('marker_btn')
			    if (marker_btn && marker_btn.length > 0) {
			    	for (let m=0; m < marker_btn.length; m++) {
			    		marker_btn[m].addEventListener('click', () => handleMarkerClick('click', marker_btn[m], markerData.data.markerJobs));
			    	}
			    }
			    
			}
		};
		initLoad();

	    return () => {
			let marker_btn = document.getElementsByClassName('marker_btn')
		    if (marker_btn && marker_btn.length > 0) {
		    	for (let m=0; m < marker_btn.length; m++) {
		    		marker_btn[m].removeEventListener('click', () => handleMarkerClick());
		    	}
		    }
	    };
	}, [param, url]);

	const handleShowTradieTools = (control, nav) => {
		switch (control) {
			case 'set':
				setShowTradieTools(true)
				break;
			case 'close':
				setShowTradieTools(false);
				break;
			case 'navigate':
				switch (nav) {
					case 'dashboard':
						navigate(`/technician/dashboard/${param._id}`);
						break;
					case 'calendar':
						navigate(`/technician/calendar/${param._id}`);
						break;
				}
				break;
		}
	}


	const search_control = (event) => {
		if (indJob) { return false }

		var input, filter, target_div, inspection, property, timestamp, i, txtValue, txtValue2, txtValue3;
		input = document.getElementById("searchbar");
		filter = input.value.toUpperCase();
		target_div = document.getElementById("tq_jobs").children;
		for (i = 1; i < target_div.length; i++) {
			inspection = target_div[i].getElementsByTagName("p")[0];
			property = target_div[i].getElementsByTagName("p")[1];
			timestamp = target_div[i].getElementsByTagName("p")[2];

			if (inspection && property && timestamp) {
				txtValue = inspection.textContent || inspection.innerText;
				txtValue2 = property.textContent || property.innerText;
				txtValue3 = timestamp.textContent || timestamp.innerText;

				if (txtValue.toUpperCase().indexOf(filter) > -1 || txtValue2.toUpperCase().indexOf(filter) > -1 || txtValue3.toUpperCase().indexOf(filter) > -1) {
					target_div[i].style.display = "flex";
				} else {
					target_div[i].style.display = "none";
				}
			}
		}
	}

	function formatDate(dateString) {
	    const dateObj = new Date(dateString);
	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);
	    return `${day}-${month}-${year}`;
	}

	function formatDateAndTime(inputDateString) {
		const inputDate = new Date(inputDateString);

		const dateOptions = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};

		const timeOptions = {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
			timeZone: 'Australia/Sydney',
		};

		const formattedDate = inputDate.toLocaleString(undefined, dateOptions);
		const formattedTime = inputDate.toLocaleString(undefined, timeOptions);

		return `${formattedDate} | ${formattedTime.toLowerCase()}`;
	}

	function formatDateCognito(dateString) {
	    const dateObj = new Date(dateString);
	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);
	    return `${year}-${month}-${day}`;
	}

	async function getPositionAsync() {
	  return new Promise((resolve, reject) => {
	    if ("geolocation" in navigator) {
	      navigator.geolocation.getCurrentPosition(
	        (positionData) => {
	          const position = [positionData.coords.latitude, positionData.coords.longitude];
	          resolve(position);
	        },
	        (error) => {
	          reject(new Error('Location tracking denied.'));
	        }
	      );
	    } else {
	      reject(new Error('Geolocation not available.'));
	    }
	  });
	}

	function checkJobTypesCompletion(job) {
	  for (const type in job.jobTypes) {
	    if (job.jobTypes[type]) {
	      if (!job.completed[type]) {
	        return false;
	      }
	    }
	  }
	  return true;
	}


	const handleTradieJob = async (control, ind, name=null, input=null) => {
		switch (control) {
			case 'open':
				setInternalLoading(true)
				if (!ind.jobTypes['case'] && !ind.jobTypes['service']) {
					let formattedType = Object.entries(ind.jobTypes)
					  .filter(([jobType, selected]) => selected)
					  .map(([jobType]) => jobType.charAt(0).toUpperCase() + jobType.slice(1))
					  .join(', ')

					ind['formattedType'] = formattedType
				}

				setIndJob(ind)

				if (ind.stamps.start.stamped && !(ind.stamps.completed.stamped)) {
					if (ind.jobTypes['electrical'] && !(ind.completed['electrical'])) {
						setIndNav("Electrical Report")
					} else if (ind.jobTypes['gas'] && !(ind.completed['gas'])) {
						setIndNav("Gas Report")
					} else if (ind.jobTypes['smoke'] && !(ind.completed['smoke'])) {
						setIndNav("Smoke Report")
					} else if (ind.jobTypes['case'] && !(ind.completed['case'])) {
						setIndNav("Case Report")
					} else if (ind.jobTypes['service'] && !(ind.completed['service'])) {
						setIndNav("Service Report")
					} else if (ind.jobTypes['checklist'] && !(ind.completed['checklist'])) {
						setIndNav("Checklist Report")
					} else {
						if (ind.jobTypes['service'] || ind.jobTypes['checklist']) {
							setIndNav("Service History")
							setLoadingComplete(true)
							setInternalLoading(true)
							const history = await axios.post(`/api/technician/history/${param._id}`, {assetAddress: `${ind.property.street}, ${ind.property.suburb}, ${ind.property.postcode}, ${ind.property.state}`, service: ind.jobTypes.service});
							setIndHistory(history.data)
							setInternalLoading(false)
						} else {
							setIndNav("History")
							setLoadingComplete(true)
							setInternalLoading(true)
							const history = await axios.post(`/api/technician/history/${param._id}`, {assetAddress: `${ind.property.street}, ${ind.property.suburb}, ${ind.property.postcode}, ${ind.property.state}`, service: ind.jobTypes.service});
							setIndHistory(history.data)
							setInternalLoading(false)
						}
					}
				} else {
					if (ind.jobTypes['service'] || ind.jobTypes['checklist']) {
						setIndNav("Service History")
						setLoadingComplete(true)
						setInternalLoading(true)
						const history = await axios.post(`/api/technician/history/${param._id}`, {assetAddress: `${ind.property.street}, ${ind.property.suburb}, ${ind.property.postcode}, ${ind.property.state}`, service: ind.jobTypes.service});
						setIndHistory(history.data)
						setInternalLoading(false)
					} else {
						setIndNav("History")
						setLoadingComplete(true)
						setInternalLoading(true)
						const history = await axios.post(`/api/technician/history/${param._id}`, {assetAddress: `${ind.property.street}, ${ind.property.suburb}, ${ind.property.postcode}, ${ind.property.state}`, service: ind.jobTypes.service});
						setIndHistory(history.data)
						setInternalLoading(false)
					}
				}
				break;
			case 'nav':				
				setIndNav(ind)
				if (ind === "Electrical Report" && indJob.saved['electrical']) {
					setSavedCognitoLoading(true)
				}
				if (ind === "Gas Report" && indJob.saved['gas']) {
					setSavedCognitoLoading(true)
				}
				if (ind === "Smoke Report" && indJob.saved['smoke']) {
					setSavedCognitoLoading(true)
				}
				if (ind === "Case Report" && indJob.saved['case']) {
					setSavedCognitoLoading(true)
				}
				if (ind === "Service Report" && indJob.saved['service']) {
					setSavedCognitoLoading(true)
				}
				if (ind === "Checklist Report" && indJob.saved['checklist']) {
					setSavedCognitoLoading(true)
				}
				if (ind === "History") {
					setInternalLoading(true)
					const history = await axios.post(`/api/technician/history/${param._id}`, {assetAddress: `${indJob.property.street}, ${indJob.property.suburb}, ${indJob.property.postcode}, ${indJob.property.state}`, service: indJob.jobTypes.service});
					setIndHistory(history.data)
					setInternalLoading(false)
				}
				if (ind === "Service History") {
					setInternalLoading(true)
					const history = await axios.post(`/api/technician/history/${param._id}`, {assetAddress: `${indJob.property.street}, ${indJob.property.suburb}, ${indJob.property.postcode}, ${indJob.property.state}`, service: indJob.jobTypes.service});
					setIndHistory(history.data)
					setInternalLoading(false)
				}

				if (ind !== "History" && ind !== "Service History" && ind !== "Cancel Job" && ind !== indNav) {
					setInternalLoading(true)
					setLoadingComplete(false)
				}

				if (ind === "Payment") {
					setInternalLoading(true)
					setInternalLoading(false)
					setLoadingComplete(true)
				}


				let live_response = await axios.post(`/api/technician/is_submitted/${param._id}`, {
					job: indJob
				});

				setIndJob(live_response.data)

				break;
			case 'cancel':
				if (ind === "set") {
					setCancellationReason({...cancellationReason, [name]: input.target.value})
				} else {
					setIsLoading(true)
					const history = await axios.post(`/api/technician/cancel/${param._id}`, {
						reason: cancellationReason,
						job: indJob
					});
					window.location.reload(false)
				}
				break;
			case 'start':
				setIsLoading(true)

				let position_s;
				try {
					position_s = await getPositionAsync()
				} catch(err) {
					window.alert("In order to proceed, please enable and allow location tracking.")
					
					setLoadingComplete(true)
					setIsLoading(false)
					break;
				}

				let start_response = await axios.post(`/api/technician/start/${param._id}`, {
					job: ind,
					position: position_s
				});
				let userJobsDup = [...userJobs]
				let indexToReplace = userJobsDup.findIndex(item => item._id === start_response.data._id);

				if (indexToReplace !== -1) {
					userJobsDup[indexToReplace] = start_response.data;
				  	setUserJobs(userJobsDup)
				  	setIndJob({...indJob, stamps: start_response.data.stamps})
				}
				setLoadingComplete(true)
				setIsLoading(false)
				break;
			case 'leaving':
				let position_l = await getPositionAsync();
				await axios.post(`/api/technician/leaving/${param._id}`, {
					job: ind,
					position: position_l
				});
				resetToInitialState()
				break;
			case 'completed':
				setIsLoading(true)
				let submit_response = await axios.post(`/api/technician/is_submitted/${param._id}`, {
					job: indJob
				});

				setIndJob(submit_response.data)
				let jobsCompleted = checkJobTypesCompletion(submit_response.data)

				if (jobsCompleted) {
					let position_c = await getPositionAsync();
					await axios.post(`/api/technician/completed/${param._id}`, {
						job: ind,
						position: position_c
					});
					resetToInitialState()
				} else {
					setIsLoading(false)
					window.alert("Please complete all the forms first.")
					return false;
				}
				break;
			case 'sms':
				let sms_response = await axios.post(`/api/technician/send_sms/${param._id}`, {
					job: indJob
				});

				window.alert("Sent SMS Successfully!")
				break;
			case 'payment':
				setPaymentLoading(true)

				const price_pulled = await axios.post(`/api/technician/payment/${param._id}`, {
					job: ind
				});

				const final_price = price_pulled.data.final_price || 0
				setFinalPrice(final_price)

				// clear finalPrice after 15s
				setPaymentLoading(false)
				startProgress()
				break;
		}
	}

	const startProgress = () => {
		const timerDuration = 15000;
		const intervalDuration = 10;

		let startTime = null;

		const updateWidth = () => {
	  		const currentTime = Date.now();
	  		const elapsed = currentTime - startTime;
	  		const progressPercentage = (elapsed / timerDuration) * 100;

	  		priceTimer.current.style.width = progressPercentage + '%';

	  		if (elapsed < timerDuration) {
	    		requestAnimationFrame(updateWidth);
	  		} else {
	  			setFinalPrice(null)
	  		}
		};

		startTime = Date.now();
		requestAnimationFrame(updateWidth);
	};

	const toggleSquarePayment = (e) => {
		const jsonData = {
		  amount_money: {
		    amount: finalPrice * 100,
		    currency_code: "AUD"
		  },
		  callback_url: "https://myloportal.com.au/api/square_auth",
		  client_id: "sq0idp-0wTfIFDAoX-3NY0Z2SjMLw",
		  version: "1.3",
		  notes: indJob._id,
		  options: {
		    supported_tender_types: ["CREDIT_CARD"]
		  }
		};

		const base64Data = encodeURIComponent(JSON.stringify(jsonData));

		e.preventDefault();
		window.open(`square-commerce-v1://payment/create?data=${base64Data}`);
	};

	const handleCognito = async () => {
		if (window.location.href.includes('#')) {
			console.log("HIT")
			let updated_job = await axios.post(`/api/technician/cognito/${param._id}`, {
				suffix: window.location.href.split('#')[1],
				job: indJob,
				type: indNav.split(' ')[0].toLowerCase()
			});
			let updatedJob = updated_job.data
			let su_response = await axios.post(`/api/technician/is_submitted/${param._id}`, {
				job: indJob
			});
			setIndJob(su_response.data)

			navigate(`/technician/dashboard/${param._id}`, { state: { arrivedExternally: true, updatedJob } });
		} else {
			setLoadingComplete(true)
		}
	}

	const resetToInitialState = () => {
 		setIndHistory([]);
 		setHoveredInfo(null);
 		setCancellationReason({});
 		setPosition([])
 		setUserJobs([]);
		setOldIncompleteJobs([])
		setUpcomingCases([])
		setActiveFilter("Pending")
 		setIndJob(null);
 		setIndNav(null);
 		setLoadingComplete(false)
 		
 		navigate(`/technician/dashboard/${param._id}`, { state: { arrivedExternally: false } });
	};

	const handleSavedCognito = () => {
		setSavedCognitoLoading(false)
		setLoadingComplete(true)
	}

	const filterResults = (filter) => {
		let userJobsUnfiltered = [...userJobsOG]
		let userJobsFiltered;

		switch (filter) {
			case 'pending':
				userJobsFiltered = userJobsUnfiltered.filter(job => job.status === "Pending" || job['custom'])
				setActiveFilter("Pending")
				break;
			case 'incomplete':
				if (oldIncompleteJobs.length > 0) {
					let unfilteredAndOld = [...oldIncompleteJobs, ...userJobsOG]
					userJobsFiltered = unfilteredAndOld.filter(job => job.status === "Incomplete")
				} else {
					userJobsFiltered = userJobsUnfiltered.filter(job => job.status === "Incomplete")
				}
				
				setActiveFilter("Incomplete")
				break;
			case 'completed':
				userJobsFiltered = userJobsUnfiltered.filter(job => job.status === "Completed")
				setActiveFilter("Completed")
				break;
			case 'upcoming':
				userJobsFiltered = userJobsUnfiltered.filter(job => job.status === "Completed")
				setActiveFilter("Upcoming")
				break;
		}

		setUserJobs(userJobsFiltered)
	}


	const handleMarkerClick = async (control, marker?, jobs?) => {
		switch (control) {
			case 'click':
				let markerOpen = document.getElementById(`markerJob_${marker.id}`)

				if (markerOpen) {
					setMarkerClicked(null)
					setMarkerJob(null)
				} else {
					setMarkerClicked(null)
					setMarkerJob(null)

					let markerPointsArr = (marker.style.transform.split(')')[0].split('translate(')[1].split(', '))
					markerPointsArr[1] = `${Number(markerPointsArr[1].split('px')[0]) + 60}px`
					setMarkerClicked(markerPointsArr)

					let markerRelatedJob = jobs.filter(job => job._id.toString() === marker.id.toString())[0]
					setMarkerJob(markerRelatedJob)
				}
				break;
			case 'clear':
				if (marker.target.nodeName !== "I") {
					setMarkerClicked(null)
					setMarkerJob(null)
				}
				break;
		}
	}


	function mapDrag() {
		setMarkerClicked(null)
		setMarkerJob(null)
	}

	const navigationOptions = (control, ind=null) => {
		switch (control) {
			case 'toggle':
				if (showNavigationOptions) {
					setNavAddress(null)
				} else {
					setNavAddress(ind)
				}

				setShowNavigationOptions(!showNavigationOptions)
				break;
		}
	}


	return (
		<>
			<Offline>
				<div className="offline_container animate__animated animate__slideInDown">
					<i className="fa-solid fa-heart-crack"></i>
					<div className="offline_content">
						<p className="offline_title">You are offline</p>
						<p className="offline_subtitle">Please check your internet connect.</p>
					</div>

				</div>
			</Offline>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={tstyles.bg_white}>
								<Dashboard {...{ param, userJobs, formatDate, formatDateAndTime, handleTradieJob, indJob, indNav, indHistory, setHoveredInfo, hoveredInfo, cancellationReason, handleCognito, resetToInitialState, search_control, internalLoading, handleSavedCognito, savedCognitoLoading, loadingComplete, formatDateCognito, filterResults, activeFilter, upcomingCases, markerClicked, markerJob, handleMarkerClick, userJobsOG, showTradieTools, handleShowTradieTools, handleLogout, oldIncompleteJobs, mapLoading, paymentLoading, finalPrice, toggleSquarePayment, priceTimer, startProgress, accountData, navigationOptions, showNavigationOptions }} />

								{showNavigationOptions && <NavOptions {...{ param, navAddress, navigationOptions }} />}
							</div>
							: <Forbidden />
						}
					</>
				:
					<LoadingForm/>
			}
		</>
	);
};

export default TradieDashboard;