import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from './login.module.css';

import { ReactComponent as Logo } from "../../images/chatbot_logo.svg";
import bg from "../../images/bg.svg";
import mylo_banner from "../../images/mylo_banner.svg";


const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [target, setTarget] = useState(null)
  const [indTarget, setIndTarget] = useState(null)
  const [captureFactorAuth, setCaptureFactorAuth] = useState(false)
  const [authData, setAuthData] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState("password")

  useEffect(() => {
    const userData = async () => {
      const access_url = window.location.href.split("login");

      if (access_url[1].length > 0) {
        setTarget(access_url[1].split("&ind=")[0].split("?target=")[1])
        setIndTarget(access_url[1].split("&ind=")[1])
      }

    };
    userData();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleAuth = ({ currentTarget: input }) => {
    setAuthData({ ...authData, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    e.preventDefault();
    try {
      const url = "/api/auth";
      setIsLoading(true)
      const { data: res } = await axios.post(url, data);
      setIsLoading(false)

      localStorage.setItem("role_token", res.data.jwt_token);

      if (res.data.specific_role_name == "User") {
        if (res.data.user_factor_auth) {
          if (authData['code'] && authData['code'] !== "") {
            if (isRealWholeNumber(authData['code'])) {
              setIsLoading(true)
              const two_fa_response = await axios.post(`/api/dashboard/check_2fa`, {code: authData.code, _id: res._id});
              setIsLoading(false)

              if (!two_fa_response.data) {
                setError("Your 2fa code is incorrect or has expired. Please try again or resend the email.")
                return false;
              } else {
                setError("")
              }
            } else {
              setError("Please enter a real whole number.")
              return false;
            }
          } else {
            if (!captureFactorAuth) {
              setCaptureFactorAuth(true)
              return false;
            } else {
              setError("Please enter your 2fa code from your email.")
              return false;
            }
          }
        }


        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_portal_data", JSON.stringify(res.data['userPortalData']));

        if (target && indTarget) {
          const nav_data = await axios.get(`/api/dashboard/${res._id}/target_navigation/${target}/${indTarget}`);
          const navigation_data = nav_data.data

          if (navigation_data !== "Not found") {
            // navigate(`/${target}/${res._id}`, { state: { arrivedExternally: true, navigation_data } });
            // window.location.reload(false);

            // UNCOMMENT THIS FOR NEW PORTAL
            // ALSO DO THIS FOR reports.html
            const state = { from: { _id: navigation_data._id }, location: capitalizeFirstLetter(target) };
            sessionStorage.setItem('navigationState', JSON.stringify(state));
            navigate(`/portal/hub/${res._id}`);
            window.location.reload(false);
          } else {
            window.location = "/portal/hub/" + res._id;
          }
        } else {
          window.location = "/portal/hub/" + res._id;
        }

      } else if (res.data.specific_role_name == "Tradie") {
        localStorage.setItem("tradietoken", res.data.token);
        window.location = "/technician/dashboard/" + res._id;
      } else if (res.data.specific_role_name == "Admin") {
        localStorage.setItem("admintoken", res.data.token);
        window.location = "/admin/home/" + res._id;
      }
    } catch (error) {
      console.log(error)
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setIsLoading(false)
        setError(error.response.data.message);
      }
    }
  };

  const redirectForgotPassword = () => {
    navigate(`/forgot-password`)
  }

  const redirectRegister = () => {
    navigate(`/register`)
  }
  
  function isRealWholeNumber(str) {
    if (!/^\d+$/.test(str)) {
      return false;
    }

    if (str.length > 1 && str[0] === '0') {
      return false;
    }

    const number = parseInt(str, 10);
    return number >= 0;
  }

  const handlePasswordToggle = () => {
    if (type === "password") {
      setType("text")
    } else {
      setType("password")
    }
  }


  return (
    <div className={styles.outer_container}>
      <div className={styles.banner_container}>
        <img src={bg} className={styles.bg}/>

        <div className={styles.banner_inner}>
          <img src={mylo_banner} className={styles.mylo_banner}/>
        </div>
      </div>

      {isLoading
        ?
          <div className={styles.load_container}>
            <div className={styles.load}>
              <hr />
              <hr />
              <hr />
              <hr />
            </div>
          </div>
        :

          <div className={styles.content_container}>
            <Logo className={styles.logo_main}/>
            <h1>Welcome back!</h1>
            <p className={styles.sub_title}>Please enter your details to continue</p>

            {captureFactorAuth
              ?
                <div className={styles.form_container}>
                  <div className={styles.login_msg}>
                    <p>We have sent you an email with your authentication code!</p>
                  </div>

                  <div className={styles.input_container}>
                    <p>2FA code</p>
                    <input
                      type="text"
                      name="code"
                      onChange={handleAuth}
                      value={authData.code}
                      required
                    />
                  </div>

                </div>
              :
                <div className={styles.form_container}>
                  {error &&
                    <div className={`${styles.login_msg} ${styles.error_msg}`}>
                      <p>{error}</p>
                    </div>
                  }

                  <div className={styles.input_container}>
                    <p>Email</p>
                    <input
                      type="text"
                      name="email"
                      onChange={handleChange}
                      value={data.email}
                      required
                    />
                  </div>

                  <div className={styles.input_container}>
                    <p>Password</p>
                    <input
                      type={type}
                      name="password"
                      className={styles.custom_input}
                      onChange={handleChange}
                      value={data.password}
                      required
                    />
                    <button className={styles.togglePassword} onClick={() => handlePasswordToggle()}>
                      {type === "password"
                        ? <i className="fa-solid fa-eye"></i>
                        : <i className="fa-solid fa-eye-slash"></i>
                      }
                    </button>
                  </div>

                  <button className={styles.forgot_password} onClick={redirectForgotPassword}><p>Forgot password?</p></button>
                </div>
            }

            <div className={styles.form_footer}>
              <button className={styles.form_btn} onClick={handleSubmit}>
                <p>
                  {captureFactorAuth
                    ? "Authenticate"
                    : "Login"
                  }
                </p>
              </button>

              <div className={styles.register_container}>
                <p>Don't have an account?</p>
                <button onClick={redirectRegister}><p>Register now!</p></button>
              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default Login;
