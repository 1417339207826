import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./styles.css";

import { ReactComponent as Logo } from "../../images/mylologo.svg";
import snippet_banner from "../../images/snippet_banner.svg";
import commerical from "../../images/commerical.jpg";
import residential from "../../images/residential.jpg";
import c_and_r from "../../images/c_and_r.jpg";

const Signup = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [userData, setUserData] = useState({});
    const [validationError, setValidationError] = useState(null);
    const [falseError, setFalseError] = useState(false);

    const param = useParams();
    const url = `/api/users/${param._id}/verify/${param.token}`;

    useEffect(() => {
      const userData = async () => {
        try {
          const data = await axios.get(url);
          setData(data.data)


          setValidationError(null)
          setFalseError(false)
        } catch (error) {
          setFalseError(true)
          setValidationError(error.response.data.message)
        }
      };
      userData();
    }, []);


    const handleChange = ({ currentTarget: input }) => {
        setUserData({ ...userData, [input.name]: input.value });
    };


    const controlPanel = async (direction) => {
      switch (direction) {
        case 'submit':
          try {
            // validation
            if (!(userData.hasOwnProperty('password')) || userData['password'] == '') {
              setValidationError(`Please enter a password.`)
              return false;
            } else {
              if (isValidPassword(userData['password']) !== true) {
                setValidationError(isValidPassword(userData['password']))
                return false;
              }
            }

            setValidationError(null)
            let postData = await axios.post(url, userData)
            navigate(`/login`)
          } catch (error) {
            console.log(error)
          }

          setValidationError(null)
          break;
      }
    }



    function isValidPassword(password) {
      const minLength = 8;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numericRegex = /[0-9]/;

      if (password.length < minLength) {
        return "The password length must be greater than or equal to 8 characters";
      }

      if (!uppercaseRegex.test(password)) {
        return "The password must contain one or more uppercase characters";
      }

      if (!lowercaseRegex.test(password)) {
        return "The password must contain one or more lowercase characters";
      }

      if (!numericRegex.test(password)) {
        return "The password must contain one or more numeric values";
      }

      return true;
    }

    return (
        <div className="bg">
            <div className="wrapper centered">
                <div className="outer">
                    <div className="login_panel">

                      <div className="logoContainer">
                        <Logo className="logo"/>
                      </div>

                      <div className="login_container">
                        <div className="login_form">
                          <div className="login_container">
                              <div className="login_text">
                                  <h1>Get Started</h1>
                                  <p>
                                      Provide us with some of your
                                      details.
                                  </p>
                              </div>
                              <div className="field_half">
                                  <div className="field_container">
                                      <label htmlFor="first_name">
                                          First name
                                      </label>
                                      <input
                                          type="text"
                                          name="first_name"
                                          value={data.first_name}
                                          required
                                          disabled={true}
                                          className="custom_input"
                                      />
                                  </div>
                                  <div className="field_container">
                                      <label htmlFor="last_name">
                                          Last name
                                      </label>
                                      <input
                                          type="text"
                                          name="last_name"
                                          value={data.last_name}
                                          required
                                          disabled={true}
                                          className="custom_input"
                                      />
                                  </div>
                              </div>

                              <div className="field_full">
                                <label htmlFor="email">Email address</label>
                                <input
                                    className="mb-15 custom_input"
                                    type="email"
                                    name="email"
                                    value={data.email}
                                    required
                                    disabled={true}
                                />
                              </div>

                              <div className="field_full">
                                <label htmlFor="password">Password</label>
                                <input
                                    className="mb-30 custom_input"
                                    type="password"
                                    name="password"
                                    placeholder="*********"
                                    onChange={handleChange}
                                    value={userData.password}
                                    required
                                />
                              </div>

                              <div className="form_controls">
                                {validationError && (
                                    <div className="error-box"><p>{validationError}</p></div>
                                )}

                                {!falseError &&
                                  <button type="button" className="submit_btn" onClick={() => controlPanel('submit')}>Sign up</button>
                                }
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="graphic_panel">
                        <h1>Simpler, smarter, better, MYLO.</h1>
                        <img src={snippet_banner} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
