import { useEffect, useState, useRef } from "react";
import axios from "axios";
import styles from './utilstyles.module.css';


const PopUpInspectionType = ({ param, handleActions, node }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({})


  useEffect(() => {
    const handleLoad = async () => {
      if (node) {
        setFormData({package_name: node.package})
      }
    }

    handleLoad()
  }, []);

  const handlePopup = async (control, name?, input?) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          alert("An email has been successfully sent to office to request this package change.")
          handleClose(true)
          const res = await axios.post(`/api/properties/${param._id}/change_package/${node.sf_id}`, { type: formData.package_name });
        }

        setInnerLoading(false)
        break;
      case 'set':
        if (formDataDup[name] === input) {
          formDataDup[name] = "Without Service Package"
        } else {
          formDataDup[name] = input
        }
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setFormData({})
      setValidationError(null)
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)
        setFormData({})
        setValidationError(null)
        handleActions('close')
      }
    }

  }

  const validationTest = async () => {
    if (page === 1) {
      if (!(formData.hasOwnProperty('package_name'))) {
        setValidationError("You did not change your package.");
        return false;
      } else {
        if (formData.package_name === node.package) {
          setValidationError("You did not change your package.");
          return false;
        }
      }
    }

    setValidationError(null)
    return true
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_auto}`}>
      <div className={styles.popup_heading}>
        <div>
          <p className={styles.mediumer_text}>Change inspection type</p>
          <p className={styles.small_text}>Use this module to request a change of inspection type.</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  <div className={styles.warning_box}>
                    <p className={styles.small_text}>For any due dates or additional information, please write a message in the inspection commments.</p>
                  </div>

                  <div className={styles.inspection_type_container}>
                    <p className={`${styles.text_label} ${styles.mid_text} ${styles.align_center}`}>Select your inspection type</p>

                    <div className={styles.inspection_type_grid}>
                      <button className={`${styles.radio_group} ${styles.radio_br} ${formData["inspection_electric"] === true && styles.active_radio}`} onClick={() => handlePopup("set_type", "inspection_electric")}>
                        <div><i className="fa-solid fa-bolt"></i></div>
                        <p className={`${styles.text_label} ${styles.mid_text}`}>Electrical</p>
                      </button>

                      <button className={`${styles.radio_group} ${styles.radio_br} ${styles.radio_bl} ${formData["inspection_gas"] === true && styles.active_radio}`} onClick={() => handlePopup("set_type", "inspection_gas")}>
                        <div><i className="fa-solid fa-house-fire"></i></div>
                        <p className={`${styles.text_label} ${styles.mid_text}`}>Gas</p>
                      </button>

                      <button className={`${styles.radio_group} ${styles.radio_bl} ${formData["inspection_smoke"] === true && styles.active_radio}`} onClick={() => handlePopup("set_type", "inspection_smoke")}>
                        <div><i className="fa-solid fa-fire"></i></div>
                        <p className={`${styles.text_label} ${styles.mid_text}`}>Smoke</p>
                      </button>
                    </div>
                  </div>
                </>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>
      </div>
    </div>
  );
};

export default PopUpInspectionType;