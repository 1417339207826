import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import styles from './login.module.css';

import { ReactComponent as Logo } from "../../images/chatbot_logo.svg";
import bg from "../../images/bg.svg";
import mylo_banner from "../../images/mylo_banner.svg";


const Apply = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [userData, setUserData] = useState({});
  const [validationError, setValidationError] = useState(null);
  const [falseError, setFalseError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [postMessage, setPostMessage] = useState(null)

  const param = useParams();
  const url = `/api/users/register`;


  const handleChange = ({ currentTarget: input }) => {
      setUserData({ ...userData, [input.name]: input.value });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const controlPanel = async (direction) => {
    switch (direction) {
      case 'submit':
        try {
          // validation
          if (!(userData.hasOwnProperty('first_name')) || userData['first_name'] == '') {
            setValidationError(`Please enter your first name.`)
            return false;
          }

          if (!(userData.hasOwnProperty('last_name')) || userData['last_name'] == '') {
            setValidationError(`Please enter your last name.`)
            return false;
          }

          if (!(userData.hasOwnProperty('email')) || userData['email'] == '') {
            setValidationError(`Please enter your email address.`)
            return false;
          } else {
            if (!(isValidEmail(userData.email))) {
              setValidationError(`Please enter a valid email address.`)
              return false;
            }
          }

          setValidationError(null)
          setUserData({})
          setIsLoading(true)
          let postData = await axios.post(url, userData)
          setIsLoading(false)

          setPostMessage(postData.data.message)
        } catch (error) {
          console.log(error)
        }

        setValidationError(null)
        break;
    }
  }

  const redirectLogin = () => {
    navigate(`/login`)
  }


  return (
    <div className={styles.outer_container}>
      <div className={styles.banner_container}>
        <img src={bg} className={styles.bg}/>

        <div className={styles.banner_inner}>
          <img src={mylo_banner} className={styles.mylo_banner}/>
        </div>
      </div>


      {isLoading
        ?
          <div className={styles.load_container}>
            <div className={styles.load}>
              <hr />
              <hr />
              <hr />
              <hr />
            </div>
          </div>
        :
          <div className={styles.content_container}>
            <Logo className={styles.logo_main}/>
            <h1>Register!</h1>
            <p className={styles.sub_title}>We will search for your data in our system and you will receive an email.</p>


            <div className={styles.form_container}>
              {validationError &&
                <div className={`${styles.login_msg} ${styles.error_msg}`}>
                  <p>{validationError}</p>
                </div>
              }

              {postMessage &&
                <div className={`${styles.login_msg}`}>
                  <p>{postMessage}</p>
                </div>
              }

              <div className={styles.input_container}>
                <p>First name</p>
                <input
                  type="text"
                  name="first_name"
                  onChange={(input) => handleChange(input)}
                  value={userData.first_name}
                  required
                />
              </div>

              <div className={styles.input_container}>
                <p>Last name</p>
                <input
                  type="text"
                  name="last_name"
                  onChange={(input) => handleChange(input)}
                  value={userData.last_name}
                  required
                />
              </div>

              <div className={styles.input_container}>
                <p>Email</p>
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={userData.email}
                  required
                />
              </div>
            </div>

            <div className={styles.form_footer}>
              {!falseError &&
                <button className={styles.form_btn} onClick={() => controlPanel('submit')}>
                  <p>Submit</p>
                </button>
              }

              <div className={styles.register_container}>
                <button onClick={redirectLogin}><p>Back to login!</p></button>
              </div>
            </div>

          </div>
      }
    </div>
  );
};

export default Apply;
