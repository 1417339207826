import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import moment from "moment-timezone"
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import mylologo from '../../../images/mylologo.svg'
import styles from "./AdminUsers.module.css"
import Forbidden from '../../Forbidden';
import initializeMapWithAccessToken from '../../../utils/mapUtils';
import PopUpFilter from './PopUpFilter';
import IndInspection from './IndInspection';
import IndCase from './IndCase';
import IndService from './IndService';
import IndOverview from './IndOverview';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={`${styles.sidebar_account}`}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/admin/home/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-house ${styles.sidebar_icon}`} />
						</div>
					</Link>
				
					<Link to={`/admin/users/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-users ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/technicians/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-screwdriver-wrench ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/payments/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/map/${param._id}`}>
						<div className={`${styles.sidebar_box} ${styles.sidebar_active}`}>
							<i className={`fa-solid fa-map ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link className={styles.disabled_link}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gear ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

const DashboardPage = ({ param, navType, changeNavType, search_control, allPayments, formatDate, allUsers, indPayment, popUpControls, loadingMap, showingMap, handleMarkerClick, markerJob, popUpFilter, refreshDashboard, allFilters, handleNewFilterData, activeFilter, handleCollapse, collapseDeg, collapseFilters, clearOpened, showOverview }) => {
	return (
		<div className={`${styles.page_container}`}>
			<div className={`${styles.sites_container_map} ${(popUpFilter || markerJob || showOverview) && styles.hidden_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						<button onClick={() => popUpControls('open')}>
							<i className="fa-solid fa-plus"></i>
							<p className={styles.smaller_font}>Add filter</p>
						</button>
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Map' && styles.sitenav_active}`} onClick={() => changeNavType('Map')}><p>Map</p></button>
					</div>
				</div>

				<div className={`${styles.sites_tablecontainer}`}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder="Search by title..."
								    onKeyUp={(event) => search_control(event, "button_table")}
								/>
							</div>
						</div>
					</div>


					<div className={`${styles.table_container} ${collapseFilters ? styles.mapOuterContainerCollapsed : styles.mapOuterContainer}`}>

						{!collapseFilters &&
							<table className={styles.sites_table}>
								{loadingMap
									? 
										<div className={styles.load}>
											<hr /><hr /><hr /><hr />
										</div>
									:
										<>
											{showingMap && 
												<>	
									    			{allFilters && allFilters.length > 0
									    				? 
															<tbody className={styles.fleet_tbody}>
																<tr className={`${styles.fleet_container} ${styles.nohover}`}>
																	<div className={styles.filterList} id="button_table">
													    				{allFilters.map((IF, key) => {
													    					return (
													    						<td className={`${styles.fleet_td} ${styles.nohover}`} key={key}>
														    						<button className={`${styles.filterItem} ${styles.fleet_box} ${activeFilter && activeFilter.toString() === IF._id.toString() && styles.activeFilterItem}`} onClick={(input) => handleNewFilterData('apply', IF._id, input)}>
														    							<p className="indFilter_title">{IF.title}</p>
														    							<div className={styles.filterInner}>
														    								{(IF.time_elapsed && IF.time_elapsed.length > 0) && <p className={`${styles.filterP} ${styles.smaller_font} ${styles.time_stamp}`}>~{IF.time_elapsed[IF.time_elapsed.length - 1].time_taken}s</p>}
														    								<p className={`${styles.filterP} ${styles.smaller_font}`}>Inspection: {IF.inspection[0].active ? "Yes" : "No"}</p>
														    								<p className={`${styles.filterP} ${styles.smaller_font}`}>Case: {IF.case[0].active ? "Yes" : "No"}</p>
														    								<p className={`${styles.filterP} ${styles.smaller_font}`}>Service: {IF.service[0].active ? "Yes" : "No"}</p>
														    							</div>

														    							<div className={styles.filterControls}>
														    								<button onClick={() => handleNewFilterData('edit', IF._id)}><i className="fa-solid fa-pen"></i></button>
														    								<button onClick={() => handleNewFilterData('overview', IF._id)}><i className="fa-solid fa-list"></i></button>
														    								<button onClick={() => handleNewFilterData('export', IF._id)}><i className="fa-solid fa-file-export"></i></button>
														    								<button onClick={() => handleNewFilterData('delete', IF._id)}><i className="fa-solid fa-trash-can"></i></button>
														    							</div>
														    						</button>
													    						</td>
																	    	)
																	    })}
																    </div>
														    	</tr>
															</tbody>
									    				: <h1 className={`${styles.medium_font} ${styles.create_first}`}>There are no filters available!</h1>
									    			}
												</>
											}
										</>	
								}
							</table>
						}

						{!loadingMap &&
							<div className={`${styles.collapseMap}`}>
								<div className={`${styles.collapseInner} ${collapseDeg === 0 && styles.collapseOpacitated}`} onMouseEnter={() => handleCollapse('enter')} onMouseLeave={() => handleCollapse('leave')} onClick={() => handleCollapse('toggle')}>
					            	<div className={styles.collapseBtn} style={{transform: `translateY(0.15rem) rotate(${collapseFilters ? "-" : ""}${collapseDeg}deg) translateZ(0px)`}}></div>
					            	<div className={styles.collapseBtn} style={{transform: `translateY(-0.15rem) rotate(${(!collapseFilters && collapseDeg !== 0) ? "-" : ""}${collapseDeg}deg) translateZ(0px)`}}></div>
					            </div>
							</div>
						}

						{loadingMap
							? 
								<div className={styles.load}>
									<hr /><hr /><hr /><hr />
								</div>
							:
								<>
									{showingMap && 
										<div className={styles.mapFullContainer}>
											<div id="map" onClick={(marker) => handleMarkerClick('clear', marker)}></div>

											<button id="resetMapButton" className={styles.centerIcon}><i className="fa-solid fa-arrows-to-dot"></i></button>
											<button onClick={() => clearOpened('all')} className={styles.refreshIcon}><i className="fa-solid fa-broom"></i></button>
											<button onClick={() => refreshDashboard()} className={styles.clearIcon}><i className="fa-solid fa-rotate-right"></i></button>
										</div>
									}
								</>
						}
					</div>
				</div>
			</div>
		</div>
	)
}


const Map = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [key, setKey] = useState(0);
	const [UserData, setUserData] = useState({});
	const [isLoading, setIsLoading] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(null)
	const [authStatusLoading, setIsAuthStatusLoading] = useState(null)
	const [popUpPage, setPopUpPage] = useState(1)
	const [loadingMap, setLoadingMap] = useState(false);
	const [showingMap, setShowingMap] = useState(false)
	
	const [validationError, setValidationError] = useState(null);
	const [navType, setNavType] = useState('Map');

	const [allPayments, setAllPayments] = useState(null);
	const [allUsers, setAllUsers] = useState(null)
	const [indPayment, setIndPayment] = useState(null)
	const [indNav, setIndNav] = useState("Details");
	const [indNavFilter, setIndNavFilter] = useState("General");

	const [allTradies, setAllTradies] = useState([])
	const [allFilters, setAllFilters] = useState({})
	const [popUpFilter, setPopUpFilter] = useState(false)
	const [newFilterData, setNewFilterData] = useState({
		date_range_bool: false,
		inspection: {active: false},
		case: {active: false},
		service: {active: false}
	})
	const [activeFilter, setActiveFilter] = useState(null)
 	const [markerJob, setMarkerJob] = useState(null)

 	const [indInspection, setIndInspection] = useState(null)
 	const [indCase, setIndCase] = useState(null)
 	const [indService, setIndService] = useState(null)
 	const [filesData, setFilesData] = useState([])
 	const [filesLoading, setFilesLoading] = useState(false)
 	const [collapseDeg, setCollapseDeg] = useState(0)
 	const [collapseFilters, setCollapseFilters] = useState(false)
 	const [showOverview, setShowOverview] = useState(false)
 	const [overviewInfo, setOverviewInfo] = useState({})
 	const [overviewNav, setOverviewNav] = useState('ALL')

 	const [typeFilters, setTypeFilters] = useState({
 		inspection: [[]]
 	})

	const navigate = useNavigate();

	const param = useParams();
	const url = `/api/admin/admin_map/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};


	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAllFilters(data.data.allFilters)
					setAllTradies(data.data.allTradieNames)
					
					// setUserData(data.data.user_data);
					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)

			} catch (error) {
				console.log(error)
			} finally {
				setShowingMap(true)
				await initializeMapWithAccessToken(param, [], mapDrag)
			}
		};
		initLoad();
	}, [key]);

	const formatOptions = (options) => {
		let options_arr = []
		for (let o=0; o < options.length; o++) {
			options_arr.push({label: options[o], value: options[o]})
		}

		return options_arr
	}

	const changeNavType = (type) => {
		setNavType(type)
	}

	const search_control = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = document.getElementById(tableId).children

	    for (let i = 0; i < target_div.length; i++) {
	        let mainValue = target_div[i].getElementsByClassName("indFilter_title")[0].textContent

	        let showItem = false;
	        [mainValue.toUpperCase()].forEach((value) => {
	            if (value.indexOf(filter) > -1) {
	                showItem = true;
	            }
	        });

	        if (showItem) {
	            target_div[i].style.display = "flex";
	        } else {
	            target_div[i].style.display = "none";
	        }
	    }
	};

	function formatDate(dateString, showTime, short) {
	    const dateObj = new Date(dateString);

	    let options;
	    if (short) {
		    options = {
		        year: '2-digit',
		        month: '2-digit',
		        day: '2-digit',
		        timeZone: 'Australia/Melbourne'
		    };
	    } else {
		    options = {
		        year: 'numeric',
		        month: '2-digit',
		        day: '2-digit',
		        timeZone: 'Australia/Melbourne'
		    };
	    }


	    if (showTime) {
	        options.hour = '2-digit';
	        options.minute = '2-digit';
	        options.hour12 = false;
	    }

	    const formattedDate = dateObj.toLocaleString('en-AU', options);
	    return formattedDate;
	}

	const changeIndNav = async (type, sf_id=null) => {
		setIndNav(type)

		if (type === "Files") {
			setFilesLoading(true)
			let filesData = await axios.get(`/api/admin/indInspectionFiles/${sf_id}`);
			setFilesData(filesData.data.insp_files)
			setFilesLoading(false)
		}
	}

	const changeOverviewNav = (type) => {
		setOverviewNav(type)
	}

	const changeIndNavFilter = async (type) => {
		setIndNavFilter(type)
	}

	const popUpControls = (control) => {
		switch (control) {
			case 'next':
				setPopUpPage(popUpPage+1)
				break;
			case 'prev':
				setPopUpPage(popUpPage-1)
				break;
			case 'open':
				setPopUpFilter(true)
				break;
			case 'close':
				setPopUpFilter(false)
				setNewFilterData({
					date_range_bool: false,
					inspection: {active: false},
					case: {active: false},
					service: {active: false}
				})
				setTypeFilters({
			 		inspection: [[]]
			 	})
				setIndNavFilter("General")
				setValidationError(null)
				setPopUpPage(1)
				setFilesData([])

				break;
			case 'close_overview':
				setShowOverview(false)
				setOverviewInfo({})
				setOverviewNav("ALL")
				break;
		}
	}

	const handleNewFilterData = async(control, name=null, input=null, location=null) => {
		switch (control) {
			case 'set':
				let newFilterDup = {...newFilterData}

				if (location) {
					if (name === "active") {
						if (input.value === false) {
							newFilterDup[location] = {}

							let typeFiltersDup = {...typeFilters}
							typeFiltersDup[location] = [[]]

							setTypeFilters(typeFiltersDup)
						}
					}

					if (name === "status" || name === "type" || name === "suburb" || name === "priority" || name === "tradies") {
						newFilterDup[location][name] = input.map(inp => inp.value)
					} else {
						newFilterDup[location][name] = input.value
					}
				} else {
					if (name === "date_range_bool" && input.value === false) {
						newFilterDup['date_range'] = []
					}
					newFilterDup[name] = input.value
				}

				setNewFilterData(newFilterDup)
				break;
			case 'submit':
				if (!(newFilterData.hasOwnProperty('title') || newFilterData.title == '')) {
					setValidationError("Please enter a title for this filter.")
					return false;
				} else {
					if (!newFilterData['edit']) {
						let dupChecker = allFilters.filter(filter => filter.title === newFilterData.title)
						if (dupChecker.length > 0) {
							setValidationError("Please enter a unique filter name.")
							return false;
						}
					}
				}

				if (newFilterData.date_range_bool === true && (!newFilterData.hasOwnProperty('date_range') || newFilterData.date_range.includes(null))) {
					setValidationError("Please choose both a start date and a finish date.")
					return false;
				}


				setValidationError(null)
				await axios.post(url, {filterData: newFilterData, typeFilters: typeFilters});
				window.location.reload()

				break;
			case 'apply':
				if (input === "bypass" || input.target.nodeName !== "I") {
					let filterFound = allFilters.filter(filter => filter._id.toString() === name.toString())
					if (filterFound.length > 0) {
						setValidationError(null)
						setNewFilterData({
							date_range_bool: false,
							inspection: {active: false},
							case: {active: false},
							service: {active: false}
						})
						setTypeFilters({
					 		inspection: [[]]
					 	})
						setPopUpFilter(false)
						setPopUpPage(1)
						setActiveFilter(filterFound[0]._id)
	
						setShowingMap(false)
						setLoadingMap(true)
						
						const data_configure = await axios.post(`/api/admin/configure_map/${param._id}`, {activeFilter: filterFound[0]});

						let newMapFilter = data_configure.data.updatedMapFilter
						let allFiltersDup = [...allFilters]
						let swapFilter = allFiltersDup.filter(filter => filter._id.toString() === newMapFilter._id)
						let swapFilterIndex = swapFilter.length > 0 ? allFiltersDup.indexOf(swapFilter[0]) : false
						if (swapFilterIndex !== false) {
							allFiltersDup[swapFilterIndex] = newMapFilter
							setAllFilters(allFiltersDup)
						}

						setLoadingMap(false)
						setShowingMap(true)
	
						await initializeMapWithAccessToken(param, data_configure.data.markerDetails, mapDrag)
	
						let marker_btn = document.getElementsByClassName('marker_btn')
					    if (marker_btn && marker_btn.length > 0) {
					    	for (let m=0; m < marker_btn.length; m++) {
					    		marker_btn[m].addEventListener('click', () => handleMarkerClick('click', marker_btn[m], data_configure.data.markerInspections));
					    	}
					    }
					}
				}

				break;
			case 'edit':
				let filterEditFound = allFilters.filter(filter => filter._id.toString() === name.toString())
				if (filterEditFound.length > 0) {
					setPopUpFilter(true)
					let foundFilter = { ...filterEditFound[0] }
					foundFilter['inspection'] = foundFilter['inspection'][0]
					foundFilter['case'] = foundFilter['case'][0]
					foundFilter['service'] = foundFilter['service'][0]
					foundFilter['edit'] = true
					foundFilter['date_range_bool'] = (foundFilter['date_range'] && foundFilter['date_range'].length === 2) ? true : false

					if (foundFilter['inspection']['type'] && foundFilter['inspection']['type'][0].length > 0) {
						let filterTypesObj = {
							inspection: foundFilter['inspection']['type']
						}
						setTypeFilters(filterTypesObj)
					}

					setNewFilterData(foundFilter)
					setValidationError(null)
				}
				break;
			case 'delete':
				const confirm_delete_filter = window.confirm("Are you sure you would like to delete this filter?")

				if (confirm_delete_filter) {
					let filterDelFound = allFilters.filter(filter => filter._id.toString() === name.toString())
					if (filterDelFound.length > 0) {
		  				await axios.post(`/api/admin/delete_filter/${param._id}`, {
		  					filter_id: filterDelFound[0]._id
		  				});
		  				window.location.reload(false);
					}
				}
				break;
			case 'export':
				const confirm_export_filter = window.confirm("Are you sure you would like to export this filter?")

				if (confirm_export_filter) {
					let filterExFound = allFilters.filter(filter => filter._id.toString() === name.toString())
					if (filterExFound.length > 0) {
						setValidationError(null)
						setNewFilterData({
							date_range_bool: false,
							inspection: {active: false},
							case: {active: false},
							service: {active: false}
						})
						setTypeFilters({
					 		inspection: [[]]
					 	})
						setPopUpFilter(false)
						setPopUpPage(1)
						setActiveFilter(filterExFound[0]._id)
	
						setShowingMap(false)
						setLoadingMap(true)
						
						const data_configure = await axios.post(`/api/admin/configure_map/${param._id}`, {activeFilter: filterExFound[0]});

						let newMapFilter = data_configure.data.updatedMapFilter
						let allFiltersDup = [...allFilters]
						let swapFilter = allFiltersDup.filter(filter => filter._id.toString() === newMapFilter._id)
						let swapFilterIndex = swapFilter.length > 0 ? allFiltersDup.indexOf(swapFilter[0]) : false
						if (swapFilterIndex !== false) {
							allFiltersDup[swapFilterIndex] = newMapFilter
							setAllFilters(allFiltersDup)
						}

						setLoadingMap(false)
						setShowingMap(true)

						await initializeMapWithAccessToken(param, data_configure.data.markerDetails, mapDrag)
	
						let marker_btn = document.getElementsByClassName('marker_btn')
					    if (marker_btn && marker_btn.length > 0) {
					    	for (let m=0; m < marker_btn.length; m++) {
					    		marker_btn[m].addEventListener('click', () => handleMarkerClick('click', marker_btn[m], data_configure.data.markerInspections));
					    	}
					    }

		  				const workbook_output = await axios.post(`/api/admin/export_filter/${param._id}`, {
		  					markerDetails: data_configure.data.markerDetails,
		  					markerInspections: data_configure.data.markerInspections
		  				});

						generateAndDownload(workbook_output.data.excelData, filterExFound[0].title)
					}
				}
				break;
			case 'overview':
				let filterExFound = allFilters.filter(filter => filter._id.toString() === name.toString())
				if (filterExFound.length > 0) {
					setValidationError(null)
					setNewFilterData({
						date_range_bool: false,
						inspection: {active: false},
						case: {active: false},
						service: {active: false}
					})
					setTypeFilters({
				 		inspection: [[]]
				 	})
					setPopUpFilter(false)
					setPopUpPage(1)
					setActiveFilter(filterExFound[0]._id)

					setShowingMap(false)
					setLoadingMap(true)
					
					const data_configure = await axios.post(`/api/admin/configure_map/${param._id}`, {activeFilter: filterExFound[0]});

					let newMapFilter = data_configure.data.updatedMapFilter
					let allFiltersDup = [...allFilters]
					let swapFilter = allFiltersDup.filter(filter => filter._id.toString() === newMapFilter._id)
					let swapFilterIndex = swapFilter.length > 0 ? allFiltersDup.indexOf(swapFilter[0]) : false
					if (swapFilterIndex !== false) {
						allFiltersDup[swapFilterIndex] = newMapFilter
						setAllFilters(allFiltersDup)
					}

					setLoadingMap(false)
					setShowingMap(true)

					await initializeMapWithAccessToken(param, data_configure.data.markerDetails, mapDrag)

					let marker_btn = document.getElementsByClassName('marker_btn')
				    if (marker_btn && marker_btn.length > 0) {
				    	for (let m=0; m < marker_btn.length; m++) {
				    		marker_btn[m].addEventListener('click', () => handleMarkerClick('click', marker_btn[m], data_configure.data.markerInspections));
				    	}
				    }

					let exportFinal = {'ALL': []}

					for (let marker of data_configure.data.markerDetails) {
						let inspFound = data_configure.data.markerInspections.filter(insp => insp.Id.toString() === marker.related_id.toString())
						if (inspFound.length > 0) {

							exportFinal['ALL'].push({
								Account: inspFound[0].Account__c,
								Id: inspFound[0].Id,
								Name: inspFound[0].Name,
								Status: inspFound[0].Status__c,
								Priority: inspFound[0].Priority__c,
								Address: inspFound[0].Asset_Address__c,
								Electricity: inspFound[0].Electricity__c,
								Gas: inspFound[0].Gas__c,
								Smoke: inspFound[0].Smoke_Alarm__c
							})

							if (exportFinal.hasOwnProperty(inspFound[0].Asset_Suburb__c)) {
								exportFinal[inspFound[0]['Asset_Suburb__c']].push({
									Account: inspFound[0].Account__c,
									Id: inspFound[0].Id,
									Name: inspFound[0].Name,
									Status: inspFound[0].Status__c,
									Priority: inspFound[0].Priority__c,
									Address: inspFound[0].Asset_Address__c,
									Electricity: inspFound[0].Electricity__c,
									Gas: inspFound[0].Gas__c,
									Smoke: inspFound[0].Smoke_Alarm__c
								})
							} else {
								exportFinal[inspFound[0]['Asset_Suburb__c']] = [{
									Account: inspFound[0].Account__c,
									Id: inspFound[0].Id,
									Name: inspFound[0].Name,
									Status: inspFound[0].Status__c,
									Priority: inspFound[0].Priority__c,
									Address: inspFound[0].Asset_Address__c,
									Electricity: inspFound[0].Electricity__c,
									Gas: inspFound[0].Gas__c,
									Smoke: inspFound[0].Smoke_Alarm__c
								}]
							}
						}
					}

					let sortedExportFinal = {"ALL": exportFinal['ALL']}
					if (filterExFound[0].inspection[0]['suburb'] && filterExFound[0].inspection[0]['suburb'].length > 0) {
						filterExFound[0].inspection[0]['suburb'].forEach(suburb => {
						    if (exportFinal[suburb]) {
						        sortedExportFinal[suburb] = exportFinal[suburb];
						    }
						});
					} else {
						sortedExportFinal = {...exportFinal}
					}

				    setOverviewInfo({
				    	title: filterExFound[0].title,
				    	exportFinal: sortedExportFinal,
				    	markerInspections: data_configure.data.markerInspections
				    })
				    setShowOverview(true)
				}
				break;
		}
	}


	const handleMarkerClick = async (control, marker?, jobs?, pos?) => {
		switch (control) {
			case 'click':
				if (pos && pos.target.nodeName === "SPAN") {
					return false
				}

				let markerOpen = document.getElementById(`markerJob_${marker.id}`)

				setShowOverview(false)

				if (markerOpen) {
					setIndInspection(null)
					setIndCase(null)
					setIndService(null)
					setMarkerJob(null)
					
				} else {
					setIndInspection(null)
					setIndCase(null)
					setIndService(null)
					setMarkerJob(null)

					let markerIcon = document.getElementById(`${marker.id}`)
					let markerDotExists = markerIcon.querySelector('.openDot')
					if (!markerDotExists) {
						let openDot = document.createElement('div');
						openDot.style.width = "10px"
						openDot.style.height = "10px"
						openDot.style.borderRadius = "100%"
						openDot.style.backgroundColor = "#ffb71b"
						openDot.style.margin = "0 auto"
						openDot.className = "openDot"
						markerIcon.appendChild(openDot)
					}

					let markerRelatedJob = jobs.filter(job => job.Id.toString() === marker.id.toString())[0]
					setMarkerJob(markerRelatedJob)
					indControls('open', markerRelatedJob)
				}
				break;
			case 'clear':
				if (marker.target.nodeName !== "I") {
					setIndInspection(null)
					setIndCase(null)
					setIndService(null)
					setActiveFilter(null)
					setMarkerJob(null)
				}
				break;
		}
	}


	function mapDrag() {
		setMarkerJob(null)
	}


	const indControls = (control, ind) => {
		switch (control) {
			case 'open':
				if (ind.attributes.type === "Inspection__c") {
					setIndInspection(ind)
				} else if (ind.attributes.type === "Case") {
					setIndCase(ind)
				} else if (ind.attributes.type === "Services__c") {
					setIndService(ind)
				}

				break;
			case 'close':
				setIndInspection(null)
				setIndCase(null)
				setIndService(null)
				setMarkerJob(null)
				setIndNav("Details")

				if (Object.keys(overviewInfo).length > 0) {
					setShowOverview(true)
				}
				break;
		}
	}

	const refreshDashboard = () => {
		setKey((prevKey) => prevKey + 1);
	};


	const handleTypes = (control, location, index=null, selected=null) => {
		let typeArrDup = {...typeFilters}

		switch (control) {
			case 'add':
				typeArrDup[location].push([]);
				break;
			case 'remove':
				typeArrDup[location].splice(index, 1);
				break;
			case 'set':
				typeArrDup[location][index] = selected.map(sel => sel.value)
				break;
		}

		setTypeFilters(typeArrDup);
	}


    const handleCollapse = async (control) => {
    	let intervalId;

        switch (control) {
            case 'enter':
            	if (collapseDeg !== 0) {
            		setCollapseDeg(0)
            	}

            	if (collapseDeg !== 15) {
	                intervalId = setInterval(() => {
	                    setCollapseDeg(prevDeg => {
	                        if (prevDeg >= 15) {
	                            clearInterval(intervalId);
	                            return prevDeg;
	                        }
	                        return prevDeg + 1;
	                    });
	                }, 10);
            	}
                break;
            case 'leave':
                intervalId = setInterval(() => {
                    setCollapseDeg(prevDeg => {
                        if (prevDeg === 0) {
                            clearInterval(intervalId);
                            return prevDeg;
                        }
                        return prevDeg - 1;
                    });
                }, 10);
                break;
             case 'toggle':
             	await setCollapseDeg(0)
             	await setCollapseFilters(!collapseFilters)
             	
             	if (activeFilter) {
             		await handleNewFilterData('apply', activeFilter, 'bypass')
             	} else {
             		reInitMap()
             	}
             	break;
        }
    };

    const reInitMap = async () => {
		setIsLoading(true)
		setShowingMap(false)

		try {
			setIsLoading(false)
		} catch (error) {
			console.log(error)
		} finally {
			setShowingMap(true)
			await initializeMapWithAccessToken(param, [], mapDrag)
		}
    }

    const clearOpened = (control, ind=null) => {
    	switch(control) {
    		case 'all':
				var openDots = document.querySelectorAll('.openDot');

				openDots.forEach(function(dots) {
				    dots.parentNode.removeChild(dots);
				});
    			break;
    		case 'ind':
    			var dotContainer = document.getElementById(ind)
    			var openDotInd = dotContainer.querySelector('.openDot');

    			if (openDotInd) {
    				openDotInd.parentNode.removeChild(openDotInd);
    			}
    			break;
    	}
    }

	async function generateAndDownload(excelData, title) {
		const excelDataUint8Array = new Uint8Array(atob(excelData).split('').map((char) => char.charCodeAt(0)));

		const workbook = new ExcelJS.Workbook();
		await workbook.xlsx.load(excelDataUint8Array);

		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

		FileSaver.saveAs(blob, `${title} (${formatDate(new Date())}).xlsx`);
	}

	return (
		<div key={key}>	
			<Offline>
				<div className="offline_container">
					<div className="offline_inner animate__animated animate__slideInDown">
						<i className="fa-solid fa-heart-crack"></i>
						<div className="offline_content">
							<p className="offline_title">You are offline</p>
							<p className="offline_subtitle">Please check your internet connect.</p>
						</div>
					</div>
				</div>
			</Offline>


			{isLoading
				? <div className={styles.load}>
					  <hr /><hr /><hr /><hr />
				  </div>
				: isAuthenticated === null
					? <div className={styles.load}>
						  <hr /><hr /><hr /><hr />
					  </div>
	        : isAuthenticated 
				  ? <div className={styles.main_container}>
						<NavigationBar {...{ param, handleLogout }} />
						<DashboardPage {...{ param, navType, changeNavType, search_control, allPayments, formatDate, allUsers, indPayment, popUpControls, loadingMap, showingMap, handleMarkerClick, markerJob, popUpFilter, refreshDashboard, allFilters, handleNewFilterData, activeFilter, handleCollapse, collapseDeg, collapseFilters, clearOpened, showOverview }} />

						{popUpFilter && <PopUpFilter {...{ param, popUpPage, popUpControls, newFilterData, handleNewFilterData, validationError, formatOptions, allFilters, indNavFilter, changeIndNavFilter, handleTypes, typeFilters, allTradies }} />}
						{indInspection && <IndInspection {...{ param, indNav, changeIndNav, indControls, indInspection, markerJob, formatDate, search_control, filesData, filesLoading, clearOpened }} /> }
						{indCase && <IndCase {...{ param, indNav, changeIndNav, indControls, indCase, markerJob, formatDate, search_control, filesData, filesLoading, clearOpened }} /> }
						{indService && <IndService {...{ param, indNav, changeIndNav, indControls, indService, markerJob, formatDate, search_control, filesData, filesLoading, clearOpened }} /> }

						{showOverview && <IndOverview {...{ param, overviewInfo, overviewNav, changeOverviewNav, handleMarkerClick, popUpControls, search_control }} /> }
					</div>
					: <Forbidden/>
			}
		</div>
	);
};


export default Map;
