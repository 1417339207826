// mapUtils.js
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';


function findCenter(points) {
  if (points.length === 0) {
    throw new Error("No points provided");
  }

  let totalLat = 0;
  let totalLon = 0;

  for (const point of points) {
    totalLat += parseFloat(point['lngLat'][0]);
    totalLon += parseFloat(point['lngLat'][1]);
  }

  const centerLat = totalLat / points.length;
  const centerLon = totalLon / points.length;

  return { latitude: centerLat, longitude: centerLon };
}

const initializeMapWithAccessToken = async (param, markerData, mapDrag, isProperties=false) => {
  const url = `/api/technician/mapbox/${param._id}`;
  const data = await axios.get(url);
  mapboxgl.accessToken = data.data.access_token;

  return new Promise((resolve) => {
    const createMap = () => {
      const mapContainer = document.getElementById('map');

      let centerPoint;
      if (markerData.length > 0) {
        const center = findCenter(markerData);
        centerPoint = [center.latitude.toString(), center.longitude.toString()]
      } else {
        centerPoint = ['144.96701770519513', '-37.81532629828826']
      }

      if (mapContainer && mapContainer.childNodes.length == 0) {
        const map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/hikasdasd/clduslmz4000401qsu6p53z6o',
          center: centerPoint,
          zoom: 10
        });

        if (!isProperties) {
          map.addControl(
            new MapboxGeocoder({
              accessToken: mapboxgl.accessToken,
              mapboxgl: mapboxgl
            })
          );
        }

        markerData.forEach((markerInfo, index) => {
          const { lngLat, title } = markerInfo;
          const el = document.createElement('a');

          if (markerInfo.hasOwnProperty('related_id')) {
            el.id=markerInfo.related_id.toString()
          }
          
          el.className = 'marker marker_btn'

          let icon = document.createElement('i');
          el.appendChild(icon)

          if (markerInfo['admin_map']) {
            icon.className = 'fa-solid fa-location-pin marker_custom marker_pending'
            icon.style.position = 'relative'
            icon.style.fontSize = '35px'

            let locator = document.createElement('h4');
            locator.style.position = 'absolute'
            locator.style.top = "44%"
            locator.style.left = "50%"
            locator.style.transform = "translate(-50%, -50%)"
            locator.style.color = "#fff"
            locator.style.fontWeight = "600"
            locator.style.fontSize = '18px'
            locator.style.fontFamily = 'Verdana'
            locator.style.pointerEvents = 'none'

            if (markerInfo['type'] === "Inspection__c") {
              locator.innerHTML = "I"
            } else if (markerInfo['type'] === "Case") {
              locator.innerHTML = "C"
            } else if (markerInfo['type'] === "Services__c") {
              locator.innerHTML = "S"
            }

            if (markerInfo['priority']) {
              if (markerInfo['priority'] === "Low") {
                icon.style.color = "#a8a8a8"
              } else if (markerInfo['priority'] === "Normal") {
                icon.style.color = "#a8a8a8"
              } else if (markerInfo['priority'] === "Medium") {
                icon.style.color = "#a8a8a8"
              } else if (markerInfo['priority'] === "High") {
                icon.style.color = "#ff0000"
              } else if (markerInfo['priority'] === "Critical") {
                icon.style.color = "#a70000"
              }
            }

            icon.appendChild(locator)
            el.appendChild(icon)
            

          } else {
            icon.className = 'fa-solid fa-location-dot marker_custom marker_pending'
            if (markerInfo['old']) {
              icon.className = 'fa-solid fa-location-dot marker_custom marker_old';
            } else if (markerInfo['pending']) {
              icon.className = 'fa-solid fa-location-dot marker_custom marker_pending';
            } else if (markerInfo['incomplete']) {
              icon.className = 'fa-solid fa-location-dot marker_custom marker_incomplete';
            } else if (markerInfo['completed']) {
              icon.className = 'fa-solid fa-location-dot marker_custom marker_completed';
            } else if (markerInfo['upcoming']) {
              icon.className = 'fa-solid fa-location-dot marker_custom marker_upcoming';
            }
          }

          const marker = new mapboxgl.Marker(el)
            .setLngLat(lngLat)
            .addTo(map);
        });

        const resetMapButton = document.getElementById('resetMapButton');
        if (resetMapButton) {
          resetMapButton.addEventListener('click', () => {
            let centerPoint;
            if (markerData.length > 0) {
              const center = findCenter(markerData);
              centerPoint = [center.latitude.toString(), center.longitude.toString()]
            } else {
              centerPoint = ['144.96701770519513', '-37.81532629828826']
            }
            map.setCenter(centerPoint);
            map.setZoom(11);

            mapDrag()
          });
        }

        map.on('drag', () => {
          mapDrag()
        });

        map.on('wheel', () => {
          mapDrag()
        });

        map.on('load', () => {
          map.addLayer({
            'id': 'add-3d-buildings',
            'source': 'composite',
            'source-layer': 'building',
            'filter': ['==', 'extrude', 'true'],
            'type': 'fill-extrusion',
            'minzoom': 15,
            'paint': {
              'fill-extrusion-color': '#aaa',
             
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height']
              ],

              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height']
              ],

              'fill-extrusion-opacity': 0.6
            }
          });
        })

        resolve(map);
      } else {
        requestAnimationFrame(createMap);
      }
    };

    createMap();
  });
};

export default initializeMapWithAccessToken;