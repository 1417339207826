import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../ReVerify/styles.css";
import NotFound from '../NotFound';
import LoadingForm from '../LoadingForm';

import { ReactComponent as Logo } from "../../images/mylologo.svg";
import snippet_banner from "../../images/snippet_banner.svg";
import success from "../../images/success.svg";

const ManageServicePM = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const param = useParams();
    const url = `/api/services/manage-service/${param.serv_id}/${param.flag}`;

    useEffect(() => {
      setIsLoading(true)

      const userData = async () => {
        try {
          const data = await axios.get(url);
          setIsAuthenticated(true)
        } catch (error) {
          console.log(error)
        }

        setIsLoading(false)
      };
      userData();
    }, []);

    const navigateLogin = () => {
      navigate(`/login`)
    }


    return (
      <>
      {isLoading
        ? <LoadingForm/>
        :
          <>
            {isAuthenticated
              ?
                <div className="bg">
                    <div className="wrapper centered">
                        <div className="outer">
                            <div className="login_panel">

                              <div className="logoContainer">
                                <Logo className="logo"/>
                              </div>

                              <div className="login_container">
                                <div className="login_form">
                                  <div className="login_container">
                                    <img src={success} style={{height: '250px', width: '250px'}}/>
                                    
                                    <button type="button" className="submit_btn" onClick={() => navigateLogin()} style={{marginTop: '15px'}}>Login</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="graphic_panel">
                                <h1>Simpler, smarter, better, MYLO.</h1>
                                <img src={snippet_banner} />
                            </div>
                        </div>
                    </div>
                </div>
              : <NotFound/>
            }
          </>
      }
      </>
    );
};

export default ManageServicePM;
