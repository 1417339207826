import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import styles from './overduestyles.module.css';
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import TableUtil from '../Utils/TableUtil'
import PopUpProperty from '../Utils/PopUpProperty'
import PopUpService from '../Utils/PopUpService'
import PopUpBeepingAlarm from '../Utils/PopUpBeepingAlarm'
import PopUpComplianceFilters from '../Utils/PopUpComplianceFilters'
import PopUpSettings from '../Utils/PopUpSettings'
import PopUpSecurity from '../Utils/PopUpSecurity'


const ActionContent = ({ handleActions, stats }) => {
	return (
		<div className={styles.action_container}>
			<button className={styles.action_item} onClick={() => handleActions('open', 'Properties')}>
				<i className={`fa-solid fa-house-circle-check ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Create a new property</p>
					<p className={styles.small_text}>{stats.props} properties</p>
				</div>
			</button>

			<button className={styles.action_item} onClick={() => handleActions('open', 'Services')}>
				<i className={`fa-solid fa-calendar-plus ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Create a new service</p>
					<p className={styles.small_text}>{stats.servs} services</p>
				</div>
			</button>

			<button className={styles.action_item} onClick={() => handleActions('open', 'Beeping Alarm')}>
				<i className={`fa-solid fa-triangle-exclamation ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Beeping smoke alarm</p>
					<p className={styles.small_text}>{stats.props} properties</p>
				</div>
			</button>
		</div>
	)
}


const TableContent = ({ param, setActiveTab, activeTab, setIndActive, formatDate, nodes, setNodes, handleActions, setNodesOG, filterApplied, setTableFilters, setFilterApplied, isAA, handleNavigate }) => {
	const [key, setKey] = useState(0);
	const tableDataRef = useRef(null);
	const blank = null

	const [isLoading, setIsLoading] = useState(false)
	const [columns, setColumns] = useState(["Address", "Package", "Property Manager", "Due Date", "Compliance"])
	const [gridLayout, setGridLayout] = useState("minmax(0px, 3fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr)")
	const [search, setSearch] = useState("")


	const setTableDataHeight = () => {
		const tableContainer = document.querySelector('.table_container');
		const tableDataContainer = tableDataRef.current;

		if (tableContainer && tableDataContainer) {
	  		const tableContainerHeight = tableContainer.clientHeight;
	  		const siblingsHeight = Array.from(tableDataContainer.parentNode.children).filter(child => child !== tableDataContainer).reduce((acc, child) => acc + child.clientHeight, 0);

	  		tableDataContainer.style.height = `${tableContainerHeight - siblingsHeight - 60}px`;
		}
	};

	useEffect(() => {
		const handleLoad = async () => {
			setIsLoading(true)
			const url = `/api/overdue/main/${param._id}/${activeTab.toLowerCase().replaceAll(" ", "_")}`;
			const role_token = localStorage.getItem('role_token');
			const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

			setNodes(data.data.dataMapped)
			setNodesOG(data.data.dataMapped)
			setIsLoading(false)
		}

		const handleResize = async () => {
			setTableDataHeight();
			window.addEventListener('resize', setTableDataHeight);

			return () => {
		  		window.removeEventListener('resize', setTableDataHeight);
			};
		}

		handleLoad()
		handleResize()
	}, [key]);

	const handleTableTab = (control, ind) => {
		switch (control) {
			case 'tab':
				setNodes([])
				setNodesOG([])

				setTableFilters({
					inspection_type: [],
					compliance: []
				})

				setFilterApplied(false)

				switch(ind) {
					case 'Overdue Properties':
						setColumns(["Address", "Package", "Property Manager", "Due Date", "Compliance"])
						setGridLayout("minmax(0px, 3fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr)")
						break;
				}

				setActiveTab(ind)
				setKey((prevKey) => prevKey + 1);
				break;
		}
	}

	const handleSearch = (event) => {
		setSearch(event.target.value);
	};

	return (
		<div className={`table_container ${styles.table_container}`}>
			<div className={styles.table_nav}>
				<button className={styles.table_nav_item} onClick={() => handleTableTab('tab', "Overdue Properties")} disabled={isLoading}>
					<i className={`fa-solid fa-house ${styles.medium_text} ${activeTab === "Overdue Properties" && styles.nav_text_active}`}></i>
					<p className={`${styles.medium_text} ${styles.nav_text} ${activeTab === "Overdue Properties" && styles.nav_text_active}`}>Overdue Properties</p>
					{activeTab === "Overdue Properties" && <div className={styles.table_nav_active}/>}
				</button>
			</div>


			<div className={styles.table_search}>
				<div className={styles.search_container}>
					<i className={`fa-solid fa-magnifying-glass ${styles.medium_text}`}></i>
					<input type="text" placeholder="Search for anything in this table..." className={styles.mid_text} value={search} onChange={handleSearch}/>
				</div>

				<button className={`${styles.filter_container} ${filterApplied && styles.active_filter}`} onClick={() => handleActions("open", "Compliance Filters")}>
					<i className={`fa-solid fa-filter ${styles.medium_text}`}></i>
					<p className={styles.mid_text}>Compliance Filters</p>
				</button>
			</div>

			<div className={`table_data ${styles.table_data}`} ref={tableDataRef}>
				{(!isLoading && nodes)
					? <TableUtil { ... { nodes, columns, gridLayout, setIndActive, formatDate, search, activeTab, blank, blank, blank, handleNavigate } }/>
					:
						<div className={styles.load_container}>
							<div className={styles.load}>
								<hr />
								<hr />
								<hr />
								<hr />
							</div>
						</div>
				}
			</div>
		</div>
	)
}


const Overdue = () => {
	const param = useParams();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState("Overdue")
	const [isAA, setIsAA] = useState(false)
	const [userData, setUserData] = useState({})
	const [nodes, setNodes] = useState([])
	const [nodesOG, setNodesOG] = useState([])
	const [indActive, setIndActive] = useState(null)
	const [activeTab, setActiveTab] = useState("Overdue Properties")
	const [openPopup, setOpenPopup] = useState(null)
	const [filterApplied, setFilterApplied] = useState(false)
	const [stats, setStats] = useState({
		props: 0,
		servs: 0
	})
	const [tableFilters, setTableFilters] = useState({
		inspection_type: [],
		compliance: []
	})
	const [searchResults, setSearchResults] = useState({
		properties: [],
		inspections: [],
		services: []
	})


	useEffect(() => {
		const handleLoad = async () => {
			const url = `/api/overdue/main/${param._id}/stats`;
			const role_token = localStorage.getItem('role_token');
			const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

			const { is_agency, user_data, ...statsCleaned } = data.data.dataMapped;

			setStats(statsCleaned)
			setIsAA(data.data.dataMapped.is_agency)
			setUserData(data.data.dataMapped.user_data)
		}

		handleLoad()
	}, []);


  	const formatDate = (dateString, showDay, showTime) => {
  		if (dateString === "-") {
  			return "-"
  		}

    	const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    	const dateObj = new Date(dateString);

    	if (!showTime) {
      		dateObj.setHours(0, 0, 0, 0);
    	}

    	const year = dateObj.getFullYear();
    	const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    	const day = ('0' + dateObj.getDate()).slice(-2);

    	const currentDayNumber = dateObj.getDay();

    	const format12Hour = (hours) => {
      		const period = hours >= 12 ? 'pm' : 'am';
      		hours = hours % 12 || 12;

      		return { hours: ('0' + hours).slice(-2), period };
    	};

    	if (showDay && showTime) {
      		let { hours: shours, period: speriod } = format12Hour(dateObj.getHours());
      		let sminutes = ('0' + dateObj.getMinutes()).slice(-2);
      		let sseconds = ('0' + dateObj.getSeconds()).slice(-2);

      		return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year} @ ${shours}:${sminutes}${speriod}`;
    	} else if (showDay) {
      		return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
    	} else if (showTime) {
      		let { hours, period } = format12Hour(dateObj.getHours());
      		let minutes = ('0' + dateObj.getMinutes()).slice(-2);
      		let seconds = ('0' + dateObj.getSeconds()).slice(-2);

      		return `${day}/${month}/${year} @ ${hours}:${minutes}${period}`;
    	} else {
      		return `${day}/${month}/${year}`;
    	}
  	};

  	const handleActions = (control, ind) => {
  		switch (control) {
  			case 'open':
  				setOpenPopup(ind)
  				break;
  			case 'close':
  				setOpenPopup(null)
  				break;
  		}
  	}

  	const handleNavigate = (control, ind) => {
  		switch (control) {
  			case 'nav':
    			const state = { from: ind };
    			sessionStorage.setItem('navigationState', JSON.stringify(state));
    			navigate(`/portal/hub/${param._id}`);
  				break;
  		}
  	} 

	return (
		<div className={styles.outer_page}>
			<div className={styles.full_page}>
				<Sidebar {... { param, currentPage } }/>

				<div className={styles.main_container}>
					<Topbar
						param={param}
						setSearchResults={setSearchResults}
						searchResults={searchResults}
						customNav={true}
						setOpenPopup={setOpenPopup}
					/>

					<div className={`${styles.content_container} ${(openPopup || Object.values(searchResults).flat().length > 0) && styles.hidden_container}`}>
						<ActionContent {... { handleActions, stats } }/>
						<TableContent {... { param, setActiveTab, activeTab, setIndActive, formatDate, nodes, setNodes, handleActions, setNodesOG, filterApplied, setTableFilters, setFilterApplied, isAA, handleNavigate } }/>
					</div>

					{(openPopup && openPopup === "Properties") && <PopUpProperty {... { param, handleActions } }/>}
					{(openPopup && openPopup === "Services") && <PopUpService {... { param, handleActions } }/>}
					{(openPopup && openPopup === "Beeping Alarm") && <PopUpBeepingAlarm {... { param, handleActions } }/>}
					{(openPopup && openPopup === "Compliance Filters") && <PopUpComplianceFilters {... { param, handleActions, nodesOG, setNodes, setTableFilters, tableFilters, filterApplied, setFilterApplied } }/>}
					{(openPopup && openPopup === "Settings") && <PopUpSettings {... { param, handleActions, userData } }/>}
					{(openPopup && openPopup === "Security") && <PopUpSecurity {... { param, handleActions, userData } }/>}
				</div>
			</div>
		</div>
	);
};

export default Overdue;