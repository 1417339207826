import { useEffect, useState, useRef } from "react";
import axios from "axios";
import styles from './utilstyles.module.css';


const PopUpKeyPickup = ({ param, handleActions, node, setMainKey }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({})


  useEffect(() => {
    const handleLoad = async () => {
      if (node) {
        setFormData({address: node.key_pickup.address || "", comments: node.key_pickup.comments || ""})
      }
    }

    handleLoad()
  }, []);

  const handlePopup = async (control, name?, input?) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          formData['_id'] = node._id
          formData['sf_id'] = node.insp_sf_id
          formData['prop_sf_id'] = node.sf_id
          const role_token = localStorage.getItem('role_token');
          const res = await axios.post(`/api/hub/${param._id}/updateData`, { type: "Key Pickup", data: formData }, { headers: { Authorization: `Bearer ${role_token}` } });

          handleClose(true)
        }

        setInnerLoading(false)
        break;
      case 'set':
        formDataDup[name] = input.target.value
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setFormData({})
      setValidationError(null)
      setMainKey((prevKey) => prevKey + 1);
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)
        setFormData({})
        setValidationError(null)
        handleActions('close')
      }
    }
  }

  const validationTest = async () => {
    if (page === 1) {
      if (formData['address'] === node.key_pickup.address && formData['comments'] === node.key_pickup.comments) {
        setValidationError("You did not change the key pickup information.")
        return false;
      }
    }

    setValidationError(null)
    return true
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_auto} ${styles.min_popup_height}`}>
      <div className={styles.popup_heading}>
        <div>
          <p className={styles.mediumer_text}>Change key pickup</p>
          <p className={styles.small_text}>Use this module to change the key pickup information.</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <div className={`${styles.text_grid}`}>
                  <div className={`${styles.text_area}`}>
                    <p className={`${styles.text_label} ${styles.small_text}`}>Address</p>
                    <input type="text" onChange={(input) => handlePopup("set", "address", input)} value={formData["address"]} className={`${styles.general_input} ${styles.mid_text}`}/>
                  </div>

                  <div className={`${styles.text_area} ${styles.mb3}`}>
                    <p className={`${styles.text_label} ${styles.small_text}`}>Comments</p>
                    <textarea onChange={(input) => handlePopup("set", "comments", input)} value={formData["comments"]} className={`${styles.general_textarea} ${styles.mid_text}`}/>
                  </div>
                </div>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>
      </div>
    </div>
  );
};

export default PopUpKeyPickup;