import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Select from 'react-select';
import _ from 'lodash';

import styles from './utilstyles.module.css';


const PopUpAddRole = ({ param, handleActions, setActiveTabInner, setOuterKey, node }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({
    role: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: ""
  })


  const handlePopup = async (control, name?, input?, sel=false) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          let sendObj = {
            sf_id: node.sf_id,
            roledata: {
              nar_role: formData.role,
              nar_fname: `${formData.first_name} ${formData.last_name}`,
              nar_email: formData.email,
              nar_phone: formData.phone
            }
          }

          const res = await axios.post(`/api/properties/${param._id}/nar`, sendObj);
          handleClose(true)
        }

        setInnerLoading(false)
        break;
      case 'set':
        if (sel) {
          formDataDup[name] = input.value  
        } else {
          formDataDup[name] = input.target.value
        }
        
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setFormData({
        role: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
      })
      setValidationError(null)

      setActiveTabInner("Roles")
      setOuterKey((prevKey) => prevKey + 1);
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)
        setFormData({
          role: "",
          first_name: "",
          last_name: "",
          email: "",
          phone: ""
        })
        setValidationError(null)
        handleActions('close')
      }
    }

  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validationTest = async () => {
    if (page === 1) {
      if (!(formData.hasOwnProperty('role')) || formData['role'] === '') {
        setValidationError("Please select a role.")
        return false
      }

      if (!(formData.hasOwnProperty('first_name')) || formData['first_name'] === '') {
        setValidationError("Please enter this roles first name.")
        return false
      }

      if (!(formData.hasOwnProperty('last_name')) || formData['last_name'] === '') {
        setValidationError("Please enter this roles last name.")
        return false
      }

      if (!(formData.hasOwnProperty('email')) || formData['email'] === '') {
        setValidationError("Please enter this roles email.")
        return false
      } else {
        if (!(isValidEmail(formData.email))) {
          setValidationError(`You did not enter a valid email for this role.`)
          return false;
        }
      }

      if (!(formData.hasOwnProperty('phone')) || formData['phone'] === '') {
        setValidationError("Please enter this roles phone number.")
        return false
      }
    }

    setValidationError(null)
    return true
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_auto} ${styles.popup_auto_set}`}>
      <div className={styles.popup_heading}>
        <div>
          <p className={styles.mediumer_text}>Create an asset role</p>
          <p className={styles.small_text}>Use this module to create a new asset role.</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  <div className={`${styles.text_grid} ${styles.mb3}`}>
                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Role</p>
                      <Select
                        options={[{label: "Landlord", value: "Landlord"}, {label: "Tenant", value: "Tenant"}]}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '2.5px 0',
                            marginTop: '2.5px',
                            boxSizing: 'border-box',
                            boxShadow: 'none !important',
                             '&:hover': {
                                 borderColor: '#26465333 !important'
                              },
                            borderColor: state.isFocused ? '#26465333' : '#26465333',
                          }),
                        }}
                        onChange={(selected) => handlePopup("set", "role", selected, true)}
                        value={{value: formData.role, label: formData.role}}
                      />
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>First name</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.first_name} onChange={(input) => handlePopup("set", "first_name", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Last name</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.last_name} onChange={(input) => handlePopup("set", "last_name", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Email</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.email} onChange={(input) => handlePopup("set", "email", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Phone</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.phone} onChange={(input) => handlePopup("set", "phone", input)}/>
                    </div>
                  </div>

                </>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>
      </div>
    </div>
  );
};

export default PopUpAddRole;