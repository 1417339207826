import ReactAutocomplete from "react-google-autocomplete";
import Select from 'react-select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import dstyles from "./dashboardstyles.module.css"
import styles from "./AdminUsers.module.css"
import TechnicianCalendar from "../../Tradie/Calendar";


const IndTechnician = ({ allUsers, indTradie, changeIndNav, indNav, allJobs, formatDate, calculateCompletedJobs, indControls, search_control, addEvent, handleNewEvent, newEventAddress, newEvent, validationError, handleNewEventAddress, handleCustomEvent, formatOptions, changeStatus, changingStatus, deleteActivated, handleDelete, cleanPostMessage }) => {
	return (
		<div className={dstyles.indsite_container}>
			<div className={`${dstyles.indsite_popup} ${(indNav === "Jobs") && dstyles.max_width_popup} ${(indNav === "Calendar") && dstyles.fixed_width_popup}`}>

				<div className={dstyles.indsite_inner}>
					<button className={dstyles.closePopup} onClick={() => indControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={dstyles.editPopup} onClick={() => indControls('edit')}><i className="fa-solid fa-pen"></i></button>
					{indNav === "Calendar" && <button className={dstyles.calendarPopup} onClick={() => indControls('add_event')}><i className="fa-solid fa-plus"></i></button>}
					{indNav === "Jobs" && <button className={dstyles.calendarPopup} onClick={() => handleDelete('toggle')}><i className="fa-solid fa-trash-can"></i></button>}
					{indNav === "Jobs" && <button className={dstyles.finalPopup} onClick={() => indControls('clean')}><i className="fa-solid fa-broom"></i></button>}

					<h1 className={dstyles.medium_font}>Job details</h1>
					<p className={dstyles.smaller_font}>Please find the specific details for this job.</p>

					<div className={dstyles.indsite_nav}>
						<div className={dstyles.sitenav}>
							<button className={`${dstyles.sitenav_box} ${indNav == 'Details' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
							<button disabled={changingStatus} className={`${dstyles.sitenav_box} ${indNav == 'Jobs' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Jobs')}><p>Jobs</p></button>
							<button disabled={changingStatus} className={`${dstyles.sitenav_box} ${indNav == 'Calendar' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Calendar')}><p>Calendar</p></button>
							<button disabled={true} className={`${dstyles.sitenav_box} ${indNav == 'Payments' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Payments')}><p>Payments</p></button>
						</div>
					</div>

					{indNav == "Details" &&
						<div className={`${dstyles.sites_tablecontainer} ${changingStatus && dstyles.hidden_container}`}>
							<div className={`${dstyles.indinfo_grid}`}>
								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>First name</label>
									<p>{indTradie.first_name}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Last name</label>
									<p>{indTradie.last_name}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Email</label>
									<p>{indTradie.email}</p>
								</div>

								<div className={dstyles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
											<label className={dstyles.smaller_font}>Status</label>
							    			<button onClick={() => changeStatus('toggle')} className={`${dstyles.site_status} ${indTradie.active && dstyles.status_active} ${!indTradie.active && dstyles.status_inactive}`}>
							    				<p className={dstyles.smaller_font}>{indTradie.active ? "Active": "Inactive"}</p>
							    			</button>
						    			</div>
					    			</div>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>License number</label>
									<p>{indTradie.license_number}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Cognito ID</label>
									<p>{indTradie.cognito_id}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Expertise</label>
									<p>{indTradie.expertise}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Jobs pending</label>
									<p>{allJobs.filter(job => (job.tradieId.toString() === indTradie._id.toString()) && (job.status === "Pending")).length}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Jobs incomplete</label>
									<p>{allJobs.filter(job => (job.tradieId.toString() === indTradie._id.toString()) && (job.status === "Incomplete")).length}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Jobs completed</label>
									<p>{allJobs.filter(job => (job.tradieId.toString() === indTradie._id.toString()) && (job.status === "Completed")).length}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Jobs cancelled</label>
									<p>{allJobs.filter(job => (job.tradieId.toString() === indTradie._id.toString()) && (job.status === "Cancelled")).length}</p>
								</div>
							</div>
						</div>
					}

	    			{changingStatus &&
	    				<div className={dstyles.statusOptions}>
	    					<button className={`${dstyles.closePopup} ${dstyles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={dstyles.medium_font}>Change status</h1>
							<p className={`${dstyles.smaller_font}`}>Use this module to update the status for {indTradie.first_name} {indTradie.last_name}</p>

							<div className={dstyles.statusOuter}>
								<div className={dstyles.statusInner}>
					    			{!indTradie.active &&
						    			<button onClick={() => changeStatus('set', 'Tradie', indTradie, 'Active')} className={`${dstyles.site_status} ${dstyles.status_active}`}>
						    				<p className={dstyles.smaller_font}>Active</p>
						    			</button>
						    		}

						    		{indTradie.active &&
						    			<button onClick={() => changeStatus('set', 'Tradie', indTradie, 'Inactive')} className={`${dstyles.site_status} ${dstyles.status_inactive}`}>
						    				<p className={dstyles.smaller_font}>Inactive</p>
						    			</button>
						    		}
					    		</div>
				    		</div>
	    				</div>
	    			}

					{indNav == "Jobs" &&
						<div className={dstyles.sites_tablecontainer}>
							<div className={`${dstyles.sites_controls} ${dstyles.indsite_controls_wh}`}>
								<div className={`${dstyles.sites_searchcontainer} ${dstyles.autowidth}`}>
									<div className={dstyles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={dstyles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => search_control(event, "ind_table")}
										/>

										{deleteActivated &&
											<button className={styles.indsitebtn} onClick={() => handleDelete('submit')}>
												<i className="fa-solid fa-trash-can"></i>
												<p className={styles.smaller_font}>Delete selected</p>
											</button>
										}

										{cleanPostMessage && 
											<div className={styles.cleanPost}>
												<p className={styles.smaller_font}>You have successfully deleted {cleanPostMessage} tradie jobs. Refreshing in 5s...</p>
											</div>
										}
									</div>
								</div>
							</div>

							<div className={dstyles.table_container}>
								<table className={dstyles.sites_table} id="ind_table">
								    <thead className={dstyles.sitesind_thead}>
								      <tr className={`${dstyles.table_rowth} ${dstyles.tr_only}`}>
								      	{deleteActivated && <th colspan="1"></th>}
								      	<th colspan="3" className={dstyles.small_font}>Type</th>
								      	<th colspan="2" className={dstyles.small_font}>Reference</th>
								      	<th colspan="2" className={dstyles.small_font}>Address</th>
								      	<th colspan="2" className={dstyles.small_font}>Scheduled</th>
								      	<th colspan="2" className={dstyles.small_font}>Forms</th>
								      	<th colspan="3" className={dstyles.small_font}>Progress</th>
								      	<th colspan="2" className={dstyles.small_font}>Status</th>
								      </tr>
								    </thead>
								    <tbody className={dstyles.indtbody}>
								    	{allJobs
								    		?
								    		<>
								    			{allJobs.filter(job => job.tradieId.toString() === indTradie._id.toString()).length > 0
								    				? 
								    				<>
									    				{allJobs.filter(job => job.tradieId.toString() === indTradie._id.toString()).sort((a, b) => new Date(a.scheduled_timestamp) - new Date(b.scheduled_timestamp)).map((IJ) => {
									    					return (
														    	<tr className={`${dstyles.sitesind_tr} ${dstyles.tr_only}`}>
														    		{deleteActivated && <td colspan="1"><input type="checkbox" onChange={(input) => handleDelete('set', IJ._id, input)}/></td>}

														    		<td className={dstyles.td_overflow} colspan="3">{
															    		IJ.type ? <p>{IJ.jobTypes.service ? "Service" : IJ.jobTypes.checklist ? "Service" : "Case"} | {IJ.type}</p>
														    			: 
															    		<>
																            <p>Inspection | {
																				Object.entries(IJ.jobTypes)
																				  .filter(([jobType, selected]) => selected)
																				  .map(([jobType]) => jobType.charAt(0).toUpperCase() + jobType.slice(1))
																				  .join(', ')
															  				}</p>
															  			</>
															    	}</td>
															    	<td className={dstyles.td_overflow} colspan="2">
															    		{IJ.reference
															    			? <a href={`https://mylopropertysolutions.lightning.force.com/lightning/r/Services__c/${IJ.sf_id}/view`} target="_blank"><p>{IJ.reference}</p></a>
															    			: <p>-</p>
															    		}


															    	</td>
															    	<td className={dstyles.td_overflow} colspan="2"><p>{IJ.property.street}</p></td>
														    		<td className={dstyles.td_overflow} colspan="2"><p>{formatDate(IJ.scheduled_timestamp, true)}</p></td>
														    		<td className={dstyles.td_overflow} colspan="2"><p>{calculateCompletedJobs(IJ)}</p></td>
														    		<td className={dstyles.td_overflow} colspan="3">
														    			<div className={dstyles.stamps}>
														    				{!IJ.stamps.start.stamped && <p>Not started</p>}

														    				{IJ.stamps.start.stamped && <a className={dstyles.small_font} href={`https://www.google.com/maps/search/${IJ.stamps.start.geolocation[0]},${IJ.stamps.start.geolocation[1]}`} target="_blank">Started @ {formatDate(IJ.stamps.start.timestamp, true)}</a>}
														    				{IJ.stamps.leaving.stamped && <a className={dstyles.small_font} href={`https://www.google.com/maps/search/${IJ.stamps.leaving.geolocation[0]},${IJ.stamps.leaving.geolocation[1]}`} target="_blank">Left property @ {formatDate(IJ.stamps.leaving.timestamp, true)}</a>}
														    				{IJ.stamps.completed.stamped && <a className={dstyles.small_font} href={`https://www.google.com/maps/search/${IJ.stamps.completed.geolocation[0]},${IJ.stamps.completed.geolocation[1]}`} target="_blank">Completed @ {formatDate(IJ.stamps.completed.timestamp, true)}</a>}
														    			</div>
														    		</td>
														    		<td className={dstyles.td_overflow} colspan="2"><p>{IJ.status}</p></td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${dstyles.medium_font} ${dstyles.create_first2}`}>This account does not have any sites yet!</h1>
								    			}
								    		</>
								    		: <h1 className={`${dstyles.medium_font} ${dstyles.create_first2}`}>This account does not have any sites yet!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}

					{indNav == "Calendar" &&
						<>
							<div className={`${dstyles.sites_embedcontainer} ${addEvent && dstyles.hidden_container}`}>
								<TechnicianCalendar indTradie={indTradie} handleCustomEvent={handleCustomEvent}/>
							</div>

							{addEvent &&
								<div className={dstyles.popup_container}>

									<button className={dstyles.closePopup} onClick={() => indControls('event_close')}><i class="fa-solid fa-x"></i></button>

									<h1 className={dstyles.medium_font}>Event details</h1>
									<p className={dstyles.smaller_font}>Please enter the specific event details to create.</p>

									<div className={dstyles.popup_form}>
										<div className={dstyles.popup_formbox}>
											<label className={dstyles.smaller_font}>Users</label>
											<Select
												isMulti={true}
												closeMenuOnSelect={true}
												options={formatOptions(allUsers.map(user => `${user.first_name} ${user.last_name}`))}
												className={dstyles.small_font}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewEvent('set', 'event_users', selected)}
												value={newEvent["event_users"]}
											/>
										</div>


										<div className={dstyles.popup_formbox}>
											<label className={dstyles.smaller_font}>Title</label>
											<input className={dstyles.small_font} type="text" placeholder="Car service" onChange={(input) => handleNewEvent('set', 'title', input)} value={newEvent.title}/>
										</div>

				      					<div className={dstyles.popup_formbox}>
					      					<label className={dstyles.smaller_font}>Address</label>
											<ReactAutocomplete
											 	className={dstyles.small_font}
											  	apiKey="AIzaSyDpQGM_wr1B1ztyG6gK_TUmFXTOcc8Wlhk"
											  	options={{
											    	types: ["address"],
											    	componentRestrictions: { country: "au" },
											  	}}
											  	onPlaceSelected={(place) => handleNewEventAddress(place)}
											  	defaultValue={newEventAddress}
											  	placeholder="Start typing your address..."
											/>
										</div>

										<div className={dstyles.popup_form_customdate_inv}>
											<div className={`${dstyles.popup_formbox}`}>
												<div className={dstyles.label_container}>
													<label className={dstyles.smaller_font}>Start time</label>
													<label className={dstyles.smaller_font}>End time</label>
												</div>
												<div className={dstyles.label_container}>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DateTimePicker 
														 	className={dstyles.small_font}
															onChange={(selected) => handleNewEvent('set', 'event_dates1', selected)}
															value={newEvent.event_dates[0] && dayjs(newEvent.event_dates[0])}
															format="DD/MM/YYYY HH:mm"
															disablePast
														/>
													</LocalizationProvider>

													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DateTimePicker
															className={dstyles.small_font}
															onChange={(selected) => handleNewEvent('set', 'event_dates2', selected)}
															value={newEvent.event_dates[1] && dayjs(newEvent.event_dates[1])}
															format="DD/MM/YYYY HH:mm"
															disablePast
														/>
													</LocalizationProvider>
												</div>
											</div>
										</div>

										<div className={dstyles.popup_formbox}>
											<label className={dstyles.smaller_font}>Comments</label>
											<textarea className={dstyles.small_font} placeholder="Event comments..." onChange={(input) => handleNewEvent('set', 'comments', input)} value={newEvent.comments}></textarea>
										</div>

									</div>

									{validationError &&
										<div className={`${dstyles.notification} ${dstyles.validationError}`}>
											<p className={dstyles.smaller_font}>{validationError}</p>
										</div>
									}

									<div className={dstyles.popUpControls}>
										<div className={dstyles.popUpControlsContainer}>
											<button type="button" className={dstyles.submitBtn} onClick={() => handleNewEvent('submit')}>Submit</button>
							       		</div>
						       		</div>
								</div>
							}
						</>
					}
				</div>
			</div>
		</div>
    )
}

export default IndTechnician;