import styles from './dashboardstyles.module.css';

const PassKey = ({ handlePasskey, validationError, passKey, passKeySent }) => {
	return (
		<div className={`${styles.popup_container}`}>

			<button className={styles.closePopup} onClick={() => handlePasskey('toggle')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>View Statistics</h1>
			<p className={styles.smaller_font}>Use this module to view the teams statistics.</p>

			<div className={`${styles.notification} ${styles.warningMessage}`}>
				<p className={styles.smaller_font}>Press the send email button to receive your passkey to enter.</p>
			</div>
			

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Passkey</label>
					<input
						type="text"
						onChange={(input) => handlePasskey('set', input)}
						value={passKey && passKey}
					/>

					{!passKeySent
						?
		              	<div className={styles.sendpassbtn}>
		              		<button onClick={() => handlePasskey('send')}>Send Passkey</button>
		              	</div>
		              	:
		              	<div className={styles.sendpassbtn}>
		              		<button disabled={true}>Sent!</button>
		              	</div>
	              	}
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handlePasskey('submit')}>Submit</button>
	       		</div>
       		</div>


		</div>
	)
}

export default PassKey;