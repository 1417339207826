import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select, { components } from "react-select";
import styles from './Calendar.module.css';
import tstyles from './Tradie.module.css';

import { format } from 'date-fns';
const localizer = momentLocalizer(moment);


const CalendarComponent = ({ events, tradie=true, handleCustomEvent=null }) => {
  const param = useParams();
  const [indNav, setIndNav] = useState("Details");

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [currentView, setCurrentView] = useState('agenda')
  

  const eventList = events.map((event) => ({
    event_id: event._id,
    title: event.title,
    start: new Date(event.start),
    end: new Date(event.end),
    property: event.property || '',
    key_pickup: event.key_pickup,
    key_comment: event.key_comment,
    agency: event.agency,
    reference: event.reference,
    contact_formula: event.contact_formula,
    comments: event.comments,
    status: event.status,
    custom: event['custom'] || false,

    bg: event['custom'] ? '#deaaff' : event.title.split(' | ')[0] === "Inspection" ? '#dab894' : event.title.split(' | ')[0] === "Case" ? '#f48498' : event.title.split(' | ')[0] === "Service" ? '#9381ff' : '#dab894'
  }));

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setSelectedEvent(null)
    setFileData(null)
    setIndNav("Details")
  };

  function formatDateAndTime(dateString, justTime=false) {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dateObj = new Date(dateString);
    const dayOfWeek = weekdays[dateObj.getDay()];
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const time = dateObj.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

    if (justTime) {
      return `${time}`;
    } else {
      return `${dayOfWeek}, ${day} ${month} @ ${time}`;
    }
  }

  const changeIndNav = async (type) => {
    setIndNav(type)

    if (type === "Files") {
      let file_data = await axios.post(`/api/technician/calendar/history/${param._id}`, {event_id: selectedEvent.event_id})
      setFileData(file_data.data)
    }
  }

  const searchControl = (event, tableId) => {
      let input = event.target.value;
      let filter = input.toUpperCase();
      let target_div = document.getElementById(tableId).children[1].children;

      for (let i = 0; i < target_div.length; i++) {
          let txtValues = [];
          for (let j = 0; j < target_div[i].children.length; j++) {
              let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
              txtValues.push(txtValue.toUpperCase());
          }

          let showItem = false;
          txtValues.forEach((value) => {
              if (value.indexOf(filter) > -1) {
                  showItem = true;
              }
          });

          if (showItem) {
              target_div[i].style.display = tableId === "main_table" ? "table-row" : "table";
          } else {
              target_div[i].style.display = "none";
          }
      }
  };

  const CustomEvent = ({ event }) => (
    <div className={styles.event_oncal}>
      {!event.custom
        ? <p>{(currentView === "agenda" || currentView === "day") && `${event.title} | `} {event.property.street}</p>
        : <p>Custom | {(currentView === "agenda" || currentView === "day") ? `${event.title} | ` : `${event.title}`} {event.property.split(', ')[0]}</p>
      }
    </div>
  );

  const CalFormats = {
    eventTimeRangeFormat: () => { 
      return "";
    },
    timeGutterFormat: (date, culture, localizer) => { 
      return (format(date, 'ha').toLowerCase());
    },
  };

  function customEventClass(event) {
    return event.title.split(' | ')[0] === "Inspection" ? 'inspection_event_class' : event.title.split(' | ')[0] === "Case" ? 'case_event_class' : event.title.split(' | ')[0] === "Service" ? 'service_event_class' : 'custom_event_class'
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      className: customEventClass(event)
    };
  };


  return (
    <>
    <div className={`${!selectedEvent ? "" : `${styles.hiddencontainer}` }`} style={{height: '100%'}}>
      <Calendar
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
        defaultView="agenda"
        onView={(view) => setCurrentView(view)}
        selectable
        onSelectEvent={handleEventSelect}
        components={{
          event: CustomEvent,
        }}
        formats={CalFormats}
        eventPropGetter={eventStyleGetter}

      />
    </div>
    
    {selectedEvent && (
      <div className={`${styles.popup_event} ${indNav === 'Files' && tstyles.fixed_popup_height}`}>
        <div className={styles.event_titlegroup}>
          {!selectedEvent.custom && <div className={`${styles.event_status} ${styles[selectedEvent.status]}`}/>}
          <p className={styles.event_title}>{selectedEvent.title}</p>

          <div className={styles.close_event}>
            {(selectedEvent.custom && !tradie) && <button onClick={() => {
              handleClose();
              handleCustomEvent('edit', selectedEvent);
            }}><i className="fa-solid fa-pen"></i></button>}
            {(selectedEvent.custom && !tradie) && <button onClick={() => handleCustomEvent('delete', selectedEvent)}><i className="fa-solid fa-trash-can"></i></button>}
            <button onClick={handleClose}><i class="fa-solid fa-x"></i></button>
          </div>
        </div>

        <div className={styles.indsite_nav}>
          <div className={styles.sitenav}>
            <button className={`${styles.sitenav_box} ${indNav === 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
            {!selectedEvent.custom && <button className={`${styles.sitenav_box} ${indNav === 'Files' && styles.sitenav_active}`} onClick={() => changeIndNav('Files')}><p>Files</p></button>}
          </div>
        </div>

        {indNav === 'Details' &&
          <div className={styles.event_text_container}>

            {selectedEvent.property &&
              <div className={styles.event_inner}>
                <p className={styles.label_text}>Property address</p>
                {!selectedEvent.custom 
                  ? <p className={styles.event_text}>{selectedEvent.property.street}, {selectedEvent.property.suburb}, {selectedEvent.property.state}, {selectedEvent.property.postcode}</p>
                  : <p className={styles.event_text}>{selectedEvent.property}</p>
                }
              </div>
            }

            {selectedEvent.key_comment &&
              <>
                <div className={styles.event_inner}>
                  <p className={styles.label_text}>Key pickup address</p>
                  <p className={styles.event_text}>{selectedEvent.key_pickup}</p>
                </div>

                <div className={styles.event_inner}>
                  <p className={styles.label_text}>Key comments</p>
                  <p className={styles.event_text}>{selectedEvent.key_comment}</p>
                </div>
              </>
            }

            {!selectedEvent.custom && 
              <div className={styles.event_inner}>
                <p className={styles.label_text}>Reference no.</p>
                <p className={styles.event_text}>{selectedEvent.reference}</p>
              </div>
            }

            <div className={styles.event_inner}>
              <p className={styles.label_text}>Scheduled to</p>
              <p className={styles.event_text}>{formatDateAndTime(selectedEvent.start)} - {formatDateAndTime(selectedEvent.end, true)}</p>
            </div>

            {!selectedEvent.custom && 
              <div className={styles.event_inner}>
                <p className={styles.label_text}>Contact</p>
                <p className={styles.event_text}>{selectedEvent.contact_formula}</p>
              </div>
            }

            {selectedEvent.comments &&
              <div className={styles.event_inner}>
                <p className={styles.label_text}>Comments</p>
                <p className={`${styles.event_text} ${styles.event_text_break}`}>{selectedEvent.comments.split('<br>').map((line, index) => {
                  return <span key={index}>{line}<br /></span>;
                })}</p>
              </div>
            }

          </div>
        }

        {indNav === 'Files' &&
          <div className={`${tstyles.sites_tablecontainer} ${tstyles.custom_calendar_height}`}>
            <div className={`${tstyles.sites_controls} ${styles.indsite_controls_wh}`}>
              <div className={`${tstyles.sites_searchcontainer} ${styles.autowidth}`}>
                <div className={tstyles.sites_search}>
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <input
                      type="text"
                      className={tstyles.smaller_font}
                      placeholder="Search for a filename..."
                      onKeyUp={(event) => searchControl(event, "file_table")}
                  />
                </div>
              </div>
            </div>

            <div className={tstyles.table_container}>
              <table className={tstyles.sites_table} id="file_table">
                  <thead className={tstyles.sitesind_thead}>
                    <tr className={tstyles.table_rowth}>
                      <th colspan="3" className={tstyles.smaller_font}>Filename</th>
                      <th colspan="1" className={tstyles.smaller_font}>Open</th>
                    </tr>
                  </thead>
                  <tbody className={`${tstyles.indtbody}`}>
                    {fileData
                      ?
                      <>
                        {fileData.filter(file => !(file.Name.includes('Work Order') || !(file.Name.includes('MYLO Service Agreement')))).length > 0
                          ? 
                          <>
                            {fileData.filter(file => !(file.Name.includes('Work Order') || !(file.Name.includes('MYLO Service Agreement')))).map((FD) => {
                              return (
                                <tr className={`${tstyles.sitesind_tr} ${tstyles.nohover}`}>
                                  <td className={tstyles.small_font} colspan="3">{FD.Name}</td>
                                  <td className={tstyles.small_font} colspan="1"><a href={FD.DistributionPublicUrl} target="_blank"><i className="fa-solid fa-eye"></i></a></td>
                                </tr>
                              )
                            })}
                          </>
                          : <h1 className={`${tstyles.medium_font} ${tstyles.create_first2}`}>No files have been uploaded to this job yet!</h1>
                        }
                      </>
                      : 
                          <div className={tstyles.load}>
                            <hr />
                            <hr />
                            <hr />
                            <hr />
                          </div> 
                    }
                  </tbody>

              </table>
            </div>
          </div>
        }
      </div>
    )}
    </>
  );
};

export default CalendarComponent;
