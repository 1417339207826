import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../../../images/chatbot_logo.svg';
import styles from './sidebarstyles.module.css';


const Sidebar = ({ param, currentPage }) => {
	const [userDataObj, setUserDataObj] = useState({})

	useEffect(() => {
		const userData = localStorage.getItem("user_portal_data");
		setUserDataObj(JSON.parse(userData))
	}, []);

	const handleLogout = () => {
		const confirmLogout = window.confirm("Are you sure you want to logout?")

		if (confirmLogout) {
			localStorage.removeItem("token");
			localStorage.removeItem("role_token");
			localStorage.removeItem("user_portal_data");
			window.location = "/login";
		}
	};

	const checkSamePage = () => {
		if (currentPage === "Hub") {
			window.location.reload()
		}
	}

	return (
		<div className={styles.sidebar_container}>

			<div className={styles.sidebar_container_img}>
				<Logo/>
			</div>

			<div className={styles.profile_container}>
				<p className={`${styles.large_text} ${styles.align_text_center}`}>{userDataObj.first_name} {userDataObj.last_name}</p>
				<p className={`${styles.small_text} ${styles.align_text_center_wordbreak}`}>{userDataObj.email}</p>
			</div>

			<div className={styles.nav_container}>
				<div className={styles.nav_group}>
					<Link to={`/portal/hub/${param._id}`} className={styles.nav_item} onClick={() => checkSamePage()}>
						<i className={`fa-solid fa-layer-group ${styles.mediumer_text} ${currentPage === "Hub" && styles.active_nav}`}></i>
						<p className={`${styles.large_text} ${currentPage === "Hub" && styles.active_nav}`}>My Hub</p>
					</Link>
				</div>

				<div className={styles.nav_group}>
					<Link to={`/portal/overdue/${param._id}`} className={styles.nav_item}>
						<i className={`fa-solid fa-clock ${styles.mediumer_text} ${currentPage === "Overdue" && styles.active_nav}`}></i>
						<p className={`${styles.large_text} ${currentPage === "Overdue" && styles.active_nav}`}>Overdue</p>
					</Link>

					<Link to={`/portal/actions/${param._id}`} className={styles.nav_item}>
						<i className={`fa-solid fa-triangle-exclamation ${styles.mediumer_text} ${currentPage === "Actions" && styles.active_nav}`}></i>
						<p className={`${styles.large_text} ${currentPage === "Actions" && styles.active_nav}`}>Actions</p>
					</Link>
				</div>

				<div className={styles.nav_group}>
					<Link to={`/portal/debit-card/${param._id}`} className={styles.nav_item}>
						<i className={`fa-solid fa-credit-card ${styles.mediumer_text} ${currentPage === "DebitCard" && styles.active_nav}`}></i>
						<p className={`${styles.large_text} ${currentPage === "DebitCard" && styles.active_nav}`}>Debit Card</p>
					</Link>

					<Link to={`/portal/finance/${param._id}`} className={styles.nav_item}>
						<i className={`fa-solid fa-money-bill-transfer ${styles.mediumer_text} ${currentPage === "Finance" && styles.active_nav}`}></i>
						<p className={`${styles.large_text} ${currentPage === "Finance" && styles.active_nav}`}>Finance</p>
					</Link>

					<Link to={`/portal/insurance/${param._id}`} className={styles.nav_item}>
						<i className={`fa-solid fa-house-crack ${styles.mediumer_text} ${currentPage === "Insurance" && styles.active_nav}`}></i>
						<p className={`${styles.large_text} ${currentPage === "Insurance" && styles.active_nav}`}>Insurance</p>
					</Link>
				</div>

				<div className={`${styles.nav_group} ${styles.nav_group_bottom}`}>
					<button className={styles.nav_item} onClick={handleLogout}>
						<i className={`fa-solid fa-right-from-bracket ${styles.mediumer_text}`}></i>
						<p className={`${styles.large_text}`}>Logout</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;