import { useEffect, useState, useRef } from "react";
import axios from "axios";
import styles from './utilstyles.module.css';


const InsuranceForm = ({ param, handleActions }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({})


  const headings = {
    2: {
      title: "Request an insurance package",
      sub: "In order to request an insurance package, please follow the request guide below."
    },

    3: {
      title: "Property details",
      sub: "Enter the property you would like this insurance package for."
    },
  }

  const handlePopup = async (control, name?, input?, target=false) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'next':
        let valTest = await validationTest()
        if (valTest) {
          setPage(page+1)
        }

        break;
      case 'back':
        setValidationError(null)
        setPage(page-1)
        break;
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          let sendObj = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            phone: formData.phone,
            number_bedrooms: formData.number_bedrooms,
            year_built: formData.year_built
          }

          await axios.post(`/api/services/${param._id}/insurance`, {
            insurance_data: sendObj,
            insurance_address: formData.insurance_address
          });

          window.location.reload()
        }

        setInnerLoading(false)
        break;
      case 'set':
        if (target) {
          formDataDup[name] = input.target.value
        } else {
          formDataDup[name] = input
        }
        
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setFormData({})
      setValidationError(null)
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)
        setFormData({})
        setValidationError(null)
        handleActions('close')
      }
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validationTest = async () => {
    if (page === 2) {
      if (!(formData.hasOwnProperty('first_name')) || formData['first_name'] === '') {
        setValidationError("You did enter your first name.");
        return false;
      }

      if (!(formData.hasOwnProperty('last_name')) || formData['last_name'] === '') {
        setValidationError("You did enter your last name.");
        return false;
      }

      if (!(formData.hasOwnProperty('email')) || formData['email'] === '') {
        setValidationError("You did enter your email.");
        return false;
      } else {
        if (!(isValidEmail(formData.email))) {
          setValidationError(`You did not enter a valid email.`)
          return false;
        }
      }

      if (!(formData.hasOwnProperty('phone')) || formData['phone'] === '') {
        setValidationError("You did enter your phone number.");
        return false;
      }
    }

    if (page === 3) {
      if (!(formData.hasOwnProperty('insurance_address')) || formData['insurance_address'] === '') {
        setValidationError("You did enter the address.");
        return false;
      }

      if (!(formData.hasOwnProperty('number_bedrooms')) || formData['number_bedrooms'] === '') {
        setValidationError("You did enter the number of bedrooms.");
        return false;
      }

      if (!(formData.hasOwnProperty('year_built')) || formData['year_built'] === '') {
        setValidationError("You did enter the year the house was built.");
        return false;
      }
    }

    setValidationError(null)
    return true
  }

  function valuetext(value: number) {
    return `${value}°C`;
  }

  const marks = [
    {
      value: 0,
      label: '$0',
    },
    {
      value: 50000,
      label: '$50,000',
    }
  ];

  return (
    <div className={`${styles.popup_container} ${styles.popup_form}`}>
      <div className={`${styles.popup_heading} ${styles.popup_heading_notitle}`}>
        {headings[page] &&
          <div>
            <p className={styles.mediumer_text}>{headings[page].title}</p>
            <p className={styles.small_text}>{headings[page].sub}</p>
          </div>
        }
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <div className={styles.init_page}>
            <h1 className={styles.largest_text}>PROPERTY PROECTION,<br/><span>SIMPLIFIED</span></h1>
            <p className={styles.mediumer_text}>Request an insurance package for one of your properties from this form module.</p>
          </div>
        }

        {page === 2 &&
          <div className={`${styles.text_grid} ${styles.mb3}`}>
            <div className={styles.text_area}>
              <p className={`${styles.text_label} ${styles.small_text}`}>First name</p>
              <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.first_name} onChange={(input) => handlePopup("set", "first_name", input, true)}/>
            </div>

            <div className={styles.text_area}>
              <p className={`${styles.text_label} ${styles.small_text}`}>Last name</p>
              <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.last_name} onChange={(input) => handlePopup("set", "last_name", input, true)}/>
            </div>

            <div className={styles.text_area}>
              <p className={`${styles.text_label} ${styles.small_text}`}>Email</p>
              <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.email} onChange={(input) => handlePopup("set", "email", input, true)}/>
            </div>

            <div className={styles.text_area}>
              <p className={`${styles.text_label} ${styles.small_text}`}>Phone</p>
              <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.phone} onChange={(input) => handlePopup("set", "phone", input, true)}/>
            </div>
          </div>
        }

        {page === 3 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <div className={`${styles.text_grid} ${styles.mb3}`}>
                  <div className={styles.text_area}>
                    <p className={`${styles.text_label} ${styles.small_text}`}>Address</p>
                    <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.insurance_address} onChange={(input) => handlePopup("set", "insurance_address", input, true)}/>
                  </div>

                  <div className={styles.text_area}>
                    <p className={`${styles.text_label} ${styles.small_text}`}>No. Bedrooms</p>
                    <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.number_bedrooms} onChange={(input) => handlePopup("set", "number_bedrooms", input, true)}/>
                  </div>

                  <div className={styles.text_area}>
                    <p className={`${styles.text_label} ${styles.small_text}`}>Year house was built</p>
                    <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.year_built} onChange={(input) => handlePopup("set", "year_built", input, true)}/>
                  </div>
                </div>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        {page !== 1 && <button className={styles.control_btn} disabled={innerLoading} onClick={() => handlePopup('back')}><i className={`fa-solid fa-chevron-left ${styles.medium_text}`}></i></button>}
        {page !== 3 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('next')}><i className={`fa-solid fa-chevron-right ${styles.medium_text}`}></i></button>}
        {page === 3 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>}
      </div>
    </div>
  );
};

export default InsuranceForm;