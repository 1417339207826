import { useEffect, useState, useCallback } from "react";
import Select from 'react-select';
import axios from "axios";
import styles from "./AdminUsers.module.css"

import create_img from '../../../images/create.jpg'
import apply_img from '../../../images/apply.jpg'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne');


const PopUpFilter = ({ param, popUpPage, popUpControls, newFilterData, handleNewFilterData, validationError, formatOptions, allFilters, indNavFilter, changeIndNavFilter, handleTypes, typeFilters, allTradies }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [suburbsAvailable, setSuburbsAvailable] = useState([])

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				const data = await axios.get(`/api/admin/admin_map/suburbs/${param._id}`);
				setSuburbsAvailable(data.data.uniqueSuburbs)

				setIsLoading(false)
			} catch (error) {
				console.log(error)
			}
		};

		initLoad();
	}, []);

	return (
		<div className={`${styles.popup_container} ${styles.custom_height_pop}`}>
			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{isLoading
				?
					<div className={styles.load}>
						<hr /><hr /><hr /><hr />
				  	</div>
				:
					<>
						{popUpPage == 1 &&
							<>
								<h1 className={styles.medium_font}>{(newFilterData && newFilterData['edit']) ? "Edit" : "Create"} filter</h1>
								<p className={styles.smaller_font}>Please follow the create filter module prompts.</p>

						        <div className={styles.indsite_nav}>
						          <div className={styles.sitenav}>
						            <button className={`${styles.sitenav_box} ${indNavFilter === 'General' && styles.sitenav_active}`} onClick={() => changeIndNavFilter('General')}><p>General</p></button>
						            <button className={`${styles.sitenav_box} ${indNavFilter === 'Inspection' && styles.sitenav_active}`} onClick={() => changeIndNavFilter('Inspection')}><p>Inspection</p></button>
						            <button className={`${styles.sitenav_box} ${indNavFilter === 'Case' && styles.sitenav_active}`} onClick={() => changeIndNavFilter('Case')}><p>Case</p></button>
						            <button className={`${styles.sitenav_box} ${indNavFilter === 'Service' && styles.sitenav_active}`} onClick={() => changeIndNavFilter('Service')}><p>Service</p></button>
						          </div>
						        </div>

								<div className={`${styles.popup_form}`}>
									{indNavFilter === 'General' &&
										<>
											<div className={`${styles.popup_formbox}`}>
												<label className={styles.smaller_font}>Filter title</label>
												<input type="text" onChange={(input) => handleNewFilterData('set', 'title', {value: input.target.value})} value={newFilterData['title']}/>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Filter by date created</label>
												<div className={styles.radio_container}>
													<label className={styles.radio_button}>Yes
												  		<input
												  			type="radio"
												  			name="radio"
												  			checked={newFilterData['date_range_bool'] === true && "checked"}
												  			onChange={(input) => handleNewFilterData('set', 'date_range_bool', {value: true})}
												  		/>
												    	<span className={styles.checkmark}></span>
												  	</label>

												  	<label className={styles.radio_button}>No
												  		<input
												  			type="radio"
												  			name="radio"
												  			checked={newFilterData['date_range_bool'] === false && "checked"}
												  			onChange={(input) => handleNewFilterData('set', 'date_range_bool', {value: false})}
												  		/>
												    	<span className={styles.checkmark}></span>
												  	</label>
												</div>
											</div>

											{newFilterData['date_range_bool'] &&
												<div className={styles.popup_formbox}>
													<div className={styles.label_container}>
														<label className={styles.smaller_font}>From date</label>
														<label className={styles.smaller_font}>To date</label>
													</div>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DateRangePicker
															className={styles.overridebg}
															localeText={{ start: '', end: '' }}
															value={(newFilterData.date_range && newFilterData.date_range.length === 2) ? [dayjs(newFilterData.date_range[0]), dayjs(newFilterData.date_range[1])] : [null, null]}
															onChange={(selected) => handleNewFilterData('set', 'date_range', {value: selected})}
															format="DD/MM/YYYY"
														/>
													</LocalizationProvider>
												</div>
											}
										</>
									}

									{indNavFilter === 'Inspection' &&
									<>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Active</label>
											<div className={styles.radio_container}>
												<label className={styles.radio_button}>Yes
											  		<input
											  			type="radio"
											  			name="radio"
											  			checked={newFilterData['inspection']['active'] === true && "checked"}
											  			onChange={(selected) => handleNewFilterData("set", "active", {value: true}, "inspection")}
											  		/>
											    	<span className={styles.checkmark}></span>
											  	</label>

											  	<label className={styles.radio_button}>No
											  		<input
											  			type="radio"
											  			name="radio"
											  			checked={newFilterData['inspection']['active'] === false && "checked"}
											  			onChange={(selected) => handleNewFilterData("set", "active", {value: false}, "inspection")}
											  		/>
											    	<span className={styles.checkmark}></span>
											  	</label>
											</div>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Status</label>
											<Select
												isDisabled={!newFilterData['inspection']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={[{label: "To Schedule", value: "To Schedule"}, {label: "Single Service Required", value: "Single Service Required"}, {label: "Scheduled", value: "Scheduled"}]}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "status", selected, "inspection")}
										        value={
										            newFilterData['inspection']['status'] 
										                ? formatOptions(newFilterData['inspection']['status'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Suburbs</label>
											<Select
												isDisabled={!newFilterData['inspection']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={formatOptions(suburbsAvailable)}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "suburb", selected, "inspection")}
										        value={
										            newFilterData['inspection']['suburb'] 
										                ? formatOptions(newFilterData['inspection']['suburb'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Priority</label>
											<Select
												isDisabled={!newFilterData['inspection']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={[{label: 'Normal', value: 'Normal'}, {label: 'High', value: 'High'}]}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "priority", selected, "inspection")}
										        value={
										            newFilterData['inspection']['priority'] 
										                ? formatOptions(newFilterData['inspection']['priority'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Tradie assigned</label>
											<Select
												isDisabled={!newFilterData['inspection']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={formatOptions(allTradies)}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "tradies", selected, "inspection")}
										        value={
										            newFilterData['inspection']['tradies'] 
										                ? formatOptions(newFilterData['inspection']['tradies'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={`${styles.containerOR} ${styles.popup_formbox}`}>
											{typeFilters['inspection'].map((TF, idx) => {
												return (
													<>
														<div className={styles.popup_formbox}>
															{idx === 0 && <label className={styles.smaller_font}>Types</label>}

															<div className={styles.typeRow}>
																<Select
																	isDisabled={!newFilterData['inspection']['active']}
																	isMulti={true}
																	closeMenuOnSelect={true}
																	options={[{label: "Electrical", value: "Electrical"}, {label: "Gas", value: "Gas"}, {label: "Smoke", value: "Smoke"}]}
																	styles={{
																		control: (baseStyles, state) => ({
																			...baseStyles,
																			boxShadow: 'none !important',
																		   '&:hover': {
																		       borderColor: '#fce7eb !important'
																		    },
																			borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
																		}),
																	}}
															        onChange={(selected) => handleTypes("set", "inspection", idx, selected)}
															        value={formatOptions(TF)}
																>
																</Select>
																<button onClick={() => handleTypes('remove', 'inspection', idx)} disabled={idx === 0}><i className="fa-solid fa-trash-can"></i></button>
															</div>
														</div>

														{idx !== typeFilters['inspection'].length - 1 && <p className={styles.ORtext}>OR</p>}
													</>
												)
											})}


											<button disabled={!newFilterData['inspection']['active']} className={styles.addFilterBtn} onClick={() => handleTypes('add', 'inspection')}><i className="fa-solid fa-plus"></i></button>
										</div>
									</>
									}


									{indNavFilter === 'Case' &&
									<>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Active</label>
											<div className={styles.radio_container}>
												<label className={styles.radio_button}>Yes
											  		<input
											  			type="radio"
											  			name="radio"
											  			checked={newFilterData['case']['active'] === true && "checked"}
											  			onChange={(selected) => handleNewFilterData("set", "active", {value: true}, "case")}
											  		/>
											    	<span className={styles.checkmark}></span>
											  	</label>

											  	<label className={styles.radio_button}>No
											  		<input
											  			type="radio"
											  			name="radio"
											  			checked={newFilterData['case']['active'] === false && "checked"}
											  			onChange={(selected) => handleNewFilterData("set", "active", {value: false}, "case")}
											  		/>
											    	<span className={styles.checkmark}></span>
											  	</label>
											</div>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Status</label>
											<Select
												isDisabled={!newFilterData['case']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={[{label: "To Schedule", value: "To Schedule"}, {label: "Scheduled", value: "Scheduled"}]}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "status", selected, "case")}
										        value={
										            newFilterData['case']['status'] 
										                ? formatOptions(newFilterData['case']['status'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Types</label>
											<Select
												isDisabled={!newFilterData['case']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={formatOptions(["Gas & Elec Inspection Work", "Elec Inspection Work", "Gas Inspection Work", "Beeping Smoke Alarm", "Soft Maintenance", "Gardening", "Electricity", "Plumbing", "Other"])}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "type", selected, "case")}
										        value={
										            newFilterData['case']['type'] 
										                ? formatOptions(newFilterData['case']['type'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Suburbs</label>
											<Select
												isDisabled={!newFilterData['case']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={formatOptions(suburbsAvailable)}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "suburb", selected, "case")}
										        value={
										            newFilterData['case']['suburb'] 
										                ? formatOptions(newFilterData['case']['suburb'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Priority</label>
											<Select
												isDisabled={!newFilterData['case']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={[{label: 'Low', value: 'Low'}, {label: 'Medium', value: 'Medium'}, {label: 'High', value: 'High'}, {label: 'Critical', value: 'Critical'}]}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "priority", selected, "case")}
										        value={
										            newFilterData['case']['priority'] 
										                ? formatOptions(newFilterData['case']['priority'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Tradie assigned</label>
											<Select
												isDisabled={!newFilterData['case']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={formatOptions(allTradies)}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "tradies", selected, "case")}
										        value={
										            newFilterData['case']['tradies'] 
										                ? formatOptions(newFilterData['case']['tradies'])
										                : []
										        }
											>
											</Select>
										</div>
									</>
									}

									{indNavFilter === 'Service' &&
									<>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Active</label>
											<div className={styles.radio_container}>
												<label className={styles.radio_button}>Yes
											  		<input
											  			type="radio"
											  			name="radio"
											  			checked={newFilterData['service']['active'] === true && "checked"}
											  			onChange={(selected) => handleNewFilterData("set", "active", {value: true}, "service")}
											  		/>
											    	<span className={styles.checkmark}></span>
											  	</label>

											  	<label className={styles.radio_button}>No
											  		<input
											  			type="radio"
											  			name="radio"
											  			checked={newFilterData['service']['active'] === false && "checked"}
											  			onChange={(selected) => handleNewFilterData("set", "active", {value: false}, "service")}
											  		/>
											    	<span className={styles.checkmark}></span>
											  	</label>
											</div>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Status</label>
											<Select
												isDisabled={!newFilterData['service']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={[{label: "To Schedule", value: "To Schedule"}, {label: "Scheduled", value: "Scheduled"}]}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "status", selected, "service")}
										        value={
										            newFilterData['service']['status'] 
										                ? formatOptions(newFilterData['service']['status'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Types</label>
											<Select
												isDisabled={!newFilterData['service']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={formatOptions(["EV Charging Station Install","Roof Cleaning","Garage Door Service","Plumbing","Electrical","Handyman","Locksmith","Gutter Cleaning","Rubbish Removal","Pest Control","Gardening","Pool Inspection","Blinds Install","Rental Standards Checklist","Other"])}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "type", selected, "service")}
										        value={
										            newFilterData['service']['type'] 
										                ? formatOptions(newFilterData['service']['type'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Suburbs</label>
											<Select
												isDisabled={!newFilterData['service']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={formatOptions(suburbsAvailable)}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "suburb", selected, "service")}
										        value={
										            newFilterData['service']['suburb'] 
										                ? formatOptions(newFilterData['service']['suburb'])
										                : []
										        }
											>
											</Select>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Priority</label>
											<Select
												isDisabled={!newFilterData['service']['active']}
												isMulti={true}
												closeMenuOnSelect={true}
												options={[{label: 'Low', value: 'Low'}, {label: 'Medium', value: 'Medium'}, {label: 'High', value: 'High'}, {label: 'Critical', value: 'Critical'}]}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewFilterData("set", "priority", selected, "service")}
										        value={
										            newFilterData['service']['priority'] 
										                ? formatOptions(newFilterData['service']['priority'])
										                : []
										        }
											>
											</Select>
										</div>
									</>
									}

								</div>
							</>
						}

						{validationError &&
							<div className={`${styles.notification} ${styles.validationError}`}>
								<p className={styles.smaller_font}>{validationError}</p>
							</div>
						}

						<div className={styles.popUpControls}>
							<div className={styles.popUpControlsContainer}>
								<button type="button" className={styles.submitBtn} onClick={() => handleNewFilterData('submit')}>Submit</button>
				       		</div>
			       		</div>
	       			</>

			}
		</div>
	)
}

export default PopUpFilter;