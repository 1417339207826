import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import moment from "moment-timezone"

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import mylologo from '../../../images/mylologo.svg'
import styles from "./AdminUsers.module.css"
import Forbidden from '../../Forbidden';
import IndPayment from './IndPayment';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={`${styles.sidebar_account}`}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/admin/home/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-house ${styles.sidebar_icon}`} />
						</div>
					</Link>
				
					<Link to={`/admin/users/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-users ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/technicians/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-screwdriver-wrench ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/payments/${param._id}`}>
						<div className={`${styles.sidebar_box} ${styles.sidebar_active}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/map/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-map ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link className={styles.disabled_link}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gear ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

const DashboardPage = ({ param, navType, changeNavType, search_control, allPayments, formatDate, allUsers, indControls, indPayment }) => {
	return (
		<div className={`${styles.page_container}`}>
			<div className={`${styles.sites_container_users} ${(indPayment) && styles.hidden_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType === "All" ? "Payments" : navType}</h1>
						{navType === 'All'
							?
							<button>
								<i className="fa-solid fa-layer-group"></i>
								<p className={styles.smaller_font}>Manage prices</p>
							</button>
							:
							<button>
								<i className="fa-solid fa-gear"></i>
								<p className={styles.smaller_font}>Configure reader</p>
							</button>
						}

					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'All' && styles.sitenav_active}`} onClick={() => changeNavType('All')}><p>All</p></button>
						{/* <button className={`${styles.sitenav_box} ${navType == 'Reader 1' && styles.sitenav_active}`} onClick={() => changeNavType('Reader 1')}><p>Reader 1</p></button> */}
						{/* <button className={`${styles.sitenav_box} ${navType == 'Reader 2' && styles.sitenav_active}`} onClick={() => changeNavType('Reader 2')}><p>Reader 2</p></button> */}
					</div>
				</div>

				<div className={styles.user_stats}>
					<div className={styles.user_stat_box}>
						<h1 className={styles.stat_heading}>Pending payments</h1>
						{/* <h1 className={styles.stat_value}>Kfir S.</h1> */}
						<h1 className={styles.stat_value}>{allPayments.filter(payment => payment.status === "PENDING").length}</h1>
					</div>

					<div className={styles.user_stat_box}>
						<h1 className={styles.stat_heading}>Successful payments</h1>
						<h1 className={styles.stat_value}>{allPayments.filter(payment => payment.status === "COMPLETED").length}</h1>
					</div>

					<div className={styles.user_stat_box}>
						<h1 className={styles.stat_heading}>Failed payments</h1>
						<h1 className={styles.stat_value}>{allPayments.filter(payment => payment.status === "FAILED").length}</h1>
					</div>

					<div className={styles.user_stat_box}>
						<h1 className={styles.stat_heading}>Total amount</h1>
						<h1 className={styles.stat_value}>${allPayments.reduce((total, payment) => payment.status === "COMPLETED" && (total + payment.amount_money.amount / 100), 0).toFixed(2)}</h1>
					</div>

					<div className={styles.user_stat_box}>
						<h1 className={styles.stat_heading}>Last payment</h1>
						<h1 className={styles.stat_value}>{formatDate(allPayments[0].created_at, false, true)}</h1>
					</div>
				</div>

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => search_control(event, "main_table")}
								/>
							</div>
						</div>
					</div>

					<div className={styles.table_container}>
						<table className={styles.sites_table} id="main_table" align="center">						    
						    {navType == "All" &&
						    	<>
							    <thead>
							      <tr className={`${styles.table_rowth} ${styles.table_heading_custom}`}>
					                  <th>Tradie</th>
					                  <th>Date captured</th>
					                  <th>Status</th>
					                  <th>Amount</th>
					                  <th>Processing fee</th>
					                  <th>Receipt</th>
							      </tr>
							    </thead>
						    	<tbody>
					                <>
					                  {allPayments ?
					                    <>
					                      {allPayments.map((AP, index) => {
					                        return (
					                          <tr onClick={() => indControls('open', AP)} className={styles.hover_styling}>
					                            <td><p>{allUsers.filter(user => user.square_id === AP.device_details.device_installation_id)[0].first_name} {allUsers.filter(user => user.square_id === AP.device_details.device_installation_id)[0].last_name}</p></td>
					                            <td><p>{formatDate(AP.created_at, true)}</p></td>
					                            <td><p>{AP.status}</p></td>
					                            <td><p>${AP.amount_money.amount / 100}</p></td>
					                            <td><p>{AP.status === "COMPLETED" ? `$${AP.processing_fee[0].amount_money.amount / 100}` : "-"}</p></td>
					                            <td><a href={AP.receipt_url && `${AP.receipt_url}`} target="_blank">{AP.receipt_url ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</a></td>
					                          </tr>
					                        );
					                      })}
					                    </>
					                  :
					                    <div className={styles.load}>
					                      <hr />
					                      <hr />
					                      <hr />
					                      <hr />
					                    </div>
					                  }
					                </>
								</tbody>
								</>
							}
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}


const Payments = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [UserData, setUserData] = useState({});
	const [isLoading, setIsLoading] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(null)
	const [authStatusLoading, setIsAuthStatusLoading] = useState(null)
	
	const [validationError, setValidationError] = useState(null);
	const [navType, setNavType] = useState('All');

	const [allPayments, setAllPayments] = useState(null);
	const [allUsers, setAllUsers] = useState(null)
	const [indPayment, setIndPayment] = useState(null)
	const [indNav, setIndNav] = useState("Details");



	const navigate = useNavigate();

	const param = useParams();
	const url = `/api/admin/payments/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};


	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					
					setUserData(data.data.user_data);
					setAllPayments(data.data.payments) 
					setAllUsers(data.data.all_users)

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}
				setIsLoading(false)

			} catch (error) {
				console.log(error)
			}
		};
		initLoad();
	}, []);


	const changeNavType = (type) => {
		setNavType(type)
	}

	const search_control = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = document.getElementById(tableId).children[1].children;

	    for (let i = 0; i < target_div.length; i++) {
	        let txtValues = [];
	        for (let j = 0; j < target_div[i].children.length; j++) {
                if (target_div[i].children[j].className.includes('status')) {
                    // handle status
                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
                    txtValues.push(txtValue.toUpperCase());
                } else {
	            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
	            	txtValues.push(txtValue.toUpperCase());
	            }
	        }

	        let showItem = false;
	        txtValues.forEach((value) => {
	            if (value.indexOf(filter) > -1) {
	                showItem = true;
	            }
	        });

	        if (showItem) {
	            target_div[i].style.display = "table";
	        } else {
	            target_div[i].style.display = "none";
	        }
	    }
	};

	function formatDate(dateString, showTime, short) {
	    const dateObj = new Date(dateString);

	    let options;
	    if (short) {
		    options = {
		        year: '2-digit',
		        month: '2-digit',
		        day: '2-digit',
		        timeZone: 'Australia/Melbourne'
		    };
	    } else {
		    options = {
		        year: 'numeric',
		        month: '2-digit',
		        day: '2-digit',
		        timeZone: 'Australia/Melbourne'
		    };
	    }


	    if (showTime) {
	        options.hour = '2-digit';
	        options.minute = '2-digit';
	        options.hour12 = false;
	    }

	    const formattedDate = dateObj.toLocaleString('en-AU', options);
	    return formattedDate;
	}

	const changeIndNav = async (type) => {
		setIndNav(type)
	}

	const indControls = (control, ind) => {
		switch (control) {
			case 'open':
				setIndPayment(ind)
				break;
			case 'close':
				setIndPayment(null)
				setIndNav("Details")
				break;
// 			case 'edit':
// 				setCreateTradie(true);
// 				setNewTradieData({
// 					first_name: indTradie.first_name,
// 					last_name: indTradie.last_name,
// 					email: indTradie.email,
// 					expertise: indTradie.expertise,
// 					license_number: indTradie.license_number,
// 
// 					edit: true,
// 					tradieID: indTradie._id
// 				})
// 
// 				setIndNav("Details")
// 				setIndTradie(null)
// 				break;
		}
	}

	return (
		<>	
			<Offline>
				<div className="offline_container">
					<div className="offline_inner animate__animated animate__slideInDown">
						<i className="fa-solid fa-heart-crack"></i>
						<div className="offline_content">
							<p className="offline_title">You are offline</p>
							<p className="offline_subtitle">Please check your internet connect.</p>
						</div>
					</div>
				</div>
			</Offline>


			{isLoading
				? <div className={styles.load}>
					  <hr /><hr /><hr /><hr />
				  </div>
				: isAuthenticated === null
					? <div className={styles.load}>
						  <hr /><hr /><hr /><hr />
					  </div>
	        : isAuthenticated 
				  ? <div className={styles.main_container}>
							<NavigationBar {...{ param, handleLogout }} />
							<DashboardPage {...{ param, navType, changeNavType, search_control, allPayments, formatDate, allUsers, indControls, indPayment }} />

							{indPayment && <IndPayment {...{ indPayment, changeIndNav, indNav, formatDate, indControls, search_control }} />}
					</div>
					: <Forbidden/>
			}
		</>
	);
};


export default Payments;
