import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import styles from './debitcardstyles.module.css';
import booster from '../../../images/d_booster.svg';
import rewards_1 from '../../../images/d_rewards_1.svg';
import mylodebit from '../../../images/d_mylodebit.svg';
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import TableUtil from '../Utils/TableUtil'
import PopUpProperty from '../Utils/PopUpProperty'
import PopUpService from '../Utils/PopUpService'
import PopUpBeepingAlarm from '../Utils/PopUpBeepingAlarm'
import PopUpComplianceFilters from '../Utils/PopUpComplianceFilters'
import PopUpSettings from '../Utils/PopUpSettings'
import PopUpSecurity from '../Utils/PopUpSecurity'


const ActionContent = ({ handleActions, stats }) => {
	return (
		<div className={styles.action_container}>
			<button className={styles.action_item} onClick={() => handleActions('open', 'Properties')}>
				<i className={`fa-solid fa-house-circle-check ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Create a new property</p>
					<p className={styles.small_text}>{stats.props} properties</p>
				</div>
			</button>

			<button className={styles.action_item} onClick={() => handleActions('open', 'Services')}>
				<i className={`fa-solid fa-calendar-plus ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Create a new service</p>
					<p className={styles.small_text}>{stats.servs} services</p>
				</div>
			</button>

			<button className={styles.action_item} onClick={() => handleActions('open', 'Beeping Alarm')}>
				<i className={`fa-solid fa-triangle-exclamation ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Beeping smoke alarm</p>
					<p className={styles.small_text}>{stats.props} properties</p>
				</div>
			</button>
		</div>
	)
}


const DebitCard = () => {
	const param = useParams();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState("DebitCard")
	const [isLoading, setIsLoading] = useState(false)
	const [isAA, setIsAA] = useState(false)
	const [userData, setUserData] = useState({})
	const [openPopup, setOpenPopup] = useState(null)
	const [stats, setStats] = useState({
		props: 0,
		servs: 0
	})
	const [searchResults, setSearchResults] = useState({
		properties: [],
		inspections: [],
		services: []
	})


	useEffect(() => {
		const handleLoad = async () => {
			const url = `/api/finance/main/${param._id}/stats`;
			const role_token = localStorage.getItem('role_token');
			const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

			const { is_agency, user_data, ...statsCleaned } = data.data.dataMapped;

			setStats(statsCleaned)
			setIsAA(data.data.dataMapped.is_agency)
			setUserData(data.data.dataMapped.user_data)
		}

		handleLoad()
	}, []);

  	const handleActions = (control, ind) => {
  		switch (control) {
  			case 'open':
  				setOpenPopup(ind)
  				break;
  			case 'close':
  				setOpenPopup(null)
  				break;
  		}
  	}

	return (
		<div className={styles.outer_page}>
			<div className={styles.full_page}>
				<Sidebar {... { param, currentPage } }/>

				<div className={styles.main_container}>
					<Topbar
						param={param}
						setSearchResults={setSearchResults}
						searchResults={searchResults}
						customNav={true}
						setOpenPopup={setOpenPopup}
					/>


					<div className={styles.content_container}>
						<div className={`${openPopup && styles.hidden_container}`}>
							<ActionContent {... { handleActions, stats } }/>
						</div>

						<div className={`${styles.split_view} ${(openPopup || Object.values(searchResults).flat().length > 0) && styles.hidden_container}`}>
							<div className={styles.ind_view}>
								<div className={styles.debit_banner}>
									<img src={mylodebit}/>

									<div className={styles.debit_stats}>
										<div className={`${styles.debit_stat_card} ${styles.balance}`}>
											<div className={styles.card_icon}>
												<i className={`fa-solid fa-money-sign ${styles.mediumer_text}`}></i>
											</div>

											<div className={styles.card_content}>
												<p className={styles.small_text}>Total Balance</p>
												<p className={styles.large_mid_text}>$1380</p>
											</div>
										</div>

										<div className={`${styles.debit_stat_card} ${styles.balance}`}>
											<div className={styles.card_icon}>
												<i className={`fa-solid fa-money-sign ${styles.mediumer_text}`}></i>
											</div>

											<div className={styles.card_content}>
												<p className={styles.small_text}>Total Rebate</p>
												<p className={styles.large_mid_text}>$2195</p>
											</div>
										</div>
									</div>
								</div>

								<div className={styles.rewards_container}>
									<p className={styles.mediumer_text}>My Rewards</p>
									<div className={styles.rewards_grid}>
										<img src={rewards_1}/>
									</div>
								</div>
							</div>


							<div className={`${styles.ind_view} ${styles.no_padding}`}>
						    	<div className={styles.booster_container}>
						    		<img src={booster}/>
						    	</div>
							</div>
						</div>
					</div>

					{(openPopup && openPopup === "Properties") && <PopUpProperty {... { param, handleActions } }/>}
					{(openPopup && openPopup === "Services") && <PopUpService {... { param, handleActions } }/>}
					{(openPopup && openPopup === "Beeping Alarm") && <PopUpBeepingAlarm {... { param, handleActions } }/>}
					{(openPopup && openPopup === "Settings") && <PopUpSettings {... { param, handleActions, userData } }/>}
					{(openPopup && openPopup === "Security") && <PopUpSecurity {... { param, handleActions, userData } }/>}
				</div>
			</div>
		</div>
	);
};

export default DebitCard;