import styles from './dashboardstyles.module.css';

const UserStatistics = ({ statisticsData, handleDates, currentDateSelection, formatDate, weekOffset, isLoading, handlePasskey }) => {
	return (
		<div className={`${styles.popup_container} ${styles.weirdHeight}`}>
			<button className={styles.closePopup} onClick={() => handlePasskey('close_stats')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>View Statistics</h1>
			<p className={styles.smaller_font}>Use this module to view the teams statistics.</p>

			<div className={styles.sites_tablecontainer}>
				<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
					<div className={`${styles.currDateSel}`}>
						<button disabled={isLoading} onClick={() => handleDates('prev')}><i className="fa-solid fa-angle-left"></i></button>
						<p>{formatDate(currentDateSelection[0], false)} - {formatDate(currentDateSelection[1], false)}</p>
						<button disabled={isLoading || weekOffset === 0} onClick={() => handleDates('next')}><i className="fa-solid fa-angle-right"></i></button>
					</div>
				</div>

				<div className={styles.table_container}>
					<table className={styles.sites_table} id="ind_table_overview">
					    <thead className={styles.sitesind_thead}>
					      <tr className={`${styles.table_rowth} ${styles.tr_only}`}>
					      	<th colspan="2" className={styles.small_font}>Name</th>
					      	<th className={styles.small_font}>Mon</th>
					      	<th className={styles.small_font}>Tue</th>
					      	<th className={styles.small_font}>Wed</th>
					      	<th className={styles.small_font}>Thu</th>
					      	<th className={styles.small_font}>Fri</th>
					      	<th className={styles.small_font}>Total</th>
					      </tr>
					    </thead>
					    <tbody className={styles.customTBODY}>
							{isLoading
								? 
									<div className={styles.load}>
									  	<hr /><hr /><hr /><hr />
								  	</div>

								:
									<>
								    	<tr className={`${styles.sitesind_tr}`}>
									    	<td colspan="2" className={styles.td_overflow}><p>Sam</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Sam']['Monday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Sam']['Tuesday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Sam']['Wednesday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Sam']['Thursday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Sam']['Friday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Sam']['Total']}</p></td>
								    	</tr>

								    	<tr className={`${styles.sitesind_tr}`}>
									    	<td colspan="2" className={styles.td_overflow}><p>Emma</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Emma']['Monday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Emma']['Tuesday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Emma']['Wednesday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Emma']['Thursday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Emma']['Friday'].length}</p></td>
									    	<td className={styles.td_overflow}><p>{statisticsData['Emma']['Total']}</p></td>
								    	</tr>
							    	</>
							}
					    </tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default UserStatistics;