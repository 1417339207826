import React from "react";

import "./LearningOptions.css";

const PropertiesOptions = (props) => {
  const options = [
    {
      text: "How do I create a new property?",
      handler: () => props.actionProvider.handlePropertiesQuestions(1),
      id: 1,
    },
    {
      text: "What does the properties table show?",
      handler: () => props.actionProvider.handlePropertiesQuestions(2),
      id: 2,
    },
    {
      text: "How can I filter the results in the properties table?",
      handler: () => props.actionProvider.handlePropertiesQuestions(3),
      id: 3,
    },
    {
      text: "How can I view more details about each property?",
      handler: () => props.actionProvider.handlePropertiesQuestions(4),
      id: 4,
    },
      {
      text: "How do I change the package assigned to my property?",
      handler: () => props.actionProvider.handlePropertiesQuestions(5),
      id: 5,
    },
    {
      text: "How do I report a beeping smoke alarm?",
      handler: () => props.actionProvider.handlePropertiesQuestions(6),
      id: 6,
    },
    {
      text: "How do I create a new asset role?",
      handler: () => props.actionProvider.handlePropertiesQuestions(7),
      id: 7,
    },
    {
      text: "How do I edit an asset role?",
      handler: () => props.actionProvider.handlePropertiesQuestions(8),
      id: 8,
    },
    {
      text: "How does the compliance work?",
      handler: () => props.actionProvider.handlePropertiesQuestions(9),
      id: 9,
    },
    {
      text: "How can I transfer an asset to another property manager?",
      handler: () => props.actionProvider.handlePropertiesQuestions(10),
      id: 10,
    }
  ];

  const optionsMarkup = options.map((option) => (
    <button
      className="learning-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default PropertiesOptions;