import React from "react";

import "./LearningOptions.css";

const InspectionsOptions = (props) => {
  const options = [
    {
      text: "How do I create an inspection?",
      handler: () => props.actionProvider.handleInspectionsQuestions(1),
      id: 1,
    },
    {
      text: "What does the inspections table show?",
      handler: () => props.actionProvider.handleInspectionsQuestions(2),
      id: 2,
    },
    {
      text: "How can I filter the results in the inspections table?",
      handler: () => props.actionProvider.handleInspectionsQuestions(3),
      id: 3,
    },
    {
      text: "How can I view more details about each inspection?",
      handler: () => props.actionProvider.handleInspectionsQuestions(4),
      id: 4,
    },
      {
      text: "How does the compliance work?",
      handler: () => props.actionProvider.handleInspectionsQuestions(5),
      id: 5,
    },
    {
      text: "How do I view inspection reports?",
      handler: () => props.actionProvider.handleInspectionsQuestions(6),
      id: 6,
    },
    {
      text: "How do I view follow-up work?",
      handler: () => props.actionProvider.handleInspectionsQuestions(7),
      id: 7,
    },
    {
      text: "How do I edit an inspection?",
      handler: () => props.actionProvider.handleInspectionsQuestions(8),
      id: 8,
    }
  ];

  const optionsMarkup = options.map((option) => (
    <button
      className="learning-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default InspectionsOptions;