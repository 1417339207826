import { useEffect, useState, useRef } from "react";
import ReactAutocomplete from "react-google-autocomplete";
import SignatureCanvas from 'react-signature-canvas'
import Select from 'react-select';
import DOMPurify from 'dompurify';
import axios from "axios";

import styles from './utilstyles.module.css';
import SilverPackage from '../../../images/silver_package.svg';
import GoldPackage from '../../../images/gold_package.svg';
import PlatinumPackage from '../../../images/platinum_package.svg';
import SilverPackageInverted from '../../../images/silver_package2.svg';
import GoldPackageInverted from '../../../images/gold_package2.svg';
import PlatinumPackageInverted from '../../../images/platinum_package2.svg';


const PopUpProperty = ({ param, handleActions }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [termsConditions, setTermsConditions] = useState(null)
  const [formData, setFormData] = useState({ package_name: "Without Service Package" })
  const sigCanvas = useRef(null)
  const [isCanvasReady, setCanvasReady] = useState(false);

  const [addressUnformatted, setAddressUnformatted] = useState(null)
  const [selectedInspFiles, setSelectedInspFiles] = useState(null);
  const [showCustomAddress, setShowCustomAddress] = useState(false)
  const [confirmAddress, setConfirmAddress] = useState(null)

  const [customAddress, setCustomAddress] = useState({
    street: "",
    suburb: "",
    postcode: "",
    state: "VIC"
  })
  const [landlordData, setLandlordData] = useState([{
    role: "Landlord",
    name: "",
    email: "",
    mobile: ""
  }])
  const [tenantData, setTenantData] = useState([])


  const headings = {
    1: {
      title: "Create a property",
      sub: "Use this page to find your address."
    },

    2: {
      title: "Manage landlords",
      sub: "Use this page to add and manage the landlords."
    },

    3: {
      title: "Manage tenants",
      sub: "Use this page to add and manage the tenants."
    },

    4: {
      title: "Select package",
      sub: "Use this page to select your desired package."
    },

    5: {
      title: "Inspection information",
      sub: "Use this page to select information regarding the inspection."
    },

    6: {
      title: "Key-pickup information",
      sub: "Use this page to enter information regarding key pickups."
    },

    7: {
      title: "Terms & Conditions",
      sub: "Use this page to sign and agree to our terms and conditions."
    },
  }


  useEffect(() => {
    const handleLoad = async () => {
      setInnerLoading(true)
      const url = `/api/hub/${param._id}/termsConditions`;
      const role_token = localStorage.getItem('role_token');
      const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

      setTermsConditions(data.data.dataMapped)
      setInnerLoading(false)
    }

    const checkDOM = () => {
      if (sigCanvas.current) {
        setCanvasReady(true);
      }
    }

    handleLoad();
    checkDOM()
  }, []);

  const handlePopup = async (control, name?, input?, sel=false) => {
    let formDataDup = {...formData}


    switch (control) {
      case 'next':
        let valTest = await validationTest()

        if (valTest) {
          setPage(page+1)
        }
        break;
      case 'back':
        setValidationError(null)
        setPage(page-1)
        break;
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          let dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL();
          formData['signature'] = dataUrl

          const res = await axios.post(`/api/properties/${param._id}`, formData);
          if (res) {
            window.location.reload()
          }
        }

        setInnerLoading(false)
        break;
      case 'set':
        formDataDup[name] = input.target.value
        setFormData(formDataDup)
        break;
      case 'set_package':
        if (formDataDup[name] === input) {
          formDataDup[name] = "Without Service Package"
        } else {
          formDataDup[name] = input
        }
        
        setFormData(formDataDup)
        break;
      case 'set_type':
        if (formDataDup[name]) {
          formDataDup[name] = !formDataDup[name]
        } else {
          formDataDup[name] = true
        }

        if (name === "inspection_gas") {
          if (formDataDup[name] === true) {
            formDataDup['has_gas'] = true
          } else {
            formDataDup['has_gas'] = false
          }
        }
        
        setFormData(formDataDup)
        break;
      case 'toggle_custom_address':
        if (showCustomAddress) {
          setCustomAddress({
            street: "",
            suburb: "",
            postcode: "",
            state: "VIC"
          })

          setShowCustomAddress(false)
        } else {
          const { address, address_unformatted, ...rest } = formData;
          setFormData({ ...rest });
          setAddressUnformatted(null)

          setShowCustomAddress(true)
        }

        setValidationError(null)
        break;
      case 'set_custom_address':
        let customAddressDup = {...customAddress}

        if (sel) {
          customAddressDup[name] = input.value
        } else {
          customAddressDup[name] = input.target.value
        }

        setCustomAddress(customAddressDup)
        break;
    }
  }

  const handleAddress = (place) => {
    var ad1 = place.formatted_address.split(',')[0]
    var ad2 = place.formatted_address.split(',')[1].split(' ').slice(1, -2).join(' ')
    var ad3 = place.formatted_address.split(',')[1].split(' ').slice(-2).join(' ')

    setFormData({ ...formData, "address": place.formatted_address, "address_unformatted": place });
    setAddressUnformatted(place)
    return true
  }

  const handleAR = (control, type?, id?, name?, input?) => {
    switch (control) {
      case 'set':
        if (type === "landlord") {
          let landlordDataDup = [...landlordData]
          landlordDataDup[id][name] = input.target.value

          setLandlordData(landlordDataDup)
        } else {
          let tenantDataDup = [...tenantData]
          tenantDataDup[id][name] = input.target.value

          setTenantData(tenantDataDup)
        }
        break;
      case 'add':
        if (type === "landlord") {
          let landlordDataDup = [...landlordData]
          landlordDataDup.push({
            role: "Landlord",
            name: "",
            email: "",
            mobile: ""
          })

          setLandlordData(landlordDataDup)
        } else {
          let tenantDataDup = [...tenantData]
          tenantDataDup.push({
            role: "Tenant",
            name: "",
            email: "",
            mobile: ""
          })

          setTenantData(tenantDataDup)
        }
        break;
      case 'delete':
        if (type === "landlord") {
          let landlordDataDup = [...landlordData]
          landlordDataDup.splice(id, 1)

          setLandlordData(landlordDataDup)
        } else {
          let tenantDataDup = [...tenantData]
          tenantDataDup.splice(id, 1)

          setTenantData(tenantDataDup)
        }
        break;
    }
  }

  const handleInspFiles = (event) => {
    setSelectedInspFiles(event.target.files);
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validationTest = async () => {
    if (page === 1) {
      if (showCustomAddress) {
        if (!(customAddress.hasOwnProperty('street')) || customAddress['street'] === '') {
          setValidationError("You did not enter in the street number/name.")
          return false;
        }

        if (!(customAddress.hasOwnProperty('suburb')) || customAddress['suburb'] === '') {
          setValidationError("You did not enter in the suburb.");
          return false;
        }

        if (!(customAddress.hasOwnProperty('postcode')) || customAddress['postcode'] === '') {
          setValidationError("You did not enter in the postal code.");
          return false;
        } else {
          if (customAddress['postcode'].length !== 4) {
            setValidationError("The postal code you entered is not 4 digits.");
            return false;
          }
        }

        if (!(customAddress.hasOwnProperty('state')) || customAddress['state'] === '') {
          setValidationError("You did not select the state.");
          return false;
        }

        setInnerLoading(true)
        let search_dup_asset = await axios.post(`/api/properties/${param._id}/duplicates`, {customAddress: customAddress});
        setInnerLoading(false)

        if (search_dup_asset.data.message == "Property already exists") {
          setValidationError("It seems as though this property exists in our system, please contact the office if you believe this is incorrect.")
          return false;
        } else {
          setFormData({ ...formData, customAddress: customAddress })
        }
      } else {
        if (!formData['address']) {
          setValidationError("You did not enter in your property address.")
          return false;
        } else {
          setInnerLoading(true)
          let search_dup_asset = await axios.post(`/api/properties/${param._id}/duplicates`, {prop_address: addressUnformatted});
          setInnerLoading(false)

          if (search_dup_asset.data.message == "Property already exists") {
            setValidationError("It seems as though this property exists in our system, please contact the office if you believe this is incorrect.")
            setConfirmAddress(search_dup_asset.data.fullAddress)
            return false;
          } else {
            delete formData['customAddress']

            if (confirmAddress) {
              setConfirmAddress(null)
            } else {
              setConfirmAddress(search_dup_asset.data.fullAddress)
              return false
            }
          }
        }
      }
    }

    if (page === 2) {
      for (let a=0; a < landlordData.length; a++) {
        if (landlordData[a].name == '' || landlordData[a].name.split(' ').length < 2) {
          setValidationError(`You did not enter the full name for Landlord ${a+1}`)
          return false;
        }

        if (landlordData[a].email == '') {
          setValidationError(`You did not enter an email for Landlord ${a+1}`)
          return false;
        } else {
          if (!(isValidEmail(landlordData[a].email))) {
            setValidationError(`You did not enter a valid email for Landlord ${a+1}`)
            return false;
          }
        }

        if (landlordData[a].mobile == '') {
          setValidationError(`You did not enter the phone number for Landlord ${a+1}`)
          return false;
        }
      }


      setFormData({...formData, landlord_data: landlordData})
    }

    if (page === 3) {
      for (let a=0; a < tenantData.length; a++) {
        if (tenantData[a].name == '' || tenantData[a].name.split(' ').length < 2) {
          setValidationError(`You did not enter the full name for Tenant ${a+1}`)
          return false;
        }

        if (tenantData[a].email == '') {
          setValidationError(`You did not enter an email for Tenant ${a+1}`)
          return false;
        } else {
          if (!(isValidEmail(tenantData[a].email))) {
            setValidationError(`You did not enter a valid email for Tenant ${a+1}`)
            return false;
          }
        }

        if (tenantData[a].mobile == '') {
          setValidationError(`You did not enter the phone number for Tenant ${a+1}`)
          return false;
        }
      }


      setFormData({...formData, tenant_data: tenantData})
    }

    if (page === 5) {
      if (!formData.hasOwnProperty('inspection_gas') && !formData.hasOwnProperty('inspection_electric') && !formData.hasOwnProperty('inspection_smoke')) {
        setValidationError('Please select at least one inspection type.')
        return false;
      } else {
        if (!formData.inspection_gas && !formData.inspection_electric && !formData.inspection_smoke)  {
          setValidationError('Please select at least one inspection type.')
          return false
        }
      }
    }

    if (page === 7) {
      if (sigCanvas.current.isEmpty()) {
        setValidationError("Please scroll to the bottom to sign that you agree to the terms and conditions.")
        return false;
      }
    }

    setValidationError(null)
    return true
  }

  const DisplayHTMLComponent = ({ htmlContent }) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlContent);

    return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}/>;
  };

  const clearSignature = () => {
    sigCanvas.current.clear()
  }

  function handleClose() {
    const confirmClose = window.confirm("Are you sure you would like to close this?")

    if (confirmClose) {
      setInnerLoading(false)
      setValidationError(null)
      setPage(1)
      setTermsConditions(null)
      setFormData({ package_name: "Without Service Package" })
      setAddressUnformatted(null)
      setSelectedInspFiles(null)
      setShowCustomAddress(false)
      setCustomAddress({
        street: "",
        suburb: "",
        postcode: "",
        state: "VIC"
      })
      setLandlordData({
        role: "Landlord",
        name: "",
        email: "",
        mobile: ""
      })
      setTenantData([])
      handleActions('close')
    }
  }


  return (
    <div className={styles.popup_container}>
      <div className={styles.popup_heading}>
        <div>
          <p className={styles.mediumer_text}>{headings[page].title}</p>
          <p className={styles.small_text}>{headings[page].sub}</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 && 
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  {showCustomAddress
                    ?
                      <>
                        <div className={styles.text_grid}>
                          <div className={styles.text_area}>
                            <p className={`${styles.text_label} ${styles.small_text}`}>Street</p>
                            <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={customAddress.street} onChange={(input) => handlePopup("set_custom_address", "street", input)}/>
                          </div>

                          <div className={styles.text_area}>
                            <p className={`${styles.text_label} ${styles.small_text}`}>Suburb</p>
                            <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={customAddress.suburb} onChange={(input) => handlePopup("set_custom_address", "suburb", input)}/>
                          </div>

                          <div className={styles.text_area}>
                            <p className={`${styles.text_label} ${styles.small_text}`}>Postcode</p>
                            <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={customAddress.postcode} onChange={(input) => handlePopup("set_custom_address", "postcode", input)}/>
                          </div>

                          <div className={styles.text_area}>
                            <p className={`${styles.text_label} ${styles.small_text}`}>State</p>
                            <Select
                              options={[{label: "VIC", value: "VIC"}, {label: "NSW", value: "NSW"}, {label: "QLD", value: "QLD"}, {label: "WA", value: "WA"}, {label: "NT", value: "NT"}, {label: "ACT", value: "ACT"}]}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  padding: '2.5px 0',
                                  marginTop: '2.5px',
                                  boxSizing: 'border-box',
                                  boxShadow: 'none !important',
                                   '&:hover': {
                                       borderColor: '#26465333 !important'
                                    },
                                  borderColor: state.isFocused ? '#26465333' : '#26465333',
                                }),
                              }}
                              onChange={(selected) => handlePopup("set_custom_address", "state", selected, true)}
                              value={{value: customAddress.state, label: customAddress.state}}
                            />
                          </div>
                        </div>

                        <button className={styles.action_text_button}><p className={`${styles.text_label} ${styles.small_text}`} onClick={() => handlePopup("toggle_custom_address")}>Enter Automatically</p></button>
                      </>
                    :
                      <>
                        {confirmAddress &&
                          <div className={styles.warning_box}>
                            <p className={styles.small_text}>Please confirm that the address found below is correct. If not, enter the address manually.</p>
                          </div>
                        }

                        <div className={`${styles.text_area} ${confirmAddress && styles.m1_5}`}>
                          <p className={`${styles.text_label} ${styles.small_text}`}>Address</p>
                          <ReactAutocomplete
                            className={`${styles.general_input} ${styles.mid_text}`}
                            apiKey="AIzaSyDpQGM_wr1B1ztyG6gK_TUmFXTOcc8Wlhk"
                            onPlaceSelected={(place) => {handleAddress(place)}}
                            options={{
                              types: ["address"],
                              componentRestrictions: { country: "au" },
                            }}
                            defaultValue={formData['address']}
                            placeholder="Start typing your address..."
                          />

                          <button className={styles.action_text_button}><p className={`${styles.text_label} ${styles.small_text}`} onClick={() => handlePopup("toggle_custom_address")}>Enter Manually</p></button>
                        </div>

                        {confirmAddress &&
                          <div className={`${styles.text_area}`}>
                            <p className={`${styles.text_label} ${styles.small_text}`}>Address found</p>
                            <input
                              type="text"
                              className={`${styles.general_input} ${styles.mid_text}`}
                              value={confirmAddress}
                              disabled
                            />
                          </div>
                        }
                      </>
                  }
                </>
            }
          </>
        }

        {page === 2 && 
          <>
            <div className={`${styles.text_grid_outer} ${styles.mb1_5}`}>
              {landlordData.map((LD, index) => {
                return (
                  <div className={styles.text_grid}>
                    {landlordData.length > 1 &&
                      <div className={styles.ar_header}>
                        <p className={`${styles.text_label} ${styles.mid_text} ${styles.ar_heading}`}>Landlord {index+1}</p>
                        <button onClick={() => handleAR("delete", "landlord", index)}><i className="fa-solid fa-trash-can"></i></button>
                      </div>
                    }

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Full name</p>
                      <input type="text" onChange={(input) => handleAR("set", "landlord", index, "name", input)} value={LD["name"]} className={`${styles.general_input} ${styles.mid_text}`}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Email</p>
                      <input type="text" onChange={(input) => handleAR("set", "landlord", index, "email", input)} value={LD["email"]} className={`${styles.general_input} ${styles.mid_text}`}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Phone</p>
                      <input type="text" onChange={(input) => handleAR("set", "landlord", index, "mobile", input)} value={LD["mobile"]} className={`${styles.general_input} ${styles.mid_text}`}/>
                    </div>
                  </div>
                )
              })}
            </div>

            {landlordData.length < 2 && <button className={styles.add_ar} onClick={() => handleAR("add", "landlord")}><p className={styles.mid_text}>ADD ANOTHER LANDLORD</p></button>}
          </>
        }

        {page === 3 && 
          <>
            <div className={styles.warning_box}>
              <p className={styles.small_text}>If the property is vacant, simply ignore this page and click next.</p>
            </div>

            {tenantData.length > 0 &&
              <div className={`${styles.text_grid_outer} ${styles.m1_5}`}>
                {tenantData.map((TD, index) => {
                  return (
                    <div className={styles.text_grid}>
                      {tenantData.length > 0 &&
                        <div className={styles.ar_header}>
                          <p className={`${styles.text_label} ${styles.mid_text} ${styles.ar_heading}`}>Tenant {index+1}</p>
                          <button onClick={() => handleAR("delete", "tenant", index)}><i className="fa-solid fa-trash-can"></i></button>
                        </div>
                      }

                      <div className={styles.text_area}>
                        <p className={`${styles.text_label} ${styles.small_text}`}>Full name</p>
                        <input type="text" onChange={(input) => handleAR("set", "tenant", index, "name", input)} value={TD["name"]} className={`${styles.general_input} ${styles.mid_text}`}/>
                      </div>

                      <div className={styles.text_area}>
                        <p className={`${styles.text_label} ${styles.small_text}`}>Email</p>
                        <input type="text" onChange={(input) => handleAR("set", "tenant", index, "email", input)} value={TD["email"]} className={`${styles.general_input} ${styles.mid_text}`}/>
                      </div>

                      <div className={styles.text_area}>
                        <p className={`${styles.text_label} ${styles.small_text}`}>Phone</p>
                        <input type="text" onChange={(input) => handleAR("set", "tenant", index, "mobile", input)} value={TD["mobile"]} className={`${styles.general_input} ${styles.mid_text}`}/>
                      </div>
                    </div>
                  )
                })}
              </div>
            }

            {tenantData.length < 3 && <button className={`${styles.add_ar} ${tenantData.length === 0 && styles.mt1}`} onClick={() => handleAR("add", "tenant")}><p className={styles.mid_text}>ADD TENANT</p></button>}
          </>
        }

        {page === 4 && 
          <>
            <div className={styles.warning_box}>
              <p className={styles.small_text}>You may continue without selecting a package. You can also click on the package again to remove a selection you have made.</p>
            </div>
            
            <div className={styles.package_grid}>
              <button onClick={() => handlePopup("set_package", "package_name", "Silver")}>
                {formData.package_name === "Silver" ? <img src={SilverPackageInverted}/> : <img src={SilverPackage}/>}
              </button>
              <button onClick={() => handlePopup("set_package", "package_name", "Gold")}>
                {formData.package_name === "Gold" ? <img src={GoldPackageInverted}/> : <img src={GoldPackage}/>}
              </button>
              <button onClick={() => handlePopup("set_package", "package_name", "Platinum")}>
                {formData.package_name === "Platinum" ? <img src={PlatinumPackageInverted}/> : <img src={PlatinumPackage}/>}
              </button>
            </div>
          </>
        }

        {page === 5 && 
          <>
            <div className={styles.warning_box}>
              <p className={styles.small_text}>For any due dates or additional information, please write a message in the inspection commments.</p>
            </div>

            <div className={styles.inspection_type_container}>
              <p className={`${styles.text_label} ${styles.mid_text} ${styles.align_center}`}>Select your inspection type</p>

              <div className={styles.inspection_type_grid}>
                <button className={`${styles.radio_group} ${styles.radio_br} ${formData["inspection_electric"] === true && styles.active_radio}`} onClick={() => handlePopup("set_type", "inspection_electric")}>
                  <div><i className="fa-solid fa-bolt"></i></div>
                  <p className={`${styles.text_label} ${styles.mid_text}`}>Electrical</p>
                </button>

                <button className={`${styles.radio_group} ${styles.radio_br} ${styles.radio_bl} ${formData["inspection_gas"] === true && styles.active_radio}`} onClick={() => handlePopup("set_type", "inspection_gas")}>
                  <div><i className="fa-solid fa-house-fire"></i></div>
                  <p className={`${styles.text_label} ${styles.mid_text}`}>Gas</p>
                </button>

                <button className={`${styles.radio_group} ${styles.radio_bl} ${formData["inspection_smoke"] === true && styles.active_radio}`} onClick={() => handlePopup("set_type", "inspection_smoke")}>
                  <div><i className="fa-solid fa-fire"></i></div>
                  <p className={`${styles.text_label} ${styles.mid_text}`}>Smoke</p>
                </button>
              </div>
            </div>

            <div className={styles.text_large_container}>
              <div className={styles.text_grid}>
                <div className={styles.text_area}>
                  <p className={`${styles.text_label} ${styles.small_text}`}>Inspection comments</p>
                  <textarea onChange={(input) => handlePopup("set", "inspection_comment", input)} value={formData["inspection_comment"]} className={`${styles.general_textarea} ${styles.mid_text}`}/>
                </div>

                <div className={styles.text_area}>
                  <p className={`${styles.text_label} ${styles.small_text}`}>Inspection files</p>
                  <input onChange={handleInspFiles} type="file" className={`${styles.general_input} ${styles.mid_text}`} multiple/>
                </div>
              </div>
            </div>
          </>
        }

        {page === 6 && 
          <>
            <div className={styles.warning_box}>
              <p className={styles.small_text}>You may continue without any key pickup information.</p>
            </div>
            
            <div className={styles.text_large_container}>
              <div className={styles.text_grid}>
                <div className={styles.text_area}>
                  <p className={`${styles.text_label} ${styles.small_text}`}>Key pickup address</p>
                  <input type="text" onChange={(input) => handlePopup("set", "keypickup_address", input)} value={formData["keypickup_address"]} className={`${styles.general_input} ${styles.mid_text}`}/>
                </div>

                <div className={styles.text_area}>
                  <p className={`${styles.text_label} ${styles.small_text}`}>Key pickup comments</p>
                  <textarea onChange={(input) => handlePopup("set", "keypickup_comment", input)} value={formData["keypickup_comment"]} className={`${styles.general_textarea} ${styles.mid_text}`}/>
                </div>
              </div>
            </div>
          </>
        }

        {page === 7 &&
          <>
            {innerLoading 
              ?
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              : <DisplayHTMLComponent htmlContent={termsConditions}/>
            }

            <div className={styles.canv}>
              <SignatureCanvas
                canvasProps={{height: 200, className: `canvas_signature ${innerLoading && styles.hidden_canv}`}}
                ref={sigCanvas}
              />

              <button className={styles.clearSignature} onClick={() => clearSignature()}><i className="fa-solid fa-broom"></i></button>
            </div>
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        {page !== 1 && <button className={styles.control_btn} disabled={innerLoading} onClick={() => handlePopup('back')}><i className={`fa-solid fa-chevron-left ${styles.medium_text}`}></i></button>}
        {page !== 7 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('next')}><i className={`fa-solid fa-chevron-right ${styles.medium_text}`}></i></button>}
        {page === 7 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>}
      </div>
    </div>
  );
};

export default PopUpProperty;