import styles from './dashboardstyles.module.css';

const EditEmail = ({ handleChangeEmail, newEmail, validationError, showChangeEmail }) => {
	return (
		<div className={`${styles.popup_container}`}>

			<button className={styles.closePopup} onClick={() => handleChangeEmail('toggle')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Edit email</h1>
			<p className={styles.smaller_font}>Use this module to edit the user's primary email.</p>

			<div className={`${styles.notification} ${styles.warningMessage}`}>
				<p className={styles.smaller_font}>Before the user is able to sign in, they will have to complete email verification. The user's current email is: {showChangeEmail.email}</p>
			</div>
			

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Email</label>
					<input type="text" onChange={(input) => handleChangeEmail('set', null, input)} value={newEmail && newEmail}/>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleChangeEmail('submit')}>Submit</button>
	       		</div>
       		</div>


		</div>
	)
}

export default EditEmail;