import dstyles from "./dashboardstyles.module.css"
import styles from "./AdminUsers.module.css"


const IndOverview = ({ param, overviewInfo, overviewNav, changeOverviewNav, handleMarkerClick, popUpControls, search_control }) => {
	return (
		<div className={dstyles.indsite_container}>
			<div className={`${dstyles.indsite_popup} ${styles.overrideWidthPopup}`}>

				<div className={dstyles.indsite_inner}>
					<button className={dstyles.closePopup} onClick={() => popUpControls('close_overview')}><i className="fa-solid fa-x"></i></button>

					<h1 className={dstyles.medium_font}>Inspection details</h1>
					<p className={dstyles.smaller_font}>Please find the specific details for this inspection.</p>

					<div className={`${dstyles.indsite_nav} ${styles.overflowHig}`}>
						<div className={`${dstyles.sitenav} ${styles.removeBottom}`}>
			    			{Object.keys(overviewInfo.exportFinal).length > 0 &&
			    				<>
				    				{Object.keys(overviewInfo.exportFinal).map((IF) => {
				    					return (
				    						<button onClick={() => changeOverviewNav(IF)} className={`${styles.overview_box} ${styles.maxContWidth}`}><p className={overviewNav === IF && styles.overviewActive}>{IF}</p></button>
								    	)
								    })}
							    </>
			    			}

						</div>
					</div>

					<div className={dstyles.sites_tablecontainer}>
						<div className={`${dstyles.sites_controls} ${dstyles.indsite_controls_wh}`}>
							<div className={`${dstyles.sites_searchcontainer} ${dstyles.autowidth}`}>
								<div className={dstyles.sites_search}>
									<i className="fa-solid fa-magnifying-glass"></i>
									<input
									    type="text"
									    className={dstyles.smaller_font}
									    placeholder="Search by the file name..."
									    onKeyUp={(event) => search_control(event, "ind_table_overview")}
									/>
								</div>
							</div>
						</div>

						<div className={dstyles.table_container}>
							<table className={dstyles.sites_table} id="ind_table_overview">
							    <thead className={dstyles.sitesind_thead}>
							      <tr className={`${dstyles.table_rowth} ${dstyles.tr_only}`}>
							      	<th colspan="3" className={dstyles.small_font}>Address</th>
							      	<th colspan="1" className={dstyles.small_font}>Name</th>
							      	<th colspan="2" className={dstyles.small_font}>Account</th>
							      </tr>
							    </thead>
							    <tbody className={dstyles.indtbody}>
					    			{overviewInfo.exportFinal[overviewNav].length > 0
					    				? 
					    				<>
						    				{overviewInfo.exportFinal[overviewNav].map((IF) => {
						    					return (
											    	<tr className={`${dstyles.sitesind_tr} ${styles.customtroverview}`} onClick={(pos) => handleMarkerClick('click', document.getElementById(IF.Id), overviewInfo.markerInspections, pos)}>
												    	<td colspan="3" className={dstyles.td_overflow}><p>{IF.Address}</p></td>
												    	<td colspan="1" className={dstyles.td_overflow}><a className={styles.clickable_nav} href={`https://mylopropertysolutions.lightning.force.com/lightning/r/Inspection__c/${IF.Id}/view`} target="_blank"><span>{IF.Name}</span></a></td>
												    	<td colspan="2" className={dstyles.td_overflow}><p>{IF.Account}</p></td>
											    	</tr>
										    	)
										    })}
									    </>
					    				: <h1 className={`${dstyles.medium_font} ${dstyles.create_first2}`}>This inspection does not have any files yet!</h1>
					    			}
							    </tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default IndOverview;