import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from '../../images/mylologo.svg';

import './stylestech.css';
import lstyles from "./Loadingformstyles.module.css"

const Loading = () => {
	const param = useParams();

	return (
    <div className={lstyles.load}>
      <hr/><hr/><hr/><hr/>
    </div>
	)
};

export default Loading;
