import { useEffect, useState, useRef } from "react";
import axios from "axios";

import styles from './utilstyles.module.css';
import password_image from '../../../images/password.jpg';
import twofa_image from '../../../images/2fa.jpg';
import email_sent from '../../../images/emailSent.jpg';


const PopUpSecurity = ({ param, handleActions, userData }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [securityNav, setSecurityNav] = useState(null)


  function handleClose() {
    if (page === 1) {
      setInnerLoading(false);
      setValidationError(null);
      setSecurityNav(null)
      handleActions('close')
    } else {
      window.location.reload()
    }
  }

  const handlePopup = async (control, name?, input?) => {
    switch (control) {
      case 'submit':
        const confirm_security = window.confirm(`Are you sure you would like to ${securityNav === "password" ? "change your password?" : `${userData.two_factor_auth ? "disable" : "enable"} 2-factor authentication?`}`)

        if (confirm_security) {
          setInnerLoading(true)
          setPage(page + 1)

          if (securityNav === "password") {
            await axios.post(`/api/users/forgot-password`, {email: userData.email});
          } else if (securityNav === "2fa") {
            await axios.get(`/api/dashboard/${param._id}/handle_2fa`);
          }

          setInnerLoading(false)
        }
        break;
      case 'nav':
        setSecurityNav(name)
        break;
    }
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_security}`}>
      <div className={`${styles.popup_heading}`}>
        <div>
          <p className={styles.mediumer_text}>Security settings</p>
          <p className={styles.small_text}>Use this module to change your security settings.</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <div className={styles.security_btn_container}>
            <button className={`${styles.security_btn} ${securityNav === "password" && styles.active_security_btn}`} onClick={() => handlePopup("nav", "password")}>
              <div className={`${styles.security_img_container} ${securityNav === "password" && styles.active_security_border}`}>
                <img src={password_image}/>
              </div>

              <div className={styles.security_content_container}>
                <p className={styles.mid_text}>Change password</p>
                <p className={styles.small_text}>You will be sent an email with instructions to reset your password.</p>
              </div>
            </button>

            <button className={`${styles.security_btn} ${securityNav === "2fa" && styles.active_security_btn}`} onClick={() => handlePopup("nav", "2fa")}>
              <div className={`${styles.security_img_container} ${securityNav === "2fa" && styles.active_security_border}`}>
                <img src={twofa_image}/>
              </div>

              <div className={styles.security_content_container}>
                <p className={styles.mid_text}>2-factor authentication</p>
                <p className={styles.small_text}>Secure your account with 2fa through an email code on login.</p>
              </div>
            </button>
          </div>
        }

        {page === 2 &&
          <div className={styles.confirmation_container}>
            <img src={email_sent}></img>
            {securityNav === "password" && <p className={styles.medium_text}>Please check your inbox as we have sent you an email with prompts to follow in order to reset your password!</p>}
            {securityNav === "2fa" && <p className={styles.medium_text}>2 factor authentication has now been turned {userData.two_factor_auth ? "off" : "on"} for this account. {!userData.two_factor_auth && "Each login will now require a code sent to your email!"}</p>}
          </div>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        {page === 1 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>}
      </div>
    </div>
  );
};

export default PopUpSecurity;