import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import mylologo from '../../../images/mylologo.svg'
import styles from "./AdminUsers.module.css"
import Forbidden from '../../Forbidden';
import EditEmail from './EditEmail';
import TermsConditions from './TermsConditions';
import IndUser from './IndUser';


const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={`${styles.sidebar_account}`}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/admin/home/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-house ${styles.sidebar_icon}`} />
						</div>
					</Link>
				
					<Link to={`/admin/users/${param._id}`}>
						<div className={`${styles.sidebar_box} ${styles.sidebar_active}`}>
							<i className={`fa-solid fa-users ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/technicians/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-screwdriver-wrench ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/payments/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/map/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-map ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link className={styles.disabled_link}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gear ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

const DashboardPage = ({ param, navType, changeNavType, search_control, allUsers, formatDate, showTools, handleShowTools, sendVerifyEmail, removeAgencyAdmin, agencyAdmin, syncInformation, handleChangeEmail, deleteUser, showStats, handleStats, showChangeEmail, newEmail, validationError, handleTermsConditions, updateTermsConditions, handleInd, indInfo }) => {
	return (
		<div className={`${styles.page_container}`}>
			<div className={`${(showStats) ? styles.sites_container_users : styles.sites_container} ${(showChangeEmail || updateTermsConditions || indInfo) && styles.hidden_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						{navType == 'Users' &&
							<div className={styles.dual_buttons}>
								<button onClick={() => handleTermsConditions('toggle')}>
									<i className="fa-solid fa-file-contract"></i>
									<p className={styles.smaller_font}>Update T&C</p>
								</button>

								<button onClick={() => handleStats('toggle')}>
									<i className="fa-solid fa-chart-simple"></i>
									<p className={styles.smaller_font}>Toggle stats</p>
								</button>
							</div>
						}
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Users' && styles.sitenav_active}`} onClick={() => changeNavType('Users')}><p>Users</p></button>
					</div>
				</div>

				{showStats &&
					<div className={styles.user_stats}>
						<div className={styles.user_stat_box}>
							<h1 className={styles.stat_heading}>No. Users</h1>
							<h1 className={styles.stat_value}>{allUsers.length}</h1>
						</div>
						<div className={styles.user_stat_box}>
							<h1 className={styles.stat_heading}>Verified users</h1>
							<h1 className={styles.stat_value}>{allUsers.filter(user => user.verified).length}</h1>
						</div>
						<div className={styles.user_stat_box}>
							<h1 className={styles.stat_heading}>No. Properties</h1>
							<h1 className={styles.stat_value}>{allUsers.reduce((total, user) => total + user.no_properties, 0)}</h1>
						</div>
						<div className={styles.user_stat_box}>
							<h1 className={styles.stat_heading}>No. Inspections</h1>
							<h1 className={styles.stat_value}>{allUsers.reduce((total, user) => total + user.no_inspections, 0)}</h1>
						</div>
						<div className={styles.user_stat_box}>
							<h1 className={styles.stat_heading}>No. Services</h1>
							<h1 className={styles.stat_value}>{allUsers.reduce((total, user) => total + user.no_services, 0)}</h1>
						</div>
					</div>
				}

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => search_control(event, "main_table")}
								/>
							</div>
						</div>
					</div>

					<div className={styles.table_container}>
						<table className={styles.sites_table} id="main_table" align="center">						    
						    {navType == "Users" &&
						    	<>
							    <thead>
							      <tr className={`${styles.table_rowth} ${styles.table_heading_custom}`}>
					                  <th colspan="4">Accounts</th>
					                  <th colspan="2">Date created</th>
					                  <th colspan="2">Last login</th>
					                  <th colspan="1">Verified</th>
					                  <th colspan="1">Properties</th>
					                  <th colspan="1">Inspections</th>
					                  <th colspan="1">Services</th>
					                  <th colspan="1">Tools</th>
							      </tr>
							    </thead>
						    	<tbody>
					                <>
					                  {allUsers ?
					                    <>
					                      {allUsers.map((IU, index) => {
					                        return (
					                          <tr className={styles.no_hover}>
					                            <td colspan="4"><p>{IU.first_name} {IU.last_name} ({IU.agency_name ? IU.agency_name : "LOADING..."}) {IU.is_agency && <strong>(ADMIN)</strong>}</p></td>
					                            <td colspan="2"><p>{formatDate(IU.date_created)}</p></td>
					                            <td colspan="2"><p>{IU.last_login ? formatDate(IU.last_login) : '-'}</p></td>
					                            <td colspan="1"><p>{IU.verified ? <i className={`fa-solid fa-check ${styles.green_tick}`}></i> : <i className={`fa-solid fa-xmark ${styles.red_mark}`}></i>}</p></td>
					                            <td colspan="1"><p>{IU.no_properties} ({IU.props_created_portal ? IU.props_created_portal : 0})</p></td>
					                            <td colspan="1"><p>{IU.no_inspections} ({IU.insp_created_portal ? IU.insp_created_portal : 0})</p></td>
					                            <td colspan="1"><p>{IU.no_services} ({IU.serv_created_portal ? IU.serv_created_portal : 0})</p></td>
					                            <td colspan="1">
					                              <div>
						                            {(showTools === index) ?
							                            <div className={styles.toolsInner}>
							                              	<button onClick={() => handleShowTools('close', index)} className={`${styles.trash_button} ${styles.admin_close}`}>
								                            	<i className="fa-solid fa-xmark"></i>
								                            </button>

								                            <button onClick={() => handleInd("open", IU)} className={styles.trash_button}>
								                              	<i className="fa-solid fa-circle-info"></i>
								                              	<p>Show info</p>
								                            </button>

								                            <button onClick={() => sendVerifyEmail(IU)} className={styles.trash_button} disabled={IU.verified}>
								                              	<i className="fa-solid fa-paper-plane"></i>
								                              	<p>Resend email</p>
								                            </button>

								                            {IU.is_agency
								                            ?
								                            	<button onClick={() => removeAgencyAdmin(IU)} className={styles.trash_button}>
								                              		<i className="fa-solid fa-ban"></i>
								                              		<p>Remove agency</p>
								                            	</button>
								                            :
								                            	<button onClick={() => agencyAdmin(IU)} className={styles.trash_button}>
								                              		<i className="fa-solid fa-key"></i>
								                              		<p>Set agency</p>
								                            	</button>
								                          	}

								                            <button onClick={() => syncInformation(IU)} className={styles.trash_button}>
								                              	<i className="fa-solid fa-rotate"></i>
								                              	<p>Sync info</p>
								                            </button>

								                            <button onClick={() => handleChangeEmail('toggle', IU)} className={styles.trash_button}>
								                              	<i className="fa-solid fa-pen"></i>
								                              	<p>Change email</p>
								                            </button>

								                            <button onClick={() => deleteUser(IU)} className={styles.trash_button}>
								                              	<i className="fa-solid fa-trash-can"></i>
								                              	<p>Delete user</p>
								                            </button>
								                        </div>
								                    :
						                              	<button onClick={() => handleShowTools('set', index)} className={styles.trash_button}>
							                            	<i className="fa-solid fa-bars"></i>
							                            </button>
							                    	}
					                              </div>
					                            </td>
					                          </tr>
					                        );
					                      })}
					                    </>
					                  :
					                    <div className={styles.load}>
					                      <hr />
					                      <hr />
					                      <hr />
					                      <hr />
					                    </div>
					                  }
					                </>
								</tbody>
								</>
							}
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}


const Users = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [UserData, setUserData] = useState({});
	const [isLoading, setIsLoading] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(null)
	const [isAuthStatusLoading, setIsAuthStatusLoading] = useState(true);
	const [validationError, setValidationError] = useState(null);
	const [navType, setNavType] = useState('Users');
	const [showStats, setShowStats] = useState(true)

	const [allUsers, setAllUsers] = useState(null);
	const [showTools, setShowTools] = useState(null);
	const [showChangeEmail, setShowChangeEmail] = useState(null);
	const [newEmail, setNewEmail] = useState(null);
	const [showAdminTools, setShowAdminTools] = useState(false);
	const [updateTermsConditions, setUpdateTermsConditions] = useState(false);
	const editorRef = useRef(null);
	const [currentTermsConditions, setCurrentTermsConditions] = useState(null)

	const [indInfo, setIndInfo] = useState(null)
	const [indNav, setIndNav] = useState("Properties")

	const navigate = useNavigate();

	const param = useParams();
	const url = `/api/admin/users/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					
					setUserData(data.data.user_data);
					setAllUsers(data.data.all_users);
					setCurrentTermsConditions(data.data.termsConditions)

					setIsAuthenticated(true)
					setIsAuthStatusLoading(false);
					setIsLoading(false)

					const user_agencies = await axios.post(`/api/admin/${param._id}/get_agency_names`, {all_agency_ids: data.data.all_agency_ids});
					const user_results = data.data.all_users.map(user => ({ ...user, agency_name: user_agencies.data.agency_names.find(record => record.Id.toString() === user.agency_sfid)?.Name.toString() })); 
					setAllUsers(user_results)

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

			} catch (error) {
				console.log(error)
			}
		};
		initLoad();
	}, []);


	const changeNavType = (type) => {
		setNavType(type)
	}


	const search_control = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = document.getElementById(tableId).children[1].children;

	    for (let i = 0; i < target_div.length; i++) {
	        let txtValues = [];
	        for (let j = 0; j < target_div[i].children.length; j++) {
                if (target_div[i].children[j].className.includes('status')) {
                    // handle status
                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
                    txtValues.push(txtValue.toUpperCase());
                } else {
	            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
	            	txtValues.push(txtValue.toUpperCase());
	            }
	        }

	        let showItem = false;
	        txtValues.forEach((value) => {
	            if (value.indexOf(filter) > -1) {
	                showItem = true;
	            }
	        });

	        if (showItem) {
	            target_div[i].style.display = "table";
	        } else {
	            target_div[i].style.display = "none";
	        }
	    }
	};

	function formatDate(dateString) {
	    const dateObj = new Date(dateString);
	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);
	    return `${day}-${month}-${year}`;
	}

	function isValidEmail(email) {
	  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	  return emailRegex.test(email);
	}


	const sendVerifyEmail = async (user) => {
		const verify_action = window.confirm(`Are you sure you would like to send an email to user ${user.first_name} ${user.last_name}`)

		if (verify_action) {
			await axios.post(`/api/admin/${param._id}/send`, {user: user});
			window.alert("Success!")
		}
	}


	const agencyAdmin = async (user) => {
		const verify_action = window.confirm(`Are you sure you would like to make ${user.first_name} ${user.last_name} the agency admin?`)

		if (verify_action) {
			await axios.post(`/api/admin/${param._id}/agency_admin`, {user: user});
			window.location.reload()
		}
	}

	const removeAgencyAdmin = async (user) => {
		const verify_action = window.confirm(`Are you sure you would like to remove ${user.first_name} ${user.last_name} as the agency admin?`)

		if (verify_action) {
			await axios.post(`/api/admin/${param._id}/remove_agency_admin`, {user: user});
			window.location.reload()
		}
	}

	const syncInformation = async (user) => {
		const verify_action = window.confirm(`Are you sure you would like to sync all information from Salesforce to ${user.first_name} ${user.last_name}'s portal account?`)

		if (verify_action) {
			await axios.post(`/api/admin/${param._id}/sync_to_salesforce`, {user: user});
			window.location.reload()
		}
	}

	const deleteUser = async (user) => {
		const verify_action = window.confirm(`Are you sure you would like to DELETE ${user.first_name} ${user.last_name}'s portal account?`)

		if (verify_action) {
			await axios.post(`/api/admin/${param._id}/remove_user`, {user: user});
			window.location.reload()
		}
	}

	const handleShowTools = (control, index) => {
		switch (control) {
			case 'set':
				setShowTools(index)
				break;
			case 'close':
				setShowTools(null)
		}
	}

	const handleChangeEmail = async (control, user=null, input=null) => {
		switch (control) {
			case 'toggle':
				if (showChangeEmail) {
					setShowChangeEmail(null)
				} else {
					setShowChangeEmail(user)
				}
				
				break;
			case 'set':
				setNewEmail(input.target.value)
				break;
			case 'submit':
				if (newEmail === '') {
					setValidationError(`Please enter in an email address.`)
					return false;
				} else {
					// check if email exists
					let emailExisting = allUsers.filter(user => user.email === newEmail)
					if (emailExisting.length > 0) {
						setValidationError("An user already exists with this email, please use something unique.")
						return false;
					}

					if (!(isValidEmail(newEmail))) {
						setValidationError(`Please enter a valid email address.`)
						return false;
					} else {
						if (newEmail === showChangeEmail.email) {
							setValidationError(`This is the user's current email address.`)
							return false;
						}
					}
				}
				await axios.post(`/api/admin/${param._id}/update_email`, {user: showChangeEmail, newEmail: newEmail});
				window.location.reload()
				break;
		}
	}

	const handleShowAdminTools = (control, nav) => {
		switch (control) {
			case 'set':
				setShowAdminTools(true)
				break;
			case 'close':
				setShowAdminTools(false);
				break;
			case 'navigate':
				switch (nav) {
					case 'users':
						navigate(`/admin/users/${param._id}`);
						break;
					case 'technicians':
						navigate(`/admin/technicians/${param._id}`);
						break;
				}
				break;
		}
	}


	const handleStats = (control) => {
		switch (control) {
			case 'toggle':
				setShowStats(!showStats)
				break;
		}
	}

	const handleTermsConditions = async (control) => {
		switch (control) {
			case 'toggle':
				setUpdateTermsConditions(!updateTermsConditions)
				break;
			case 'submit':
				if (editorRef.current) {
					await axios.post(`/api/admin/update_tc/${param._id}`, {content: editorRef.current.getContent()});
					window.location.reload()
				}
				break;
		}
	}

	const handleInd = (control, ind) => {
		switch (control) {
			case 'open':
				setShowTools(null)
				setIndInfo(ind)
				break;
			case 'close':
				setShowTools(null)
				setIndInfo(null)
				setIndNav("Properties")
				break;
		}	
	}

	const changeIndNav = async (type) => {
		setIndNav(type)
	}

	return (
		<>	
			<Offline>
				<div className="offline_container">
					<div className="offline_inner animate__animated animate__slideInDown">
						<i className="fa-solid fa-heart-crack"></i>
						<div className="offline_content">
							<p className="offline_title">You are offline</p>
							<p className="offline_subtitle">Please check your internet connect.</p>
						</div>
					</div>
				</div>
			</Offline>

			{isLoading
				? <div className={styles.load}>
					  <hr /><hr /><hr /><hr />
				  </div>
				: isAuthenticated === null
					? <div className={styles.load}>
						  <hr /><hr /><hr /><hr />
					  </div>
	        : isAuthenticated 
				  ? <div className={styles.main_container}>
						<NavigationBar {...{ param, handleLogout }} />
						<DashboardPage {...{ param, navType, changeNavType, search_control, allUsers, formatDate, showTools, handleShowTools, sendVerifyEmail, removeAgencyAdmin, agencyAdmin, syncInformation, handleChangeEmail, deleteUser, showStats, handleStats, showChangeEmail, newEmail, validationError, handleTermsConditions, updateTermsConditions, handleInd, indInfo }} />

						{showChangeEmail && <EditEmail {...{ handleChangeEmail, newEmail, validationError, showChangeEmail }} />}
						{updateTermsConditions && <TermsConditions {...{ validationError, handleTermsConditions, editorRef, currentTermsConditions }} />}

						{indInfo && <IndUser {... { param, indInfo, handleInd, indNav, changeIndNav, search_control }}/>}
					</div>
					: <Forbidden/>
			}
		</>
	);
};


export default Users;
