import { useEffect, useState, useRef } from "react";
import axios from "axios";
import _ from 'lodash';

import styles from './utilstyles.module.css';


const PopUpEditRoles = ({ param, handleActions, customNode, setActiveTabInner, setOuterKey }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: ""
  })


  useEffect(() => {
    const handleLoad = async () => {
      if (customNode) {
        setFormData({
          first_name: customNode.first_name || "",
          last_name: customNode.last_name || "",
          email: customNode.email || "",
          phone: customNode.phone || "",
        })
      }
    }

    handleLoad()
  }, []);

  const handlePopup = async (control, name?, input?) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {

          let sendObj = {
            type: customNode.role,
            firstName: formData.first_name || "",
            lastName: formData.last_name || "",
            Email: formData.email || "",
            Phone: formData.phone || "",

            Id: customNode.contact_id,
            sf_id: customNode.sf_id,

            old_ar: {
              type: customNode.role,
              firstName: customNode.first_name || "",
              lastName: customNode.last_name || "",
              Email: customNode.email || "",
              Phone: customNode.phone || "",
              Id: customNode.contact_id
            }
          }

          const res = await axios.post(`/api/properties/${param._id}/ar`, sendObj);
          handleClose(true)
        }

        setInnerLoading(false)
        break;
      case 'set':
        formDataDup[name] = input.target.value
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setFormData({
        first_name: customNode.first_name || "",
        last_name: customNode.last_name || "",
        email: customNode.email || "",
        phone: customNode.phone || "",
      })
      setValidationError(null)

      setActiveTabInner("Roles")
      setOuterKey((prevKey) => prevKey + 1);
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)
        setFormData({
          first_name: customNode.first_name || "",
          last_name: customNode.last_name || "",
          email: customNode.email || "",
          phone: customNode.phone || "",
        })
        setValidationError(null)
        handleActions('close')
      }
    }

  }

  const validationTest = async () => {
    if (page === 1) {
      let startingForm = {
        first_name: customNode.first_name || "",
        last_name: customNode.last_name || "",
        email: customNode.email || "",
        phone: customNode.phone || "",
      }

      if (_.isEqual(startingForm, formData)) {
        setValidationError("You did not change any details")
        return false;
      }
    }

    setValidationError(null)
    return true
  }

  const handleDelete = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this asset role?")

    if (confirmDelete) {
      setInnerLoading(true)

      let sendObj = {
        Id: customNode.contact_id,
        sf_id: customNode.sf_id,
        first_name: customNode.first_name,
        last_name: customNode.last_name
      }

      const res = await axios.post(`/api/properties/${param._id}/dar`, sendObj);
      handleClose(true)

      setInnerLoading(false)
    }
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_auto} ${styles.popup_auto_set}`}>
      <div className={`${styles.popup_heading} ${styles.heading_with_trash}`}>
        <div>
          <p className={styles.mediumer_text}>Change role infomation</p>
          <p className={styles.small_text}>Use this module to change the asset role information.</p>
        </div>

        {customNode.role === "Tenant" && <button onClick={() => handleDelete()} disabled={innerLoading}><i className={`fa-solid fa-trash-can ${styles.medium_text}`}></i></button>}
        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  <div className={`${styles.text_grid} ${styles.mb3}`}>
                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>First name</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.first_name} onChange={(input) => handlePopup("set", "first_name", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Last name</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.last_name} onChange={(input) => handlePopup("set", "last_name", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Email</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.email} onChange={(input) => handlePopup("set", "email", input)}/>
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Phone</p>
                      <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.phone} onChange={(input) => handlePopup("set", "phone", input)}/>
                    </div>
                  </div>

                </>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>
      </div>
    </div>
  );
};

export default PopUpEditRoles;