import { useEffect, useState, useRef } from "react";
import Select from 'react-select';
import axios from "axios";

import styles from './utilstyles.module.css';


const PopUpComplianceFilters = ({ param, handleActions, nodesOG, setNodes, setTableFilters, tableFilters, filterApplied, setFilterApplied }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)


  const filterNodes = (tableFilters, nodesOGDup) => {
    const inspectionTypeIndices = {
      "Electrical": 0,
      "Gas": 1,
      "Smoke": 2
    };

    const { inspection_type, compliance } = tableFilters;

    return nodesOGDup.filter(node => {
      return inspection_type.every(type => {
        const complianceIndex = inspectionTypeIndices[type];
        return compliance.includes(node.compliance[complianceIndex]?.compliance)
      });
    });
  };

  const handlePopup = async (control, name?, input?) => {
    let tableFiltersDup = {...tableFilters}

    switch (control) {
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          let nodesOGDup = [...nodesOG]
          const filteredNodes = filterNodes(tableFilters, nodesOGDup);
          setNodes(filteredNodes)

          if (tableFilters.inspection_type.length === 0 && tableFilters.compliance.length === 0) {
            setFilterApplied(false)
          } else {
            setFilterApplied(true)
          }

          handleClose(true)
        }

        setInnerLoading(false)
        break;
      case 'set':
        tableFiltersDup[name] = input.map(inp => inp.value)
        setTableFilters(tableFiltersDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setValidationError(null)
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)

        if (filterApplied) {
          setTableFilters(tableFilters)
        } else {
          setTableFilters({
            inspection_type: [],
            compliance: []
          })
        }

        setValidationError(null)
        handleActions('close')
      }
    }

  }

  const validationTest = async () => {
    if (page === 1) {
      if ((tableFilters.inspection_type.length > 0 && tableFilters.compliance.length === 0) || (tableFilters.compliance.length > 0 && tableFilters.inspection_type.length === 0)) {
        setValidationError("Please select at least 1 item for both inspection type and compliance.")
        return false;
      }
    }

    setValidationError(null)
    return true
  }

  const formatOptions = (options) => {
    let options_arr = []
    for (let o=0; o < options.length; o++) {
      options_arr.push({label: options[o], value: options[o]})
    }

    return options_arr
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_compliance}`}>
      <div className={styles.popup_heading}>
        <div>
          <p className={styles.mediumer_text}>Filter compliance</p>
          <p className={styles.small_text}>Use this module to select different compliance types to filter results by.</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={`${styles.popup_content}`}>
        {page === 1 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  <div className={styles.text_grid}>
                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Inspection type</p>
                      <Select
                        isMulti={true}
                        options={[{label: "Electrical", value: "Electrical"}, {label: "Gas", value: "Gas"}, {label: "Smoke", value: "Smoke"}]}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '2.5px 0',
                            marginTop: '2.5px',
                            boxSizing: 'border-box',
                            boxShadow: 'none !important',
                             '&:hover': {
                                 borderColor: '#26465333 !important'
                              },
                            borderColor: state.isFocused ? '#26465333' : '#26465333',
                          }),
                        }}
                        onChange={(selected) => handlePopup("set", "inspection_type", selected)}
                        value={formatOptions(tableFilters.inspection_type)}
                      />
                    </div>

                    <div className={styles.text_area}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Compliance</p>
                      <Select
                        isMulti={true}
                        options={[{label: "Compliant", value: "Compliant"}, {label: "Non-Compliant", value: "Non-Compliant"}, {label: "Safety Risk", value: "Safety Risk"}, {label: "Unsafe", value: "Unsafe"}, {label: "Not Tested", value: "Not Tested"}]}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '2.5px 0',
                            marginTop: '2.5px',
                            boxSizing: 'border-box',
                            boxShadow: 'none !important',
                             '&:hover': {
                                 borderColor: '#26465333 !important'
                              },
                            borderColor: state.isFocused ? '#26465333' : '#26465333',
                          }),
                        }}
                        onChange={(selected) => handlePopup("set", "compliance", selected)}
                        value={formatOptions(tableFilters.compliance)}
                      />
                    </div>
                  </div>
                </>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>
      </div>
    </div>
  );
};

export default PopUpComplianceFilters;