import { useEffect, useState, useRef } from "react";
import axios from "axios";

import styles from './hubstyles.module.css';
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import TableUtil from '../Utils/TableUtil'
import initializeMapWithAccessToken from '../Utils/MapUtil';
import PopUpProperty from '../Utils/PopUpProperty'
import PopUpService from '../Utils/PopUpService'
import PopUpBeepingAlarm from '../Utils/PopUpBeepingAlarm'
import PopUpComments from '../Utils/PopUpComments'
import PopUpKeyPickup from '../Utils/PopUpKeyPickup'


const ActionContent = ({ handleActions, stats }) => {
	return (
		<div className={styles.action_container}>
			<button className={styles.action_item} onClick={() => handleActions('open', 'Properties')}>
				<i className={`fa-solid fa-house-circle-check ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Create a new property</p>
					<p className={styles.small_text}>{stats.props} properties</p>
				</div>
			</button>

			<button className={styles.action_item} onClick={() => handleActions('open', 'Services')}>
				<i className={`fa-solid fa-calendar-plus ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Create a new service</p>
					<p className={styles.small_text}>At this property</p>
				</div>
			</button>

			<button className={styles.action_item} onClick={() => handleActions('open', 'Beeping Alarm')}>
				<i className={`fa-solid fa-flag ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Report beeping smoke alarm</p>
					<p className={styles.small_text}>At this property</p>
				</div>
			</button>
		</div>
	)
}

const DetailsContent = ({ param, node, isLoading, formatDate, handleActions, handleRedirectToProperty }) => {
	const [expandedInspection, setExpandedInspection] = useState("Electrical")
	const [hoveredCompliance, setHoveredCompliance] = useState(null)

	const handleExpansion = (ind) => {
		if (expandedInspection === ind) {
			setExpandedInspection(null)
		} else {
			setExpandedInspection(ind)
		}
	}

	return (
		<div className={styles.ind_view}>
			{(!isLoading && node)
				?
					<>
						<div>
							<div className={styles.inspection_status}>
								<div className={styles.status_line}/>

								<div className={`${styles.status_box} ${(node.status === "Pending" || node.status === "Scheduled" || node.status === "Completed") && styles.status_box_active}`}>
									<div className={`${styles.status_icon} ${(node.status === "Pending" || node.status === "Scheduled" || node.status === "Completed") && styles.status_icon_active}`}><i class="fa-solid fa-person-circle-check"></i></div>
									<p className={`${(node.status === "Pending") ? styles.status_text_active : styles.status_text} ${styles.small_text}`}>Pending</p>
								</div>

								<div className={`${styles.status_box} ${(node.status === "Scheduled" || node.status === "Completed") && styles.status_box_active}`}>
									<div className={`${styles.status_icon} ${(node.status === "Scheduled" || node.status === "Completed") && styles.status_icon_active}`}><i class="fa-solid fa-calendar-days"></i></div>
									<p className={`${(node.status === "Scheduled") ? styles.status_text_active : styles.status_text} ${styles.small_text}`}>Scheduled</p>
								</div>

								<div className={`${styles.status_box} ${(node.status === "Completed") && styles.status_box_active}`}>
									<div className={`${styles.status_icon} ${(node.status === "Completed") && styles.status_icon_active}`}><i class="fa-solid fa-check"></i></div>
									<p className={`${(node.status === "Completed") ? styles.status_text_active : styles.status_text} ${styles.small_text}`}>Completed</p>
								</div>
							</div>

							{(node.hasOwnProperty('quote_link') && node['quote_link']['url'] !== "" ) &&
					            <a className={styles.ss_quotelink} href={node['quote_link']['url']} target="_blank">
					            	<p>There is follow-up work required on this property. Please view the quote we have prepared to approve if you would like to go ahead.</p>
					            </a>
	          				}

							<div className={styles.text_area}>
								<p className={`${styles.text_label} ${styles.small_text}`}>Address</p>
								<button className={styles.nav_inner_btn} onClick={() => handleRedirectToProperty()}><p className={`${styles.medium_text} ${styles.clickable_text}`}>{node.address}</p></button>
							</div>

							<div className={styles.text_grid}>
								<div className={styles.text_area}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Reference ID</p>
									<p className={`${styles.medium_text}`}>{node.reference_id}</p>
								</div>

								<div className={styles.text_area}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Inspection Type</p>
									<p className={`${styles.medium_text}`}>{node.inspection_type}</p>
								</div>

								<div className={styles.text_area}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Date Scheduled</p>
									<p className={`${styles.medium_text}`}>{formatDate(node.date_scheduled, false, true)}</p>
								</div>

								<div className={styles.text_area}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Date Completed</p>
									<p className={`${styles.medium_text}`}>{formatDate(node.date_completed, false, true)}</p>
								</div>

								<div className={styles.text_area_full}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Comments</p>
									<p className={`${styles.medium_text} ${styles.edit_btn_margin}`}>{node.comments}</p>

									<button className={styles.toolbar_module} onClick={() => handleActions("open", "Comments")}>
										<i className={`fa-solid fa-pen ${styles.medium_text}`}></i>
									</button>
								</div>
							</div>
						</div>

						<div className={`${styles.compliance_grid} ${styles.compliance_inner}`}>
							<div className={styles.compliance_checkbox}>
								<div className={styles.compliance_heading}>
									<p className={`${styles.small_text}`}>Compliance</p>
									<div className={styles.compliance_inner_row}>
										<div
											className={`${styles.compliance_circle} ${styles[node.compliance[0].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}
											onMouseEnter={() => setHoveredCompliance("Electrical")}
											onMouseLeave={() => setHoveredCompliance(null)}
										>
											<i className="fa-solid fa-bolt"></i>

											{hoveredCompliance === "Electrical" &&
												<div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[node.compliance[0].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}>
													<div className={`${styles.hover_connection} ${styles[node.compliance[0].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}></div>
													<p className={styles.small_text}>{hoveredCompliance} - {node.compliance[0].compliance}</p>
												</div>
											}
										</div>

										<div
											className={`${styles.compliance_circle} ${styles[node.compliance[1].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}
											onMouseEnter={() => setHoveredCompliance("Gas")}
											onMouseLeave={() => setHoveredCompliance(null)}
										>
											<i className="fa-solid fa-house-fire"></i>

											{hoveredCompliance === "Gas" &&
												<div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[node.compliance[1].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}>
													<div className={`${styles.hover_connection} ${styles[node.compliance[1].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}></div>
													<p className={styles.small_text}>{hoveredCompliance} - {node.compliance[1].compliance}</p>
												</div>
											}
										</div>

										<div
											className={`${styles.compliance_circle} ${styles[node.compliance[2].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}
											onMouseEnter={() => setHoveredCompliance("Smoke")}
											onMouseLeave={() => setHoveredCompliance(null)}
										>
											<i className="fa-solid fa-fire"></i>

											{hoveredCompliance === "Smoke" &&
												<div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[node.compliance[2].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}>
													<div className={`${styles.hover_connection} ${styles[node.compliance[2].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}></div>
													<p className={styles.small_text}>{hoveredCompliance} - {node.compliance[2].compliance}</p>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.compliance_grid}>
							<div className={styles.compliance_checkbox}>
								<div className={styles.compliance_heading}>
									<p className={`${styles.small_text}`}>Key Pickup</p>
									<div className={styles.keypickup_icon}>
										<i className="fa-solid fa-key"></i>
									</div>
								</div>

								<div className={styles.keypickup_content}>
									<button className={styles.toolbar_module} onClick={() => handleActions("open", "Key Pickup")}>
										<i className={`fa-solid fa-pen ${styles.medium_text}`}></i>
									</button>

									<div className={styles.text_area}>
										<p className={`${styles.text_label} ${styles.small_text}`}>Address</p>
										<p className={`${styles.medium_text}`}>{node.key_pickup['address'] || "-"}</p>
									</div>

									<div className={styles.text_area}>
										<p className={`${styles.text_label} ${styles.small_text}`}>Comments</p>
										<p className={`${styles.medium_text}`}>{node.key_pickup['comments'] || "-"}</p>
									</div>
								</div>
							</div>
						</div>
					</>
				:
					<div className={styles.load_container}>
						<div className={styles.load}>
							<hr />
							<hr />
							<hr />
							<hr />
						</div>
					</div>
			}
		</div>
	)
}

const TableContent = ({ param, setActiveTabInner, activeTab, setIndActive, formatDate, node, activeTabInner }) => {
	const [key, setKey] = useState(0);
	const tableDataRef = useRef(null);
	const blank = null

	const [isLoading, setIsLoading] = useState(false)
	const [columns, setColumns] = useState(["File Name"])
	const [gridLayout, setGridLayout] = useState("minmax(0px, 1fr)")
	const [nodes, setNodes] = useState([])
	const [search, setSearch] = useState("")

	const setTableDataHeight = () => {
		const tableContainer = document.querySelector('.table_container');
		const tableDataContainer = tableDataRef.current;

		if (tableContainer && tableDataContainer) {
	  		const tableContainerHeight = tableContainer.clientHeight;
	  		const siblingsHeight = Array.from(tableDataContainer.parentNode.children).filter(child => child !== tableDataContainer).reduce((acc, child) => acc + child.clientHeight, 0);

	  		tableDataContainer.style.height = `${tableContainerHeight - siblingsHeight - 60}px`;
		}
	};

	useEffect(() => {
		const handleLoad = async () => {
			setIsLoading(true)
			const url = `/api/hub/main/${param._id}/${activeTabInner.toLowerCase()}/${node._id}/inspections`;
			const role_token = localStorage.getItem('role_token');
			const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

			setNodes(data.data.dataMapped)
			setIsLoading(false)
		}

		const handleResize = async () => {
			setTableDataHeight();
			window.addEventListener('resize', setTableDataHeight);

			return () => {
		  		window.removeEventListener('resize', setTableDataHeight);
			};
		}

		handleLoad()
		handleResize()
	}, [key]);

	const handleTableTab = (control, ind) => {
		switch (control) {
			case 'tab':
				setNodes([])

				switch(ind) {
					case 'Reports':
						setColumns(["File Name"])
						setGridLayout("minmax(0px, 1fr)")
						break;
					case 'Follow-Up Work':
						setColumns(["Type", "Date Scheduled", "Status"])
						setGridLayout("minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr)")
						break;
				}

				setActiveTabInner(ind)
				setKey((prevKey) => prevKey + 1);
				break;
		}
	}

	const handleSearch = (event) => {
		setSearch(event.target.value);
	};

	return (
		<div className={`table_container ${styles.table_container}`}>
			<div className={styles.table_nav}>
				<button className={styles.table_nav_item} onClick={() => handleTableTab('tab', "Reports")} disabled={isLoading}>
					<i className={`fa-solid fa-house ${styles.medium_text} ${activeTabInner === "Reports" && styles.nav_text_active}`}></i>
					<p className={`${styles.medium_text} ${styles.nav_text} ${activeTabInner === "Reports" && styles.nav_text_active}`}>Reports</p>
					{activeTabInner === "Reports" && <div className={styles.table_nav_active}/>}
				</button>

				<button className={styles.table_nav_item} onClick={() => handleTableTab('tab', "Follow-Up Work")} disabled={isLoading}>
					<i className={`fa-solid fa-magnifying-glass-location ${styles.medium_text} ${activeTabInner === "Follow-Up Work" && styles.nav_text_active}`}></i>
					<p className={`${styles.medium_text} ${styles.nav_text} ${activeTabInner === "Follow-Up Work" && styles.nav_text_active}`}>Follow-Up Work</p>
					{activeTabInner === "Follow-Up Work" && <div className={styles.table_nav_active}/>}
				</button>
			</div>


			<div className={styles.table_search}>
				<div className={styles.search_container}>
					<i className={`fa-solid fa-magnifying-glass ${styles.medium_text}`}></i>
					<input type="text" placeholder="Search for anything in this table..." className={styles.mid_text} value={search} onChange={handleSearch}/>
				</div>
			</div>

			<div className={`table_data ${styles.table_data}`} ref={tableDataRef}>
				{(!isLoading && nodes)
					? <TableUtil { ... { nodes, columns, gridLayout, setIndActive, formatDate, search, blank, blank, activeTabInner } }/>
					:
						<div className={styles.load_container}>
							<div className={styles.load}>
								<hr />
								<hr />
								<hr />
								<hr />
							</div>
						</div>
				}
			</div>
		</div>
	)
}


const IndInspection = ({ param, indActive, setIndActive, formatDate, stats, setActiveTab }) => {
	const [mainKey, setMainKey] = useState(0);
	const [isLoading, setIsLoading] = useState(false)
	const [node, setNode] = useState(null)
	const [activeTabInner, setActiveTabInner] = useState("Reports")
	const [openPopup, setOpenPopup] = useState(null)

	useEffect(() => {
		const handleLoad = async () => {
			setIsLoading(true)
			const url = `/api/hub/${param._id}/ind/${indActive}/inspections`;
			const role_token = localStorage.getItem('role_token');
			const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });
			setNode(data.data.dataMapped)
			setIsLoading(false)
		}

		handleLoad()
	}, [mainKey]);

  	const handleActions = (control, ind) => {
  		switch (control) {
  			case 'open':
  				setOpenPopup(ind)
  				break;
  			case 'close':
  				setOpenPopup(null)
  				break;
  		}
  	}

	const handleRedirectToProperty = () => {
		setActiveTab("Properties")
		setIndActive(node.prop_id)
	}

	return (
		<>
			<div className={openPopup && styles.hidden_container}>
				<ActionContent {... { handleActions, stats } }/>
			</div>

			<div className={`${styles.split_view} ${openPopup && styles.hidden_container}`}>
				{(!isLoading && node) 
					? <DetailsContent { ... { param, node, isLoading, formatDate, handleActions, handleRedirectToProperty } }/>
					:
						<div className={styles.ind_view}>
							<div className={styles.load_container}>
								<div className={styles.load}>
									<hr />
									<hr />
									<hr />
									<hr />
								</div>
							</div>
						</div>
				}

				{(!isLoading && node) 
					? <TableContent { ... { param, setActiveTabInner, activeTabInner, setIndActive, formatDate, node, activeTabInner } }/>
					:
						<div className={styles.ind_view}>
							<div className={styles.load_container}>
								<div className={styles.load}>
									<hr />
									<hr />
									<hr />
									<hr />
								</div>
							</div>
						</div>
				}
			</div>

			{(openPopup && openPopup === "Properties") && <PopUpProperty {... { param, handleActions } }/>}
			{(openPopup && openPopup === "Services") && <PopUpService {... { param, handleActions, node } }/>}
			{(openPopup && openPopup === "Beeping Alarm") && <PopUpBeepingAlarm {... { param, handleActions, node } }/>}

			{(openPopup && openPopup === "Comments") && <PopUpComments {... { param, handleActions, node, setMainKey } }/>}
			{(openPopup && openPopup === "Key Pickup") && <PopUpKeyPickup {... { param, handleActions, node, setMainKey } }/>}
		</>
	);
};

export default IndInspection;