import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select, { components } from "react-select";
import styles from './Calendar.module.css';

const localizer = momentLocalizer(moment);


const CalendarComponent = ({ events }) => {
  const param = useParams();

  const [selectedEvent, setSelectedEvent] = useState(null);


  const eventList = events.map((event) => ({
    title: event.title,
    start: new Date(event.start),
    end: new Date(event.end),

    bg: '#FFB8C9'
  }));

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setSelectedEvent(null)
  };

  function formatDateAndTime(dateString) {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dateObj = new Date(dateString);
    const dayOfWeek = weekdays[dateObj.getDay()];
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const time = dateObj.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

    return `${dayOfWeek}, ${day} ${month} | ${time}`;
  }

  const CustomEvent = ({ event }) => (
    <div>
      <p style={{color: 'black', fontSize: '10px', margin: '0'}}>{event.title}</p>
    </div>
  );

  const CalFormats = {
    eventTimeRangeFormat: () => { 
      return "";
    },
  };

  return (
    <>
    <div className={`${!selectedEvent ? "" : `${styles.hiddencontainer}` }`} style={{height: '100%'}}>
      <Calendar
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        selectable
        onSelectEvent={handleEventSelect}
        components={{
          event: CustomEvent,
        }}
        formats={CalFormats}
        eventPropGetter={(event) => {
          const backgroundColor = event.bg;
          const border = "none"
          return { style: { backgroundColor, border } }
        }}

      />
    </div>
    
    {selectedEvent && (
      <div className={styles.popup_event}>
        <div className={styles.event_titlegroup}>
          {/* <div className={styles.event_status}></div> */}
          <p className={styles.event_title}>{selectedEvent.title}</p>
        </div>


{/*         <p className={styles.event_date}><span>Start:</span> {formatDateAndTime(selectedEvent.start)}</p> */}
{/*         <p className={styles.event_date}><span>End:</span> {formatDateAndTime(selectedEvent.end)}</p> */}
{/*  */}
{/*         <p style={{marginTop: '30px'}} className={styles.event_field}><span>Inspection:</span> {selectedEvent.name}</p> */}
{/*         <p className={styles.event_field}><span>The inspection includes:</span> {selectedEvent.type[0] === true && "Gas"}{selectedEvent.type[1] === true && (selectedEvent.type[0] === true ? ", Electricity" : "Electricity")}{selectedEvent.type[2] === true && ((selectedEvent.type[0] === true || selectedEvent.type[1] === true) ? ", Smoke" : "Smoke")}</p> */}
{/*         {selectedEvent.comments && <p className={styles.event_field}><span>Comments:</span><br/><p style={{whiteSpace: 'pre-line', maxHeight: '100px', overflowY: 'auto'}}>{selectedEvent.comments}</p></p>} */}
{/*  */}
{/*  */}
{/*         {selectedEvent.keypickup_address && <p style={{marginTop: '30px'}} className={styles.event_field}><span>Key Pickup Address:</span> {selectedEvent.keypickup_address}</p>} */}
{/*         {selectedEvent.keypickup_comment && <p className={styles.event_field}><span>Key Pickup Comments:</span><br/><p style={{whiteSpace: 'pre-line', maxHeight: '100px', overflowY: 'auto'}}>{selectedEvent.keypickup_comment}</p></p>} */}
{/*  */}
{/*  */}
{/*         <p style={{marginTop: '30px'}} className={styles.event_field}><span>Tradie/s:</span> {selectedEvent.tradie_name}</p> */}
{/*  */}
{/*         <p className={styles.event_field}><span>Scheduled by:</span> {selectedEvent.scheduled_by}</p> */}
{/*  */}
{/*         <div className={styles.event_controls}> */}
{/*           <button><i class="fa-solid fa-trash-can"></i></button> */}
{/*           <button onClick={handleClose}><i class="fa-solid fa-x"></i></button> */}
{/*         </div> */}
      </div>
    )}
    </>
  );
};

export default CalendarComponent;
