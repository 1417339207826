import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";

import Main from "./components/Main";
import Properties from "./components/Properties";
import Services from "./components/Services";
import OverdueCurrent from "./components/Overdue";
import ActionsCurrent from "./components/Actions";
import Users from "./components/Users";
import Inspections from "./components/Inspections";
import MyloDebit from "./components/MyloDebit";
import MyloFinance from "./components/MyloFinance";
import MyloInsurance from "./components/MyloInsurance";

import Hub from "./components/PM/Hub";
import Overdue from "./components/PM/Overdue";
import Actions from "./components/PM/Actions";
import DebitCard from "./components/PM/DebitCard";
import Finance from "./components/PM/Finance";
import Insurance from "./components/PM/Insurance";

import OneTime from "./components/OneTime";
import Signup from "./components/Singup";
import ReVerify from "./components/ReVerify";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Apply from "./components/Apply";

import TechnicianDashboard from "./components/Tradie/Dashboard";
import TechnicianCalendar from "./components/Tradie/Calendar";
import AdminHome from "./components/Admin/Home";
import AdminUsers from "./components/Admin/Users";
import AdminTechnicians from "./components/Admin/Technicians";
import AdminPayments from "./components/Admin/Payments";
import AdminMap from "./components/Admin/Map";
import Calendar from "./components/Calendar";

import NotFound from "./components/NotFound";

import ManageServicePM from "./components/ManageServicePM"


function App() {
	const user = localStorage.getItem("token");
	const tradie = localStorage.getItem("tradietoken");
	const admin = localStorage.getItem("admintoken");

	return (
		<Routes>
			{/* {user && <Route path="/dashboard/:_id" exact element={<Main />} />} */}
			{/* {user && <Route path="/properties/:_id" exact element={<Properties />} />} */}
			{/* {user && <Route path="/services/:_id" exact element={<Services />} />} */}
			{/* {user && <Route path="/inspections/:_id" exact element={<Inspections />} />} */}
			{/* {user && <Route path="/overdue/:_id" exact element={<OverdueCurrent />} />} */}
			{/* {user && <Route path="/actions/:_id" exact element={<ActionsCurrent />} />} */}
			{/* {user && <Route path="/users/:_id" exact element={<Users />} />} */}
			{/* {user && <Route path="/mylodebit/:_id" exact element={<MyloDebit />} />} */}
			{/* {user && <Route path="/mylofinance/:_id" exact element={<MyloFinance />} />} */}
			{/* {user && <Route path="/myloinsurance/:_id" exact element={<MyloInsurance />} />} */}

			{user && <Route path="/portal/hub/:_id" exact element={<Hub />} />}
			{user && <Route path="/portal/overdue/:_id" exact element={<Overdue />} />}
			{user && <Route path="/portal/actions/:_id" exact element={<Actions />} />}
			{user && <Route path="/portal/debit-card/:_id" exact element={<DebitCard />} />}
			{user && <Route path="/portal/finance/:_id" exact element={<Finance />} />}
			{user && <Route path="/portal/insurance/:_id" exact element={<Insurance />} />}


			<Route path="/verify/:_id/:token" exact element={<Signup />} />
			<Route path="/reverify/:_id/:token" exact element={<ReVerify />} />
			<Route path="/login" exact element={<Login />} />
			{/* <Route path="/onetime" exact element={<OneTime />} /> */}
			<Route path="/register" exact element={<Apply />} />

			<Route path="/manage-service/:serv_id/:flag" exact element={<ManageServicePM />} />

			{admin && <Route path="/admin/home/:_id" exact element={<AdminHome />} />}
			{admin && <Route path="/admin/users/:_id" exact element={<AdminUsers />} />}
			{admin && <Route path="/admin/technicians/:_id" exact element={<AdminTechnicians />} />}
			{admin && <Route path="/admin/payments/:_id" exact element={<AdminPayments />} />}
			{admin && <Route path="/admin/map/:_id" exact element={<AdminMap />} />}

			{tradie && <Route path="/technician/dashboard/:_id" exact element={<TechnicianDashboard />} />}
			{tradie && <Route path="/technician/calendar/:_id" exact element={<TechnicianCalendar />} />}

			{/* {user && <Route path="/calendar/:_id" exact element={<Calendar />} />} */}
			{/* {tradie && <Route path="/technician/:_id" exact element={<Technician />} />} */}
			{/* {tradie && <Route path="/technician/:_id/:ind_id" exact element={<TechnicianInd />} />} */}

			<Route path="/" element={<Navigate replace to="/login" />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default App;