import { useEffect, useState, useRef } from "react";
import axios from "axios";
import styles from './utilstyles.module.css';


const PropertiesTable = ({ param, handlePopup, formData }) => {
  const [key, setKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [nodes, setNodes] = useState([])
  const [nodesOG, setNodesOG] = useState([])
  const [search, setSearch] = useState("")


  useEffect(() => {
    const handleLoad = async () => {
      setIsLoading(true)
      const url = `/api/hub/main/${param._id}/properties`;
      const role_token = localStorage.getItem('role_token');
      const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

      setNodes(data.data.dataMapped)
      setNodesOG(data.data.dataMapped)
      setIsLoading(false)
    }

    handleLoad()
  }, [key]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    let nodesDup = [...nodesOG]
    let nodesFiltered;

    if (event.target.value === "") {
      setNodes(nodesDup)
    } else {
      nodesFiltered = nodesDup.filter(node => node.address.toLowerCase().includes(event.target.value.toLowerCase()))

      setNodes(nodesFiltered)
    }
  };

  return (
    <>
      <div className={styles.table_search}>
        <div className={styles.search_container}>
          <i className={`fa-solid fa-magnifying-glass ${styles.medium_text}`}></i>
          <input
            type="text"
            placeholder="Search for anything in this table..."
            className={styles.mid_text}
            value={search}
            onChange={(event) => handleSearch(event)}
          />
        </div>
      </div>

      <div className={styles.service_grid}>
        {isLoading
          ?
            <div className={styles.load}>
              <hr />
              <hr />
              <hr />
              <hr />
            </div> 
          :
            <>
              {nodes.map((p, index) => {
                return (
                  <button className={`${styles.service_item} ${styles.property_item} ${(formData['property_info'] && formData['property_info']['address']) === p.address && styles.service_active}`} onClick={() => handlePopup("set", "property_info", {address: p.address, sf_id: p.sf_id})}>
                    <p className={styles.mid_text}>{p.address}</p>
                  </button>
                )
              })}
            </>
          }
      </div>
    </>
  )
}


const PopUpBeepingAlarm = ({ param, handleActions, node }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({})


  const headings = {
    1: {
      title: "Select property",
      sub: "Use this page to select a property for to report a beeping smoke alarm."
    },

    2: {
      title: "Beeping smoke alarm information",
      sub: "Use this page to select information regarding the beeping smoke alarm."
    }
  }

  useEffect(() => {
    const handleLoad = async () => {
      if (node) {
        setFormData({property_info: {address: node.address, sf_id: node.sf_id}})
      }
    }

    handleLoad()
  }, []);

  const handlePopup = async (control, name?, input?, target=false) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'next':
        let valTest = await validationTest()
        if (valTest) {
          setPage(page+1)
        }

        break;
      case 'back':
        setValidationError(null)
        setPage(page-1)
        break;
      case 'submit':
        setInnerLoading(true)
        let valTestSub = await validationTest()
        if (valTestSub) {
          alert("An email has been successfully sent to office to request a beeping smoke alarm inspection.")
          handleClose(true)
          const res = await axios.post(`/api/properties/${param._id}/alert_beeping`, formData);
        }

        setInnerLoading(false)
        break;
      case 'set':
        if (target) {
          formDataDup[name] = input.target.value
        } else {
          formDataDup[name] = input
        }
        
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(null)
      setPage(1)
      setFormData({})
      setValidationError(null)
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(null)
        setPage(1)
        setFormData({})
        setValidationError(null)
        handleActions('close')
      }
    }

  }

  const validationTest = async () => {
    if (page === 1) {
      if (!(formData.hasOwnProperty('property_info'))) {
        setValidationError("You did not select a property.");
        return false;
      }
    }

    if (page === 2) {
      if (!(formData.hasOwnProperty('beeping_info')) || formData['beeping_info'] === '') {
        setValidationError("You did enter information regarding the beeping smoke alarm.");
        return false;
      }
    }

    setValidationError(null)
    return true
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_service}`}>
      <div className={styles.popup_heading}>
        <div>
          <p className={styles.mediumer_text}>{headings[page].title}</p>
          <p className={styles.small_text}>{headings[page].sub}</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <PropertiesTable {... { param, handlePopup, formData } }/>
        }

        {page === 2 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  <div className={styles.warning_box}>
                    <p className={styles.small_text}>Please provide a detailed description on the issue/s you are having with your smoke alarm... (location of alarm, type of beeping, ect...)</p>
                  </div>

                  <div className={styles.text_large_container}>
                    <div className={styles.text_grid}>
                      <div className={styles.text_area}>
                        <p className={`${styles.text_label} ${styles.small_text}`}>Beeping smoke alarm information</p>
                        <textarea onChange={(input) => handlePopup("set", "beeping_info", input, true)} value={formData["beeping_info"]} className={`${styles.general_textarea} ${styles.mid_text}`}/>
                      </div>
                    </div>
                  </div>
                </>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        {page !== 1 && <button className={styles.control_btn} disabled={innerLoading} onClick={() => handlePopup('back')}><i className={`fa-solid fa-chevron-left ${styles.medium_text}`}></i></button>}
        {page !== 2 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('next')}><i className={`fa-solid fa-chevron-right ${styles.medium_text}`}></i></button>}
        {page === 2 && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>}
      </div>
    </div>
  );
};

export default PopUpBeepingAlarm;