import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import LearningOptions from "../LearningOptions/LearningOptions";
import DashboardOptions from "../LearningOptions/DashboardOptions";
import PropertiesOptions from "../LearningOptions/PropertiesOptions";
import InspectionsOptions from "../LearningOptions/InspectionsOptions";
import ServicesOptions from "../LearningOptions/ServicesOptions";
// import LinkList from "../LinkList/LinkList";

import chatbot_logo from '../../images/chatbot_logo.svg';


const config = {
  botName: "Mia",
  initialMessages: [
    createChatBotMessage("Hi I'm Mia, how can I help you navigate the MYLO Portal?", {
      widget: "learningOptions",
    }),
  ],
  customComponents: {
    botAvatar: () => <div class="react-chatbot-kit-chat-bot-avatar"><div class="react-chatbot-kit-chat-bot-avatar-container"><img src={chatbot_logo}/></div></div>,
  },
  widgets: [
    {
      widgetName: "learningOptions",
      widgetFunc: (props) => <LearningOptions {...props} />,
    },
    {
      widgetName: "dashboardOptions",
      widgetFunc: (props) => <DashboardOptions {...props} />,
    },
    {
      widgetName: "propertiesOptions",
      widgetFunc: (props) => <PropertiesOptions {...props} />,
    },
    {
      widgetName: "inspectionsOptions",
      widgetFunc: (props) => <InspectionsOptions {...props} />,
    },
    {
      widgetName: "servicesOptions",
      widgetFunc: (props) => <ServicesOptions {...props} />,
    }
  ],
};

export default config;