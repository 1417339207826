import dstyles from "./dashboardstyles.module.css"
import styles from "./AdminUsers.module.css"


const IndInspection = ({ param, indNav, changeIndNav, indControls, indInspection, markerJob, formatDate, search_control, filesData, filesLoading, clearOpened }) => {
	return (
		<div id={`markerJob_${markerJob.Id}`} className={dstyles.indsite_container}>
			<div className={`${dstyles.indsite_popup}`}>

				<div className={dstyles.indsite_inner}>
					<button className={dstyles.closePopup} onClick={() => indControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={dstyles.editPopup} onClick={() => clearOpened('ind', markerJob.Id)}><i className="fa-solid fa-broom"></i></button>

					<h1 className={dstyles.medium_font}>Inspection details</h1>
					<p className={dstyles.smaller_font}>Please find the specific details for this inspection.</p>

					<div className={dstyles.indsite_nav}>
						<div className={dstyles.sitenav}>
							<button className={`${dstyles.sitenav_box} ${indNav == 'Details' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
							<button className={`${dstyles.sitenav_box} ${indNav == 'Files' && dstyles.sitenav_active}`} onClick={() => changeIndNav('Files', indInspection.Id)}><p>Files</p></button>
						</div>
					</div>

					{indNav == "Details" &&
						<div className={`${dstyles.sites_tablecontainer}`} style={{overflowY: "auto"}}>
							<div className={`${dstyles.indinfo_grid}`}>
								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Inspection Name</label>
									<a className={styles.clickable_nav} href={`https://mylopropertysolutions.lightning.force.com/lightning/r/Inspection__c/${indInspection.Id}/view`} target="_blank"><p>{indInspection.Name}</p></a>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Account</label>
									<p>{indInspection.Account__c}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Address</label>
									<a className={styles.clickable_nav} href={`http://maps.google.com/?q=${indInspection.Asset_Address__c}`} target="_blank"><p>{indInspection.Asset_Address__c}</p></a>
								</div>

								<div className={dstyles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
											<label className={dstyles.smaller_font}>Status</label>
							    			<button className={`${dstyles.site_status} ${styles.status_active} ${styles.nocursor}`}>
							    				<p className={dstyles.smaller_font}>{indInspection.Status__c}</p>
							    			</button>
						    			</div>
					    			</div>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Property has gas?</label>
									<p>{indInspection.Property_Has_Gas__c ? "Yes" : "No"}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Created Date</label>
									<p>{formatDate(indInspection.CreatedDate)}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Electricity</label>
									<p>{indInspection.Electricity__c ? "Yes" : "No"}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Scheduled To</label>
									<p>{indInspection.Scheduled_To__c ? formatDate(indInspection.Scheduled_To__c) : "-"}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Gas</label>
									<p>{indInspection.Gas__c ? "Yes": "No"}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Visit End Time</label>
									<p>{indInspection.Visit_End_Time__c ? formatDate(indInspection.Visit_End_Time__c) : "-"}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Smoke Alarm</label>
									<p>{indInspection.Smoke_Alarm__c ? "Yes" : "No"}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Completed On</label>
									<p>{indInspection.Completed_On__c ? formatDate(indInspection.Completed_On__c) : "-"}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Tradie Assigned</label>
									<p>{indInspection.Google_Calendar_ID__c || '-'}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Inspection Priority</label>
									<p>{indInspection.Priority__c}</p>
								</div>

								<div className={dstyles.popup_formbox}>
									<label className={dstyles.smaller_font}>Asset Package</label>
									<p>{indInspection.Asset_Package__c}</p>
								</div>

								<div className={`${dstyles.popup_formbox} ${dstyles.popup_wide}`}>
									<label className={dstyles.smaller_font}>Comments</label>
								  	<p>{indInspection.Comments__c.split('<br>').map((line, index) => {
								        return <span key={index}>{line}<br /></span>;
								  	})}</p>
								</div>

							</div>
						</div>
					}

					{indNav == "Files" &&
						<div className={dstyles.sites_tablecontainer}>
							<div className={`${dstyles.sites_controls} ${dstyles.indsite_controls_wh}`}>
								<div className={`${dstyles.sites_searchcontainer} ${dstyles.autowidth}`}>
									<div className={dstyles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={dstyles.smaller_font}
										    placeholder="Search by the file name..."
										    onKeyUp={(event) => search_control(event, "ind_table")}
										/>
									</div>
								</div>
							</div>

							<div className={dstyles.table_container}>
								<table className={dstyles.sites_table} id="ind_table">
								    <thead className={dstyles.sitesind_thead}>
								      <tr className={`${dstyles.table_rowth} ${dstyles.tr_only}`}>
								      	<th colspan="3" className={dstyles.small_font}>File name</th>
								      	<th colspan="1" className={dstyles.small_font}>Open</th>
								      </tr>
								    </thead>
								    <tbody className={dstyles.indtbody}>
								    	{!filesLoading
								    		?
								    		<>
								    			{filesData.length > 0
								    				? 
								    				<>
									    				{filesData.map((IF) => {
									    					return (
														    	<tr className={`${dstyles.sitesind_tr} ${dstyles.tr_only}`}>
															    	<td className={dstyles.td_overflow} colspan="3"><p>{Object.keys(IF)[0]}</p></td>
														    		<td className={dstyles.td_overflow} colspan="1"><a href={`${Object.values(IF)[0]}`} target="_blank"><i className="fa-solid fa-eye"></i></a></td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${dstyles.medium_font} ${dstyles.create_first2}`}>This inspection does not have any files yet!</h1>
								    			}
								    		</>
								    		: 
												<div className={styles.load}>
													<hr /><hr /><hr /><hr />
												</div>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
    )
}

export default IndInspection;