import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import CognitoForm from '@tylermenezes/cognitoforms-react';
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import ReactAutocomplete from "react-google-autocomplete";
import Select from 'react-select';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Logo from "../../../images/mylologo.svg";
import Forbidden from '../../Forbidden';
import LoadingForm from '../../LoadingForm';
import tstyles from "./Tradie.module.css"

import CalendarComponent from './CalendarComponent';


const Dashboard = ({ param, formatDate, formatDateAndTime, resetToInitialState, search_control, internalLoading, loadingComplete, calendarData, handleShowTradieTools, showTradieTools, handleLogout, indTradie, handleCustomEvent, handleNewEvent, addEvent, newEvent, handleNewEventAddress, newEventAddress, validationError }) => {
	return (
		<div className={tstyles.wrapper}>
	      {(!indTradie || Object.keys(indTradie).length === 0) &&
	      <>
	      <div className={tstyles.tq_topbar}>
		    <div className={tstyles.tq_info}>
		      <div className={tstyles.logoContainer}>
		      	<button onClick={() => resetToInitialState()} className={tstyles.logo_inner}>
			        <img
			          className={tstyles.logo}
			          style={{ height: "60%", paddingLeft: 20 }}
			          src={Logo}
			        />
		        </button>
		      </div>
		        <div className="toolsContainer">
		        	<div className="toolsOuter" style={{marginRight: '30px'}}>
			        	<td className={tstyles.align_td}>
	                      	<div className={tstyles.toolsFlex}>
	                            {(showTradieTools) ?
		                            <div className={tstyles.toolsInner}>
		                              	<button onClick={() => handleShowTradieTools('close')} className={`${tstyles.trash_button} ${tstyles.admin_close}`}>
			                            	<i className="fa-solid fa-xmark"></i>
			                            </button>

			                            <button onClick={() => handleShowTradieTools('navigate', 'dashboard')} className={tstyles.trash_button}>
			                              	<i className="fa-solid fa-house"></i>
			                              	<p>Dashboard</p>
			                            </button>

			                            <button onClick={() => handleShowTradieTools('navigate', 'calendar')} className={tstyles.trash_button} disabled={true}>
			                              	<i className="fa-solid fa-calendar-days"></i>
			                              	<p>Calendar</p>
			                            </button>

			                            <button onClick={handleLogout} className={tstyles.trash_button}>
			                              	<i className="fa-solid fa-right-from-bracket"></i>
			                              	<p>Logout</p>
			                            </button>
			                        </div>
			                    :
	                              	<button onClick={() => handleShowTradieTools('set')} className={tstyles.trash_button}>
		                            	<i className="fa-solid fa-bars"></i>
		                            </button>
		                    	}
	                      	</div>
	                    </td>
		            </div>
		        </div>

		    </div>
		    <div className={tstyles.tq_search}>
		      <div className={tstyles.tq_search_container}>
		        <div className={tstyles.tq_search_bar}>
		          <i className="fa-solid fa-magnifying-glass" />
		          <input
		          	type="text"
		          	name="search"
		          	placeholder="Search your jobs..."
		          	// onKeyUp={(event) => search_control(event)}
		            id="searchbar"
		          />


		          <div className={tstyles.tq_add_custom_event}>
		          	<button onClick={() => handleNewEvent('add_event')}>
		          		<i class="fa-solid fa-plus"></i>
		          		Create event
		          	</button>
		          </div>
		        </div>
		      </div>
		    </div>
		    </div>
		    </>
		    }
		  <div className={(!indTradie || Object.keys(indTradie).length === 0) ? tstyles.tq_cal_container : tstyles.tq_cal_container2}>
		  	<div className={`${tstyles.cal_container_inner} ${addEvent && tstyles.hidden_container}`}>
        		<CalendarComponent events={calendarData} tradie={!(indTradie && Object.keys(indTradie).length > 0)} handleCustomEvent={handleCustomEvent}/>
        	</div>


			{addEvent &&
				<div className={tstyles.popup_container}>

					<button className={tstyles.closePopup} onClick={() => handleNewEvent('event_close')}><i class="fa-solid fa-x"></i></button>

					<h1 className={tstyles.medium_font}>Event details</h1>
					<p className={tstyles.smaller_font}>Please enter the specific event details to create.</p>

					<div className={tstyles.popup_formbox} style={{marginTop: '10px'}}>
						<p className={tstyles.upcoming_warning}>Please use this module to add custom events, for example unavailable to work.</p>
					</div>

					<div className={tstyles.popup_form}>
						<div className={tstyles.popup_formbox}>
							<label className={tstyles.smaller_font}>Title*</label>
							<input className={tstyles.small_font} type="text" placeholder="Car service" onChange={(input) => handleNewEvent('set', 'title', input)} value={newEvent.title}/>
						</div>

	  					<div className={tstyles.popup_formbox}>
	      					<label className={tstyles.smaller_font}>Address</label>
							<ReactAutocomplete
							 	className={tstyles.small_font}
							  	apiKey="AIzaSyDpQGM_wr1B1ztyG6gK_TUmFXTOcc8Wlhk"
							  	options={{
							    	types: ["address"],
							    	componentRestrictions: { country: "au" },
							  	}}
							  	onPlaceSelected={(place) => handleNewEventAddress(place)}
							  	defaultValue={newEventAddress}
							  	placeholder="Start typing your address..."
							/>
						</div>

						<div className={tstyles.popup_form_customdate_inv}>
							<div className={`${tstyles.popup_formbox}`}>
								<div className={tstyles.label_container}>
									<label className={tstyles.smaller_font}>Start time*</label>
									<label className={tstyles.smaller_font}>End time*</label>
								</div>
								<div className={tstyles.label_container}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DateTimePicker 
										 	className={tstyles.small_font}
											onChange={(selected) => handleNewEvent('set', 'event_dates1', selected)}
											value={newEvent.event_dates[0] && dayjs(newEvent.event_dates[0])}
											format="DD/MM/YYYY HH:mm"
											disablePast
										/>
									</LocalizationProvider>

									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DateTimePicker
											className={tstyles.small_font}
											onChange={(selected) => handleNewEvent('set', 'event_dates2', selected)}
											value={newEvent.event_dates[1] && dayjs(newEvent.event_dates[1])}
											format="DD/MM/YYYY HH:mm"
											disablePast
										/>
									</LocalizationProvider>
								</div>
							</div>
						</div>

						<div className={tstyles.popup_formbox}>
							<label className={tstyles.smaller_font}>Comments</label>
							<textarea className={tstyles.small_font} placeholder="Event comments..." onChange={(input) => handleNewEvent('set', 'comments', input)} value={newEvent.comments}></textarea>
						</div>

					</div>

					{validationError &&
						<div className={`${tstyles.notification} ${tstyles.validationError}`}>
							<p className={tstyles.smaller_font}>{validationError}</p>
						</div>
					}

					<div className={tstyles.popUpControls}>
						<div className={tstyles.popUpControlsContainer}>
							<button type="button" className={tstyles.submitBtn} onClick={() => handleNewEvent('submit')}>Submit</button>
			       		</div>
		       		</div>
				</div>
			}
		  </div>
		</div>

	)
}


const TradieCalendar = ({ indTradie=null, handleCustomEvent=null }) => {
	const handleLogout = () => {
		localStorage.removeItem("admintoken");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const navigate = useNavigate();
  	const location = useLocation();
  	const isExternal = location.state && location.state.arrivedExternally;
  	const navigationData = location.state && location.state.updatedJob;

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [internalLoading, setInternalLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
 	const [validationError, setValidationError] = useState(null);
 	const [loadingComplete, setLoadingComplete] = useState(false);
  	const [calendarData, setCalendarData] = useState(null);
  	const [showTradieTools, setShowTradieTools] = useState(false)
	const [addEvent, setAddEvent] = useState(false)
	const [newEvent, setNewEvent] = useState({
		title: '',
		event_dates: [],
		comments: ''
	})
	const [newEventAddress, setNewEventAddress] = useState(null)
  
	const param = useParams();
	const url = `/api/technician/calendar/${(indTradie && Object.keys(indTradie).length > 0) ? indTradie._id : param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
          			setCalendarData(data.data.calendar_data)

					setIsAuthenticated(true)
				} catch(e) {
					console.log(e)
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url]);


	const handleShowTradieTools = (control, nav) => {
		switch (control) {
			case 'set':
				setShowTradieTools(true)
				break;
			case 'close':
				setShowTradieTools(false);
				break;
			case 'navigate':
				switch (nav) {
					case 'dashboard':
						navigate(`/technician/dashboard/${param._id}`);
						break;
					case 'calendar':
						navigate(`/technician/calendar/${param._id}`);
						break;
				}
				break;
		}
	}


	const search_control = (event) => {
		var input, filter, target_div, inspection, property, timestamp, i, txtValue, txtValue2, txtValue3;
		input = document.getElementById("searchbar");
		filter = input.value.toUpperCase();
		target_div = document.getElementById("tq_jobs").children;
		for (i = 1; i < target_div.length; i++) {
			inspection = target_div[i].getElementsByTagName("p")[0];
			property = target_div[i].getElementsByTagName("p")[1];
			timestamp = target_div[i].getElementsByTagName("p")[2];

			if (inspection && property && timestamp) {
				txtValue = inspection.textContent || inspection.innerText;
				txtValue2 = property.textContent || property.innerText;
				txtValue3 = timestamp.textContent || timestamp.innerText;

				if (txtValue.toUpperCase().indexOf(filter) > -1 || txtValue2.toUpperCase().indexOf(filter) > -1 || txtValue3.toUpperCase().indexOf(filter) > -1) {
					target_div[i].style.display = "flex";
				} else {
					target_div[i].style.display = "none";
				}
			}
		}
	}

	function formatDate(dateString) {
	    const dateObj = new Date(dateString);
	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);
	    return `${day}-${month}-${year}`;
	}

	function formatDateAndTime(inputDateString) {
		const inputDate = new Date(inputDateString);

		const dateOptions = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};

		const timeOptions = {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
			timeZone: 'Australia/Sydney',
		};

		const formattedDate = inputDate.toLocaleString(undefined, dateOptions);
		const formattedTime = inputDate.toLocaleString(undefined, timeOptions);

		return `${formattedDate} | ${formattedTime.toLowerCase()}`;
	}


	const resetToInitialState = () => {
 		setLoadingComplete(false)
 		
 		navigate(`/technician/calendar/${param._id}`, { state: { arrivedExternally: false } });
	};


	const handleNewEvent = async (control, name=null, input=null) => {
		switch (control) {
			case 'add_event':
				setAddEvent(true)
				break;
			case 'event_close':
				setNewEvent({
					title: '',
					event_dates: [],
					comments: ''
				})
				setNewEventAddress(null)
				setAddEvent(false)
				break;
			case 'set':
				let newEventDup = { ...newEvent }

				if (name === "event_dates1") {
					newEventDup['event_dates'][0] = input
				} else if (name === "event_dates2") {
					newEventDup['event_dates'][1] = input
				} else if (name === "event_users") {
					newEventDup['event_users'] = input
				} else {
					newEventDup[name] = input.target.value
				}

				setNewEvent(newEventDup)
				break;
			case 'submit':
				if (!(newEvent.hasOwnProperty('title')) || newEvent['title'] == '') {
					setValidationError('Please enter a title');
					return false;
				}

				if (newEvent.event_dates.length !== 2) {
					setValidationError('Please select both a start and finish date/time');
					return false;
				}

				if (!newEvent.event_dates[0] || newEvent.event_dates[0] === '') {
					setValidationError('Please select a start date/time');
					return false;
				}

				if (!newEvent.event_dates[1] || newEvent.event_dates[1] === '') {
					setValidationError('Please select a finish date/time');
					return false;
				}

				if (!(newEvent.event_dates[1] > newEvent.event_dates[0])) {
					setValidationError('The finish time can not be before the start time.');
					return false;
				}

				await axios.post(`/api/technician/calendar/customEvent/${param._id}`, {
					event: newEvent,
					eventAddress: newEventAddress
				});
				window.location.reload(false);
				break;
		}
	}

	const handleNewEventAddress = (input) => {
		setNewEventAddress(input.formatted_address)
	}

	return (
		<>
			<Offline>
				<div className="offline_container animate__animated animate__slideInDown">
					<i className="fa-solid fa-heart-crack"></i>
					<div className="offline_content">
						<p className="offline_title">You are offline</p>
						<p className="offline_subtitle">Please check your internet connect.</p>
					</div>

				</div>
			</Offline>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={(!indTradie || Object.keys(indTradie).length === 0) ? tstyles.bg_white : tstyles.bg_embed}>
								<Dashboard {...{ param, formatDate, formatDateAndTime, resetToInitialState, search_control, internalLoading, loadingComplete, calendarData, handleShowTradieTools, showTradieTools, handleLogout, indTradie, handleCustomEvent, handleNewEvent, addEvent, newEvent, handleNewEventAddress, newEventAddress, validationError }} />
							</div>
							: <Forbidden />
						}
					</>
				:
					<LoadingForm/>
			}
		</>
	);
};


export default TradieCalendar;