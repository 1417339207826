import Select from 'react-select';
import styles from './dashboardstyles.module.css';


const PopUpTradie = ({ param, validationError, handleNewTradie, newTradieData }) => {
	return (
		<div className={`${styles.popup_container}`}>

			<button className={styles.closePopup} onClick={() => handleNewTradie('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Tradie details</h1>
			<p className={styles.smaller_font}>Please enter the general details for this new tradie.</p>

			{!newTradieData['edit'] &&
				<div className={`${styles.notification} ${styles.warningMessage}`}>
					<p className={styles.smaller_font}>An email will be sent to their email, prompting them to complete the sign-up process.</p>
				</div>
			}
			

			<div className={`${styles.popup_form}`}>
				<div className={`${styles.popup_form_flex_rows}`}>
					<div className={styles.popup_formbox}>
						<label className={styles.smaller_font}>First name</label>
						<input type="text" onChange={(input) => handleNewTradie('set', 'first_name', input)} value={newTradieData && newTradieData.first_name}/>
					</div>

					<div className={styles.popup_formbox}>
						<label className={styles.smaller_font}>Last name</label>
						<input type="text" onChange={(input) => handleNewTradie('set', 'last_name', input)} value={newTradieData && newTradieData.last_name}/>
					</div>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Email</label>
					<input type="text" onChange={(input) => handleNewTradie('set', 'email', input)} value={newTradieData && newTradieData.email}/>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Cognito ID</label>
					<input type="text" onChange={(input) => handleNewTradie('set', 'cognito_id', input)} value={newTradieData && newTradieData.cognito_id}/>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Expertise</label>
					<Select
						closeMenuOnSelect={true}
						options={[{label: "Electrician", value: "Electrician"}, {label: "Gas Fitter", value: "Gas Fitter"}, {label: "Handyman", value: "Handyman"}, {label: "Roof and Gutter Cleaner", value: "Roof and Gutter Cleaner"}, {label: "Garage Door", value: "Garage Door"}, {label: "Handyman", value: "Handyman"}, {label: "Locksmith", value: "Locksmith"}, {label: "Rubbish Removal", value: "Rubbish Removal"}, {label: "Pest Control", value: "Pest Control"}, {label: "Gardener", value: "Gardener"}, {label: "Pool Inspector", value: "Pool Inspector"}]}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								boxShadow: 'none !important',
							   '&:hover': {
							       borderColor: '#fce7eb !important'
							    },
								borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
							}),
						}}
						onChange={(selected) => handleNewTradie('set', 'expertise', selected)}
						value={{label: newTradieData["expertise"], value: newTradieData["expertise"]}}
					/>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>License number</label>
					<input type="text" onChange={(input) => handleNewTradie('set', 'license_number', input)} value={newTradieData && newTradieData.license_number}/>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleNewTradie('submit')}>Submit</button>
	       		</div>
       		</div>


		</div>
	)
}

export default PopUpTradie;