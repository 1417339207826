import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from './topbarstyles.module.css';


const Topbar = ({ param, setSearchResults, searchResults, setActiveTab=null, setIndActive=null, customNav=false, setOpenPopup, indActive=null }) => {
	const navigate = useNavigate();
	const [search, setSearch] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const divRef = useRef(null);
	const [showNotifications, setShowNotifications] = useState(false)
	const [allNotifications, setAllNotifications] = useState([])


  	useEffect(() => {
    	document.addEventListener('mousedown', handleClickOutside);

    	return () => {
      		document.removeEventListener('mousedown', handleClickOutside);
    	};
  	}, []);


	const handleSearch = async (input) => {
		let searchVal = input.target.value
		setSearch(searchVal)

		if (searchVal === "") {
			setSearchResults({
				properties: [],
				inspections: [],
				services: []
			})
		} else {
			setIsLoading(true)
			const url = `/api/hub/main/${param._id}/topBarSearch/${searchVal}`;
			const role_token = localStorage.getItem('role_token');
			const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });
			setSearchResults(data.data.dataMapped)

			setIsLoading(false)
		}
	}

	const handleNav = (destination, id) => {
		setSearchResults({
			properties: [],
			inspections: [],
			services: []
		})

		setSearch("")

		if (customNav || indActive) {
    		const state = { from: { _id: id }, location: destination };
			sessionStorage.setItem('navigationState', JSON.stringify(state));

			navigate(`/portal/hub/${param._id}`);
			window.location.reload()
		} else {
			setActiveTab(destination)
			setIndActive(id)
		}
	}

	const handleSettings = () => {
		setShowNotifications(false)
		setAllNotifications([])
		setOpenPopup("Settings")
	}

	const handleSecurity = () => {
		setShowNotifications(false)
		setAllNotifications([])
		setOpenPopup("Security")
	}

  	const handleClickOutside = (event) => {
    	if (divRef.current && !divRef.current.contains(event.target)) {
			setSearchResults({
				properties: [],
				inspections: [],
				services: []
			})

			setSearch("")
    	}
  	};

	const handleNotifications = async (control, notification_id=null, notification_type=null, notification_relatedid=null, e=null) => {
		switch (control) {
			case 'toggle':
				if (showNotifications) {
					setShowNotifications(false)
					setAllNotifications([])
				} else {
					setOpenPopup(null)
					setIsLoading(true)
					setShowNotifications(true)

					const url = `/api/hub/main/${param._id}/notifications`;
					const role_token = localStorage.getItem('role_token');
					const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

					setAllNotifications(data.data.dataMapped.notifications.reverse())
					setIsLoading(false)
				}
				break;
			case 'close':
				setShowNotifications(false)
				setAllNotifications([])
				break;
			case 'delete_all':
				let confirm_deleteall = window.confirm("Are you sure you would like to delete all notifications?")
				if (confirm_deleteall) {
					setIsLoading(true)
					await axios.post(`/api/dashboard/${param._id}/delete_all_notifications`);
					setAllNotifications([])
					setIsLoading(false)
				}
				break;
			case 'delete':
				let confirm_deleteone = window.confirm("Are you sure you would like to delete this notifications?")

				if (confirm_deleteone) {
					setIsLoading(true)
					await axios.post(`/api/dashboard/${param._id}/delete_notification`, {notification_id: notification_id});
					setAllNotifications(allNotifications.filter(AN => AN._id !== notification_id))
					setIsLoading(false)
				}
				break;
			case 'navigate':
				if (e.target.nodeName !== "I") {
					switch (notification_type) {
						case 'Properties':
							await axios.post(`/api/dashboard/${param._id}/read_notification`, {notification_id: notification_id});
							break;
						case 'Inspections':
							await axios.post(`/api/dashboard/${param._id}/read_notification`, {notification_id: notification_id});
							break;
						case 'Services':
							await axios.post(`/api/dashboard/${param._id}/read_notification`, {notification_id: notification_id});
							break;
					}
				}

				if (customNav) {
		    		let state = { from: { _id: notification_relatedid }, location: notification_type };
					sessionStorage.setItem('navigationState', JSON.stringify(state));

					navigate(`/portal/hub/${param._id}`);
				} else {
					setActiveTab(notification_type)
					setIndActive(notification_relatedid)
				}

				setShowNotifications(false)
				setAllNotifications([])
				break;
		}
	}

  	const formatDate = (dateString, showDay, showTime) => {
  		if (dateString === "-") {
  			return "-"
  		}

    	const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    	const dateObj = new Date(dateString);

    	if (!showTime) {
      		dateObj.setHours(0, 0, 0, 0);
    	}

    	const year = dateObj.getFullYear();
    	const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    	const day = ('0' + dateObj.getDate()).slice(-2);

    	const currentDayNumber = dateObj.getDay();

    	const format12Hour = (hours) => {
      		const period = hours >= 12 ? 'pm' : 'am';
      		hours = hours % 12 || 12;

      		return { hours: ('0' + hours).slice(-2), period };
    	};

    	if (showDay && showTime) {
      		let { hours: shours, period: speriod } = format12Hour(dateObj.getHours());
      		let sminutes = ('0' + dateObj.getMinutes()).slice(-2);
      		let sseconds = ('0' + dateObj.getSeconds()).slice(-2);

      		return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year} @ ${shours}:${sminutes}${speriod}`;
    	} else if (showDay) {
      		return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
    	} else if (showTime) {
      		let { hours, period } = format12Hour(dateObj.getHours());
      		let minutes = ('0' + dateObj.getMinutes()).slice(-2);
      		let seconds = ('0' + dateObj.getSeconds()).slice(-2);

      		return `${day}/${month}/${year} @ ${hours}:${minutes}${period}`;
    	} else {
      		return `${day}/${month}/${year}`;
    	}
  	};

	return (
		<>
			<div className={styles.topbar_container}>
				<div className={styles.search_container}>
					<i className={`fa-solid fa-magnifying-glass ${styles.medium_text}`}></i>
					<input
						type="text"
						placeholder="Search by address..."
						className={styles.mid_text}
						onChange={(input) => handleSearch(input)}
						value={search}
					/>
				</div>

				<div className={styles.actions_container}>
					<button className={styles.action_item} onClick={() => handleNotifications('toggle')}>
						<i className={`fa-solid fa-bell ${styles.medium_text}`}></i>
					</button>

					{showNotifications &&
						<div className={styles.notification_container}>
							{isLoading
								?
									<div className={styles.load_outer}>
						                <div className={styles.load}>
						                  <hr />
						                  <hr />
						                  <hr />
						                  <hr />
						                </div>
					                </div>
								:
									<>
										<div className={styles.notification_banner}>
											<p className={styles.medium_text}>Notifications</p>

											<div className={styles.notification_actions}>
												<button onClick={() => handleNotifications('delete_all')} disabled={allNotifications.length === 0}><i className="fa-solid fa-broom"></i></button>
												<button onClick={() => handleNotifications('close')}><i className="fa-solid fa-x"></i></button>
											</div>
										</div>

										<div className={styles.notification_line}/>

						      			{allNotifications && allNotifications.length > 0
							      			?
							      				<>
									      			{allNotifications.map((AN, index) => {
									      				return (
									      					<>
																<button className={styles.notification_item} onClick={(e) => handleNotifications('navigate', AN._id, AN.type, AN.relatedId, e)}>
																	<div className={styles.notification_header}>
																		<p className={styles.smaller_text}>{AN.title}</p>

																		<button onClick={(e) => handleNotifications('delete', AN._id)}><i className="fa-solid fa-trash-can"></i></button>
																	</div>

																	<div className={styles.notification_content}>
																		<p className={styles.smaller_text}>{AN.log}</p>

																		<div className={styles.notification_stamp}>
																			{!AN.viewed && <div className={styles.notification_unread}/>}
																			<p className={styles.smaller_text}>{formatDate(AN.date, false, true)}</p>
																		</div>
																	</div>
																</button>

																{index !== allNotifications.length-1 && <div className={styles.notification_line}/>}
															</>
														)
													})}
												</>
											: <p className="no_notifications" style={{textAlign: 'center'}}>You do not have any new notifications!</p>
										}
									</>
							}
						</div>
					}

					<button className={styles.action_item} onClick={() => handleSecurity()}>
						<i className={`fa-solid fa-shield ${styles.medium_text}`}></i>
					</button>

					<button className={styles.action_item} onClick={() => handleSettings()}>
						<i className={`fa-solid fa-gear ${styles.medium_text}`}></i>
					</button>
				</div>
			</div>

			{Object.values(searchResults).flat().length > 0 &&
				<div className={`results_container ${styles.results_container}`} ref={divRef}>
					{isLoading
						?
							<div className={styles.load_outer}>
				                <div className={styles.load}>
				                  <hr />
				                  <hr />
				                  <hr />
				                  <hr />
				                </div>
			                </div>
						:
							<>
								{searchResults['properties'].length > 0 &&
									<>
										{searchResults['properties'].map((prop, index) => {
											return (
												<button className={`${styles.search_result_row} ${index === searchResults.properties.length - 1 && styles.no_border}`} onClick={() => handleNav("Properties", prop._id)} disabled={isLoading}>
													<i className={`fa-solid fa-house ${styles.medium_text}`}></i>
													<p className={styles.medium_text}>{prop.address}</p>
												</button>
											)
										})}
									</>
								}

								{searchResults['inspections'].length > 0 &&
									<>
										{searchResults['inspections'].map((inspection, index) => {
											return (
												<button className={`${styles.search_result_row} ${index === searchResults.inspections.length - 1 && styles.no_border}`} onClick={() => handleNav("Inspections", inspection._id)} disabled={isLoading}>
													<i className={`fa-solid fa-magnifying-glass-location ${styles.medium_text}`}></i>
													<p className={styles.medium_text}>{inspection.name || "-"} | {inspection.property}</p>
												</button>
											)
										})}
									</>
								}

								{searchResults['services'].length > 0 &&
									<>
										{searchResults['services'].map((service, index) => {
											return (
												<button className={`${styles.search_result_row} ${index === searchResults.services.length - 1 && styles.no_border}`} onClick={() => handleNav("Services", service._id)} disabled={isLoading}>
													<i className={`fa-solid fa-clipboard-list ${styles.medium_text}`}></i>
													<p className={styles.medium_text}>{service.name || "-"} | {service.property}</p>
												</button>
											)
										})}
									</>
								}
							</>
					}
				</div>
			}
		</>
	);
};

export default Topbar;