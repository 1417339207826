import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Select from 'react-select';

import styles from './utilstyles.module.css';


const PopUpChangePM = ({ param, handleActions, setActiveTabInner, setOuterKey, node }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({})
  const [agencyPms, setAgencyPms] = useState([])



  useEffect(() => {
    const handleLoad = async () => {
      setInnerLoading(true)
      const url = `/api/hub/main/${param._id}/agencyPms`;
      const role_token = localStorage.getItem('role_token');
      const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

      setAgencyPms(data.data.dataMapped.agencyPms)
      setInnerLoading(false)
    }

    handleLoad()
  }, []);

  const handlePopup = async (control, name?, input?) => {
    let formDataDup = {...formData}

    switch (control) {
      case 'submit':
        let valTestSub = await validationTest()

        if (valTestSub) {
          const confirmMove = window.confirm("Are you sure you would like to move this property to another agent permanetly?")

          if (confirmMove) {
            setInnerLoading(true)

            
            let sendObj = {
              pm_id: formData.pm,
              asset_id: node.sf_id
            }

            const res = await axios.post(`/api/properties/${param._id}/change_agent`, sendObj);
            window.location.reload()
          

            setInnerLoading(false)
          }
        }
        break;
      case 'set':
        formDataDup[name] = input.value  
        setFormData(formDataDup)
        break;
    }
  }

  function handleClose() {
    const confirmClose = window.confirm("Are you sure you would like to close this?")

    if (confirmClose) {
      setInnerLoading(null)
      setPage(1)
      setFormData({})
      setValidationError(null)
      handleActions('close')
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validationTest = async () => {
    if (page === 1) {
      if (!(formData.hasOwnProperty('pm')) || formData['pm'] === '') {
        setValidationError("Please select an agent.")
        return false
      }
    }

    setValidationError(null)
    return true
  }

  const formatOptions = (options) => {
    let options_arr = []
    for (let o=0; o < options.length; o++) {
      options_arr.push({label: `${options[o].FirstName} ${options[o].LastName}`, value: `${options[o].Id}`})
    }

    return options_arr
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_auto_set_more} ${styles.popup_agentpage}`}>
      <div className={styles.popup_heading}>
        <div>
          <p className={styles.mediumer_text}>Change agent</p>
          <p className={styles.small_text}>Use this module to move the property to another agent.</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {page === 1 &&
          <>
            {innerLoading
              ? 
                <div className={styles.load}>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                </div> 
              :
                <>
                  <div className={`${styles.warning_box} ${styles.mb_3}`}>
                    <p className={styles.small_text}>Once this property has been moved to another agent's account, the property will be removed from this account permanetly.</p>
                  </div>

                  <div className={`${styles.text_grid}`}>
                    <div className={`${styles.text_area}`}>
                      <p className={`${styles.text_label} ${styles.small_text}`}>Agents</p>
                      <Select
                        options={agencyPms.length > 0 ? formatOptions(agencyPms) : []}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '2.5px 0',
                            marginTop: '2.5px',
                            boxSizing: 'border-box',
                            boxShadow: 'none !important',
                             '&:hover': {
                                 borderColor: '#26465333 !important'
                              },
                            borderColor: state.isFocused ? '#26465333' : '#26465333',
                          }),
                        }}
                        onChange={(selected) => handlePopup("set", "pm", selected)}
                        value={formData.pm && {
                          value: formData.pm,
                          label: `${agencyPms.find(apm => apm.Id === formData.pm).FirstName} ${agencyPms.find(apm => apm.Id === formData.pm).LastName}`
                        }}
                      />
                    </div>
                  </div>
                </>
            }
          </>
        }
      </div>


      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>
      </div>
    </div>
  );
};

export default PopUpChangePM;