import { useEffect, useState, useRef } from "react";
import axios from "axios";

import styles from './hubstyles.module.css';
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import TableUtil from '../Utils/TableUtil'
import initializeMapWithAccessToken from '../Utils/MapUtil';
import PopUpProperty from '../Utils/PopUpProperty'
import PopUpService from '../Utils/PopUpService'
import PopUpBeepingAlarm from '../Utils/PopUpBeepingAlarm'
import PopUpPackage from '../Utils/PopUpPackage'
import PopUpInspectionType from '../Utils/PopUpInspectionType'
import PopUpEditRole from '../Utils/PopUpEditRole'
import PopUpAddRole from '../Utils/PopUpAddRole'
import PopUpChangePM from '../Utils/PopUpChangePM'


const ActionContent = ({ handleActions, stats }) => {
	return (
		<div className={styles.action_container}>
			<button className={styles.action_item} onClick={() => handleActions('open', 'Properties')}>
				<i className={`fa-solid fa-house-circle-check ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Create a new property</p>
					<p className={styles.small_text}>{stats.props} properties</p>
				</div>
			</button>

			<button className={styles.action_item} onClick={() => handleActions('open', 'Services')}>
				<i className={`fa-solid fa-calendar-plus ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Create a new service</p>
					<p className={styles.small_text}>At this property</p>
				</div>
			</button>

			<button className={styles.action_item} onClick={() => handleActions('open', 'Beeping Alarm')}>
				<i className={`fa-solid fa-flag ${styles.largest_text}`}></i>

				<div className={styles.action_content}>
					<p className={`${styles.medium_text} ${styles.action_heading}`}>Beeping smoke alarm</p>
					<p className={styles.small_text}>At this property</p>
				</div>
			</button>
		</div>
	)
}

const DetailsContent = ({ param, node, isLoading, formatDate, handleActions }) => {
	const [expandedInspection, setExpandedInspection] = useState("Electrical")
	const [hoveredCompliance, setHoveredCompliance] = useState(null)

	const handleExpansion = (ind) => {
		if (expandedInspection === ind) {
			setExpandedInspection(null)
		} else {
			setExpandedInspection(ind)
		}
	}

	const mapDrag = () => {  }

	useEffect(() => {
		const loadMap = async () => {
			const mapData = await axios.post(`/api/properties/${param._id}/propertyMap`, {address: node.address})

			await initializeMapWithAccessToken(param, mapData.data.markerData, mapDrag, true)
		}

		loadMap()
	}, []);

	const addYears = (dateString, yearsToAdd) => {
		if (dateString === "-") {
			return "-"
		}

		const date = new Date(dateString);
		date.setFullYear(date.getFullYear() + yearsToAdd);

		return date
	};

	let colour;
	switch (node.package) {
		case 'Silver':
			colour = "#BDBBBC"
			break;
		case 'Gold':
			colour = "#AA8D4D"
			break;
		case 'Platinum':
			colour = "#8D8B8D"
			break;
	}

	return (
		<div className={styles.ind_view}>
			{(!isLoading && node)
				? 
					<>
						<div>
							<div className={styles.text_area}>
								<p className={`${styles.text_label} ${styles.small_text}`}>Address</p>
								<p className={`${styles.medium_text}`}>{node.address}</p>
							</div>

							<div className={styles.text_grid}>
								<div className={styles.text_area}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Package</p>

									{node.package !== "Without Service Package"
										?
						                    <div className={styles.package_flex}>
						                    	<i className={`fa-solid fa-medal`} style={{color: colour}}></i>
						                    	<p className={`${styles.medium_text}`}>{node.package}</p>
						                    </div>
						                : <p className={`${styles.medium_text} ${styles.edit_btn_margin}`}>{node.package}</p>
				                	}

									<button className={styles.toolbar_module} onClick={() => handleActions("open", "Package")}>
										<i className={`fa-solid fa-pen ${styles.medium_text}`}></i>
									</button>
								</div>

								<div className={styles.text_area}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Inspection Type</p>
									<p className={`${styles.medium_text}`}>{node.inspection_type}</p>

									{/* <button className={styles.toolbar_module} onClick={() => handleActions("open", "Inspection Type")}> */}
									{/* 	<i className={`fa-solid fa-pen ${styles.medium_text}`}></i> */}
									{/* </button> */}
								</div>

								<div className={styles.text_area}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Property Manager</p>
									<p className={`${styles.medium_text}`}>{node.property_manager}</p>
								</div>

								<div className={styles.text_area}>
									<p className={`${styles.text_label} ${styles.small_text}`}>Date Created</p>
									<p className={`${styles.medium_text}`}>{formatDate(node.date_created, false, false)}</p>
								</div>
							</div>

							<div className={styles.compliance_grid}>
								<div className={styles.compliance_checkbox}>
									<button className={styles.compliance_heading} onClick={() => handleExpansion('Electrical')}>
										<p className={`${styles.small_text}`}>Electrical Inspection</p>
										<div
											className={`${styles.compliance} ${styles[node.compliance[0].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}
											onMouseEnter={() => setHoveredCompliance("Electrical")}
											onMouseLeave={() => setHoveredCompliance(null)}
										>
											<i className="fa-solid fa-bolt"></i>

											{hoveredCompliance === "Electrical" &&
												<div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[node.compliance[0].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}>
													<div className={`${styles.hover_connection} ${styles[node.compliance[0].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}></div>
													<p className={styles.small_text}>{hoveredCompliance} - {node.compliance[0].compliance}</p>
												</div>
											}
										</div>

										{expandedInspection === "Electrical"
											? <i className="fa-solid fa-chevron-up"></i>
											: <i className="fa-solid fa-chevron-down"></i>
										}
									</button>

									{expandedInspection === "Electrical" &&
										<div className={styles.compliance_content}>
											<div className={styles.text_area}>
												<p className={`${styles.text_label} ${styles.small_text}`}>Check Date</p>
												<p className={`${styles.medium_text}`}>{formatDate(node.compliance[0].scheduledTo, false, true)}</p>
											</div>

											<div className={styles.text_area}>
												<p className={`${styles.text_label} ${styles.small_text}`}>Due Date</p>
												<p className={`${styles.medium_text}`}>{formatDate(addYears(node.compliance[0].scheduledTo, 2), false, false)}</p>
											</div>
										</div>
									}
								</div>

								<div className={styles.compliance_checkbox}>
									<button className={styles.compliance_heading} onClick={() => handleExpansion('Gas')}>
										<p className={`${styles.small_text}`}>Gas Inspection</p>
										<div
											className={`${styles.compliance} ${styles[node.compliance[1].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}
											onMouseEnter={() => setHoveredCompliance("Gas")}
											onMouseLeave={() => setHoveredCompliance(null)}
										>
											<i className="fa-solid fa-house-fire"></i>

											{hoveredCompliance === "Gas" &&
												<div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[node.compliance[1].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}>
													<div className={`${styles.hover_connection} ${styles[node.compliance[1].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}></div>
													<p className={styles.small_text}>{hoveredCompliance} - {node.compliance[1].compliance}</p>
												</div>
											}
										</div>

										{expandedInspection === "Gas"
											? <i className="fa-solid fa-chevron-up"></i>
											: <i className="fa-solid fa-chevron-down"></i>
										}
									</button>

									{expandedInspection === "Gas" &&
										<div className={styles.compliance_content}>
											<div className={styles.text_area}>
												<p className={`${styles.text_label} ${styles.small_text}`}>Check Date</p>
												<p className={`${styles.medium_text}`}>{formatDate(node.compliance[1].scheduledTo, false, true)}</p>
											</div>

											<div className={styles.text_area}>
												<p className={`${styles.text_label} ${styles.small_text}`}>Due Date</p>
												<p className={`${styles.medium_text}`}>{formatDate(addYears(node.compliance[1].scheduledTo, 2), false, false)}</p>
											</div>
										</div>
									}
								</div>

								<div className={styles.compliance_checkbox}>
									<button className={styles.compliance_heading} onClick={() => handleExpansion('Smoke')}>
										<p className={`${styles.small_text}`}>Smoke Inspection</p>
										<div
											className={`${styles.compliance} ${styles[node.compliance[2].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}
											onMouseEnter={() => setHoveredCompliance("Smoke")}
											onMouseLeave={() => setHoveredCompliance(null)}
										>
											<i className="fa-solid fa-fire"></i>

											{hoveredCompliance === "Smoke" &&
												<div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[node.compliance[2].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}>
													<div className={`${styles.hover_connection} ${styles[node.compliance[2].compliance.replaceAll(' ', '').replaceAll('-', '')]}`}></div>
													<p className={styles.small_text}>{hoveredCompliance} - {node.compliance[2].compliance}</p>
												</div>
											}
										</div>

										{expandedInspection === "Smoke"
											? <i className="fa-solid fa-chevron-up"></i>
											: <i className="fa-solid fa-chevron-down"></i>
										}
									</button>

									{expandedInspection === "Smoke" &&
										<div className={styles.compliance_content}>
											<div className={styles.text_area}>
												<p className={`${styles.text_label} ${styles.small_text}`}>Check Date</p>
												<p className={`${styles.medium_text}`}>{formatDate(node.compliance[2].scheduledTo, false, true)}</p>
											</div>

											<div className={styles.text_area}>
												<p className={`${styles.text_label} ${styles.small_text}`}>Due Date</p>
												<p className={`${styles.medium_text}`}>{formatDate(addYears(node.compliance[2].scheduledTo, 1), false, false)}</p>
											</div>
										</div>
									}
								</div>
							</div>
						</div>

						<div className={styles.map_container}>
							<div className={styles.map_view}>
								<div id="custom_map"></div>
							</div>
						</div>
					</>
				:
					<div className={styles.load_container}>
						<div className={styles.load}>
							<hr />
							<hr />
							<hr />
							<hr />
						</div>
					</div>
			}
		</div>
	)
}

const TableContent = ({ param, setActiveTabInner, activeTabInner, setIndActive, formatDate, node, activeTab, setActiveTab, handleActions, outerKey }) => {
	const [key, setKey] = useState(0);
	const tableDataRef = useRef(null);

	const [isLoading, setIsLoading] = useState(false)
	const [columns, setColumns] = useState(["Date Scheduled", "Date Completed", "Compliance"])
	const [gridLayout, setGridLayout] = useState("minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr)")
	const [nodes, setNodes] = useState([])
	const [search, setSearch] = useState("")

	const setTableDataHeight = () => {
		const tableContainer = document.querySelector('.table_container');
		const tableDataContainer = tableDataRef.current;

		if (tableContainer && tableDataContainer) {
	  		const tableContainerHeight = tableContainer.clientHeight;
	  		const siblingsHeight = Array.from(tableDataContainer.parentNode.children).filter(child => child !== tableDataContainer).reduce((acc, child) => acc + child.clientHeight, 0);

	  		tableDataContainer.style.height = `${tableContainerHeight - siblingsHeight - 60}px`;
		}
	};

	useEffect(() => {
		const handleLoad = async () => {
			setIsLoading(true)
			const url = `/api/hub/main/${param._id}/${activeTabInner.toLowerCase()}/${node._id}`;
			const role_token = localStorage.getItem('role_token');
			const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });

			setNodes(data.data.dataMapped)
			setIsLoading(false)
		}

		const handleResize = async () => {
			setTableDataHeight();
			window.addEventListener('resize', setTableDataHeight);

			return () => {
		  		window.removeEventListener('resize', setTableDataHeight);
			};
		}

		handleLoad()
		handleResize()
	}, [key, outerKey]);

	const handleTableTab = (control, ind) => {
		switch (control) {
			case 'tab':
				setNodes([])

				switch(ind) {
					case 'Inspections':
						setColumns(["Reference ID", "Date Scheduled", "Date Completed", "Compliance"])
						setGridLayout("minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr)")
						break;
					case 'Services':
						setColumns(["Reference ID", "Type", "Date Scheduled", "Date Completed"])
						setGridLayout("minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr)")
						break;
					case 'Roles':
						setColumns(["Role", "Name", "Email", "Phone"])
						setGridLayout("minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr)")
						break;
				}

				setActiveTabInner(ind)
				setKey((prevKey) => prevKey + 1);
				break;
		}
	}

	const handleSearch = (event) => {
		setSearch(event.target.value);
	};

	return (
		<div className={`table_container ${styles.table_container}`}>
			<div className={styles.table_nav}>
				<button className={styles.table_nav_item} onClick={() => handleTableTab('tab', "Inspections")} disabled={isLoading}>
					<i className={`fa-solid fa-house ${styles.medium_text} ${activeTabInner === "Inspections" && styles.nav_text_active}`}></i>
					<p className={`${styles.medium_text} ${styles.nav_text} ${activeTabInner === "Inspections" && styles.nav_text_active}`}>Inspections</p>
					{activeTabInner === "Inspections" && <div className={styles.table_nav_active}/>}
				</button>

				<button className={styles.table_nav_item} onClick={() => handleTableTab('tab', "Services")} disabled={isLoading}>
					<i className={`fa-solid fa-magnifying-glass-location ${styles.medium_text} ${activeTabInner === "Services" && styles.nav_text_active}`}></i>
					<p className={`${styles.medium_text} ${styles.nav_text} ${activeTabInner === "Services" && styles.nav_text_active}`}>Services</p>
					{activeTabInner === "Services" && <div className={styles.table_nav_active}/>}
				</button>

				<button className={styles.table_nav_item} onClick={() => handleTableTab('tab', "Roles")} disabled={isLoading}>
					<i className={`fa-solid fa-clipboard-list ${styles.medium_text} ${activeTabInner === "Roles" && styles.nav_text_active}`}></i>
					<p className={`${styles.medium_text} ${styles.nav_text} ${activeTabInner === "Roles" && styles.nav_text_active}`}>Roles</p>
					{activeTabInner === "Roles" && <div className={styles.table_nav_active}/>}
				</button>
			</div>


			<div className={`${styles.table_search} ${activeTabInner === "Roles" && styles.table_search_padding}`}>
				<div className={`${styles.search_container} ${activeTabInner === "Roles" && styles.search_container_withbtn}`}>
					<i className={`fa-solid fa-magnifying-glass ${styles.medium_text}`}></i>
					<input type="text" placeholder="Search for anything in this table..." className={styles.mid_text} value={search} onChange={handleSearch}/>

					{activeTabInner === "Roles" &&
						<button className={styles.table_add_btn} disabled={isLoading} onClick={() => handleActions("open", "Add Role")}><p className={styles.mid_text}>Add Role</p></button>
					}
				</div>
			</div>

			<div className={`table_data ${styles.table_data}`} ref={tableDataRef}>
				{(!isLoading && nodes)
					? <TableUtil { ... { nodes, columns, gridLayout, setIndActive, formatDate, search, activeTab, setActiveTab, activeTabInner, handleActions } }/>
					:
						<div className={styles.load_container}>
							<div className={styles.load}>
								<hr />
								<hr />
								<hr />
								<hr />
							</div>
						</div>
				}
			</div>
		</div>
	)
}


const IndProperty = ({ param, indActive, setIndActive, formatDate, activeTab, setActiveTab, stats }) => {
	const [outerKey, setOuterKey] = useState(0);
	const [isLoading, setIsLoading] = useState(false)
	const [node, setNode] = useState(null)
	const [activeTabInner, setActiveTabInner] = useState("Inspections")
	const [openPopup, setOpenPopup] = useState(null)
	const [customNode, setCustomNode] = useState(null)

	useEffect(() => {
		const handleLoad = async () => {
			setIsLoading(true)

			const url = `/api/hub/${param._id}/ind/${indActive}/properties`;
			const role_token = localStorage.getItem('role_token');
			const data = await axios.get(url, { headers: { Authorization: `Bearer ${role_token}` } });
			setNode(data.data.dataMapped)
			setIsLoading(false)
		}

		handleLoad()
	}, []);

  	const handleActions = (control, ind, val?) => {
  		switch (control) {
  			case 'open':
  				setOpenPopup(ind)

				if (val) {
					setCustomNode(val)
				}
  				break;
  			case 'close':
  				setOpenPopup(null)
  				setCustomNode(null)
  				break;
  		}
  	}


	return (
		<>
			<div className={openPopup && styles.hidden_container}>
				<ActionContent {... { handleActions, stats } }/>
			</div>

			<div className={`${styles.split_view} ${openPopup && styles.hidden_container}`}>
				{(!isLoading && node) 
					? <DetailsContent { ... { param, node, isLoading, formatDate, handleActions } }/>
					:
						<div className={styles.ind_view}>
							<div className={styles.load_container}>
								<div className={styles.load}>
									<hr />
									<hr />
									<hr />
									<hr />
								</div>
							</div>
						</div>
				}

				{(!isLoading && node) 
					? <TableContent { ... { param, setActiveTabInner, activeTabInner, setIndActive, formatDate, node, activeTab, setActiveTab, handleActions, outerKey } }/>
					:
						<div className={styles.ind_view}>
							<div className={styles.load_container}>
								<div className={styles.load}>
									<hr />
									<hr />
									<hr />
									<hr />
								</div>
							</div>
						</div>
				}
			</div>

			{(openPopup && openPopup === "Properties") && <PopUpProperty {... { param, handleActions } }/>}
			{(openPopup && openPopup === "Services") && <PopUpService {... { param, handleActions, node } }/>}
			{(openPopup && openPopup === "Beeping Alarm") && <PopUpBeepingAlarm {... { param, handleActions, node } }/>}

			{(openPopup && openPopup === "Package") && <PopUpPackage {... { param, handleActions, node } }/>}
			{(openPopup && openPopup === "Inspection Type") && <PopUpInspectionType {... { param, handleActions, node } }/>}

			{(openPopup && openPopup === "Change PM") && <PopUpChangePM {... { param, handleActions, setActiveTabInner, setOuterKey, node } }/>}
			{(openPopup && openPopup === "Edit Role") && <PopUpEditRole {... { param, handleActions, customNode, setActiveTabInner, setOuterKey } }/>}
			{(openPopup && openPopup === "Add Role") && <PopUpAddRole {... { param, handleActions, setActiveTabInner, setOuterKey, node } }/>}
		</>
	);
};

export default IndProperty;