import { useEffect, useState, useRef } from "react";
import styles from './utilstyles.module.css';

import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useSort } from "@table-library/react-table-library/sort";
import { useRowSelect } from "@table-library/react-table-library/select";
import { usePagination } from "@table-library/react-table-library/pagination";


const TableUtil = ({ nodes, columns, gridLayout, setIndActive, formatDate, search, activeTab=null, setActiveTab=null, activeTabInner=null, handleActions=null, handleNavigate=null, tableDataRef=null, nodesOG=null }) => {
  const [hoveredCompliance, setHoveredCompliance] = useState([]);
  const [isTableRendered, setIsTableRendered] = useState(false);
  let data = { nodes };


  const theme = useTheme([
    getTheme(),
    {
      HeaderRow: `
        background-color: #ffac1b;

        &:hover {
          background-color: #ffac1b !important
        }

        .th {
          font-size: 14px !important;
          color: white !important;
          font-weight: 700 !important;
          padding: 15px !important;
          z-index: 3;
        }

        .th span {
          text-decoration: none !important;
        }

        .th .resizer-handle {
          width: 2px !important;
          margin: 10px 0 !important;
        }
      `,
      Row: `
        .td {
          border: none !important;
          padding: 30px 15px !important;
          font-size: 16px !important;
        }

        &:hover .td {
          background-color: white !important
        }

        &:hover .td div,
        &:hover .td div a,
        &:hover .td div i:not(.compliance_row i) {
          color: #ffac1b !important
        }
      `,
    },
  ]);

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: <i className={`fa-solid fa-sort ${styles.sortIcon}`}></i>,
        iconUp: <i className={`fa-solid fa-sort-up ${styles.sortIcon}`}></i>,
        iconDown: <i className={`fa-solid fa-sort-down ${styles.sortIcon}`}></i>,
      },
        sortFns: {
          REFERENCE_ID: (array) => array.sort((a, b) => a.reference_id.localeCompare(b.reference_id)),
          ADDRESS: (array) => array.sort((a, b) => a.address.localeCompare(b.address)),
          PACKAGE: (array) => array.sort((a, b) => a.package.localeCompare(b.package)),
          PROPERTY_MANAGER: (array) => array.sort((a, b) => a.property_manager.localeCompare(b.property_manager)),
          INSPECTION_TYPE: (array) => array.sort((a, b) => a.inspection_type.localeCompare(b.inspection_type)),
          PHONE: (array) => array.sort((a, b) => a.phone.localeCompare(b.phone)),
          EMAIL: (array) => array.sort((a, b) => a.email.localeCompare(b.email)),
          VERIFIED: (array) => array.sort((a, b) => a.verified.localeCompare(b.verified)),
          FULL_NAME: (array) => array.sort((a, b) => a.full_name.localeCompare(b.full_name)),
          NAME: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),

          TYPE: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
          STATUS: (array) => array.sort((a, b) => a.status.localeCompare(b.status)),
          FILE_NAME: (array) => array.sort((a, b) => a.file_name.localeCompare(b.file_name)),
          DUE_DATE: (array) => array.sort((a, b) => new Date(a.due_date) - new Date(b.due_date)),
          DATE_SCHEDULED: (array) => array.sort((a, b) => new Date(a.date_scheduled) - new Date(b.date_scheduled)),
          DATE_COMPLETED: (array) => array.sort((a, b) => new Date(a.date_completed) - new Date(b.date_completed)),
          DATE_CREATED: (array) => array.sort((a, b) => new Date(a.date_created) - new Date(b.date_created)),
          LAST_LOGIN: (array) => array.sort((a, b) => new Date(a.last_login) - new Date(b.last_login))
        },
    }
  );

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const CUSTOM_COLUMNS = columns.map((col, index) => {
    let val = col.replaceAll(' ', '_').toLowerCase();

    return {
      label: col,
      renderCell: (item) => {
        switch (val) {
          case 'date_scheduled':
          case 'date_completed':
          case 'due_date':
            if (item[val] !== "-") {
              let returnVal;
              if (val === "due_date") {
                returnVal = formatDate(item[val], false, false)
              } else {
                returnVal = formatDate(item[val], false, true)
              }
              
              return (
                <>
                  <div>{returnVal.split(' @ ')[0]}</div>
                  <div style={{fontSize: '13px'}}>{returnVal.split(' @ ')[1]}</div>
                </>
              )
            } else {
              return item[val]
            }
            break;
          case 'date_created':
          case 'last_login':
          case 'date_issued':
            if (item[val] !== "-") {
              let returnVal = formatDate(item[val], false, false)

              return returnVal
            } else {
              return item[val]
            }
            break;
          case 'compliance':
            const arrangeCompliance = (idx) => {
              let receivedCompliance = item[val][idx].compliance
              let complianceFiltered = receivedCompliance ? receivedCompliance.replaceAll(' ', '').replaceAll('-', '') : "NotTested"

              return complianceFiltered
            }

            return (
              <div className={`compliance_row ${styles.compliance_row}`}>
                <div
                  className={`${styles.compliance_circle} ${styles[arrangeCompliance(0)]}`}
                  onMouseEnter={() => setHoveredCompliance([item.id, "Electrical"])}
                  onMouseLeave={() => setHoveredCompliance([])}
                >
                  <i className="fa-solid fa-bolt"></i>

                  {hoveredCompliance.length > 0 && (hoveredCompliance[0] === item.id && hoveredCompliance[1] === "Electrical") &&
                    <div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[arrangeCompliance(0)]}`}>
                      <div className={`${styles.hover_connection} ${styles[arrangeCompliance(0)]}`}></div>
                      <p className={styles.small_text}>Electrical - {item[val][0].compliance}</p>
                    </div>
                  }
                </div>
                <div
                  className={`${styles.compliance_circle} ${styles[arrangeCompliance(1)]}`}
                  onMouseEnter={() => setHoveredCompliance([item.id, "Gas"])}
                  onMouseLeave={() => setHoveredCompliance([])}
                >
                  <i className="fa-solid fa-house-fire"></i>

                  {hoveredCompliance.length > 0 && (hoveredCompliance[0] === item.id && hoveredCompliance[1] === "Gas") &&
                    <div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[arrangeCompliance(1)]}`}>
                      <div className={`${styles.hover_connection} ${styles[arrangeCompliance(1)]}`}></div>
                      <p className={styles.small_text}>Gas - {item[val][1].compliance}</p>
                    </div>
                  }
                </div>
                <div
                  className={`${styles.compliance_circle} ${styles[arrangeCompliance(2)]}`}
                  onMouseEnter={() => setHoveredCompliance([item.id, "Smoke"])}
                  onMouseLeave={() => setHoveredCompliance([])}
                >
                  <i className="fa-solid fa-fire"></i>

                  {hoveredCompliance.length > 0 && (hoveredCompliance[0] === item.id && hoveredCompliance[1] === "Smoke") &&
                    <div className={`${styles.hover_compliance} ${styles.hover_comp_insp} ${styles[arrangeCompliance(2)]}`}>
                      <div className={`${styles.hover_connection} ${styles[arrangeCompliance(2)]}`}></div>
                      <p className={styles.small_text}>Smoke - {item[val][2].compliance}</p>
                    </div>
                  }
                </div>
              </div>
            )
            break;
          case 'file_name':
            return (
              <div>
                <a href={item["link"]} target="_blank" style={{textDecoration: 'none', fontFamily: 'arial, sans-serif', color: 'rgb(117, 117, 117)'}}>{item[val]}</a>
              </div>
            )
            break;
          case 'name':
            return `${item['first_name']} ${item['last_name']}`
            break;
          case 'type':
            if (activeTab && activeTab === "Services") {
              return item["service_type"]
            } else if (activeTabInner && activeTabInner === "Services") {
              return item["service_type"]
            } else {
              return item[val]
            }
            break;
          case 'package':
            let colour;
            switch (item[val]) {
              case 'Silver':
                colour = "#BDBBBC"
                break;
              case 'Gold':
                colour = "#AA8D4D"
                break;
              case 'Platinum':
                colour = "#8D8B8D"
                break;
            }

            return (
              <>
                {item[val] !== "Without Service Package"
                  ?
                    <div className={styles.package_flex}>
                      <div>
                        <i className={`fa-solid fa-medal`} style={{color: colour}}></i>
                      </div>
                      <div className={styles.package_flex_inner}>{item[val]}</div>
                    </div>
                  : <div className={styles.package_flex_inner}>{item[val]}</div>
                }
              </>
            )
            break;
          default:
            return item[val]
            break;
        }
      },
      sort: { sortKey: val.toUpperCase() },
      resize: index !== columns.length - 1 ? true : false
    }
  })

  const handleRowClick = (action, state) => {
    let nodesToUse;

    if (nodesOG) {
      nodesToUse = [...nodesOG]
    } else {
      nodesToUse = [...nodes]
    }


    if (activeTab === "Users") {
      return false;
    } else if (activeTab === "Overdue Properties") {
      handleNavigate("nav", nodesToUse[state.id])
      return false;
    } else if (activeTab === "Pending Quotes") {
      handleNavigate("nav", nodesToUse[state.id])
      return false;
    } else if (activeTab === "Tenant Issues") {
      handleNavigate("nav", nodesToUse[state.id])
      return false;
    }

    if (activeTabInner === "Roles") {
      if (nodesToUse[state.id]['role'] === "Agent") {
        handleActions("open", "Change PM", nodesToUse[state.id])
      } else {
        handleActions("open", "Edit Role", nodesToUse[state.id])
      }

      deselectInd(state.id)
    } else {
      if (activeTab && (activeTab === "Properties") && setActiveTab) {
        setActiveTab(activeTabInner)
      }

      let indClicked = nodesToUse[state.id]
      setIndActive(indClicked._id)
    }
  }

  const select = useRowSelect(data, {
    onChange: handleRowClick,
  });

  const deselectInd = (id) => {
    select.fns.onToggleById(id)
  }

  function isValidDateString(dateString) {
    const date = new Date(dateString);
    return !isNaN(date) && date.toString() !== 'Invalid Date';
  }

  const colsMapped = columns.map(col => col.replaceAll(' ', '_').toLowerCase())

  if (data.nodes.length > 0) {
    data = {
      nodes: data.nodes.filter((item) => {
        return colsMapped.some((col) => {
          if (item[col]) {
            if (Array.isArray(item[col])) {
            } else if (isValidDateString(item[col])) {
              return formatDate(item[col], false, true).toLowerCase().includes(search.toLowerCase());  
            } else {
              return item[col].toLowerCase().includes(search.toLowerCase());  
            }
          } else {
            if (col === "type") {
              return item["service_type"].toLowerCase().includes(search.toLowerCase());    
            }
          }
        });
      }),
    };
  }




  useEffect(() => {
    setIsTableRendered(true);
  }, []);

  useEffect(() => {
    if (isTableRendered) {
      const complianceRows = document.querySelectorAll('.compliance_row');

      complianceRows.forEach(complianceRow => {
        const parentDiv = complianceRow.parentElement;
        parentDiv.style.overflow = 'visible';
      });
    }
  }, [isTableRendered]);


  return (
    <>
      {nodes.length > 0
        ?
          <>
            <style>
              {tableDataRef
                ?
                  <>
                    {`
                      .${tableDataRef} .table {
                        --data-table-library_grid-template-columns: ${gridLayout};
                      }
                    `}
                  </>
                :
                  <>
                    {`
                      .table {
                        --data-table-library_grid-template-columns: ${gridLayout};
                      }
                    `}
                  </>
              }
            </style>
            
            <CompactTable
              columns={CUSTOM_COLUMNS}
              data={data}
              theme={theme}
              sort={sort}
              select={(activeTabInner === "Reports" || activeTab === "Users") ? false : select}
              layout={{ custom: true, fixedHeader: true }}
            />
          </>
        : 
          <div className={styles.no_data_container}>
            <p className={styles.large_text}>There is no data to be displayed</p>
          </div>
      }
    </>
  );
};

export default TableUtil;