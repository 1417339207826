import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from './login.module.css';

import { ReactComponent as Logo } from "../../images/chatbot_logo.svg";
import bg from "../../images/bg.svg";
import mylo_banner from "../../images/mylo_banner.svg";


const ForgotPassword = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [validationError, setValidationError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const url = `/api/users/forgot-password`;


  const handleChange = ({ currentTarget: input }) => {
      setUserData({ ...userData, [input.name]: input.value });
  };


  const controlPanel = async (direction) => {
    switch (direction) {
      case 'submit':
        try {
          // validation
          if (!(userData.hasOwnProperty('email')) || userData['email'] == '') {
            setValidationError(`Please enter an email.`)
            return false;
          } else {
            if (isValidEmail(userData['email']) !== true) {
              setValidationError("Please enter a valid email.")
              return false;
            }
          }

          setValidationError(null)
          let postData = await axios.post(url, userData)
          setSuccessMsg(postData.data.message)
          // navigate(`/login`)
        } catch (error) {
          console.log(error)
        }

        setValidationError(null)
        break;
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  

  const redirectLogin = () => {
    navigate(`/login`)
  }


  return (
    <div className={styles.outer_container}>
      <div className={styles.banner_container}>
        <img src={bg} className={styles.bg}/>

        <div className={styles.banner_inner}>
          <img src={mylo_banner} className={styles.mylo_banner}/>
        </div>
      </div>

      <div className={styles.content_container}>
        <Logo className={styles.logo_main}/>
        <h1>Forgot password!</h1>
        <p className={styles.sub_title}>If we find your account, you will receive an email.</p>


        <div className={styles.form_container}>
          {validationError &&
            <div className={`${styles.login_msg} ${styles.error_msg}`}>
              <p>{validationError}</p>
            </div>
          }

          {successMsg &&
            <div className={`${styles.login_msg}`}>
              <p>{successMsg}</p>
            </div>
          }

          <div className={styles.input_container}>
            <p>Email</p>
            <input
              type="text"
              name="email"
              onChange={handleChange}
              value={userData.email}
              required
            />
          </div>
        </div>

        <div className={styles.form_footer}>
          {!successMsg &&
            <button className={styles.form_btn} onClick={() => controlPanel('submit')}>
              <p>Submit</p>
            </button>
          }

          <div className={styles.register_container}>
            <button onClick={redirectLogin}><p>Back to login!</p></button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ForgotPassword;
