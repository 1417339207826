import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import axios from "axios";

import mylologo from '../../../images/mylologo.svg'
import styles from "./AdminUsers.module.css"
import PassKey from './PassKey';
import UserStatistics from './UserStatistics';



const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={`${styles.sidebar_account}`}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/admin/home/${param._id}`}>
						<div className={`${styles.sidebar_box} ${styles.sidebar_active}`}>
							<i className={`fa-solid fa-house ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/users/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-users ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/technicians/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-screwdriver-wrench ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/payments/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/admin/map/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-map ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link className={styles.disabled_link}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gear ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

const DashboardPage = ({ param, navType, handlePasskey, isCapturingKey }) => {
	return (
		<div className={`${styles.page_container}`}>
			<div className={`${styles.sites_container_map} ${(isCapturingKey) && styles.hidden_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Home' && styles.sitenav_active}`}><p>Home</p></button>
					</div>
				</div>

				<div className={styles.homeContainer}>
					<div className={styles.homeLayout}>
						<a className={styles.homeItem} href={`/admin/users/${param._id}`}>
							<div className={styles.homeIcon}><i className={`fa-solid fa-users`} /></div>
							<h1>Users</h1>
						</a>
						<a className={styles.homeItem} href={`/admin/technicians/${param._id}`}>
							<div className={styles.homeIcon}><i className={`fa-solid fa-screwdriver-wrench`} /></div>
							<h1>Tradies</h1>
						</a>
						<a className={styles.homeItem} href={`/admin/payments/${param._id}`}>
							<div className={styles.homeIcon}><i className={`fa-solid fa-file-invoice-dollar`} /></div>
							<h1>Square</h1>
						</a>
						<a className={styles.homeItem} href={`/admin/map/${param._id}`}>
							<div className={styles.homeIcon}><i className={`fa-solid fa-map`} /></div>
							<h1>Map</h1>
						</a>
						<a className={`${styles.homeItem} ${styles.homeItemDisabled}`}>
							<div className={styles.homeIcon}><i className={`fa-solid fa-gear`} /></div>
							<h1>Settings</h1>
						</a>
						<a className={`${styles.homeItem} ${styles.homeItemDisabled}`} style={{gridColumnStart: '2'}}>
							<div className={styles.homeIcon}><i className={`fa-solid fa-user`} /></div>
							<h1>Profile</h1>
						</a>
						<a className={`${styles.homeItem} ${styles.homeItemDisabled}`}>
							<div className={styles.homeIcon}><i className={`fa-solid fa-people-group`} /></div>
							<h1>Team</h1>
						</a>
						<a className={`${styles.homeItem}`} onClick={() => handlePasskey('toggle')}>
							<div className={styles.homeIcon}><i className={`fa-solid fa-chart-pie`} /></div>
							<h1>Statistics</h1>
						</a>

					</div>
				</div>				
			</div>
		</div>
	)
}


const Home = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [UserData, setUserData] = useState({});
	const [isLoading, setIsLoading] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(null)
	const [authStatusLoading, setIsAuthStatusLoading] = useState(null)
	const [navType, setNavType] = useState('Home');
	const [validationError, setValidationError] = useState(null)
	const [isCapturingKey, setIsCapturingKey] = useState(false)
	const [passKey, setPassKey] = useState(null)
	const [passKeySent, setPassKeySent] = useState(false)
	const [isConfirmed, setIsConfirmed] = useState(false)
	const [statisticsData, setStatisticsData] = useState(null)
	const [weekOffset, setWeekOffset] = useState(0)
	const [currentDateSelection, setCurrentDateSelection] = useState(getCurrentWeekDays(weekOffset))

	const navigate = useNavigate();
	const param = useParams();



	const handlePasskey = async (control, input) => {
		switch (control) {
			case 'toggle':
				setIsCapturingKey(!isCapturingKey)
				break;
			case 'set':
				setPassKey(input.target.value)
				break;
			case 'send':
				setPassKeySent(true)
				await axios.post(`/api/admin/send_passkey/${param._id}`, {})
				break;
			case 'submit':
				const confirmPass = await axios.post(`/api/admin/check_passkey/${param._id}`, {code: passKey})
				if (confirmPass.data) {
					setIsCapturingKey(false)
					setValidationError(null)
					setPassKey(null)
					setPassKeySent(false)

					setIsLoading(true)
					setIsConfirmed(true)
					setIsCapturingKey(true)

					const statsData = await axios.post(`/api/admin/stats_data/${param._id}`, {weekSet: currentDateSelection})
					setStatisticsData(statsData.data)

					setIsLoading(false)
				} else {
					setValidationError("The code you entered was incorrect.")
					return false
				}
				break;
			case 'close_stats':
				const confirmClose = window.confirm("Are you sure you would like to exit the statistics module?")

				if (confirmClose) {
					setIsCapturingKey(false)
					setPassKey(null)
					setPassKeySent(false)
					setIsConfirmed(false)
					setStatisticsData(null)
					setWeekOffset(0)
					setCurrentDateSelection(getCurrentWeekDays(0))
				}

				break;
		}
	}

	const handleDates = async (control) => {
		switch (control) {
			case 'prev':
		        let newOffsetP = weekOffset - 1;
		        setWeekOffset(newOffsetP);
		        setCurrentDateSelection(getCurrentWeekDays(newOffsetP));
		        setIsLoading(true)

				let newStatsDataB = await axios.post(`/api/admin/stats_data/${param._id}`, {weekSet: getCurrentWeekDays(newOffsetP)})
				setStatisticsData(newStatsDataB.data)

		        setIsLoading(false)
				break;
			case 'next':
        		let newOffsetN = weekOffset + 1;
        		setWeekOffset(newOffsetN);
        		setCurrentDateSelection(getCurrentWeekDays(newOffsetN));
        		setIsLoading(true)

				let newStatsDataF = await axios.post(`/api/admin/stats_data/${param._id}`, {weekSet: getCurrentWeekDays(newOffsetN)})
				setStatisticsData(newStatsDataF.data)

        		setIsLoading(false)
				break;
		}
	}

	function getCurrentWeekDays(weekOffset = 0) {
	    const today = new Date(); 
	    const currentDay = today.getDay();
	    const distanceToMonday = currentDay === 0 ? -6 : 1 - currentDay; // Distance to the last Monday
	    const distanceToFriday = 5 - currentDay; // Distance to the upcoming Friday

	    const monday = new Date(today);
	    monday.setDate(monday.getDate() + distanceToMonday + 7 * weekOffset); // Adjust for week offset

	    const friday = new Date(today);
	    friday.setDate(friday.getDate() + distanceToFriday + 7 * weekOffset); // Adjust for week offset

	    return [monday, friday];
	}

	const formatDate = (dateString, showDay) => {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const dateObj = new Date(dateString);
		dateObj.setHours(0, 0, 0, 0);

		const year = dateObj.getFullYear();
		const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
		const day = ('0' + dateObj.getDate()).slice(-2);

		const currentDayNumber = dateObj.getDay();


		if (showDay) {
			return daysOfWeek[currentDayNumber]
		} else {
			return `${day}-${month}-${year}`;
		}
	};

	return (
		<>	
			<Offline>
				<div className="offline_container">
					<div className="offline_inner animate__animated animate__slideInDown">
						<i className="fa-solid fa-heart-crack"></i>
						<div className="offline_content">
							<p className="offline_title">You are offline</p>
							<p className="offline_subtitle">Please check your internet connect.</p>
						</div>
					</div>
				</div>
			</Offline>


		  	<div className={styles.main_container}>
				<NavigationBar {...{ param, handleLogout }} />
				<DashboardPage {...{ param, navType, handlePasskey, isCapturingKey }} />

				{(isCapturingKey && !isConfirmed) && <PassKey {...{ handlePasskey, validationError, passKey, passKeySent }} />}
				{(isCapturingKey && isConfirmed) && <UserStatistics {...{ statisticsData, handleDates, currentDateSelection, formatDate, weekOffset, isLoading, handlePasskey }} />}
			</div>
		</>
	);
};


export default Home;
