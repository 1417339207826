import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./styles.css";

import { ReactComponent as Logo } from "../../images/mylologo.svg";
import snippet_banner from "../../images/snippet_banner.svg";
import commerical from "../../images/commerical.jpg";
import residential from "../../images/residential.jpg";
import c_and_r from "../../images/c_and_r.jpg";
import success from "../../images/success.svg";

const ReVerify = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [userData, setUserData] = useState({});
    const [validationError, setValidationError] = useState(null);
    const [falseError, setFalseError] = useState(false);

    const param = useParams();
    const url = `/api/users/${param._id}/reverify/${param.token}`;

    useEffect(() => {
      const userData = async () => {
        try {
          const data = await axios.get(url);
          setData(data.data)

          setValidationError(null)
          setFalseError(false)
        } catch (error) {
          setFalseError(true)
          setValidationError(error.response.data.message)
        }
      };
      userData();
    }, []);

    const navigateLogin = () => {
      navigate(`/login`)
    }


    return (
        <div className="bg">
            <div className="wrapper centered">
                <div className="outer">
                    <div className="login_panel">

                      <div className="logoContainer">
                        <Logo className="logo"/>
                      </div>

                      <div className="login_container">
                        <div className="login_form">
                          <div className="login_container">
                            {validationError 
                                ? <div className="error-box"><p>{validationError}</p></div>
                                : <img src={success} style={{height: '250px', width: '250px'}}/>
                            }
                            
                            <button type="button" className="submit_btn" onClick={() => navigateLogin()} style={{marginTop: '15px'}}>Login</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="graphic_panel">
                        <h1>Simpler, smarter, better, MYLO.</h1>
                        <img src={snippet_banner} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReVerify;
