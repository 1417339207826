import React from "react";

import "./LearningOptions.css";

const LearningOptions = (props) => {
  const options = [
    {
      text: "Dashboard",
      handler: props.actionProvider.handleDashboardList,
      id: 1,
    },
    { 
      text: "Properties",
      handler: props.actionProvider.handlePropertiesList,
      id: 2
    },
    { 
      text: "Inspections",
      handler: props.actionProvider.handleInspectionsList,
      id: 3
    },
    { 
      text: "Services",
      handler: props.actionProvider.handleServicesList,
      id: 4
    }
  ];

  const optionsMarkup = options.map((option) => (
    <button
      className="learning-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default LearningOptions;