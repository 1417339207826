import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import fstyles from "./Forbiddenstyles.module.css"
import { ReactComponent as Logo } from '../../images/mylologo.svg';

const NotFound = () => {
	const param = useParams();

	return (
		<>
		<Link className={fstyles.logo} to={`/login`}>
			<Logo></Logo>
		</Link>

		<div className={fstyles.hover}>
		  <div className={fstyles.background}>
		    <div className={fstyles.door}><p className={fstyles.forbidden_1}>Page not found</p></div>
		    <div className={fstyles.rug}></div>
		  </div>
		  <div className={fstyles.foreground}>
		    <div className={fstyles.bouncer}>
		      <div className={fstyles.head}>
		        <div className={fstyles.neck} />
		        <div className={`${fstyles.eye} ${fstyles.left}`} />
		        <div className={`${fstyles.eye} ${fstyles.right}`} />
		        <div className={fstyles.ear} />
		      </div>
		      <div className={fstyles.body} />
		      <div className={fstyles.arm} />
		    </div>
		    <div className={fstyles.poles}>
		      <div className={`${fstyles.pole} ${fstyles.left}`} />
		      <div className={`${fstyles.pole} ${fstyles.right}`} />
		      <div className={fstyles.rope} />
		    </div>
		  </div>
		</div>

		</>

	)
};

export default NotFound;