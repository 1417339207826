import { Editor } from '@tinymce/tinymce-react';
import styles from './dashboardstyles.module.css';

const TermsConditions = ({ validationError, handleTermsConditions, editorRef, currentTermsConditions }) => {
	return (
		<div className={`${styles.popup_container}`}>

			<button className={styles.closePopup} onClick={() => handleTermsConditions('toggle')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Terms & Conditions</h1>
			<p className={styles.smaller_font}>Use this module to update the terms & conditions.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Text</label>
					<Editor
						apiKey='f4ggxn5t07efoxs57sdhvuu1fa3q5pzqdochtvixing4d87h'
						onInit={(evt, editor) => editorRef.current = editor}
						initialValue={`${currentTermsConditions}`}
						init={{
							height: 500,
							width: '100%',
							menubar: false,
							plugins: [
					            "advlist",
					            "autolink",
					            "link",
					            "image",
					            "lists",
					            "charmap",
					            "preview",
					            "anchor",
					            "pagebreak",
					            "searchreplace",
					            "wordcount",
					            "visualblocks",
					            "code",
					            "fullscreen",
					            "insertdatetime",
					            "media",
					            "table",
					            "emoticons",
					            "template",
					            "help",
							],
							toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | fullscreen | ",
							menu: {
							    format: { title: "Format", items: "bold italic underline strikethrough superscript subscript codeformat | blocks fontsize align | removeformat" },
							    insert: { title: "Insert", items: "image link | inserttable charmap emoticons | insertdatetime" },
							},
							menubar: "edit view insert format tools table help",
					}}
					/>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleTermsConditions('submit')}>Submit</button>
	       		</div>
       		</div>


		</div>
	)
}

export default TermsConditions;