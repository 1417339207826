import React from "react";

import "./LearningOptions.css";

const ServicesOptions = (props) => {
  const options = [
    {
      text: "How do I order a service?",
      handler: () => props.actionProvider.handleServicesQuestions(1),
      id: 1,
    },
    {
      text: "How do I view more details about each service request?",
      handler: () => props.actionProvider.handleServicesQuestions(2),
      id: 2,
    },
    {
      text: "How do I cancel a service?",
      handler: () => props.actionProvider.handleServicesQuestions(3),
      id: 3,
    },
    {
      text: "How do I view service files/reports?",
      handler: () => props.actionProvider.handleServicesQuestions(4),
      id: 4,
    }
  ];

  const optionsMarkup = options.map((option) => (
    <button
      className="learning-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default ServicesOptions;